/// <reference path="../../shared-data-entry/picklist-item.service.ts" />
/// <reference path="../../user/add-case/case-fields.service.ts" />
import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { UserManagementService } from "../../user/manage-users/user-management.service";
import { PicklistItemService } from "../../shared-data-entry/picklist-item.service";
import { PicklistItemResponse } from '../../../shared/picklist-item-response';
import { PicklistItemModel } from '../../../shared/picklist-item-model';
import { DropdownItemModel } from '../../../shared/dropdown-item-model';
import { Directive, Input } from '@angular/core';
import { CaseFieldsService } from '../../user/add-case/case-fields.service';


@Component({
  selector: "app-user-info-modal",
  templateUrl: "./user-info-modal.component.html",
  styleUrls: ["./user-info-modal.component.scss"],
   host: { class: "host" }
})
export class UserInfoModalComponent implements OnInit {
  @ViewChild('close') close:ElementRef;
  users;
  public picklistItemService: PicklistItemService;
  public fields: CaseFieldsService;
  name = "Case Identification";
  hospitals = [];
 
  constructor(users: UserManagementService, fields: CaseFieldsService, _picklistItemService: PicklistItemService) {
    this.users = users;
    this.fields = fields;
    this.picklistItemService = _picklistItemService;
  }

  closeModal() {
    this.close.nativeElement.click();
  }
  save() {
    console.log("inside model component");
    this.users.save();  
    this.closeModal();
  }


 
  onChange(registryValue) {
    this.users.populatedropdownonregistrychange(registryValue,1);    
  }
  

  ngOnInit() {}
}
