import { Component, OnInit,ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";

@Component({
  selector: "app-c3po-iqic",
  templateUrl: "./c3po-iqic.component.html",
  styleUrls: ["./c3po-iqic.component.scss"],
  host: { 'class': "host" }
})
export class C3poIQICComponent  implements OnInit {
  @ViewChild('aboutform')
  form: NgForm;
  constructor() {
  }

  ngOnInit() {}
}
