import { Component, OnInit ,ViewChild } from '@angular/core';
import { LoginService } from '../login.service';

import { NgForm } from "@angular/forms";



@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent  implements OnInit {
  @ViewChild('usermenuform')
  form: NgForm;
  user: LoginService;
  addCaseLink: string;
  constructor(user: LoginService) {
  
    this.user = user;

    this.addCaseLink = "/user-portal/add-case";

    if (this.user.IsIqicUser()) {
      this.addCaseLink = "/user-portal/add-case/iqic";
    }


  }

  ngOnInit() {
    this.user.logIn();
  }

}
