export class DropdownData {
    selected;
    toSelect;

    constructor(selected, toSelect) {
        this.selected = selected;
        this.toSelect = toSelect;

    }

    

    selectAll() {
        this.selected = this.toSelect;
 

    }

    unSelectAll() {
        this.selected = [];
  
    }
}
