import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Observable, pipe } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { ProcedurePicklistResponse } from '../../shared/procedure-picklist-response';
import { ProcedurePicklistModel } from '../../shared/procedure-picklist-model';
import { DropdownItemModel } from '../../shared/dropdown-item-model';
import { CONFIG } from '../../shared/config';

let apiUrl = CONFIG.baseUrls.api;
@Injectable()
export class ProcedurePicklistService {
  private http: Http = null;

  public procedureList: ProcedurePicklistModel[];
  public dropDownProcedureList: DropdownItemModel[];

  constructor(_http: Http) {
    this.http = _http;
  }

  getProcedurePicklist() {
    let headers = new Headers({
      //'Content-Type': 'application/x-www-form-urlencoded'
    });
    let options = new RequestOptions({ headers: headers });
    return this.http.get(apiUrl + "picklist/getprocedureslist", options)
               .pipe(map((response: Response) => <ProcedurePicklistResponse>response.json()));  
  }

  mapToDropDown() {
    let ids = [];
    this.dropDownProcedureList = [];

    for (let entry of this.procedureList) {
      let needsDetails = entry.ProcedureNeedsDetails === "1";

      if (ids.indexOf(entry.ParentProcedureId) > -1) {
        ids.push(entry.ProcedureId);

        let newChildItem = new DropdownItemModel;
        newChildItem.id = entry.ProcedureId;
        newChildItem.name = entry.ProcedureTextToShow;
        newChildItem.code = entry.ProcedureCode;
        newChildItem.needsDetails = needsDetails;
        newChildItem.children = [];

        let parentItem = this.dropDownProcedureList.find(x => x.id === entry.ParentProcedureId);
        if (parentItem != null) {
          if (parentItem.children == null) {
            parentItem.children = [];
          }

          if (needsDetails) {
            parentItem.name = parentItem.name + " [add details]";
            parentItem.needsDetails = needsDetails;
            parentItem.children = [];
          } else {
            parentItem.children.push(newChildItem);
          }
        } else {
          let found = false;
          while (found === false) {
            for (let item of this.dropDownProcedureList) {
              let childParentItem = item.children.find(x => x.id === entry.ParentProcedureId);
              if (childParentItem != null) {
                if (childParentItem.children == null) {
                  childParentItem.children = [];
                }

                if (needsDetails) {
                  childParentItem.name = childParentItem.name + " [add details]";
                  childParentItem.needsDetails = needsDetails;
                  childParentItem.children = [];
                } else {
                  childParentItem.children.push(newChildItem);
                }
                
                found = true;
                break;
              }
            }
          }          
        }       
      } else {
        ids.push(entry.ParentProcedureId);
        ids.push(entry.ProcedureId);

        if (needsDetails) {
          let newItem = new DropdownItemModel;
          newItem.id = entry.ParentProcedureId;
          newItem.name = entry.ParentProcedureTextToShow + " [add details]";
          newItem.code = entry.ProcedureCode;
          newItem.needsDetails = needsDetails;
          newItem.children = [];

          this.dropDownProcedureList.push(newItem);
        } else {
          let newParentItem = new DropdownItemModel;
          newParentItem.id = entry.ParentProcedureId;
          newParentItem.name = entry.ParentProcedureTextToShow;
          newParentItem.children = [];

          let newChildItem = new DropdownItemModel;
          newChildItem.id = entry.ProcedureId;
          newChildItem.name = entry.ProcedureTextToShow;
          newChildItem.code = entry.ProcedureCode;
          newChildItem.needsDetails = needsDetails;
          newChildItem.children = [];

          newParentItem.children.push(newChildItem);
          this.dropDownProcedureList.push(newParentItem);
        }        
      }
    }

  }
}
