export class MailRequestInfoModel {
  subject: string;
  body: string;
  contactemail: string;
  contactname: string;
  contactPhone: string;
  mailsendto: string;

  constructor() {
    this.subject = "";
    this.body = "";
    this.contactemail = "";
    this.contactname = "";
    this.contactPhone = "";
    this.mailsendto = "";
  }
}
