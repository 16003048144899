import { Component, AfterViewChecked,HostListener,ViewChild } from "@angular/core";
import { CaseFieldsService } from "../case-fields.service";
import { LoginService } from "../../../user/login.service";
import { ComponentCanDeactivate } from '../../../../CanDeactivateGaurd';
import { Router, ActivatedRoute, ParamMap, NavigationEnd, NavigationStart, RouterEvent } from "@angular/router";
import { filter, map, mergeMap, tap } from "rxjs/operators";
import { Observable } from 'rxjs';
import { NgForm, FormGroup } from "@angular/forms";
declare var jquery: any;
declare var $: any;

@Component({
  selector: "app-procedure-characteristics",
  templateUrl: "./procedure-characteristics.component.html",
  styleUrls: ["./procedure-characteristics.component.scss"],
  host: { class: "host" }
})
export class ProcedureCharacteristicsComponent implements AfterViewChecked, ComponentCanDeactivate {
  @ViewChild('form') form: NgForm;
  typeFluoroPerformedClicked = false;
  public inTime = null;
  public outTime = null;
  public duration;
  loading: boolean = false;
  fields:CaseFieldsService;
  name = "Procedure Characteristics";
  user: LoginService;

  constructor(private router: Router,fields: CaseFieldsService, user: LoginService) {
    router.events
      .pipe(filter(e => e instanceof NavigationStart))
      .subscribe((e: NavigationStart) => {
        localStorage.setItem('editcase', e.url);
      });
    this.fields = fields;
    this.user = user;
    this.calcFluoroTime();
    this.calculateContractVolumePerKg();

    var tfp = this.fields.getField(this.name, 'Type Fluoro Performed');
    this.typeFluoroPerformedClicked = tfp != null;
    this.calcDAP();
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {    
    if (this.form.dirty)
      localStorage.setItem('dirty', 'true');


    return !this.form.dirty;
  }

  changeTime(event, picker) {
    return picker._selected;
  }

  typeFluoroPerformedClickedEvent(type: boolean) {
    this.fields.setValue(this.name, 'Type Fluoro Performed', type);
    this.typeFluoroPerformedClicked = true;
  }

  public calcFluoroTime() {
    let ft = this.fields.getValue(this.name, "Total Fluoro Time");
    if (ft != null) {
      ft = +ft;
      this.fields.setValue(this.name, "Total Fluoro Time", ft.toFixed(2));
    }
  }

  public calcPreCath() {
    let ft = this.fields.getValue(this.name, "Pre Cath Hemoglobin");
    ft = Math.round(ft * 10) / 10
  
    this.fields.setValue(this.name, "Pre Cath Hemoglobin", ft);
  }

 

  public calcDAP() {
    let dap = this.fields.getValue(this.name, "Total DAP");
    if (dap!=null) {
      dap = +dap;
      this.fields.setValue(this.name, "Total DAP", dap.toFixed(0));
    }

  }

  setInTime(picker, input) {
    this.fields.getField(this.name, "Sheath-in Time").value = picker._selected;
    this.inTime = picker._selected;

    this.setDuration();

    let val = picker._selected;
    input.value = this.timeFormat(this.format(val.getHours()), this.format(val.getMinutes()));
  }

  setOutTime(picker, input) {
    this.fields.getField(this.name, "Sheath-out Time").value = picker._selected;
    this.outTime = picker._selected;

    this.setDuration();

    let val = picker._selected;
    input.value = this.timeFormat(this.format(val.getHours()), this.format(val.getMinutes()));
  }

  setDuration() {
    this.inTime = this.fields.getField(this.name, "Sheath-in Time").value;
    this.outTime = this.fields.getField(this.name, "Sheath-out Time").value;
    
    if (this.inTime !== null && this.outTime !== null) {
      let inTimeHours = this.inTime.getHours();
      let inTimeMinutes = this.inTime.getMinutes();
      let inTimeTotal = (inTimeHours * 60) + inTimeMinutes;

      let outTimeHours = this.outTime.getHours();
      let outTimeMinutes = this.outTime.getMinutes();
      let outTimeTotal = (outTimeHours * 60) + outTimeMinutes;

      let totalMinutes = 0;
      //if inTime is larger than outTime, assume outTime is the next day, so add 1440 minutes to outTime
      if (inTimeTotal > outTimeTotal) {
        outTimeTotal = outTimeTotal + 1440;
      }
      totalMinutes = (outTimeTotal - inTimeTotal);

      let hours = Math.floor(totalMinutes / 60);
      let minutes = totalMinutes % 60;
      let hourStr = this.format(hours);
      let minuteStr = this.format(minutes);
      this.duration = this.timeFormat(hourStr, minuteStr);
    }

    this.fields.getField(this.name, "Duration").value = this.duration;
  }

  format(val) {
    return (val < 10) ? "0" + val : val;
  }
  timeFormat(hr, min) {
    return hr + ':' + min;
  }
  ngAfterViewChecked() {
    this.setTimeFromInput(this.fields.getField(this.name, "Sheath-in Time").value, "#sheathin");
    this.setTimeFromInput(this.fields.getField(this.name, "Sheath-out Time").value, "#sheathout");
  }

  setTimeFromInput(d: Date, inputString: string) {
    if (d != null) {
      let hours = d.getHours();
      let minutes = d.getMinutes();

      let hourStr = this.format(hours);
      let minuteStr = this.format(minutes);
      let timeStr = this.timeFormat(hourStr, minuteStr);

      $(inputString).val(timeStr);
    }
  }

  calculateContractVolumePerKg() {
   
    let tcv = this.fields.getValue(this.name, 'Total Contrast Volume');
    this.fields.setValue(this.name, 'Total Contrast Volume', Math.round(tcv));
    let kg = this.fields.getValue('Clinical Characteristics', 'Weight');

    if (kg > 0) {
      this.fields.setValue(this.name, 'Total Contrast Volume Per Kg', this.round((Math.round(tcv) / kg), 3));
    } else {
      this.fields.setValue(this.name, 'Total Contrast Volume Per Kg', null);
    }
  }

  ngOnInit() {
    
  }

  round(number, precision) {
    var factor = Math.pow(10, precision);
    var tempNumber = number * factor;
    var roundedTempNumber = Math.round(tempNumber);
    return roundedTempNumber / factor;
  }
}
