import { Component, AfterViewChecked,ViewChild,HostListener } from "@angular/core";
import { CaseFieldsService } from "../case-fields.service";

import { PicklistItemService } from "../../../shared-data-entry/picklist-item.service";
import { PicklistItemResponse } from "../../../../shared/picklist-item-response";
import { PicklistItemModel } from "../../../../shared/picklist-item-model";
import { LoginService } from "../../../user/login.service";
import { ComponentCanDeactivate } from '../../../../CanDeactivateGaurd';
import { Router, ActivatedRoute, ParamMap, NavigationEnd, NavigationStart, RouterEvent } from "@angular/router";
import { filter, map, mergeMap, tap } from "rxjs/operators";
import { Observable } from 'rxjs';
import { NgForm, FormGroup } from "@angular/forms";
declare var jquery: any;
declare var $: any;

@Component({
  selector: "app-procedural-efficacy",
  templateUrl: "./procedural-efficacy.component.html",
  styleUrls: ["./procedural-efficacy.component.scss"],
  host: { class: "host" }
})
export class ProceduralEfficacyComponent implements ComponentCanDeactivate {
  @ViewChild('form') form: NgForm;
  private procs: Array<string> = ["Pre-Procedure",  "Post-Procedure"];
  public inTime = null;
  public outTime = null;
  public duration;
  public isAortic = false;
  public picklistItemService: PicklistItemService;
  public user: LoginService;
  public avProcOptions = [];
  public pvProcOptions = [];
  public cpProcOptions = [];
  public pdaProcOptions = [];
 
  public AorticRegurgitationOptions = [];
  public SystolicAVGradientOptions = [];
  public VentricleDyfunctionOptions = []; 
  public PulmonaryRegurgitationOptions = [];  
  public TricuspidRegurgitationOptions = [];
  public SystolicGradientCoarctationOptions = [];
  public 
  pvProc = [];
  selectedPvProc = [];
  avProc = [];
  selectedAvProc = [];
  cpProc = [];
  selectedCpProc = [];
  pdaProc = [];
  selectedPdaProc = []; 
  avtypes = [];
  pvtypes = [];
  cptypes = [];
  asdProcOptions = [];
  asdProc = [];
  selectedAsdProc = [];
  pdaShuntProcOptions = [];
  pdaShuntProc = [];
  selectedPdaShuntProc = [];
  deviceRemoval = [];
  selectedDeviceRemoval = [];
  deviceRemovalOptions = [];
  seccondDeviceRemoval = [];
  selectedSeccondDeviceRemoval = [];
  seccondDeviceRemovalOptions = [];
  asdShuntOptions = [];
  asdShunt = [];
  selectedAsdShunt = [];

  selectValavo = false;


  public fields: CaseFieldsService;
 
  
  name = "Procedural Efficacy";

  constructor(private router: Router,fields: CaseFieldsService, _picklistItemService: PicklistItemService, _user: LoginService) {
    router.events
      .pipe(filter(e => e instanceof NavigationStart))
      .subscribe((e: NavigationStart) => {
        localStorage.setItem('editcase', e.url);
      });
    this.fields = fields;

    this.user = _user;
    this.picklistItemService = _picklistItemService;

    if (this.user.user) {
      
      this.picklistItemService
        .getPicklistItem(this.user.user.HospitalId.toString(), "ALL")
        .subscribe(data => this.populatePicklistItems(data));
      
    }
    
  }
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    if (this.form.dirty)
      localStorage.setItem('dirty', 'true');


    return !this.form.dirty;
  }

  private populatePicklistItems(picklistItemResponse: PicklistItemResponse) {
    this.picklistItemService.picklistItemModel = picklistItemResponse.DataObject;
    this.picklistItemService.mapToDropDown();

   
    this.deviceRemovalOptions = this.picklistItemService.dropDownPicklistItem
      .filter(x => x.description === "PDA Reason for device removal");
    this.deviceRemoval = this.deviceRemovalOptions.map(x => x.name);
    this.loadProblems(this.deviceRemovalOptions, this.deviceRemoval, this.selectedDeviceRemoval, 'PDA Reason for device removal');

    this.seccondDeviceRemovalOptions = this.picklistItemService.dropDownPicklistItem
      .filter(x => x.description === "PDA Reason for second device removal");
    this.seccondDeviceRemoval = this.seccondDeviceRemovalOptions.map(x => x.name);
    this.loadProblems(this.seccondDeviceRemovalOptions, this.seccondDeviceRemoval, this.selectedSeccondDeviceRemoval, 'PDA Reason for second device removal');

    this.asdShuntOptions = this.picklistItemService.dropDownPicklistItem
      .filter(x => x.description === "ASD shunt Size");
    this.asdShunt = this.asdShuntOptions.map(x => x.name);
    this.loadProblems(this.asdShuntOptions, this.asdShunt, this.selectedAsdShunt, 'ASD shunt Size');


    this.avProcOptions = this.picklistItemService.dropDownPicklistItem
      .filter(x => x.description === "AV Procedure indications");
    this.avProc = this.avProcOptions.map(x => x.name);
    this.loadProblems(this.avProcOptions, this.avProc, this.selectedAvProc, 'AV Procedure indications');

    this.AorticRegurgitationOptions = this.picklistItemService.dropDownPicklistItem
      .filter(x => x.description === "Aortic regurgitation")
      .map(x => x.name);

    this.SystolicAVGradientOptions = this.picklistItemService.dropDownPicklistItem
      .filter(x => x.description === "Peak systolic AV gradient")
      .map(x => x.name);

   

    this.pvProcOptions = this.picklistItemService.dropDownPicklistItem
      .filter(x => x.description === "PV Procedure indications ");
    
    this.pvProc = this.pvProcOptions.map(x => x.name);
    this.loadProblems(this.pvProcOptions, this.pvProc, this.selectedPvProc, 'PV Procedure indications');

    this.pdaProcOptions = this.picklistItemService.dropDownPicklistItem
      .filter(x => x.description === "PDADC Procedure Indications");

    this.pdaProc = this.pdaProcOptions.map(x => x.name);
    this.loadProblems(this.pdaProcOptions, this.pdaProc, this.selectedPdaProc, 'PDADC Procedure Indications');

    this.PulmonaryRegurgitationOptions = this.picklistItemService.dropDownPicklistItem
      .filter(x => x.description === "Pulmonary regurgitation")
      .map(x => x.name);

    this.SystolicGradientCoarctationOptions = this.picklistItemService.dropDownPicklistItem
      .filter(x => x.description === "Peak systolic gradient at coarctation")
      .map(x => x.name);
    this.TricuspidRegurgitationOptions = this.picklistItemService.dropDownPicklistItem
      .filter(x => x.description === "Tricuspid valve regurgitation")
      .map(x => x.name);

    this.asdProcOptions = this.picklistItemService.dropDownPicklistItem
      .filter(x => x.description === "ASDDC Procedure Indications");
    this.asdProc = this.asdProcOptions.map(x => x.name);
    this.loadProblems(this.asdProcOptions, this.asdProc, this.selectedAsdProc, 'ASDDC Procedure Indications');

    this.cpProcOptions = this.picklistItemService.dropDownPicklistItem
      .filter(x => x.description === "CP Procedure Indications");
    this.cpProc = this.cpProcOptions.map(x => x.name);
    this.loadProblems(this.cpProcOptions, this.cpProc, this.selectedCpProc, 'CP Procedure Indications');

    this.pdaShuntProcOptions = this.picklistItemService.dropDownPicklistItem
      .filter(x => x.description === "PDA shunt Size");
    this.pdaShuntProc = this.pdaShuntProcOptions.map(x => x.name);
    this.loadProblems(this.pdaShuntProcOptions, this.pdaShuntProc, this.selectedPdaShuntProc, 'PDA shunt Size');

    this.cptypes = [

      {
        name: "Peak systolic gradient at coarctation",
        multiple: false,
        id: "systolicGradientCoarctation",
        items: this.SystolicGradientCoarctationOptions,
        selections: ["", ""]

      }    


    ];

    this.avtypes = [
      
      {
        name: "Aortic regurgitation",
        multiple: false,
        id: "preAorticRegurgitation",
        items: this.AorticRegurgitationOptions,
        selections: ["",""]

      },
      {
        name: "Peak systolic AV gradient",
        multiple: false,
        id: "preSystolicAVGradient",
        items: this.SystolicAVGradientOptions,
        selections: ["",""]

      }
      

    ];

    this.pvtypes = [     
      {        
        name: "Pulmonary regurgitation",
        multiple: false,
        id: "prePulmonaryRegurgitation",
        items: this.PulmonaryRegurgitationOptions,
        selections: ["",""]
      },
       {        
         name: "Tricuspid valve regurgitation",
        multiple: false,
        id: "preTricuspidRegurgitation",
        items: this.TricuspidRegurgitationOptions,
        selections: ["",""]
      }


    ];
    

    this.setSelectionFromFields();

  }

  private loadProblems(dropdownOptions,dropdown,selectionOption,type) {
    let valMap: Array<string> = [];

    let probs = this.fields.getField(this.name, type).value;
    if (probs != null) {
      let probArray = probs.split('|');

      for (let p of probArray) {
        for (let dropDownItem of dropdownOptions) {
          if (dropDownItem.code === p) {
            valMap.push(dropDownItem.name);
          }
        }
      }
    }
   
   selectionOption = valMap;
    if (type == "AV Procedure indications") {
      this.selectedAvProc = valMap;
   }
    if (type == "PV Procedure indications") {
      this.selectedPvProc = valMap;
    }
    if (type == "CP Procedure Indications") {
      this.selectedCpProc = valMap;
    }
    if (type == "PDADC Procedure Indications") {
      this.selectedPdaProc = valMap;
    }
    if (type == "PDA shunt Size") {
      this.selectedPdaShuntProc = valMap;
    }
    if (type == "ASDDC Procedure Indications") {
      this.selectedAsdProc = valMap;
    }
    if (type == "PDA Reason for device removal") {
      this.selectedDeviceRemoval = valMap;
    }
    if (type == "PDA Reason for second device removal") {
      this.selectedSeccondDeviceRemoval = valMap;
    }
    if (type == "ASD shunt Size") {
      this.selectedAsdShunt = valMap;
    }
  }

  public updateProblems(dropdownOptions, dropdown, selectionOption, type) {
    if (selectionOption.length > 0 && Array.isArray(selectionOption) == true) {
      let valMap: Array<string> = [];

      for (let p of selectionOption) {
        for (let dropDownItem of dropdownOptions) {
          if (dropDownItem.name === p) {
            valMap.push(dropDownItem.code)
          }
        }
      }

      this.fields.setValue(this.name, type, valMap.join("|"));
    } else {
      this.fields.setValue(this.name, type, null);
    }

    if (Array.isArray(selectionOption) == false)
    { this.fields.setValue(this.name, type, dropdownOptions.find(x => x.name == selectionOption)["code"]); }
  }


  checkNone(event, type, period): boolean {
    var arr = type.selections[period].splice(0);
    const index = arr.indexOf("None");
    let hasNone = type.items.indexOf("None") > -1;
    if (arr.length === 0) {
      if (hasNone) {
        arr = ["None"];
      } else {
        arr = [type.items[0]];
      }
    }
    if (index === 0 && arr.length > 1) {
      arr.shift();
    }
    if (index == arr.length - 1) {
      arr = [arr[arr.length - 1]];
    }
    type.selections[period] = arr;

    return true;
  }



  private setSelectionFromFields() {
    try {
    
    
      for (let t of this.avtypes) {

        
          this.setSelection(t)
        
      }
      for (let t of this.pvtypes) {
        this.setSelection(t)
      }
      for (let t of this.cptypes) {
        this.setSelection(t)
      }
      
    } catch (ex) {
      console.log(ex);
    }
  }

  private setSelection(type) {
    let period: Array<number> = [0, 1];
    for (let p of period) {
      let list = null;
      list = this.picklistItemService.dropDownPicklistItem.filter(x => x.description === type.name);
      let val = "";

      val = this.fields.getValue(this.name, (type.name + " " + this.procs[p]));


      if (type.multiple) {
        let valMap: Array<string> = [];
        let valList: Array<string> = [];
        if (val != null) {
          valList = val.split("|");
        }

        for (let v of valList) {
          for (let dropDownItem of list) {
            if (dropDownItem.code === v) {
              valMap.push(dropDownItem.name);
            }
          }
        }

        if (valMap.length === 0) {
          valMap = ["None"];
        }
        type.selections[p] = valMap;

      } else {
        let valMap = null;
        for (let dropDownItem of list) {
          if (dropDownItem.code === val) {
            valMap = dropDownItem.name;
          }
        }

        //if (valMap == null) {
        //  valMap = "None";
        //}

        type.selections[p] = valMap;

      }
    }
  }


  public updateFields(type,period): boolean {
    try {
   
      let val = type.selections[period];
      let list = null;
    
      
          list = this.picklistItemService.dropDownPicklistItem.filter(x => x.description === type.name);
       
      

      if (type.multiple) {
        let valMap = [];

        for (let select of val) {
          for (let dropDownItem of list) {
            if (dropDownItem.name === select) {
              valMap.push(dropDownItem.code);
            }
          }
        }
        if (valMap != null && valMap.length > 0) {
          this.fields.setValue(this.name, (type.name), valMap.join("|"));
        } else {
          this.fields.setValue(this.name, (type.name), "");
        }
      } else {
        let valMap = null;
        for (let dropDownItem of list) {
          if (dropDownItem.name === val) {
            valMap = dropDownItem.code;
          }
        }      
          this.fields.setValue(this.name, (type.name + " " + this.procs[period]), valMap);
        
      }
    } catch (ex) {
      console.log(ex);
    }

    return true;
  }


 
  toggleCheckbox(select, val) {
    this.selectValavo = select;
    console.log(val);
    this.fields.getField(this.name, val).hide = select;
    if (select == true)
      this.fields.setValue(this.name, val, 0);
    else
      this.fields.setValue(this.name, val, 1);

    if (val == 'Aortic Valvuloplasty' && select == true) {
    
      this.fields.setValue(this.name, "AV Procedure indications", null);
      this.loadProblems(this.avProcOptions, this.avProc, null, 'AV Procedure indications');
      this.fields.setValue(this.name, "Aortic regurgitation Pre-Procedure", null);
      this.fields.setValue(this.name, "Aortic regurgitation Post-Procedure", null);
      this.fields.setValue(this.name, "Peak systolic AV gradient Pre-Procedure", null);
      this.fields.setValue(this.name, "Peak systolic AV gradient Post-Procedure", null);
      this.toggleCheckbox(true, "Pre Left Ventricle Dysfunction");
      this.toggleCheckbox(true, "Post Left Ventricle Dysfunction");
      this.setSelectionFromFields();

    }
   
    if (val == "Pulmonary Valvuloplasty" && select == true) {      
      this.fields.setValue(this.name, "PV Procedure indications", null);
      this.loadProblems(this.pvProcOptions, this.pvProc, null, 'PV Procedure indications');
      this.fields.setValue(this.name, "Pulmonary regurgitation Pre-Procedure", null);
      this.fields.setValue(this.name, "Tricuspid valve regurgitation Pre-Procedure", null);
      this.fields.setValue(this.name, "Pulmonary regurgitation Post-Procedure", null);
      this.fields.setValue(this.name, "Tricuspid valve regurgitation Post-Procedure", null);
      this.toggleCheckbox(true, "Pre Pulmonary valve gradient");
      this.toggleCheckbox(true, "Post Pulmonary valve gradient");
      this.toggleCheckbox(true, "Subvalvar Obstruction");
      this.toggleCheckbox(true, "Pulmonary Valve Type");
      this.toggleCheckbox(true, "Any main pulmonary artery or right ventricular outflow tract tear");
      this.toggleCheckbox(true, "Intervention performed");
      this.setSelectionFromFields();
    }    
    
    if (val == "ASD Device Closure" && select == true) {
      this.fields.setValue(this.name, "ASDDC Procedure Indications", null);
      this.loadProblems(this.asdProcOptions, this.asdProc, null, 'ASDDC Procedure Indications');
      this.toggleCheckbox(true, "Residual shunt on echocardiogram");
      this.fields.setValue(this.name, "ASD shunt Size", null);
      this.loadProblems(this.asdShuntOptions, this.asdShunt, null, 'ASD shunt Size');
      this.toggleCheckbox(true, "New or Changed mitral valve insufficiency");
      this.setSelectionFromFields();
    }
    else
    {
      if (val == "Residual shunt on echocardiogram" && select == true)
      {
        this.fields.setValue(this.name, "ASD shunt Size", null);
        this.loadProblems(this.asdShuntOptions, this.asdShunt, null, 'ASD shunt Size');
      }
    }
    
    if (val == "Coarctation Procedure" && select == true) {
      this.fields.setValue(this.name, "CP Procedure Indications", null);
      this.loadProblems(this.cpProcOptions, this.cpProc, null, 'CP Procedure Indications');
      this.toggleCheckbox(true, "Any aneurysm post procedure");
      this.toggleCheckbox(true, "stent migration");
      this.toggleCheckbox(true, "Jailing of head/neck vessels");
      this.fields.setValue(this.name, "Peak systolic gradient at coarctation Pre-Procedure", null);
      this.fields.setValue(this.name, "Peak systolic gradient at coarctation Post-Procedure", null);
      this.setSelectionFromFields();
    }   
    
    if (val == "PDA Device Closure" && select == true) {
      this.fields.setValue(this.name, "PDADC Procedure Indications", null);
      this.loadProblems(this.pdaProcOptions, this.pdaProc, null, 'PDADC Procedure Indications');
      this.fields.setValue(this.name, "PDA shunt Size", null);
      this.loadProblems(this.pdaShuntProcOptions, this.pdaShuntProc, null, 'PDA shunt Size');
      this.toggleCheckbox(true, "Residual Shunt");
      this.toggleCheckbox(true, "New left pulmonaryartery stenosis");
      this.toggleCheckbox(true, "New aortic arch obstruction");
      this.toggleCheckbox(true, "Hemolysis");
      this.toggleCheckbox(true, "Device removed from body");
      this.toggleCheckbox(true, "Was a second device placed?");
      this.toggleCheckbox(true, "Was the second device removed?");
      this.fields.setValue(this.name, "PDA Reason for device removal", null);
      this.loadProblems(this.deviceRemovalOptions, this.deviceRemoval, null, 'PDA Reason for device removal');
      this.fields.setValue(this.name, "PDA Reason for second device removal", null);
      this.loadProblems(this.seccondDeviceRemovalOptions, this.seccondDeviceRemoval, null, 'PDA Reason for second device removal');
      this.setSelectionFromFields();

    }
    else
    {
      if (val == "Residual Shunt" && select == true) {
        this.fields.setValue(this.name, "PDA shunt Size", null);
        this.loadProblems(this.pdaShuntProcOptions, this.pdaShuntProc, null, 'PDA shunt Size');
      }
      if (val == "Device removed from body" && select == true) {
        this.fields.setValue(this.name, "PDA Reason for device removal", null);
        this.loadProblems(this.deviceRemovalOptions, this.deviceRemoval, null, 'PDA Reason for device removal');
      }
      if (val == "Was the second device removed?" && select == true) {
        this.fields.setValue(this.name, "PDA Reason for second device removal", null);
        this.loadProblems(this.seccondDeviceRemovalOptions, this.seccondDeviceRemoval, null, 'PDA Reason for second device removal');
      }
    }
    
   
  
   
  }

  getDropdown(val) {

   
    return this.fields.getField(this.name, val).hide;
  }

}
