import * as wjChart from 'wijmo/wijmo.angular2.chart';
import * as wjcCore from 'wijmo/wijmo';
import { Component, ViewChild, Input, ElementRef, OnInit, SimpleChanges, OnChanges, AfterViewInit  } from '@angular/core';
import { ReportModelDetails } from '../../../../shared/report-model-details';
import { NgxSpinnerService } from 'ngx-spinner';

//Used for all Volume Reports
@Component({
    //directives: [wjChart.WjFlexChart, wjChart.WjFlexChartSeries],
  template: `<wj-flex-chart #fChart name="fChart" [itemsSource]="data" [bindingX]="'SiteLegend'" [chartType]="chartType" [stacking]="stacking" [rotated]="rotated" (rendered)="onChartRendered()">
                    <wj-flex-chart-axis [wjProperty]="'axisX'" [title]="titleX"></wj-flex-chart-axis>
                    <wj-flex-chart-axis [wjProperty]="'axisY'" [title]="totalCasesLabel" [format]="yAxisFormat"></wj-flex-chart-axis>
                    <wj-flex-chart-series *ngIf="showYAxisData" [binding]="'YAxis'" ></wj-flex-chart-series>
                    <div *ngIf="showStackedItems">
                      <wj-flex-chart-series [name]="'< 30 days'" [binding]="'LessThan30Days'"></wj-flex-chart-series>
                      <wj-flex-chart-series [name]="'30 days to 1 year'" [binding]="'ThirtyDaysToOneYear'"></wj-flex-chart-series>
                      <!--<wj-flex-chart-series [name]="'< 1 year'" [binding]="'LessThanOneYear'"></wj-flex-chart-series>
                          <wj-flex-chart-series [name]="'>= 1 year'" [binding]="'GreaterThanOrEqualToOneYear'"></wj-flex-chart-series>-->
                      <wj-flex-chart-series [name]="'1 to 18 years'" [binding]="'OneToEightteenYears'"></wj-flex-chart-series>
                      <wj-flex-chart-series [name]="'> 18 years'" [binding]="'EightTeenYears'"></wj-flex-chart-series>
                    </div>
                    <wj-flex-chart-legend [position]="legendPosition"></wj-flex-chart-legend>
                     <!--"Top", "Bottom", "Left", "Right", and "None" (case-sensitive).-->
                    <wj-flex-chart-animation #animation [easing]="easing" [duration]="duration"></wj-flex-chart-animation>
                  </wj-flex-chart>
                  <!--Child templates emit changes to parent -->
                  <!--Also pass in the default start-up values-->
                 <chart-type-template (chartTypeChange)="getChartTypeChange($event)" [chartType]="chartType" ></chart-type-template>
                 <!--hidding rotating option because the labels are not changing when the view is changed-->
                 <chart-view-template (rotatedChange)="getRotatedChange($event)" [rotated]="rotated" [hidden]="true"></chart-view-template>                 
                 <stacked-items-template (stackingChange)="getStackingChange($event)" *ngIf="showStackedItems" [stacking]="stacking" [spinner]="spinner" [hidden]="true"></stacked-items-template>`,  
  selector: 'case-volume-report-by-center-template',
})
export class CaseVolumeReportByCenterComponent implements OnInit, AfterViewInit{
  @ViewChild('fChart') flexChart: wjChart.WjFlexChart;
  @ViewChild('axisY') axisY: wjChart.WjFlexChartAxis;
  @ViewChild('axisX') axisX: wjChart.WjFlexChartAxis;
  @Input() data: ReportModelDetails[];
  @Input() chartType: string;
  @Input() stacking: string;
  @Input() rotated: string;
  @Input() titleX: string;
  @Input() totalCasesLabel: string;
  @Input() XAxisLabel: string;
  @Input() showStackedItems: boolean;
  @Input() showYAxisData: boolean;
  @Input() legendPosition: string;
  @Input() easing: string;
  @Input() duration: number;
  @Input() spinner: NgxSpinnerService;
  @Input() yAxisFormat: string;

  constructor(public element: ElementRef) {
    this.element.nativeElement // element reference for printing
  }
  ngOnInit(): void {   

  }

  ngAfterViewInit() {
    //Set custom tool tip content
    this.flexChart.tooltip.content = `<b></b><br />` + `<span>{y} cases</span>`;
  }

   
  onChartRendered() {
    this.spinner.hide();
  }

  getChartTypeChange($event) {

    this.chartType = $event;


    //If chart type is bar then flip the axis
    if (this.chartType == "Bar") {

      var tempXTitle = this.titleX;
      this.titleX = this.totalCasesLabel;
      this.totalCasesLabel = tempXTitle;
    }

    //If the Y axis equals center and not a bar chart then flip them back
    else if (this.totalCasesLabel == "Center") {
      var tempYTitle = this.totalCasesLabel;
      this.totalCasesLabel = this.titleX;
      this.titleX = tempYTitle;
    }

  }

  getRotatedChange($event) {

    this.rotated = $event;
  }

  getStackingChange($event) {

    this.stacking = $event;
  }


  


}
