import { Component, OnInit, ViewEncapsulation, AfterViewInit, ViewChild } from '@angular/core';
import { RouterService } from '../navigation/router.service';
import { MatTabGroup, MatTabChangeEvent, MatTab } from '@angular/material';
import { MatTabsModule } from '@angular/material/tabs';
import { NgForm } from "@angular/forms";


@Component({
  selector: 'app-sites',
  templateUrl: './sites.component.html',
  styleUrls: ['./sites.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SitesComponent   implements OnInit, AfterViewInit {
  @ViewChild('tabGroup') tabGroup;
  @ViewChild('sitesform')
  form: NgForm; 
  url: string = '';
  defaultTab: number = 0;
  //isActiveC3PO: boolean = false;
  //isActiveIQIC: boolean = false;

  constructor(private routerService: RouterService) {
    
  }

  ngOnInit() {   
    
    var isIQIC = this.routerService.showIQIC();
   
    if (isIQIC) {
      this.defaultTab = 1;    

    }


  }

  ngAfterViewInit() {
    //console.log(this.tabGroup.selectedIndex);
    //This is an attempt to get the select tab to be highlighted
    //You can set the default tab by using the selected Index in the HTML but
    //it seems to only become actively styled when it is clicked.
    //this.tabGroup._tabs._results[1].isActive = true;
    //this.tabGroup.selectedTabChange.emit(MatTabChangeEvent);
  }


  tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
    //var tab = tabChangeEvent.tab;
    //var index = tabChangeEvent.index;
  }

  animationDone() {
  
}

}
