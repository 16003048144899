import { CaseClinicalInfoModel } from './case-clinical-info-model';
import { CaseProcedureInfoModel } from './case-procedure-info-model';
import { CaseAdmitDisposInfoModel } from './case-admit-dispos-info-model';
import { CaseHemoDynamicsInfoModel } from './case-hemo-dynamics-info-model';
import { CaseProcAssignmentModel } from './case-proc-assignment-model';
import { CaseAEInfoModel } from './case-ae-info-model';
import { CaseReqResourcesModel } from './case-req-resources-model';
import { CasePreCalcInfoModel } from './case-pre-calc-info-model';
import { CaseTypeCalcModel } from '../case-type-calc-model';
import { CaseProcEfficacyModel } from './case-proc-efficacy-model';
import { CaseAddlProcedureInfoModel } from './case-addl-procedure-info-model';
import { CaseAddlHemoDynamicsInfoModel } from './case-addl-hemo-dynamics-info-model';

export class CaseModel {
  CaseId: number;
  CathDate: string;
  HospitalId: number;
  PrimaryOperatorId: number;
  SensisReferenceNumber: number;
  CompletionStatus: string;
  CaseUpdatedby: string;
  ActiveStatus: string;
  CaseClinicalInfo: CaseClinicalInfoModel;
  CaseProcedureInfo: CaseProcedureInfoModel;
  CaseAddlProcedureInfo: CaseAddlProcedureInfoModel;
  CaseEOCAdmDisposn: CaseAdmitDisposInfoModel;
  CaseHemoDynamicInfo: CaseHemoDynamicsInfoModel;
  CaseAddlHemoDynamicInfo: CaseAddlHemoDynamicsInfoModel;
  CaseProcAssingedlist: CaseProcAssignmentModel[];
  CaseProcAEInfo: CaseAEInfoModel;
  CaseReqResources: CaseReqResourcesModel[];
  CasePreCalcInfo: CasePreCalcInfoModel;
  CaseTypeCalcResponse: CaseTypeCalcModel;
  CaseProcEfficacy: CaseProcEfficacyModel[];

  constructor() {
    this.CaseClinicalInfo = new CaseClinicalInfoModel;
    this.CaseProcedureInfo = new CaseProcedureInfoModel;
    this.CaseAddlProcedureInfo = new CaseAddlProcedureInfoModel;
    this.CaseEOCAdmDisposn = new CaseAdmitDisposInfoModel;
    this.CaseHemoDynamicInfo = new CaseHemoDynamicsInfoModel;
    this.CaseAddlHemoDynamicInfo = new CaseAddlHemoDynamicsInfoModel;
    this.CaseProcAEInfo = new CaseAEInfoModel;
    this.CasePreCalcInfo = new CasePreCalcInfoModel;
    this.CaseTypeCalcResponse = new CaseTypeCalcModel;
  }
}
