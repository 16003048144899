export class CaseAEItemModel {
  CaseAEId: number;
  CaseId: number;
  AECODE: string;
  AEMajorInd: string;
  AESeriousness: string;
  Timeneeded: string;
  Updatedby: string;
  AeTier: string;
}
