import { Injectable } from "@angular/core";
import { LoginService } from "../login.service";

@Injectable({
  providedIn: "root"
})
export class CaseFieldsService {
  public type = "add";
  validate = false;
  validateDate = false;
  goodToSubmit = false;
  currentSeriousness = null;
  currentSeriousnessDisplay = null;
  caseLoaded: boolean = false;
  completedCase: boolean = false;
  checked6Hours: boolean;
  checked24Hours: boolean;
  checked48Hours: boolean;
  anticipatedCase = false;
  caseCreated: boolean = false;
  user: LoginService;
  fields = [];  

  //gets a particular field object based on its parent and name
  public getField(parent, name) {
    let parentFields: any = this.fields.find(x => x.name == parent)["fields"];
    let field = parentFields.find(x => x["name"] == name);

    return field;
  }

  //Get Expection Validation for Hospital

  getExpectionValidation() {
    //For CHOP Expection
    return this.user.user.Hospital == "Children's Hospital of Philadelphia";
  }

  //retrieves the value of a field
  getValue(parent, name) {
    return this.getField(parent, name)["value"];
  }

  //gets a parent object
  getParent(parent) {
    return this.fields.find(x => x.name == parent);
  }

  //checks if a field has a value
  hasValue(parent, name) {
    if (this.validate) {
      if (parent == "Case Types" && name == "Recorded Procedures") {
        let v = this.getValue(parent, name);
        return (v === null) || (Object.keys(v).length === 0);
      } else {
        return (this.getValue(parent, name) === null);
      }
    } else if (this.validateDate && parent == "Case Identification" && name == "Cath Date") {
      return this.getValue(parent, name) === null;
    } else {
      return false;
    }
  }

  setValue(parent, name, val) {
    this.getField(parent, name).value = val;
   }

  //returns the ngStyle object required to indicate form errors
  classObject(parent, name) {
    if (this.hasValue(parent, name)) {
      return "error";
    }

    if (this.hasOutRangeValue(parent, name)) {
      this.categoryOutRangeTotalMessage(parent);
      return "warning";
    }

    return "";
  }

  //hides a particular field from missing field screen
  setHide(name, bool) {
    this.fields.find(x => x.name == name)["hide"] = bool;
  }
  constructor(_user: LoginService) {
    this.user = _user;
    this.resetFields();
  }

  public resetFields() {    
    this.validate = false;
    this.validateDate = false;
    this.caseLoaded = false;
    this.completedCase = false;
    let defaultFields = [
      {
        name: "Case Identification",
        link: "/user-portal/" + this.type + "-case/case-id",
        fields: [
          {
            name: "Case Number",
            value: null,
            hide: true
          },
          {
            name: "Hospital",
            value: null
          },
          {
            name: "Cath Date",
            value: null,
          },
          {
            name: "Operator",
            value: null
          },
          {
            name: "Case Time",
            value: null
          }
        ]
      },
      {
        name: "Clinical Characteristics",
        link: "/user-portal/" + this.type + "-case/clinical-characteristics",
        fields: [
          {
            name: "Id",
            value: 0,
            hide: true
          },
          {
            name: "Age",
            value: null
          },
          {
            name: "Age Unit",
            value: "Days",
            hide: true
          },
          {
            name: "Sex",
            value: null
          },
          {
            name: "Weight",
            value: null,
            min: 1,
            max: 200
          },
          {
            name: "Height",
            value: null,
            min: 1,
            max: 180
          },
          {
            name: "BSA",
            value: null
          },
          {
            name: "PrimarySTS",
            value: null,
            text: null
          },
          {
            name: "SecondSTS",
            value: null,
            text: null,
            hide: true
          },
          {
            name: "ThirdSTS",
            value: null,
            text: null,
            hide: true
          },
          {
            name: "FourthSTS",
            value: null,
            text: null,
            hide: true
          },
          {
            name: "Date Last Cath",
            value: 0,
            hide: true
          },
          {
            name: "Date Last Cardiac Surgery",
            value: 0,
            hide: true
          },
          {
            name: "Genetic Syndrome",
            value: 0,
            hide: true
          },
          {
            name: "Non-cardiac Problems",
            value: null,
            hide: true
          },
        ]
      },
      {
        name: "Procedural Efficacy",
        link: "/user-portal/" + this.type + "-case/procedural-efficacy",
        fields: [
          
          { name: "Aortic Valvuloplasty", value: null, hide: true },
          { name: "AV Procedure indications", value: null, hide: true },
          { name: "Aortic regurgitation Pre-Procedure", value: null, hide: true},
          { name: "Aortic regurgitation Post-Procedure", value: null, hide: true},          
          { name: "Peak systolic AV gradient Pre-Procedure", value: null, hide: true},
          { name: "Peak systolic AV gradient Post-Procedure", value: null, hide: true},
          { name: "Pre Left Ventricle Dysfunction", value: null, hide: true },
          { name: "Post Left Ventricle Dysfunction", value: null, hide: true },
         
          { name: "Pulmonary Valvuloplasty", value: null, hide: true },
          { name: "PV Procedure indications", value: null, hide: true },
          { name: "Pulmonary regurgitation Pre-Procedure",   value:null , hide:true },    
          { name: "Pulmonary regurgitation Post-Procedure", value: null, hide: true },
          { name: "Tricuspid valve regurgitation Pre-Procedure", value: null, hide: true },
          { name: "Tricuspid valve regurgitation Post-Procedure", value: null, hide: true },
          { name: "Pulmonary Valve Type", value: null, hide: true },
         
          { name: "Subvalvar Obstruction", value: null, hide: true },
          { name: "Any main pulmonary artery or right ventricular outflow tract tear", value: null, hide: true },
          { name: "Intervention performed", value: null, hide: true },
          { name: "Pre Pulmonary valve gradient", value: null, hide: true },
          { name: "Post Pulmonary valve gradient", value: null, hide: true },

          { name: "ASD Device Closure", value: null, hide: true },
          { name: "ASDDC Procedure Indications", value: null, hide: true },
          { name: "Residual shunt on echocardiogram", value: null, hide: true },
          { name: "ASD shunt Size", value: null, hide: true },
          { name: "New or Changed mitral valve insufficiency", value: null, hide: true},

          { name: "Coarctation Procedure", value: null, hide: true },
          { name: "CP Procedure Indications", value: null, hide: true },
          { name: "Any aneurysm post procedure", value: null, hide: true },
          { name: "stent migration", value: null, hide: true },
          { name: "Jailing of head/neck vessels", value: null, hide: true },
          { name: "Peak systolic gradient at coarctation Pre-Procedure",  value: null,hide:true },
          { name: "Peak systolic gradient at coarctation Post-Procedure",  value: null,hide:true},


          { name: "PDA Device Closure",value: null,hide: true},
          { name: "PDADC Procedure Indications", value: null,hide:true },
          { name: "PDA shunt Size", value: null ,hide:true },       
          { name: "PDA Reason for device removal", value: null, hide: true },
          { name: "PDA Reason for second device removal", value: null, hide: true },
          { name: "Device removed from body", value: null, hide: true },
          { name: "Was a second device placed?", value: null, hide: true },
          { name: "Was the second device removed?", value: null, hide: true },
          { name: "Hemolysis", value: null, hide: true },                              
          { name: "Residual Shunt", value: null, hide: true },
          { name: "New left pulmonaryartery stenosis",value: null, hide: true},
          { name: "New aortic arch obstruction", value: null, hide: true }
          
        ]
      },
      {
        name: "Procedure Characteristics",
        link: "/user-portal/" + this.type + "-case/procedure-characteristics",
        fields: [
          { name: "Id", value: 0, hide: true },
          { name: "Total Fluoro Time", value: null, min: 1, max: 99, hide: false},
          { name: "Total DAP", value: null, min: 1, max: 50000, hide: false},
          { name: "Sheath-in Time", value: null },
          { name: "Sheath-out Time", value: null },
          { name: "Duration", value: null, max: 480 },
          { name: "Blood Transfusions", value: false, hide: true },
          { name: "AddlId", value: 0, hide: true },
          { name: "Type Fluoro Performed", value: true, hide: true },
          { name: "Total Contrast Volume", value: null, hide: true },
          { name: "Total Contrast Volume Per Kg", value: null, hide: true },
          { name: "Pre Cath Hemoglobin", value: null, hide: true, min: 5, max: 20 }
        ]
      },
      {
        name: "Hemodynamics",
        link: "/user-portal/" + this.type + "-case/hemodynamics",
        fields: [
          {
            name: "Id",
            value: 0,
            hide: true
          },
          {
            name: "Single Ventricle Physiology",
            value: null, hide: false
          },
          {
            name: "Physiologic Category",
            value: null, hide: false
          },
          {
            name: "SV EDP",
            value: false, hide: true
          },
          {
            name: "Sys Sat",
            value: false, hide: true
          },
          {
            name: "MV Sat",
            value: false, hide: true
          },
          {
            name: "PA",
            value: false, hide: true
          },
          {
            name: "Qp:Qs",
            value: false, hide: true
          },
          {
            name: "PVR",
            value: false, hide: true
          },
          { name: "AddlId", value: 0, hide: true },
          { name: "Considered for Shunt Closure", value: false, hide: true },
          { name: "Is Recommended for SC", value: null, hide: true },
          { name: "Acute Vasodilator Testing", value: false, hide: true },
          { name: "Congenital Heart Disease Diagnosis", value: null, hide: true },
          { name: "Name of Vasodilator Agent", value: null, hide: true },
          { name: "Baseline Pulmonary Vascular Resistance", value: null, hide: true },
          { name: "Baseline Systemic Vascular Resistance", value: null, hide: true },
          { name: "Baseline PVR:SVR Ratio", value: null, hide: true },
          { name: "Baseline Qp:Qs Ratio", value: null, hide: true },
          { name: "Baseline Cardiac Index", value: null, hide: true },
          { name: "Post-Testing Pulmonary Vascular Resistance", value: null, hide: true },
          { name: "Post-Testing Systemic Vascular Resistance", value: null, hide: true },
          { name: "Post-Testing PVR:SVR Ratio", value: null, hide: true },
          { name: "Post-Testing Qp:Qs Ratio", value: null, hide: true },
          { name: "Post-Testing Cardiac Index", value: null, hide: true }
        ]
      },
      {
        name: "Case Types",
        link: "/user-portal/" + this.type + "-case/case-types",
        fields: [
          { name: "Recorded Procedures", value: null },
          { name: "Anticipated Procedures", value: null, hide: true },
          { name: "Case Type", value: null, hide: true },
          { name: "Case Type Done", value: null, hide: true },
          { name: "Case Type QI Text", value: null, hide: true }
        ]
      },
      {
        name: "Pre-Case Calculation",
        link: "/user-portal/" + this.type + "-case/case-types",
        hide: true,
        fields: [
          {
            name: "Id",
            value: 0,
            hide: true
          },
          { name: 'STS Diagnosis Category', value: null, hide: true },
          { name: "Recorded Procedures", value: null, hide: true },
          { name: "Case Type", value: null, hide: true },
          { name: "Case Type Done", value: null, hide: true },          
          {
            name: "Single Ventricle Physiology",
            value: null, hide: true
          },
          {
            name: "Physiologic Category",
            value: null, hide: true,
          },
          {
            name: "SV EDP",
            value: false, hide: true
          },
          {
            name: "Sys Sat",
            value: false, hide: true
          },
          {
            name: "MV Sat",
            value: false, hide: true
          },
          {
            name: "PA",
            value: false, hide: true
          },
          {
            name: "Qp:Qs",
            value: false, hide: true
          },
          {
            name: "PVR",
            value: false, hide: true
          }
        ]
      },
      {
        name: "Adverse Events",
        link: "/user-portal/" + this.type + "-case/adverse-events",
        hide: true,
        fields: [
          { name: "Recorded Events", value: null },
          { name: "Patient Die", value: false, hide: true},
          { name: "Unanticipated Catheterization", value: false, hide: true},
          { name: "Unanticipated Surgery", value: false, hide: true},
          { name: "Description", value: null },
          { name: "Seriousness", value: "AES01" },
          { name: "Time Needed", value: "0" }
        ]
      },
      {
        name: "Indicators of Required Resources",
        link: "/user-portal/" + this.type + "-case/indicators",
        fields: [
          { name: "Airway Management Pre-Procedure", value: "AM01", id: 0 },
          { name: "Airway Management Procedural", value: "AM04", id: 0, hide: true },
          { name: "Airway Management Post-Procedure", value: "AM01", id: 0, hide: true },
          { name: "Airway Management 48 Hrs Post-Cath", value: "AM01", id: 0, hide: true },
          { name: "Ventilation Pre-Procedure", value: "VENT01", id: 0, hide: true },
          { name: "Ventilation Procedural", value: "VENT01", id: 0, hide: true },
          { name: "Ventilation Post-Procedure", value: "VENT01", id: 0, hide: true },
          { name: "Ventilation 48 Hrs Post-Cath", value: "VENT01", id: 0, hide: true },
          { name: "Sedation Pre-Procedure", value: null, id: 0, hide: true },
          { name: "Sedation Procedural", value: "SED05", id: 0, hide: true },
          { name: "Sedation Post-Procedure", value: null, id: 0, hide: true },
          { name: "Sedation 48 Hrs Post-Cath", value: null, id: 0, hide: true },
          { name: "Lines Pre-Procedure", value: null, id: 0, hide: true },
          { name: "Lines Procedural", value: null, id: 0, hide: true },
          { name: "Lines Post-Procedure", value: null, id: 0, hide: true },
          { name: "Lines 48 Hrs Post-Cath", value: null, id: 0, hide: true },
          { name: "Drains Pre-Procedure", value: null, id: 0, hide: true },
          { name: "Drains Procedural", value: null, id: 0, hide: true },
          { name: "Drains Post-Procedure", value: null, id: 0, hide: true },
          { name: "Drains 48 Hrs Post-Cath", value: null, id: 0, hide: true },
          { name: "Mechanical Support Pre-Procedure", value: null, id: 0, hide: true },
          { name: "Mechanical Support Procedural", value: null, id: 0, hide: true },
          { name: "Mechanical Support Post-Procedure", value: null, id: 0, hide: true },
          { name: "Mechanical Support 48 Hrs Post-Cath", value: null, id: 0, hide: true },
          { name: "IV Medications Pre-Procedure", value: null, id: 0, hide: true },
          { name: "IV Medications Procedural", value: null, id: 0, hide: true },
          { name: "IV Medications Post-Procedure", value: null, id: 0, hide: true },
          { name: "IV Medications 48 Hrs Post-Cath", value: null, id: 0, hide: true },
          { name: "Nursing Assignment 6 Hrs Pre-Procedure", value: null, id: 0, hide: false  },
          { name: "Nursing Assignment Post-Procedure Before 6hrs", value: null, id: 0, hide: true },
          { name: "Nursing Assignment 18 to 24 Hrs Post-Cath", value: null, id: 0, hide: false },
          { name: "Nursing Assignment 48 Hrs Post-Cath", value: null, id: 0, hide: false },
          { name: "Checked6Hours Pre-Procedure", value: false, id: 0, hide: true },
          { name: "Checked24Hours Post-Procedure", value: false, id: 0, hide: true },
          { name: "Checked48Hours 48 Hrs Post-Cath", value: false, id: 0, hide: true }
        ]
      },
      {
        name: "EOC Admit/Disposition",
        link: "/user-portal/" + this.type + "-case/eoc",
        fields: [
          { name: "Id", value: 0, hide: true },
          { name: "Admission Source", value: true, hide: true },
          { name: "Admit Prior Cath", value: false, hide: true },
          { name: "Post-Cath Location", value: null },
          { name: "Discharge Post Cath", value: false, hide: true },
          { name: "Unplanned Admission", value: false, hide: true },
          { name: "Alive at Discharge", value: false, hide: true }
        ]
      }
    ];

    this.fields = defaultFields;
  }

  public isComplete(): boolean {
    if (this.fields.length > 0) {
      for (let category of this.fields) {
        if (!category["hide"]) {
          for (let field of category.fields) {
            //value cannot be null and field should be 
            if (!field.value && !field["hide"]) {
              return false;
            }
            if (field.name == "Recorded Procedures" && !field.hide) {
              if ((field.value === null) || (Object.keys(field.value).length === 0)) {
                return false;
              }
            }
          }
        }        
      }
      return true;
    }

    return false;
  }

  hasOutRangeValue(parent, name): boolean {
    let result = false;

    try {
      let f = this.getField(parent, name);
      if (f["value"]) {
        let val = f["value"];

        if (f["min"]) {
          let min = +f["min"];
          if (min > +val) {
            result = true;
          }
        }

        if (f["max"]) {
          let max = +f["max"];

          if (parent == "Procedure Characteristics" && name == "Duration") {
            let v = val.split(':');
            val = (+(v[0] * 60)) + (+v[1]);
          }

          if (max < +val) {
            result = true;
          }
        }
      }
    } catch (ex) {
      console.log(ex)
    }

    return result;
  }

  private outRangeMessage(parent, name): string {
    let result = "";

    if (this.hasOutRangeValue(parent, name)) {
      try {
        let f = this.getField(parent, name);
        let val = f["value"];

        if (f["min"]) {
          let min = +f["min"];
          result = "" + name + " value should be greater than " + min;
        }

        if (f["max"]) {
          let max = +f["max"];
          if (parent == "Procedure Characteristics" && name == "Duration") {
            result = "Case " + name + " value should be less than " + (max / 60) + " hours";
          } else {
            result = result + " and less than " + max;
          }
        }
      } catch (ex) {
        console.log(ex)
      }
    }    

    return result;
  }

  public categoryOutRangeTotalMessage(parent): string {
    let result = [];

    if (this.fields.length > 0) {
      for (let category of this.fields) {
        if (category["name"] == parent) {
          for (let field of category.fields) {
            let m = this.outRangeMessage(parent, field["name"]);
            if (m.length > 0) {
              result.push(m);
            }
          }
        }
      }
    }

    if (result.length > 0) {
      result.unshift("Range Warning:");
    }

    return result.join("<br/>");
  }
}
