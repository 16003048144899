import { Component, OnInit, Input ,ViewChild,HostListener} from "@angular/core";
import { HemosService } from "./hemos.service";
import { CaseFieldsService } from "../../user/add-case/case-fields.service";
import { NgForm, FormGroup } from "@angular/forms";
import { ComponentCanDeactivate } from '../../../CanDeactivateGaurd';
import { Observable } from 'rxjs';
import { filter, map, mergeMap, tap } from "rxjs/operators";
import { Router, ActivatedRoute, ParamMap, NavigationEnd, NavigationStart, RouterEvent } from "@angular/router";
import { HemoInfo } from "../../../shared/report-model-hemo-info";

@Component({
  selector: "app-hemos",
  templateUrl: "./hemos.component.html",
  styleUrls: ["./hemos.component.scss"],
})
export class HemosComponent implements OnInit, ComponentCanDeactivate {
  @ViewChild('form') form: NgForm;
  @Input() pre: boolean;

  name = "Hemodynamics";
  public hemos:HemosService;
  public fields:CaseFieldsService
  constructor(private router: Router, hemos: HemosService, fields: CaseFieldsService) {
    router.events
      .pipe(filter(e => e instanceof NavigationStart))
      .subscribe((e: NavigationStart) => {
        localStorage.setItem('editcase', e.url);
      });
    this.fields = fields;
    this.hemos = hemos;    
  }

  ngOnInit() {  
    if (this.pre) {
      this.name = "Pre-Case Calculation";
      this.hemos.name = "Pre-Case Calculation";
    } else {
      this.name = "Hemodynamics";
      this.hemos.name = "Hemodynamics";

    }
    this.hemos.setSVP();
  }
  ngOnDestroy() {
    
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
   
    return !this.form.dirty;
   
  }

  private convertTrueFalse(type: boolean): string {
   
    if (type === true) return '1';
    else if(type === false || type === null ) return '0';
  }
}
