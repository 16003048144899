import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-webinars',
  templateUrl: './webinars.component.html',
  styleUrls: ['./webinars.component.scss'],
  host: { 'class': "host" }
})
export class WebinarsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
