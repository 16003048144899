export class UserModel {
  UserId: number;
  SelfProviderId: number;
  IsActive: boolean;
  Password: string;
  UserName: string;
  Email: string;
  Role: string;
  FullName: string;
  UserAppRegistry: string;
  UserAppRegistryID: string;
  Hospital: string;
  HospitalId: number;
  ValidationMessage: string;
}
