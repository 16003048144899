
import { Component, ViewChild, Input, Output, ElementRef, OnInit, EventEmitter  } from '@angular/core';
import { EventArgs } from 'wijmo/wijmo';


//Used for AE Summary Report
@Component({   
  template: `<wj-menu #chartviewmenu [(value)]="rotated" [header]="'ChartView'" [maxDropDownWidth]="15" [maxDropDownHeight]="75" (itemClicked)="menuItemClicked(chartviewmenu, $event)">
                     <wj-menu-item [value]="false">Vertical</wj-menu-item>
                     <wj-menu-item [value]="true">Horizontal</wj-menu-item>
                   </wj-menu>`,  
  selector: 'chart-view-template',
})
export class ChartViewComponent implements OnInit{  
  @Input() rotated: string = '';
  @Output() rotatedChange = new EventEmitter<string>();
  

  constructor(public element: ElementRef) {
   
  }
  ngOnInit(): void {   

  } 


  menuItemClicked(sender: any, e: EventArgs) {
    var menuItem = sender.selectedValue;
    this.rotatedChange.emit(menuItem);
  } 

}
