import { Component, OnInit } from '@angular/core';
import { CaseFieldsService } from '../../user/add-case/case-fields.service';

@Component({
  selector: 'app-age',
  templateUrl: './age.component.html',
  styleUrls: ['./age.component.scss']
})
export class AgeComponent implements OnInit {
  name = "Clinical Characteristics";
  min = 1;
  max = 31;
  age;
  ageError = false;
  userInsertedAge: any;

  public fields;
  constructor(fields: CaseFieldsService) {

    this.fields = fields;
   }

  ngOnInit() {

  }

  setMinMax(ageType:any) {
    this.fields.getField(this.name, 'Age Unit').value = ageType;
    if (ageType == "Days") {
      this.max = 31;
    }
    else if (ageType == "Months") {
      this.max = 12;
    }
    else if (ageType == "Years") {
      this.max = 100;
    }
    if (this.age) {
      this.fields.getField(this.name, 'Age').value = this.userInsertedAge;
      this.checkVal();
    }

  }

  checkVal() {
    
    this.age = this.fields.getField(this.name, 'Age').value;
    // this.age = Math.round(this.age);
    this.age = this.age.toString().split('.')[0];
    this.fields.getField(this.name, 'Age').value = this.age;

    this.userInsertedAge = this.age;

    if (this.age > this.max) {
      this.fields.getField(this.name, 'Age').value = this.max;
    } else if (this.age < this.min) {
      this.fields.getField(this.name, 'Age').value = this.min;
    }
    else {
      this.ageError = false;
    }
  }
}
