
import { Component, OnInit, HostListener } from "@angular/core";
import { CaseService } from '../../user/add-case/case.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-confirmsave-modal',
  templateUrl: './confirmsave-modal.component.html',
  styleUrls: ['./confirmsave-modal.component.scss']
})
export class ConfirmsaveModalComponent implements OnInit {
 
  public caseService: CaseService;

  constructor(  _caseService: CaseService, private router: Router) {
    this.caseService = _caseService;  
  }

  ngOnInit() {
   
  }

  saveCase() {
    if (this.caseService.saveCaseId) {
      if (this.caseService.route == 'editcase')
        this.router.navigate(['/user-portal/edit-case/save/' + this.caseService.saveCaseId]);
      
    
    }
    if (this.caseService.route == 'addcase')
      this.router.navigate(['/user-portal/add-case/save']);
  }

  close() {
    this.caseService.saveCaseId = 0;
  }

 
}
