import { Component, EventEmitter, Input, Inject, enableProdMode, ViewChild, NgModule, Injectable  } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import * as wjcGridSheet from 'wijmo/wijmo.grid.sheet';
import * as wjcInput from 'wijmo/wijmo.input';
import * as wjcGrid from 'wijmo/wijmo.grid';
import * as wjcCore from 'wijmo/wijmo';
import * as wjcXlsx from 'wijmo/wijmo.xlsx';
import * as JSZip from 'jszip';





wjcXlsx.useJSZip(JSZip);
//'use strict';
//Used for AE Summary Report
@Component({
  template: `<wj-flex-sheet #flexSheet (initialized)="flexSheetInit(flexSheet)">
                <wj-sheet name="CaseInfo"></wj-sheet>
                <wj-sheet name="ClinicalInfo"></wj-sheet>
                <wj-sheet name="ProcedureInfo"></wj-sheet>
                <wj-sheet name="HemoInfo"></wj-sheet>
                <wj-sheet name="CaseTypeInfo"></wj-sheet>
                <wj-sheet name="AEEventInfo"></wj-sheet>
                <wj-sheet name="ResourceInfo"></wj-sheet>
                <wj-sheet name="EOCAdmitDispoInfo"></wj-sheet>
                <wj-sheet name="CalculaterRiskValues"></wj-sheet>
              </wj-flex-sheet>
              <div class="row row-margin">
                   <div class="btn-group">                 
                   <button #exporttoexcel class="btn-warning btn-sm" (click)="save()"><i class="fa fa-spinner"></i>&nbsp;ExportToExcel</button>&nbsp;&nbsp;&nbsp; 
                   </div>
              </div>`,
  selector: 'site-extract-template',

})
@Injectable()
export class SiteExtractComponent {
  sheets: any[] = [];
  selectedSheetIndex: number = 0;
  @ViewChild('flexSheet') flexSheet: wjcGridSheet.FlexSheet;
  @Input() cvExtractCaseInfo: wjcCore.CollectionView;
  @Input() cvExtractClinicalInfo: wjcCore.CollectionView;
  @Input() cvExtractProcedureInfo: wjcCore.CollectionView;
  @Input() cvExtractHemoInfo: wjcCore.CollectionView;
  @Input() cvExtractCaseTypeInfo: wjcCore.CollectionView;
  @Input() cvExtractAEEventInfo: wjcCore.CollectionView;
  @Input() cvExtractResourceInfo: wjcCore.CollectionView;
  @Input() cvExtractEOCAdmitDispoInfo: wjcCore.CollectionView;
  @Input() cvExtractCalculatorInfo: wjcCore.CollectionView;
  
  //@Input() cvPaging: wjcCore.CollectionView;
  @Input() exportAll: boolean = false;
  @Input() spinner: NgxSpinnerService;
 

  constructor() {
  }


  flexSheetInit(flexSheet: wjcGridSheet.FlexSheet) {
    var self = this;
     //var data = [];
        //data.push({ id: 1, countryID: 'USA', productID: 'new' });
        //data.push({ id: 2, countryID: 'CAD', productID: 'new' });
        //data.push({ id: 3, countryID: 'AUD', productID: 'new' });
    
    if (flexSheet) {
      flexSheet.deferUpdate(() => {
       //ToDo get data for the other 7 work sheets
        for (var i = 0; i < flexSheet.sheets.length; i++) {
          flexSheet.sheets.selectedIndex = i;
          switch (flexSheet.sheets[i].name) {
            case 'CaseInfo':
              flexSheet.selectedSheet.itemsSource = self.cvExtractCaseInfo;
              //self._initDataMapForBindingSheet(flexSheet);
              break;
            case 'ClinicalInfo':
              flexSheet.selectedSheet.itemsSource = self.cvExtractClinicalInfo;
              //self._initDataMapForBindingSheet(flexSheet);
              break;
            case 'ProcedureInfo':
              flexSheet.selectedSheet.itemsSource = self.cvExtractProcedureInfo;
              //flexSheet.selectedSheet.itemsSource = self.cvExtractClinicalInfo;
              //self._initDataMapForBindingSheet(flexSheet);
              break;
            case 'HemoInfo':
              flexSheet.selectedSheet.itemsSource = self.cvExtractHemoInfo;
              //flexSheet.selectedSheet.itemsSource = self.cvExtractClinicalInfo;
              //self._initDataMapForBindingSheet(flexSheet);
              break;
            case 'CaseTypeInfo':
              flexSheet.selectedSheet.itemsSource = self.cvExtractCaseTypeInfo;
              //flexSheet.selectedSheet.itemsSource = self.cvExtractClinicalInfo;
              //self._initDataMapForBindingSheet(flexSheet);
              break;
            case 'AEEventInfo':
              flexSheet.selectedSheet.itemsSource = self.cvExtractAEEventInfo;
              //flexSheet.selectedSheet.itemsSource = self.cvExtractClinicalInfo;
              //self._initDataMapForBindingSheet(flexSheet);
              break;
            case 'ResourceInfo':
              flexSheet.selectedSheet.itemsSource = self.cvExtractResourceInfo;
              //flexSheet.selectedSheet.itemsSource = self.cvExtractClinicalInfo;
              //self._initDataMapForBindingSheet(flexSheet);
              break;
            case 'EOCAdmitDispoInfo':
              flexSheet.selectedSheet.itemsSource = self.cvExtractEOCAdmitDispoInfo;
              //flexSheet.selectedSheet.itemsSource = self.cvExtractClinicalInfo;
              //self._initDataMapForBindingSheet(flexSheet);
              break;
            case 'CalculaterRiskValues':
              flexSheet.selectedSheet.itemsSource = self.cvExtractCalculatorInfo;
              //flexSheet.selectedSheet.itemsSource = self.cvExtractClinicalInfo;
              //self._initDataMapForBindingSheet(flexSheet);
              break;


          }


          self.sheets.push(flexSheet.sheets[i].name);
        }

        flexSheet.selectedSheetIndex = 0;


      });

      flexSheet.loaded.addHandler(() => {
        var sheetIndex = 0;
        self.sheets.length = 0;
        for (; sheetIndex < flexSheet.sheets.length; sheetIndex++) {
          self.sheets.push(flexSheet.sheets[sheetIndex].name);
        }
        self.selectedSheetIndex = flexSheet.selectedSheetIndex;
      });


    }

    this.spinner.hide();
  }

  
  // Save FlexSheet to xlsx file.
  // export 
   async save() {
    
     this.spinner.show();
      
     var spinner = await this.exportToExcel(this.flexSheet, this.spinner);

     spinner.hide();

    return true;
  }


  async exportToExcel(flexSheet: wjcGridSheet.FlexSheet, spinner: NgxSpinnerService): Promise<NgxSpinnerService> {

    if (flexSheet) {

      await flexSheet.saveAsync('SiteDataExtract.xlsx', function (base64) {       
       
      }, function (reason) {

        
      });

    }

    return spinner;
  }



//  save() {    
   
//    var interval = setInterval(() => {
//      this.spinner.show();
//    }, 100);

//    var interval = setInterval(() => {
//      this.exportToExcel();
//    }, 6000);

    
    
    
//}

//  exportToExcel(){

//    if (this.flexSheet) {
      
//      this.flexSheet.saveAsync('SiteDataExtract.xlsx');
//    }


//    var interval = setInterval(() => {
//      this.spinner.hide();
//    }, 6000);
    
//  }


  exportComplete() {


  }

 

  //  Change the selected sheet for the flexsheet control
  changeSelectedSheet(e) {
    if (this.flexSheet) {
      this.flexSheet.selectedSheetIndex = +e.target.value;
    }
  }




  // initialize the dataMap for the bound sheet.
  private _initDataMapForBindingSheet(flexSheet) {
    var column;

    if (flexSheet) {
      column = flexSheet.columns.getColumn('CaseID');
      if (column && !column.dataMap) {
        column.dataMap = this._buildDataMap(this.cvExtractCaseInfo);
      }
      column = flexSheet.columns.getColumn('CathDate');
      if (column && !column.dataMap) {
        column.dataMap = this._buildDataMap(this.cvExtractCaseInfo);
      }
    }
  }

  // build a data map from a string array using the indices as keys
  private _buildDataMap(items) {
    var map = [];
    for (var i = 0; i < items.length; i++) {
      map.push({ key: i, value: items[i] });
    }
    return new wjcGrid.DataMap(map, 'key', 'value');
  }



  }



