export class ReportDataAE {
  CaseAeID: string;
  CaseID: string;
  Flag: string;
  AEName: string;
  Severity: string;  
  DateOfCath: string;
  Operator: string;
  Age: string;
  PatientInitial: string;
  Preventabilty: string;
  Relationship: string;
  Outcome: string;
  Timing: string;
  TimeToManage: string;
  MajorIndicator: string;
  STSDiagnosis: string;
  CaseType: string;
  Description: string;
  TimeNeeded: string;
  Presentation: string;
  Reviewed: boolean;
  Updatedby: number;
}
