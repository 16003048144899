import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Observable, pipe } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { StsDiagnosisResponse } from '../../shared/sts-diagnosis-response';
import { StsDiagnosisModel } from '../../shared/sts-diagnosis-model';
import { DropdownItemModel } from '../../shared/dropdown-item-model';
import { CONFIG } from '../../shared/config';

let apiUrl = CONFIG.baseUrls.api;
@Injectable()
export class StsDiagnosisService {
  private http: Http = null;

  public stsTreedata = [];
  public treeModel:any
  public stsDiagnosisList: StsDiagnosisModel[];
  public dropDownStsDiagnosis: DropdownItemModel[];

  constructor(_http: Http) {
    this.http = _http;
  }

  getStsDiagnosis() {
    let headers = new Headers({
      //'Content-Type': 'application/x-www-form-urlencoded'
    });
    let options = new RequestOptions({ headers: headers });
    return this.http.get(apiUrl + "picklist/getstsdiagnosislist", options)
      .pipe(map((response: Response) => <StsDiagnosisResponse>response.json()));
  }

  mapToDropDown() {
    let ids = [];
    this.dropDownStsDiagnosis = [];

    for (let entry of this.stsDiagnosisList) {
      if (ids.indexOf(entry.StsDiagParentId) > -1) {
        ids.push(entry.StsDiagId);

        let newChildItem = new DropdownItemModel;
        newChildItem.id = entry.StsDiagId;
        newChildItem.name = entry.StsDiagTextToShow;
        newChildItem.code = entry.StsDiagCode;
        newChildItem.children = [];

        let parentItem = this.dropDownStsDiagnosis.find(x => x.id === entry.StsDiagParentId);
        if (parentItem != null) {
          if (parentItem.children == null) {
            parentItem.children = [];
          }
          parentItem.children.push(newChildItem);
        } else {
          let found = false;
          while (found === false) {
            for (let item of this.dropDownStsDiagnosis) {
              let childParentItem = item.children.find(x => x.id === entry.StsDiagParentId);
              if (childParentItem != null) {
                if (childParentItem.children == null) {
                  childParentItem.children = [];
                }
                childParentItem.children.push(newChildItem);
                found = true;
                break;
              }
            }
          }
        }   
      } else {
        ids.push(entry.StsDiagParentId);
        ids.push(entry.StsDiagId);

        let newParentItem = new DropdownItemModel;
        newParentItem.id = entry.StsDiagParentId;
        newParentItem.name = entry.StsDiagParentTextToShow;
        newParentItem.children = [];

        let newChildItem = new DropdownItemModel;
        newChildItem.id = entry.StsDiagId;
        newChildItem.name = entry.StsDiagTextToShow;
        newChildItem.code = entry.StsDiagCode;
        newChildItem.children = [];

        newParentItem.children.push(newChildItem);
        this.dropDownStsDiagnosis.push(newParentItem);
      }
    }
  }
}
