import { CaseAEItemModel } from './case-ae-item-model';

export class CaseAEInfoModel {
  CaseId: number;
  caseAEItems: CaseAEItemModel[];
  CaseAENotes: string;
  PatientDie: string;
  UnanticipatedCath: string;
  UnanticipatedSurg: string;
  Updatedby: string;
}
