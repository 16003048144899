
import { Component, ViewChild, Input, ElementRef, OnInit, OnChanges, SimpleChanges, SimpleChange, Output, EventEmitter  } from '@angular/core';
import { EventArgs } from 'wijmo/wijmo';


//Used for AE Summary Report
@Component({   
  template: `<wj-menu #charttypemenu [(value)]="chartType" [header]="'Chart Type'" [maxDropDownWidth]="15" [maxDropDownHeight]="100" (itemClicked)="menuItemClicked(charttypemenu, $event)">
                     <wj-menu-item [value]="'Column'">Column</wj-menu-item>
                     <wj-menu-item [value]="'Bar'">Bar</wj-menu-item>
                     <wj-menu-item [value]="'Scatter'">Scatter</wj-menu-item>
                     <wj-menu-item [value]="'Line'">Line</wj-menu-item>
                     <wj-menu-item [value]="'LineSymbols'">LineSymbols</wj-menu-item>
                     <wj-menu-item [value]="'Area'">Area</wj-menu-item>
                     <wj-menu-item [value]="'Spline'">Spline</wj-menu-item>
                     <wj-menu-item [value]="'SplineSymbols'">SplineSymbols</wj-menu-item>
                     <wj-menu-item [value]="'SplineArea'">SplineArea</wj-menu-item>
                   </wj-menu>`,  
  selector: 'chart-type-template',
})
export class ChartTypeComponent implements OnInit{
  private _chartType: string;
  @Input() chartType: string;
  @Output()chartTypeChange = new EventEmitter<string>();
 
  menuItemClicked(sender: any, e: EventArgs) {
    var menuItem = sender.selectedValue;
    this.chartTypeChange.emit(menuItem);
  } 

  constructor(public element: ElementRef) {
   
  }
  ngOnInit(): void {   

  } 

}
