export class CaseHemoDynamicsInfoModel {
  CaseHemoDynamicsInfoId: number;
  CaseId: number;
  IsSingleVenPhysiology: string;
  IsSVEDP: string;
  IsSysSat: string;
  IsMvSat: string;
  IsPAMean: string;
  IsQPQS: string;
  IsPVR: string;
  PhysiologicCategory: string;
  Updatedby: string;
}
