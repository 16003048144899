import { Component, OnInit } from "@angular/core";
import { CalculatorService } from "../../calculator/calculator.service";
import { CategoryFilter } from "../category-filter";

@Component({
  selector: "app-sts-modal",
  templateUrl: "./sts-modal.component.html",
  styleUrls: ["./sts-modal.component.scss"]
})
export class StsModalComponent implements OnInit {

  cat1: string[] = [
    "Airway disease",
    "Aneurysm, Other",
    "Aneurysm, Pulmonary artery",
    "Aneurysm, Ventricular, Right (including pseudoaneurysm)",
    "Aortic valve, Other",
    "AP window (aortopulmonary window)",
    "Arrhythmia",
    "Arrhythmia, Atrial",
    "Arrhythmia, Heart block",
    "Arrhythmia, Heart block, Acquired",
    "Arrhythmia, Heart block, Congenital",
    "Arrhythmia, Junctional",
    "Arrhythmia, Pacemaker, Indication for replacement",
    "Arrhythmia, Ventricular",
    "ASD, Common atrium (single atrium)",
    "ASD, Coronary sinus",
    "ASD, Secundum",
    "ASD, Sinus venosus",
    "AVC (AVSD), Complete (CAVSD)",
    "AVC (AVSD), Intermediate (transitional)",
    "AVC (AVSD), Partial (incomplete) (PAVSD) (ASD, primum)",
    "Cardiac tumor",
    "Cardiac, Other",
    "Cor triatriatum",
    "Coronary artery anomaly, Anomalous aortic origin of coronary artery from aorta (AAOCA)",
    "Coronary artery anomaly, Anomalous pulmonary origin (includes ALCAPA)",
    "DCRV",
    "Dextrocardia",
    "Diaphragm disease, Other",
    "Diaphragm paralysis",
    "DORV + AVSD (AV Canal)",
    "DORV, IVS",
    "DORV, Remote VSD (uncommitted VSD)",
    "DORV, TGA type",
    "Empyema",
    "Esophageal disease, Benign",
    "Esophageal disease, Malignant",
    "Levocardia",
    "Lung disease, Benign",
    "Mesocardia",
    "Miscellaneous, other",
    "Mitral regurgitation",
    "Normal heart",
    "Partial anomalous pulmonary venous connection (PAPVC)",
    "Patent ductus arteriosis",
    "Pectus",
    "Pericardial disease, Other",
    "Pericardial effusion",
    "Pericarditis",
    "PFO",
    "Pleural disease, Benign",
    "Pleural disease, Malignant",
    "Pleural effusion",
    "Pneumothorax",
    "Postoperative bleeding",
    "Pulmonary insufficiency",
    "Pulmonary stenosis, Valvar",
    "Pulmonary valve, Other",
    "Sinus of Valsalva aneurysm",
    "Situs inversus",
    "Status post - Transplant, Heart",
    "Systemic venous anomaly",
    "Systemic venous obstruction",
    "TGA, IVS",
    "Thoracic and/or mediastinal, Other",
    "Total anomalous pulmonary venous connection (TAPVC), Type 1 (supracardiac)",
    "Total anomalous pulmonary venous connection (TAPVC), Type 2 (cardiac)",
    "Tricuspid regurgitation and tricuspid stenosis",
    "Tricuspid regurgitation, non-Ebstein's related",
    "Tricuspid valve, Other",
    "Truncal valve insufficiency",
    "Vascular ring",
    "VSD, Multiple",
    "VSD, Type 1 (Subarterial) (Supracristal) (Conal septal defect) (Infundibular)",
    "VSD, Type 2 (Perimembranous) (Paramembranous) (Conoventricular)",
    "VSD, Type 3 (Inlet) (AV canal type)",
    "VSD, Type 4 (Muscular)",
    "VSD, Type: Gerbode type (LV-RA communication)"
  ];

  cat2: string[] = [
    "Aneurysm, Ventricular, Left (including pseudoaneurysm)",
    "Aortic aneurysm (including pseudoaneurysm)",
    "Aortic arch hypoplasia",
    "Aortic insufficiency",
    "Aortic stenosis, Subvalvar",
    "Aortic stenosis, Valvar",
    "Aortic valve atresia",
    "Atrial Isomerism, Left",
    "Atrial Isomerism, Right",
    "Cardiomyopathy (including dilated, restrictive, and hypertrophic)",
    "Chylothorax",
    "Coarctation of aorta",
    "Conduit failure",
    "Congenitally corrected TGA",
    "Congenitally corrected TGA, IVS",
    "Congenitally corrected TGA, IVS-LVOTO",
    "Congenitally corrected TGA, VSD",
    "Congenitally corrected TGA, VSD-LVOTO",
    "Coronary artery anomaly, Fistula",
    "Coronary artery anomaly, Other",
    "DOLV",
    "DORV, TOF type",
    "Ebstein's anomaly",
    "Endocarditis",
    "Hypoplastic left heart syndrome (HLHS)",
    "Hypoplastic LV",
    "Hypoplastic RV",
    "Interrupted aortic arch",
    "Interrupted aortic arch + AP window (aortopulmonary window)",
    "Interrupted aortic arch + VSD",
    "LV to aorta tunnel",
    "MAPCA(s) (major aortopulmonary collateral[s]) (without PA-VSD)",
    "Meconium aspiration",
    "Mediastinal disease",
    "Mediastinal disease, Benign",
    "Mediastinal disease, Malignant",
    "Mediastinitis",
    "Mitral regurgitation and mitral stenosis",
    "Mitral stenosis",
    "Mitral stenosis, Subvalvar",
    "Mitral stenosis, Subvalvar, Parachute",
    "Mitral stenosis, Supravalvar mitral ring",
    "Mitral stenosis, Valvar",
    "Mitral valve, Other",
    "Partial anomalous pulmonary venous connection (PAPVC), scimitar",
    "Peripheral vascular, Other",
    "Persistent fetal circulation",
    "Primary pulmonary hypertension",
    "Prosthetic valve failure",
    "Pulmonary artery origin from ascending aorta (hemitruncus)",
    "Pulmonary artery sling",
    "Pulmonary artery stenosis (hypoplasia), Main (trunk)",
    "Pulmonary artery stenosis, Branch, Central (within the hilar bifurcation)",
    "Pulmonary artery stenosis, Branch, Peripheral (at or beyond the hilar bifurcation)",
    "Pulmonary artery, Discontinuous",
    "Pulmonary atresia",
    "Pulmonary atresia, IVS",
    "Pulmonary atresia, VSD (Including TOF, PA)",
    "Pulmonary atresia, VSD-MAPCA (pseudotruncus)",
    "Pulmonary AV fistula",
    "Pulmonary insufficiency and pulmonary stenosis",
    "Pulmonary stenosis, Subvalvar",
    "Pulmonary venous stenosis",
    "Rheumatic heart disease",
    "Shone's syndrome",
    "Shunt Failure NA",
    "Single Ventricle + Total anomalous pulmonary venous connection (TAPVC)",
    "Single ventricle, DILV",
    "Single ventricle, DIRV",
    "Single ventricle, Heterotaxia syndrome",
    "Single ventricle, Mitral atresia",
    "Single ventricle, Other",
    "Single ventricle, Tricuspid atresia",
    "Single ventricle, Unbalanced AV canal",
    "TGA, IVS-LVOTO",
    "TGA, VSD",
    "TGA, VSD-LVOTO",
    "TOF, AVC (AVSD)",
    "TOF, Pulmonary stenosis",
    "Total anomalous pulmonary venous connection (TAPVC), Type 3 (infracardiac)",
    "Total anomalous pulmonary venous connection (TAPVC), Type 4 (mixed)",
    "Tricuspid stenosis",
    "Truncus arteriosus",
    "Truncus arteriosus + Interrupted aortic arch",
    "VSD + Aortic arch hypoplasia",
    "VSD + Coarctation of aorta"
  ];

  cat3: string[] = [
    "Aortic dissection",
    "Aortic insufficiency and aortic stenosis",
    "Aortic stenosis, Supravalvar",
    "Cardiomyopathy, End-stage congenital heart disease",
    "Coronary artery anomaly, Aneurysm",
    "Lung disease, Malignant",
    "Myocardial infarction",
    "Pulmonary embolism",
    "Pulmonary vascular obstructive disease",
    "Pulmonary vascular obstructive disease (Eisenmenger's)",
    "TOF, Absent pulmonary valve",
    "Tracheal stenosis"
  ];
  filter:CategoryFilter;
  public calc: CalculatorService

  constructor(calc: CalculatorService) {
    this.calc = calc;
    this.filter = new CategoryFilter(this.cat1, this.cat2, this.cat3);
  }

  ngOnInit() {}

  
}
