


import { NgModule } from "@angular/core";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";

import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule } from "@angular/forms";
import { HttpModule } from "@angular/http";
import { RouterModule } from "@angular/router";
import { TreeModule } from "angular-tree-component";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { OrderModule } from 'ngx-order-pipe';
import { NgxPaginationModule } from 'ngx-pagination'
import { ChartsModule } from 'ng2-charts';
import { NgxSpinnerModule } from 'ngx-spinner';

import { DatePipe } from '@angular/common'
import { HomeComponentC3POR3 } from "./components/home/home.componentC3POR3";
import { HomeComponentIQIC } from "./components/home/home.componentIQIC";
import { NavbarComponent } from "./components/navbar/navbar.component";
import { BackgroundComponent } from "./components/background/background.component";
import { SitesComponent } from "./components/sites/sites.component";
import { IqicBackgroundComponent } from "./components/iqic-background/iqic-background.component";
import { MissionVisionComponent } from "./components/iqic-background/mission-vision/mission-vision.component";
import { BackgroundHistoryComponent } from "./components/iqic-background/background-history/background-history.component";
import { EnrolledPartnersComponent } from "./components/iqic-background/enrolled-partners/enrolled-partners.component";
import { KeyDriverComponent } from "./components/iqic-background/key-driver/key-driver.component";
import { WebinarsComponent } from "./components/iqic-background/webinars/webinars.component";
//import { CathChatComponent } from "./components/iqic-background/cathchat/cathchat.component";
import { DatabaseManualComponent } from "./components/iqic-background/database-manual/database-manual.component";
import { CalculatorComponent } from "./components/calculator/calculator.component";
import { ContactComponent } from "./components/contact/contact.component";
import { LoginComponent } from "./components/login/login.component";
import { C3poComponent } from "./components/background/c3po/c3po.component";
import { C3poIQICComponent } from "./components/background/iqic/c3po-iqic.component";
import { C3poQiComponent } from "./components/background/c3po-qi/c3po-qi.component";
import { C3poR3Component } from "./components/background/c3po-r3/c3po-r3.component";
import { UserMenuComponent } from "./components/user/user-menu/user-menu.component";
import { LoginService } from "./components/user/login.service";
import { ProcedurePicklistService } from "./components/shared-data-entry/procedure-picklist.service";
import { StsDiagnosisService } from "./components/shared-data-entry/sts-diagnosis.service";
import { MajorAdverseEventService } from "./components/shared-data-entry/major-adverse-event.service";
import { OtherAdverseEventService } from "./components/shared-data-entry/other-adverse-event.service";
import { PicklistItemService } from "./components/shared-data-entry/picklist-item.service";
import { MailService } from "./components/contact/mail.service";
//import { HttpClient } from "selenium-webdriver/http";
import { AppComponent } from "./app.component";
import { AddCaseComponent } from "./components/user/add-case/add-case.component";
import { EditCaseComponent } from "./components/user/add-case/edit-case.component";
import { CaseLookupComponent } from "./components/user/case-lookup/case-lookup.component";
import { ManageReportsComponent } from "./components/user/manage-reports/manage-reports.component";
import { PreCalcComponent } from "./components/user/add-case/pre-calc/pre-calc.component";
import { CaseIdComponent } from "./components/user/add-case/case-id/case-id.component";
import { ClinicalCharacteristicsComponent } from "./components/user/add-case/clinical-characteristics/clinical-characteristics.component";
import { ProcedureCharacteristicsComponent } from "./components/user/add-case/procedure-characteristics/procedure-characteristics.component";
import { ProceduralEfficacyComponent } from "./components/user/add-case/procedural-efficacy/procedural-efficacy.component";

import { HemodynamicsComponent } from "./components/user/add-case/hemodynamics/hemodynamics.component";
import { CaseTypesComponent } from "./components/user/add-case/case-types/case-types.component";
import { AdverseEventsComponent } from "./components/user/add-case/adverse-events/adverse-events.component";
import { IndicatorsComponent } from "./components/user/add-case/indicators/indicators.component";
import { EocComponent } from "./components/user/add-case/eoc/eoc.component";
import { C3poCharmComponent } from "./components/background/c3po-charm/c3po-charm.component";

import { CalculatorService } from "./components/calculator/calculator.service";
import { CalcCharacteristicsComponent } from "./components/calculator/calc-characteristics/calc-characteristics.component";
import { CaseTypesEntryComponent } from "./components/shared-data-entry/case-types-entry/case-types-entry.component";

import { ModalComponent } from "./components/modal/modal.component";
import { HemosComponent } from "./components/shared-data-entry/hemos/hemos.component";
import { HemoModalComponent } from "./components/modal/hemo-modal/hemo-modal.component";
import { StsModalComponent } from "./components/modal/sts-modal/sts-modal.component";
//import { SpinnerModalComponent } from "./components/modal/spinner-modal/spinner.component";
import { HemosService } from "./components/shared-data-entry/hemos/hemos.service";
import { CaseTypesEntryService } from "./components/shared-data-entry/case-types-entry/case-types-entry.service";
import { CrispCatComponent } from "./components/shared-data-entry/hemos/crisp-cat/crisp-cat.component";
import { CrispCatSelectService } from "./components/shared-data-entry/hemos/crisp-cat/crisp-cat-select.service";
import { ConfirmModalComponent } from "./components/modal/confirm-modal/confirm-modal.component";
import { ConfirmsaveModalComponent } from "./components/modal/confirmsave-modal/confirmsave-modal.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { StsTreeComponent } from "./components/modal/sts-tree/sts-tree.component";
import { InfoModalComponent } from "./components/modal/info-modal/info-modal.component";
import { InfoModalServiceService } from "./components/modal/info-modal/info-modal-service.service";
import { TitleService } from "./title.service"; 
import { ForgotPasswordComponent } from "./components/login/forgot-password/forgot-password.component";
import { ChangePasswordComponent } from "./components/login/change-password/change-password.component";
import { Routing, routes } from "./routing";
import { ManageUsersComponent } from './components/user/manage-users/manage-users.component';
import { UserManagementService } from "./components/user/manage-users/user-management.service";
import { UserInfoModalComponent } from './components/modal/user-info-modal/user-info-modal.component';
import { CaseLookupService } from "./components/user/case-lookup/case-lookup.service";
import { AdverseEventsReportService } from "./components/user/manage-reports/report-services/adverse-events-report.service";
import { CaseTypeService } from "./components/user/manage-reports/report-services/case-type.service";
import { CharacteristicsService } from "./components/user/manage-reports/report-services/characteristics.service";
//import { DateRangeService } from "./components/user/manage-reports/report-services/date-range.service";
import { HemoVariablesService } from "./components/user/manage-reports/report-services/hemo-variables.service";
import { RadiationService } from "./components/user/manage-reports/report-services/radiation.service";
import { RiskAdjustmentService } from "./components/user/manage-reports/report-services/risk-adjustment.service";
import { RiskTypeService } from "./components/user/manage-reports/report-services/risk-type.service";
import { ColorServiceService } from "./components/user/manage-reports/report-services/color-service.service";
import { ProcedureModalComponent } from './components/modal/procedure-modal/procedure-modal.component';
import { AlertComponent } from './components/modal/alert/alert.component';
import { LoginCardComponent } from './components/login/login-card/login-card.component';
import { CaseTypeCalcService } from "./components/shared-data-entry/case-type-calc.service";
import { MissingFieldsComponent } from "./components/user/add-case/missing-fields/missing-fields.component";
import { CaseFieldsService } from "./components/user/add-case/case-fields.service";
import { CaseService } from "./components/user/add-case/case.service";
import { ValidationPipe } from './pipes/validation.pipe';
import { AgeTypePipe } from './pipes/age-type.pipe';
import { CathDateFormatPipe } from './pipes/cath-date-format.pipe';
import { AgeComponent } from './components/calculator/age/age.component';
import { SectionValidatePipe } from './pipes/section-validate.pipe';
import { PublicationsComponent } from './components/publications/publications.component';
import { SeriousnessComponent } from './components/modal/seriousness/seriousness.component';
import { FooterComponent } from './components/footer/footer.component';
import { CaseVolumeReportByCenterComponent } from './components/user/manage-reports/report-templates/case-volume-report-by-center-component'; 
import { SiteCaseVolumeReportOverTimeComponent } from './components/user/manage-reports/report-templates/site-case-volume-report-over-time-component';
import { AEReportComponent } from './components/user/manage-reports/report-templates/ae-component';
import { MMReportComponent } from './components/user/manage-reports/report-templates/mm-component';
import { MMReportEditComponent } from './components/user/manage-reports/report-templates/mm-edit-component';
import { SiteExtractComponent } from './components/user/manage-reports/report-templates/site-extract-component';
import { ChartTypeComponent } from './components/user/manage-reports/report-templates/chart-type-menu-component'; 
import { StackedItemsComponent } from './components/user/manage-reports/report-templates/stacked-items-menu-component';
import { ChartViewComponent } from './components/user/manage-reports/report-templates/chart-view-menu-component';
import { RouterService } from "./components/navigation/router.service";
import { SafeHtmlPipe } from './safehtmlpipe';
import { CanDeactivateGaurd } from './CanDeactivateGaurd';
import { CanLogoutDeactivateGuard } from './CanLogoutDeactivateGaurd';
import { ProgressBarMaterialModule } from '../app/components/calculator/material-module'



// import Wijmo modules
import { WjChartModule } from 'wijmo/wijmo.angular2.chart';
import { WjGridModule } from 'wijmo/wijmo.angular2.grid';
import { WjGridFilterModule } from 'wijmo/wijmo.angular2.grid.filter';
import { WjInputModule } from 'wijmo/wijmo.angular2.input';
import { WjGridDetailModule } from "wijmo/wijmo.angular2.grid.detail";
import { WjChartAnimationModule } from 'wijmo/wijmo.angular2.chart.animation';

import { CommonModule } from '@angular/common';
import { WjGridSheetModule } from 'wijmo/wijmo.angular2.grid.sheet';
import { WjNavModule } from 'wijmo/wijmo.angular2.nav';


//License Key for wijmo
import * as wijmo from 'wijmo/wijmo';
//wjcCore.setLicenseKey("Boston Children's Hospital,chboston.org|localhost|cardion40482|cardio.chboston.org|chcvpwebdev1|c3po-r3test.chboston.org|c3po-r3dev.chboston.org|c3po-r3.chboston.org,412145521768249#B0eNOIu3Gdz3mQiojIh94QiwiI9QjM8YzNxITN5QTMyEDNiojIklkIs4nIyYHOxAjMiojIyVmdiwSZzxWYmpjIyNHZisnOiwmbBJye0ICRiwiI34zZxA5aGVFc6YXaXpnTsBTOxgzYJVXNRJ4R4tmRYZTM4d7cYdmV5c7KFlzbrMVb6FFdhRENuZTQyo5NyhnZ5klYslTa6xmeHlTWNpmaSlUWy2SeFB5ZzIUWzV4b0FEZRxme5s4MUdDW4NnT6J5M6EkUOd6Zq56LycVaEJFUyJWdrU6QwNDaZhVOtVnVK9GMNB7QCdjeURmQrUmbhBnW0lWSDlWO8B7Zxw6RoR6YPhnaWdGcCRzdmRHO8hUTEhUSuh6aVF6bSJkUSJWMkhVRoNUdT9UUQFWW4h5SrgGexwUNv8kQroXT4MkaQd4UtRURWNDcFVjR4BXND34cllFTwo4R05mTZFmYSN7NwZVdvREbJlma5QUWZ34Yy2mc5pWRihXUXRDatZTbWJzQSFXQGtGNU9kWW5EcywEUUFFUCxEMhJXcHR4Y8djUyY6MItmY9MkcKhnd9omI0IyUiwiIzETQFREO5UjI0ICSiwSNxQzM6QDN7AjM0IicfJye35XX3JSSwIjUiojIDJCLi86bpNnblRHeFBCI4VWZoNFelxmRg2Wbql6ViojIOJyes4nI5kkTRJiOiMkIsIibvl6cuVGd8VEIgIXZ7VWaWRncvBXZSBybtpWaXJiOi8kI1xSfis4N8gkI0IyQiwiIu3Waz9WZ4hXRgAydvJVa4xWdNBybtpWaXJiOi8kI1xSfiQjR6QkI0IyQiwiIu3Waz9WZ4hXRgACUBx4TgAybtpWaXJiOi8kI1xSfiMzQwIkI0IyQiwiIlJ7bDBybtpWaXJiOi8kI1xSfiUFO7EkI0IyQiwiIu3Waz9WZ4hXRgACdyFGaDxWYpNmbh9WaGBybtpWaXJiOi8kI1tlOiQmcQJCLiAzMxMzNwACNxETM8EDMyIiOiQncDJCLicmcv9ibvR7cvJGaj9yMy5ybwNzYscmcv9ibvR7cvJGaj9idlR6My5ybwNzYscmcv9ibvR7cvJGaj9CdzVGdzIXLvB7MjxSM6VGZiV6dwZ7YoNGLnJ7bu86b4N7bih6Yu2WakJXYjxiM8QDM486bpRmchNGL4N7boxWYj3Gbscmcv9ibvR7cvJGajJiOiMXbEJCLiwWY4lGcz3GSgM7JuVmcwxKa");
wijmo.setLicenseKey("Boston Children's Hospital,c3po-r3dev.chboston.org|c3po-r3test.chboston.org|c3po-r3.chboston.org,326292914978239#B0KIJlkIs4nIyYXOxAjMiojIyVmdiwSZzxWYmpjIyNHZisnOiwmbBJye0ICRiwiI34zZhhVbixWMqJUOBZ6cM9UaGhTQwMkYtJ4dlR4U5xGRE5ka6MHR9kzdzc4YFV4UxdzM83SZzRldFNkWJxUO9VnZTJTcJRneOtERBZUTChGR0Vzd6RDbLZ5SjpHeJpXatNENJl5VmBjcT54UDJEUNh5NKZGc4dkQQNjM7ZzRZ5EcnFVVlV7NTd4NRN4ZBVkYsl4aQtUVOt6U6AFexkHen54YKZTTnZWOsFGOwR4QshEOulVUEhGWN9mev9UUJ3SMsV4R6kDN4RVQzIjZTRENv5mV78kMSp5VEJWZ7lVWPVWStp5VWNzcIFlSrQ4cjRTevlTSzFDczFVW586QQtmehVTclJVR6c6LTdUYGJmeJFzNHJnTvF7ZChHMOh6MsNVRQZGVIdXR8gTdRdFO8xUcudFdEZHNm3UV05ET9FTVzcVNr8EMYt4RnJ6YDhWWTFUZSVmUJJVVxVmI0IyUiwiIBJTRFJUOGNjI0ICSiwiN5ATNzYDN7cTM0IicfJye35XX3JSSwIjUiojIDJCLi86bpNnblRHeFBCI4VWZoNFelxmRg2Wbql6ViojIOJyes4nI5kkTRJiOiMkIsIibvl6cuVGd8VEIgIXZ7VWaWRncvBXZSBybtpWaXJiOi8kI1xSfis4N8gkI0IyQiwiIu3Waz9WZ4hXRgAydvJVa4xWdNBybtpWaXJiOi8kI1xSfiQjR6QkI0IyQiwiIu3Waz9WZ4hXRgACUBx4TgAybtpWaXJiOi8kI1xSfiMzQwIkI0IyQiwiIlJ7bDBybtpWaXJiOi8kI1xSfiUFO7EkI0IyQiwiIu3Waz9WZ4hXRgACdyFGaDxWYpNmbh9WaGBybtpWaXJiOi8kI1tlOiQmcQJCLicDNyEjNwACMxITM9EDMyIiOiQncDJCLicmcv9ibvR7cvJGaj9yMy5ybwNzYscmcv9ibvR7cvJGaj9CdzVGdzIXLvB7MjxyZy3mLu3Gdz3mYoNmL6VGZzIXLvB7MjJiOiMXbEJCLiwWY4lGcz3GSgM7JuVmckxWaoNEIu3Gdz3mQiojIh94QiwiI9MjM8cTO4ETOykjM6IIMZo");
@NgModule({
  declarations: [
    AppComponent,
    HomeComponentC3POR3,
    HomeComponentIQIC,
    NavbarComponent,
    BackgroundComponent,
    SitesComponent,
    IqicBackgroundComponent,
    MissionVisionComponent,
    BackgroundHistoryComponent,
    EnrolledPartnersComponent,
    KeyDriverComponent,
    WebinarsComponent,
   // CathChatComponent,
    DatabaseManualComponent,
    CalculatorComponent,
    ContactComponent,
    LoginComponent,
    C3poComponent,
    C3poIQICComponent, 
    C3poQiComponent,
    C3poR3Component,
    UserMenuComponent,
    AddCaseComponent,
    EditCaseComponent,
    ManageReportsComponent,
    CaseLookupComponent,
    PreCalcComponent,
    CaseIdComponent,
    ClinicalCharacteristicsComponent,
    ProcedureCharacteristicsComponent,
    ProceduralEfficacyComponent,
    HemodynamicsComponent,
    CaseTypesComponent,
    AdverseEventsComponent,
    IndicatorsComponent,
    EocComponent,
    C3poCharmComponent,
    CalcCharacteristicsComponent,
    CaseTypesEntryComponent,
    HemosComponent,
    HemoModalComponent,
    ModalComponent,
    StsModalComponent,
    CrispCatComponent,
    StsTreeComponent, 
    InfoModalComponent,
    //SpinnerModalComponent,
    ConfirmModalComponent,
    ConfirmsaveModalComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    ManageUsersComponent,
    UserInfoModalComponent,
    ProcedureModalComponent,
    AlertComponent,
    LoginCardComponent,
    MissingFieldsComponent,
    ValidationPipe,
    AgeTypePipe,
    CathDateFormatPipe,
    AgeComponent,
    SectionValidatePipe,
    PublicationsComponent,
    SeriousnessComponent,
    FooterComponent,
    CaseVolumeReportByCenterComponent,
    SiteCaseVolumeReportOverTimeComponent,
    AEReportComponent,
    MMReportComponent,
    MMReportEditComponent,
    SiteExtractComponent,
    ChartTypeComponent,
    StackedItemsComponent,
    ChartViewComponent,    
    SafeHtmlPipe
    
  ],
  imports: [

    BrowserModule,
    BrowserAnimationsModule,
    HttpModule,
    TreeModule,
    FormsModule,
    RouterModule,
    NgSelectModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    Routing,
    Ng2SearchPipeModule,
    OrderModule,
    NgxPaginationModule,
    ChartsModule,   
    WjChartModule,
    WjGridModule,    
    WjInputModule,
    WjGridFilterModule,
    WjGridDetailModule,
    WjChartAnimationModule,
    NgxSpinnerModule,
    MatTableModule,
    MatTabsModule,   
    CommonModule,    
    WjGridSheetModule,
    WjNavModule,
    ProgressBarMaterialModule
 
  ],
  providers: [
    DatePipe,
    TitleService,
    LoginService,
    ProcedurePicklistService,
    StsDiagnosisService,
    MajorAdverseEventService,
    OtherAdverseEventService,
    PicklistItemService,
    CalculatorService,
    HemosService,
    CaseTypesEntryService,
    CrispCatSelectService,
    InfoModalServiceService,
    UserManagementService,
    CaseLookupService,
    AdverseEventsReportService,
    CaseTypeService,
    CharacteristicsService,
    //DateRangeService,
    HemoVariablesService,
    RadiationService,
    RiskAdjustmentService,
    RiskTypeService,
    ColorServiceService,
    CaseTypeCalcService,
    CaseFieldsService,
    CaseService,
    MailService,
    RouterService,
    CanDeactivateGaurd,
    CanLogoutDeactivateGuard
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private routerService: RouterService) { }
}
