import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "cathDateFormat"
})
export class CathDateFormatPipe implements PipeTransform {
  transform(valString: string): string {
    valString = valString.substring(0, 10);

    let valArray = valString.split('-');
    let year = valArray[0];
    let month = valArray[1];
    let day = valArray[2];

    return [month, day, year].join('/');    
  }
}
