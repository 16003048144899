import * as wjChart from 'wijmo/wijmo.angular2.chart';
import { Component, ViewChild, Input } from '@angular/core';
import { ReportModelDetails } from '../../../../shared/report-model-details';
 
@Component({
    //directives: [wjChart.WjFlexChart, wjChart.WjFlexChartSeries],
    template: `<wj-flex-chart [itemsSource]="data" [bindingX]="'SiteLegend'" [chartType]="chartType" [stacking]="stacking" [rotated]="rotated">
                    <wj-flex-chart-axis [wjProperty]="'axisX'" [title]="titleX"></wj-flex-chart-axis>
                    <wj-flex-chart-axis [wjProperty]="'axisY'" [title]="totalCasesLabel"></wj-flex-chart-axis>
                    <wj-flex-chart-series *ngIf="showYAxisData" [binding]="'YAxis'" [name]="XAxisLabel"></wj-flex-chart-series>
                    <div *ngIf="showStackedItems">
                      <wj-flex-chart-series [name]="'< 30 days'" [binding]="'LessThan30Days'"></wj-flex-chart-series>
                      <wj-flex-chart-series [name]="'30 days to 1 year'" [binding]="'ThirtyDaysToOneYear'"></wj-flex-chart-series>
                      <!--<wj-flex-chart-series [name]="'< 1 Year'" [binding]="'LessThanOneYear'"></wj-flex-chart-series>
                          <wj-flex-chart-series [name]="'>= 1 Year'" [binding]="'GreaterThanOrEqualToOneYear'"></wj-flex-chart-series>-->
                      <wj-flex-chart-series [name]="'1 to 18 Years'" [binding]="'OneToEightteenYears'"></wj-flex-chart-series>
                      <wj-flex-chart-series [name]="'18 Years'" [binding]="'EightTeenYears'"></wj-flex-chart-series>
                    </div>
                    <wj-flex-chart-legend [position]="legendPosition"></wj-flex-chart-legend>
                     <!--"Top", "Bottom", "Left", "Right", and "None" (case-sensitive).-->
                    <wj-flex-chart-animation #animation [easing]="easing" [duration]="duration"></wj-flex-chart-animation>
                  </wj-flex-chart>`,  
  selector: 'site-case-volume-report-over-time-template',
})
export class SiteCaseVolumeReportOverTimeComponent {    
  @Input() data: ReportModelDetails[];
  @Input() chartType: string;
  @Input() stacking: string;
  @Input() rotated: string;
  @Input() titleX: string;
  @Input() totalCasesLabel: string;
  @Input() XAxisLabel: string;
  @Input() showStackedItems: boolean;
  @Input() showYAxisData: boolean;
  @Input() legendPosition: string;
  @Input() easing: string;
  @Input() duration: number;
  

  //constructor() {
  //  this.data = new ReportModelDetails; 


  //}

  onChartRendered() {

  }

}
