import { Component, OnInit, Input } from '@angular/core';
import { CrispCatSelectService } from './crisp-cat-select.service';

@Component({
  selector: 'app-crisp-cat',
  templateUrl: './crisp-cat.component.html'
})
export class CrispCatComponent implements OnInit {
  @Input() pre: boolean;

  public cat:CrispCatSelectService;
  constructor(cat:CrispCatSelectService) {
    this.cat = cat;       
   }

  ngOnInit() {
    if (this.pre) {
      this.cat.name = "Pre-Case Calculation";
    } else {
      this.cat.name = "Hemodynamics";
    }
    this.cat.setPC(); 
  }
}
