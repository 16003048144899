import { Component, OnInit,ViewChild,HostListener } from '@angular/core';
import { CaseFieldsService } from '../case-fields.service';
import { LoginService } from "../../../user/login.service";
import { PicklistItemService } from "../../../shared-data-entry/picklist-item.service";
import { PicklistItemResponse } from "../../../../shared/picklist-item-response";
import { PicklistItemModel } from "../../../../shared/picklist-item-model";
import { DropdownItemModel } from "../../../../shared/dropdown-item-model";
import { NgForm, FormGroup } from "@angular/forms";
import { ComponentCanDeactivate } from '../../../../CanDeactivateGaurd';
import { Observable } from 'rxjs';
import { filter, map, mergeMap, tap } from "rxjs/operators";
import { Router, ActivatedRoute, ParamMap, NavigationEnd, NavigationStart, RouterEvent } from "@angular/router";

@Component({
  selector: 'app-eoc',
  templateUrl: './eoc.component.html',
  styleUrls: ['./eoc.component.scss'],
  host: { 'class': "host" }
})
export class EocComponent implements OnInit, ComponentCanDeactivate {
  @ViewChild('form') form: NgForm;
  locationsDropDown = [];
  
  name = "EOC Admit/Disposition"
  public fields: CaseFieldsService;
  public user: LoginService;
  public picklistItemService: PicklistItemService;
  constructor(private router: Router,fields: CaseFieldsService, _user: LoginService, _picklistItemService: PicklistItemService) {
    router.events
      .pipe(filter(e => e instanceof NavigationStart))
      .subscribe((e: NavigationStart) => {
        localStorage.setItem('editcase', e.url);
      });
    this.fields = fields;
    this.user = _user;
    this.picklistItemService = _picklistItemService;

    if (this.user.user) {
      this.picklistItemService
        .getPicklistItem(this.user.user.HospitalId.toString(), "Post Cath Location")
        .subscribe(data => this.populatePicklistItems(data));
    }
  }

  ngOnInit() {
   
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    if (this.form.dirty)
      localStorage.setItem('dirty', 'true');


    return !this.form.dirty;
  }

  private populatePicklistItems(picklistItemResponse: PicklistItemResponse) {
    this.picklistItemService.picklistItemModel = picklistItemResponse.DataObject;
    this.picklistItemService.mapToDropDown();

    this.locationsDropDown = this.picklistItemService.dropDownPicklistItem;
  }

}
