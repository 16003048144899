

import { Component, ViewChild, Input, ElementRef, OnInit, NgModule } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import * as JSZip from 'jszip';
import * as wjcCore from 'wijmo/wijmo';
import * as wjcGrid from 'wijmo/wijmo.grid';
import * as wjcGridFilter from 'wijmo/wijmo.grid.filter';
import * as wjcGridDetail from 'wijmo/wijmo.grid.detail';

import * as wjcXlsx from 'wijmo/wijmo.xlsx';
import * as gridXls from 'wijmo/wijmo.grid.xlsx';
import * as wjcGridPdf from 'wijmo/wijmo.grid.pdf';
import { GroupRow } from 'wijmo/wijmo.grid';


wjcXlsx.useJSZip(JSZip);
//Used for MM Summary Report
@Component({
  template: `<input type="checkbox" #checkbox (click)="showGridDetails(checkbox, gridmm, $event)" id="expand-all" name="expand-all" [value]="false" />&nbsp;Expand All    
                   <wj-flex-grid #gridmm [itemsSource]="cvPagingmm" selection-mode="Row">                  
                   <!--<wj-flex-grid-filter #filter [hidden]="true"></wj-flex-grid-filter>-->
                   <wj-flex-grid-column [header]="'MRN'" [binding]="'MRN'" [width]="90"></wj-flex-grid-column>                 
                   <wj-flex-grid-column [header]="'Date Of Cath'" [binding]="'DateOfCath'" [width]="110"></wj-flex-grid-column>
                   <wj-flex-grid-column [header]="'Last Name'" [binding]="'LastName'" [width]="100"></wj-flex-grid-column>
                   <wj-flex-grid-column *ngIf="showOperatorColumn" [header]="'Operator'" [binding]="'Operator'" [width]="140"></wj-flex-grid-column>
                   <wj-flex-grid-column [header]="'Fellow'" [binding]="'Fellow'" [width]="100"></wj-flex-grid-column>
                   <wj-flex-grid-column [header]="'AdverseEvent'" [binding]="'AEName'" [width]="'*'"></wj-flex-grid-column>     
                   <wj-flex-grid-column [header]="'Severity'" [binding]="'Severity'" [width]="'*'"></wj-flex-grid-column>                             
                   <wj-flex-grid-column [header]="'Preventability'" [binding]="'Preventability'" [width]="110"></wj-flex-grid-column>
                   <wj-flex-grid-column [header]="'Attributability'" [binding]="'Attributability'" [width]="110"></wj-flex-grid-column>                                                                                           
                    <ng-template wjFlexGridDetail #dp='wjFlexGridDetail' [maxHeight]="350" [detailVisibilityMode]="'ExpandMulti'" ngFor let-item="item" >
                        <div class="container-fluid">
                            <div id="mmdetailslist" name="mmdetailslist">
                                <ul style="list-style: none;">
                                    <li>
                                        <div class="row">
                                            <div class="col-sm-8 " id="mmCaseType" name="CaseType"><b>CaseType:</b>&nbsp;{{item.CaseType}}</div>
                                            <div class="col-sm-4 " id="mmAge" name="Age"><b>Age:</b>&nbsp;{{item.Age}}</div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-12 " id="mmTimeNeeded" name="Time Needed"><b>Time Needed:</b>&nbsp;{{item.TimeNeeded}}</div>

                                        </div>
                                        <div class="row">
                                            <div class="col-sm-12 " id="mmPresentation" name="Presentation"><b>Presentation:</b>&nbsp;{{item.Presentation}}</div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm wrap-div-text" id="mmDescription" name="Description"><b>Description:</b>{{item.Description}}</div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </ng-template>
                 </wj-flex-grid>
                  <!--Hidden grid used for grid details export to Excel-->
                  <wj-flex-grid #hiddenGridMM [itemsSource]="reportDataMM" (initialized)="initHiddenGrid(hiddenGridMM)" [hidden]="true">                  
                   <wj-flex-grid-column [header]="'MRN'" [binding]="'MRN'" [width]="110"></wj-flex-grid-column>   
                   <wj-flex-grid-column [header]="'DateOfCath'" [binding]="'DateOfCath'"></wj-flex-grid-column>
                   <wj-flex-grid-column [header]="'LastName'" [binding]="'LastName'" [width]="110"></wj-flex-grid-column>
                   <wj-flex-grid-column *ngIf="showOperatorColumn" [header]="'Operator'" [binding]="'Operator'" ></wj-flex-grid-column>
                   <wj-flex-grid-column [header]="'Fellow'" [binding]="'Fellow'" [width]="110"></wj-flex-grid-column>
                   <wj-flex-grid-column [header]="'Age'" [binding]="'Age'" ></wj-flex-grid-column>
                   <!--<wj-flex-grid-column [header]="'Sex'" [binding]="'Sex'" ></wj-flex-grid-column>-->
                 
                   <wj-flex-grid-column [header]="'R3CaseType'" [binding]="'CaseType'"></wj-flex-grid-column>
                   <wj-flex-grid-column [header]="'Severity'" [binding]="'Severity'"></wj-flex-grid-column>
                   <wj-flex-grid-column [header]="'Preventability'" [binding]="'Preventability'" [width]="110"></wj-flex-grid-column>
                   <wj-flex-grid-column [header]="'Attributability'" [binding]="'Attributability'" [width]="110"></wj-flex-grid-column>
                   <wj-flex-grid-column [header]="'Time Needed'" [binding]="'TimeNeeded'" [width]="110"></wj-flex-grid-column>
                  <wj-flex-grid-column [header]="'Presentation'" [binding]="'Presentation'" [width]="110"></wj-flex-grid-column>
                    <wj-flex-grid-column [header]="'AEName'" [binding]="'AEName'" [width]="'*'"></wj-flex-grid-column>
                   <wj-flex-grid-column [header]="'Description'" [binding]="'Description'" [width]="'*'"></wj-flex-grid-column>
                 
                   </wj-flex-grid>
                 
                  <div class="row row-margin">
                   <div class="btn-group">                  
                   <div>
                     <input [(ngModel)]="exportAllmm" type="radio" id="export-this-page" name="export-this-page" [value]="false" (click)="showThisGridDetails(checkbox, gridmm, $event)">
                     <label class="btn-sm" for="export-this-page">This Page</label>

                     <input [(ngModel)]="exportAllmm" type="radio" id="export-all-data" name="export-all-data" [value]="true" (click)="showAllGridDetails(checkbox, gridmm, $event)">
                     <label class="btn-sm" for="export-all-data">Ten Pages</label>&nbsp;&nbsp;
                   </div>
                    </div>
                   <div class="btn-group">
                   <button #exporttopdfMM class="btn-warning btn-sm" (click)="exportToPDF(gridmm)"><i class="fa fa-spinner"></i>&nbsp;ExportToPDF</button>&nbsp;&nbsp;&nbsp;
                   <button #exporttoexcelMM class="btn-warning btn-sm" (click)="exportToExcel(hiddenGridMM)"><i class="fa fa-spinner"></i>&nbsp;ExportAllToExcel</button>&nbsp;&nbsp;&nbsp; 
                   </div>
                   <br>
                   <div class="btn-group max-width d-flex align-items-center justify-content-center" style="padding-top:10px">
                     <button type="button" class="btn btn-primary"
                             (click)="cvPagingmm.moveToFirstPage();showGridDetails(checkbox, gridmm, $event)"
                             [disabled]="cvPagingmm.pageIndex <= 0">
                       First
                     </button>
                     <button type="button" class="btn btn-primary"
                             (click)="cvPagingmm.moveToPreviousPage();showGridDetails(checkbox, gridmm, $event)"
                             [disabled]="cvPagingmm.pageIndex <= 0">
                       Previous
                     </button>
                     <button type="button" class="btn btn-primary"
                             (click)="cvPagingmm.moveToNextPage();showGridDetails(checkbox, gridmm, $event)"
                             [disabled]="cvPagingmm.pageIndex >= cvPagingmm.pageCount - 1">
                       Next
                     </button>
                     <button type="button" class="btn btn-primary"
                             (click)="cvPagingmm.moveToLastPage();showGridDetails(checkbox, gridmm, $event)"
                             [disabled]="cvPagingmm.pageIndex >= cvPagingmm.pageCount - 1">
                       Last
                     </button>
                   </div>
                 </div>`,
  selector: 'mm-report-template',
  //Works for Chrome IE in Native Emulated for IE in debug mode does not render?
  //encapsulation: ViewEncapsulation.Emulated,
  //styleUrls: [
  //  "../../../../../../node_modules/wijmo/styles/wijmo.css" 
  //]

})
export class MMReportComponent implements OnInit {
  [x: string]: any;
  @ViewChild('filter') flexFilter: wjcGridFilter.FlexGridFilter;
  @ViewChild('dp') dp: wjcGridDetail.FlexGridDetailProvider;
  @ViewChild('dph') dph: wjcGridDetail.FlexGridDetailProvider;  
  //This gets a reference to the existing grid 
  @ViewChild('gridmm') flexGrid: wjcGrid.FlexGrid;
  @ViewChild('gridPDFmm') flexGridPDF: wjcGrid.FlexGrid;
  @Input() cvPagingmm: wjcCore.CollectionView;
  @Input() reportDataMM: wjcCore.CollectionView;
  @Input() exportAllmm: boolean = false;
  @Input() spinner: NgxSpinnerService;
  @Input() showOperatorColumn: boolean = false;
  //pdfWorkerComponent: PDFWorkerComponent;
  rowFooter:wjcGrid.GroupRow = new wjcGrid.GroupRow();
  isFooterDisplayed: boolean = true;


  constructor(public element: ElementRef ) {
  }


  ngOnInit(): void {

  }

  init(s: wjcGrid.FlexGrid) {   

    //Set the default sorting on CaseID   
    this.cvPagingmm.sortDescriptions.push(new wjcCore.SortDescription("MRN", true));   
    this.cvPagingmm.sortDescriptions.push(new wjcCore.SortDescription("DateOfCath", true));  


  }

  initHiddenGrid(hiddenGrid: wjcGrid.FlexGrid) {
    //this is used to group CaseID on a flex grid
    //var groupDescription = new wjcCore.PropertyGroupDescription('CaseID',);
    //hiddenGrid.collectionView.groupDescriptions.push(groupDescription);
    //hiddenGrid.columnFooters.rows.push(this.rowFooter);
    //hiddenGrid.bottomLeftCells.setCellData(0, 0, '\u03A3');
  }


  exportOptions = {
    exportMode: wjcGridPdf.ExportMode.All,
    customCellContent: true,
    drawDetailRows: true,
    maxPages: 10,
    scaleMode: wjcGridPdf.ScaleMode.PageWidth,
    documentOptions: {
      compress: true,
      header: { declarative: { text: '\t&[Page] of &[Pages]' } },
      footer: { declarative: { text: '\t&[Page] of &[Pages]' } },
      info: { author: 'C1', title: 'HtmlDetail' }
    },
    styles: {
      cellStyle: {
        backgroundColor: '#ffffff',
        borderColor: '#000000'
      },
      altCellStyle: {
        backgroundColor: '#f9f9f9',
        borderColor: '#000000'
      },
      headerCellStyle: {
        backgroundColor: '#eaeaea'
      }
    },

    formatItem: function (args) {

      if (args.panel.cellType === wjcGrid.CellType.Cell) {
        var row = args.panel.rows[args.row];

        if (row.detail != null) {
          args.cancel = false;

          var detail = row.detail.childNodes[0],
            doc = args.canvas.document,
            clr = args.clientRect,
            cnr = args.contentRect;

          doc.saveState();

          args.canvas.paths.rect(clr.left, clr.top, clr.width, clr.height).clip();

          var rIndex = row.index;

          //Get a list of the MMItems
          var mmListItems = row.detail.getElementsByTagName('div').namedItem('mmdetailslist').getElementsByTagName('li');

          //Now loop through the list collection of MM Events
          var yPosition = 17;
          for (var i = 0; i < mmListItems.length; i++) {
            var mmName = mmListItems[i].getElementsByTagName('div').namedItem('mmCaseType').innerText;
            var severity = mmListItems[i].getElementsByTagName('div').namedItem('mmAge').innerText;
            doc.drawText(mmName, 60, clr.top + yPosition, { height: 10, width: 250 });
            doc.drawText(severity, 400, clr.top + yPosition, { height: 10, width: 75 });
            //Increment the y position for each list item
            yPosition = yPosition + 12;
          }

          //This needs to adjust based on the amount of items in the list above
          var yPositionTimeneeded = yPosition;
          var timeneeded = row.detail.getElementsByTagName('div').namedItem('mmTimeNeeded').innerText;
          doc.drawText(timeneeded, 60, clr.top + yPositionTimeneeded, { height: 10, width: 500 });

          var yPositionPresentation = 10 + yPosition;         
          var presentation = row.detail.getElementsByTagName('div').namedItem('mmPresentation').innerText;        
          doc.drawText(presentation, 60, clr.top + yPositionPresentation, { height: 10, width: 500 });

          var yPositionText = 30 + yPosition;
         // doc.drawText('Description', 50, clr.top + yPositionDescription, { height: 5, width: 75 });
          var description = row.detail.getElementsByTagName('div').namedItem('mmDescription').innerText;
          //This next line roughly adjusts the height based on the amount of text in each line
          var descHeight = description.length > 80 ? (description.length / 120) * 25 : 80;
          doc.drawText(description, 60, clr.top + yPositionText, { height: 1000, width: 500 });

          //args.canvas.paths.lineTo(descHeight, 500);          

          // wjcGridPdf.FlexGridPdfConverter.drawToPosition(this.detail, doc, new wjcCore.Point(cnr.left, cnr.top), null, null, { customCellContent: true });
          doc.restoreState();
          args.cancel = true;

        }
      }
    }


  };

  showGridDetails(sender: any, gridmm: wjcGrid.FlexGrid, e: wjcCore.EventArgs) {
    var checkBox = sender;

    gridmm.beginUpdate();
    if (checkBox.checked == true) {
      for (var i = 0; i < gridmm.rows.length; i++) {
        this.dp.showDetail(i);
      }
    }

    if (checkBox.checked == false) {
      for (var i = 0; i < gridmm.rows.length; i++) {
        this.dp.hideDetail(i);
      }
    }

    gridmm.endUpdate()
  }


  showThisGridDetails(sender: any, gridmm: wjcGrid.FlexGrid, e: wjcCore.EventArgs) {
    var checkBox = sender;

    gridmm.beginUpdate();

    this.cvPagingmm.pageSize = 10;

    if (checkBox.checked == false) {
      for (var i = 0; i < gridmm.rows.length; i++) {
        this.dp.hideDetail(i);
      }
    }

    if (checkBox.checked == true) {
      for (var i = 0; i < gridmm.rows.length; i++) {
        this.dp.showDetail(i);
      }
    }
    gridmm.endUpdate()
  }

  showAllGridDetails(sender: any, gridmm: wjcGrid.FlexGrid, e: wjcCore.EventArgs) {
    var checkBox = sender;

    gridmm.beginUpdate();

    this.cvPagingmm.pageSize = 100;

    if (checkBox.checked == false) {
      for (var i = 0; i < gridmm.rows.length; i++) {
        this.dp.hideDetail(i);
      }
    }

    if (checkBox.checked == true) {
      for (var i = 0; i < gridmm.rows.length; i++) {
        this.dp.showDetail(i);
      }
    }
    gridmm.endUpdate()
  }


  exportToPDF(gridmm: wjcGrid.FlexGrid) {
    this.spinner.show();
    this.exportToPDFInterval(gridmm);
    //var interval = setInterval(() => {
    //  this.exportToPDFInterval(grid, interval);
    //}, 1000);   

  }



  exportToPDFInterval(gridmm: wjcGrid.FlexGrid) {
   
    var PDFExportFileName = 'M&MDetails.pdf';

    if (this.exportAllmm == true) {
      //Temp turn off paging to export the entire grid
      PDFExportFileName = 'M&MDetails_ ' + new Date().toLocaleString() + '.pdf';
    }

   wjcGridPdf.FlexGridPdfConverter.export(gridmm, PDFExportFileName, this.exportOptions);

    this.spinner.hide();
  }


  // Callback function executed when PDF creation is completed
done() {
  //isExporting = false;
 // saveButton1.innerText = 'Create PDF(using Web Worker)';
  //saveButton2.disabled = false;
}

// Callback function executed during PDF creation
progress(value) {
  //value = Math.floor((value * 100));
  //progressText.innerText = value;
  //progressGauge.value = value;
}

  exportToExcel(hiddenGrid: wjcGrid.FlexGrid) {

    this.spinner.show();

   

   
    gridXls.FlexGridXlsxConverter.saveAsync(hiddenGrid, {      
      includeColumnHeaders: true, sheetName: 'M&MInfo',
    }, 'M&MSummary_' + new Date().toLocaleString() + '.xlsx');

    


    this.spinner.hide();
    
  }

  importGrid(gridmm, inp) {
    if (!inp.files.length) {
      return;
    }

    let reader,
      file = inp.files[0];
    reader = new FileReader();
    reader.onload = function (e) {
      gridXls.FlexGridXlsxConverter.load(gridmm, reader.result, { includeColumnHeaders: true });
    };
    reader.readAsArrayBuffer(file);
  }

}
