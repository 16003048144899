import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { NgForm } from "@angular/forms";

@Component({
  selector: "app-publications",
  templateUrl: "./publications.component.html",
  styleUrls: ["./publications.component.scss"]
})
export class PublicationsComponent  implements OnInit {
  @ViewChild('publicationsform')
  form: NgForm;
  filter: string = "";
  p: number = 1;
  data: Publication[];
  urlStart = "/assets/Publications/";
  @ViewChild("container") private container: ElementRef;

  constructor() {
    
    this.data = [
      {
        name:
          "Exploring procedure duration and risk for serious adverse events during congenital cardiac catheterization.",
        authors:
          "Mary J. Yeh; Elizabeth Lyndon; Kimberlee Gauvreau; Kathy J. Jenkins; David Slater; Lisa Bergersen",
        source: "BMJ Surgery, Interventions, and Health Technologies",
        date: "2023",
        pages: "",
        link: this.getUrl("2023_Exploring_procedure_duration"),
        epub: ""
      },
      {
        name:
          "ICU Admission Tool for Congenital Heart Catheterization (iCATCH): A Predictive Model for High Level Post-Catheterization Care and Patient Management.",
        authors:
          "Brian P. Quinn, MD; Lauren C. Shirley; Mary J. Yeh; Kimberlee Gauvreau, ScD; Juan C. Ibla, MD; Sarah G. Kotin; Diego Porras, MD; Lisa J. Bergersen, MD MPH",
        source: "Pediatric Critical Care Medicine",
        date: "2022",
        pages: "",
        link: this.getUrl("2022_ICU_Admission_Tool"),
        epub: ""
      },
      {
        name:
          "COVID-19 international experience in paediatric patients with congenital heart disease.",
        authors:
          "Mary J. Yeh; Lisa Bergersen; Kimberlee Gauvreau; Oliver M Barry; Sarosh P Batlivala; Elsa Bjornlund; Brian Boe; Luiz Fernando Caneo; Ulisses A Croti; Thomas Doyle; Shumaila Furnaz; Rodrigo Cesar Moraes; Michael O'Byrne; Erica de Oliveira Paes; Alexis Palacios-Macedo; Dimitar Pechilkov; Nestor Fernando Sandoval; Supratim Sen; Mila Stajevic; Marco Antonio Ferreira Travessa; Kathy J Jenkins",
        source: "Heart",
        date: "2022",
        pages: "",
        link: this.getUrl("2022_COVID_19_international_experience"),
        epub: ""
      },
      {
        name:
          "Early Postoperative Congenital Cardiac Catheterization Outcomes: A Multicenter Study.",
        authors:
          "Mary J. Yeh, BA; Kimberlee Gauvreau, ScD; Aimee K. Armstrong, MD;  Sarosh P. Batlivala, MD, MSCI; Ryan Callahan, MD; Todd M. Gudausky, MD; Michael R. Hainstock, MD; Babar Hasan, MBBS; George T. Nicholson, MD; Michael O'Byrne MD, MSCE; Shabana Shahanavaz, MBBS; Sara Trucco, MD; Jeffrey D. Zampi, MD; and Lisa Bergersen, MD, MPH",
        source: "The Society of Thoracic Surgeons",
        date: "2022",
        pages: "",
        link: this.getUrl("2022_Early_Postoperative_Congenital"),
        epub: ""
      },
      {
        name:
          "Changes in Practice/Outcomes of Pediatric/Congenital Catheterization in Response to the First Wave of COVID.",
        authors:
          "Brian Quinn, MD; Oliver M. Barry, MD; Sarosh P. Batlivala, MD, MSCI; Brian A. Boe, MD; Andrew C. Glatz, MD, MSCE; Kimberlee Gauvreau, PhD; Bryan Goldstein, MD; Todd Gudausky, MD; Michael Hainstock, MD; Ralf Holzer, MD, MSc; George T. Nicholson, MD; Sara M. Trucco, MD; Wendy Whiteside, MD, Mary Yeh, Lisa Bergersen, MD, MPH; Michael O'Byrne MD, MSCE",
        source: "JACC: Advances",
        date: "2022",
        pages: "",
        link: this.getUrl("2022_Changes_in_Practice"),
        epub: ""
      },
      {
        name:
          "Procedural Risk in Congenital Cardiac Catheterization (PREDIC3T).",
        authors:
          "Brian P. Quinn, MD; Mary Yeh; Kimberlee Gauvreau, ScD; Fatima Ali, MBBS; David Balzer, MD; Oliver Barry, MD; Sarosh Batlivala, MD; Darren Berman, MD; Susan Foerster , MD; Bryan Goldstein, MD; Michael Hainstock, MD; Ralf Holzer, MD; Dana Janssen, MD; Michael L. O’Byrne, MD, MSCE; Lauren Shirley; Sara Trucco, MD; Wendy Whiteside, MD; Lisa Bergersen, MD, MPH",
        source: "JAHA",
        date: "2022",
        pages: "",
        link: this.getUrl("2022_Procedural_Risk_in_Congenital"),
        epub: ""
      },
      {
        name:
          "Interpreting Quality Improvement When Introducing New Technology: A Collaborative Experience in ASD Device Closures.",
        authors:
          "Mary J. Yeh; Lauren Shirley; David T. Balzer, MD; Brian A. Boe, MD; Howaida El‑Said, MD; Susan Foerster, MD; Kimberlee Gauvreau, ScD; Todd M. Gudausky, MD; Michael R. Hainstock, MD; Nicola Maschietto, MD, PhD;  George T. Nicholson, MD;Brian P. Quinn, MD; Shabana Shahanavaz, MBBS; Sara Trucco, MD; Wendy Whiteside, MD; Lisa Bergersen, MD, MPH",
        source: "Pediatr Cardiol",
        date: "2022",
        pages: "",
        link: this.getUrl("2022_Interpreting_Quality_Improvement"),
        epub: ""
      },
      {
        name:
          "Longitudinal Improvements in Radiation Exposure in Cardiac Catheterization for Congenital Heart Disease: A Prospective Multicenter C3PO-QI Study.",
        authors:
          "Brian P. Quinn, MD; Priscila Cevallos, MS; Aimee Armstrong, MD; David Balzer, MD; Howaida El-said, MD; Susan Foerster, MD; Andrew C. Glatz, MD; Andrea Goodman, MPH; Bryan Goldstein, MD; Michael Hainstock, MD; Dana Janssen, MD; Jacqueline Kreutzer, MD; Larry Latson, MD; Ryan Leahy, MD; Christopher Petit, MD; Shabana Shahanavaz, MD; Sara Trucco, MD; Wendy Whiteside, Jeffrey D. Zampi, MD; Lisa Bergersen MD, MPH.",
        source: "Catheter Cardiovasc Interv",
        date: "2020",
        pages: "",
        link: this.getUrl("2020_Quinn_RadiationQI_CircInterventions"),
        epub: ""
      },
      {
        name:
          " Adverse Events, Radiation Exposure, and Reinterventions Following Transcatheter Pulmonary Valve Replacement.",
        authors:
          "Bryan H. Goldstein, MD; Lisa Bergersen, MD, MPH; Aimee K. Armstrong, MD; Brian A. Boe, MD; Howaida El-Said, MD; Diego Porras, MD; Shabana Shahanavaz, MD; Ryan A. Leahy, MD; Jacqueline Kreutzer, MD; Jeffrey D. Zampi, MD; Michael R. Hainstock, MD; Todd M. Gudausky, MD; George T. Nicholson, MD; Kimberlee Gauvreau, ScD; Andrea Goodman, MPH; Christopher J. Petit, MD.",
        source: "JACC",
        date: "2020",
        pages: "",
        link: this.getUrl("2020_Goldstein_TPV_JACC"),
        epub: ""
      },
      {
        name:
          "Pilot phase experience of the International Quality Improvement Collaborative catheterization registry.",
        authors:
          "Oliver M. Barry, MD; Fatima Ali, FCPS; Miguel Ronderos, MD; Abish Sudhaker, MA; R. Krishna Kumar, MD; Marhisham C. Mood, MD; Carlos Corona-Villalobos, MD; Dinh Toai Nguyen, MD;  Kaitlin Doherty-Schmeck, BS; Lisa Bergersen, MD, MPH; Kimberlee Gauvreau, ScD; Kathy Jenkins, MD, MPH; Babar Hasan, MD.",
        source: "Catheter Cardiovasc Interv",
        date: "2020",
        pages: "",
        link: this.getUrl("2020_Barry_IQIC Cath Pilot_CircInterventions"),
        epub: ""
      },
      {
        name:
          "Radiation Risk Categories in Cardiac Catheterization for Congenital Heart Disease: A Tool to Aid in the Evaluation of Radiation Outcomes.",
        authors:
          "Brian P. Quinn, MD; Aimee K. Armstrong, MD; Holly D. Bauser‑Heaton, MD; Ryan Callahan, MD; Howaida G. El‑Said, MD; Susan R. Foerster, MD; Bryan H. Goldstein, MD; Andrea S. Goodman, MPH; Todd M. Gudausky, MD; Jacqueline N. Kreutzer, MD; Ryan A. Leahy, MD; Christopher J. Petit, MD; Toby A. Rockefeller, MD; Shabana Shahanavaz, MD; Sara M. Trucco, MD; Lisa Bergersen, MD, MPH",
        source: "Pediatr Cardiol",
        date: "2019",
        pages: "",
        link: this.getUrl("Quinn2019_Article_RadiationRiskCategoriesInCardi"),
        epub: ""
      },
      {
        name:
          "Impact of Congenital Cardiac Catheterization Project on Outcomes-Quality Improvement (C3PO-QI) in LMICs",
        authors:
          "Fatima Ali, Mohammad Qasim Mehdi, Saleem Akhtar, Nadeem Aslam, Rashid Abbas, Izat Shah, Jabbir Abidi, Sajid Arthur, Zeenat Nizar, Andrea Goodman, Lisa Bergersen, Babar Hasan",
        source: "Heart Asia",
        date: "Jan 10, 2019",
        pages: "",
        link: this.getUrl("Heart Asia.2019 Vol.11C3PO"),
        epub: ""
      },
      {
        name:
          "Radiation Dose Benchmarks in Pediatric Cardiac Catheterization: A Prospective Multi-Center C3PO-QI Study",
        authors:
          "Priscila C. Cevallos, Aimee K. Armstrong, Andrew C. Glatz, Bryan H. Goldstein, Todd M. Gudausky, Ryan A. Leahy, Christopher J. Petit, Shabana Shahanavaz, Sara M. Trucco, Lisa J. Bergersen",
        source: "Catheter Cardiovasc Interv",
        date: "Feb 15, 2017",
        pages: "",
        link: this.getUrl("Radiation Benchmarks 2017"),
        epub: "[Epub ahead of print]"
      },
      {
        name:
          "Implementation of Methodology for Quality Improvement in Pediatric Cardiac Catheterization: A Multi-center Initiative by the Congeital Cardiac Catheterization Project on Outcomes – Quality Improvement (C3PO-QI) ",
        authors:
          "Priscila C. Cevallos, Molly J. Rose, Laurie B. Armsby, Aimee K. Armstrong, Howaida EL-Said, Susan R. Foerster, Andrew C. Glatz, Bryan H. Goldstein, Michael R. Hainstock, Jacqueline Kreutzer, Larry A. Latson, Ryan A. Leahy, Christopher J. Petit, Alejandro Torres, Shabana Shahanavaz, Jeffrey D. Zampi, Lisa Bergersen",
        source: "Pediatr Cardiol",
        date: "July 2016",
        pages: "",
        link: this.getUrl("art_3A10-1007_2Fs00246-016-1454-z"),
        epub: "[Epub ahead of print]"
      },
      {
        name:
          "Practice Variation in Single-Ventricle Patients Undergoing Elective Cardiac Catheterization: A Report from the Congenital Cardiac Catheterization Project on Outcomes (C3PO)",
        authors:
          "Bryan H. Goldstein, MD, Ralf J. Holzer, MD, MSc, Sara M. Trucco, MD, Diego Porras, MD, Joshua Murphy, MD, Susan R. Foerster, MD, Howaida G. El-Said, MD, Robert H. Beekman III, MD, Lisa Bergersen, MD MPH",
        source: "Congenit Heart Dis.",
        date: "2016",
        pages: "11:122-135",
        link: this.getUrl("Goldstein_et_al-2016-Congenital_Heart_Disease"),
        epub: ""
      },
      {
        name:
          "Device Therapy for Atrial Septal Defects in a Multicenter Cohort: Acute Outcomes and Adverse Events",
        authors:
          "Howaida El-Said, MD, PhD; Sanjeet Hegde, MD PhD; Susan Foerster, MD; William Hellenbrand, MD; Jacqueline Kreutzer, MD; Sara M. Trucco, MD; Ralf Holzer, MD; Grant Burch, MD; Ajay Mirani, MD; Ramzi Nicolas, MD; Diego Porras, MD; Lisa Bergersen, MD, MPH; John Moore, MD MPH",
        source: "Catheter Cardiovasc Interv.",
        date: "2015",
        pages: "85:227-233",
        link: this.getUrl(
          "El-Said_et_al-2015-Catheterization_and_Cardiovascular_Interventions"
        ),
        epub: ""
      },
      {
        name:
          "Incidence and management of life-threatening adverse events during cardiac catheterization for congenital heart disease",
        authors:
          "C. Huie Lin, Sanjeet Hedge, Audrey C. Marshall A, Diego Porras, Kimberlee Gauvreau, David T. Balzer, Robert H. Beekman III, Alejandro Torres, Julie A. Vincent, John W. Moore, Ralf Holzer, Laurie Armsby, Lisa Bergersen",
        source: "Pediatr Cardiol",
        date: "2014",
        pages: "",
        link: this.getUrl(
          "Lin2014_Article_IncidenceAndManagementOfLife-T"
        ),
        epub: ""
      },
      {
        name:
          "Quality Metrics in Cardiac Catheterization for Congenital Heart Disease: Utility of 30-Day Mortality",
        authors:
          "Carl H. Backes, MD, Lisa Bergersen, MD, Jonathan J. Rome, MD, Sarosh P. Batlivala, MD, Andrew C. Glatz, MD, Bugsu Ovunc, MD, Sthuthi David, MS, Brian K. Rivera, MS, Urbee Haque, MS, Kevin Kollins, MD, Han Yin, MS, and Ralf J. Holzer, MD",
        source: "Catheter Cardiovasc Interv.",
        date: "2015",
        pages: "85:104-110",
        link: this.getUrl(
          "Backes_et_al-2015-Catheterization_and_Cardiovascular_Interventions"
        ),
        epub: ""
      },
      {
        name:
          "Sedation and Anesthesia in Pediatric and Congenital Cardiac Catheterization: A Prospective Multicenter Experience",
        authors:
          "C. Huie Lin, Sanyukta Desai, Ramzi Nicolas, Kimberlee Gauvreau, Susan Foerster, Anshuman Sharma, Laurie Armsby, Audrey C. Marshall, Kirsten Odegard, James DiNardo, Julie Vincent, Howaida El-Said, James Spaeth, Bryan Goldstein, Ralf Holzer, Jackie Kreutzer, David Balzer, Lisa Bergersen",
        source: "Pediatr Cardiol",
        date: "2015",
        pages: "36:1363-1375",
        link: this.getUrl("Lin_Sedation"),
        epub: ""
      },
      {
        name:
          "Balloon Valvuloplasty for Congenital Aortic Stenosis: Multi-Center Safety and Efficacy Outcome Assessment",
        authors:
          "Alejandro Torres, MD, Julie A. Vincent, MD, Allen Everett, MD, Scott Lim, MD, Susan R. Foerster, MD, Audrey C. Marshall, MD, Robert H. Beekman III, MD, Joshua Murphy, MD, Sara M. Trucco, MD, Kimberlee Gauvreau, MD, Ralf Holzer, MD MSc, Lisa Bergersen, MD MPH, Diego Porras, MD",
        source: "Catheter Cardiovasc Interv.",
        date: "2015",
        pages: "86:808-820",
        link: this.getUrl(
          "Torres_et_al-2015-Catheterization_and_Cardiovascular_Interventions"
        ),
        epub: ""
      },
      {
        name:
          "Radiation Dose Benchmarks during Cardiac Catheterization for Congenital Heart Disease in the United States",
        authors:
          "Sunil J. Ghelani, Andrew Glatz, Sthuthi David, Ryan Leahy, Russel Hirsch, Laurie Armsby, Sara Trucco, Ralf Holzer, Lisa Bergersen",
        source: "JACC",
        date: "April 1, 2014",
        pages: "Volume 63, Issue 12",
        link: this.getUrl("J. Am. Coll. Cardiol. 2014 Ghelani"),
        epub: ""
      },
      {
        name:
          "Developing Tools to Measure Quality in Congenital Catheterization and Interventions: The Congenital Cardiac Catheterization Project on Outcomes (C3PO)",
        authors:
          "Nadia Chaudhry-Watermana, Sandra Coombsa, Diego Porras, MD, Ralf Holzer, MD, Lisa Bergersen, MD, MPH",
        source: " MDCVJ | X (2)",
        date: "2014",
        pages: "",
        link: this.getUrl("4_JMDHVC10.2_DL_Chaudhry_Waterman"),
        epub: ""
      },
      {
        name:
          "Relationship between procedural adverse events associated with cardiac catheterization for congenital heart disease and operator factors: Results of a multi-institutional registry (C3PO)",
        authors:
          "Holzer RJ, Gauvreau K, Kreutzer J, Moore JW, McElhinney DB, Bergersen L",
        source: "Catheter Cardiovasc Interv.",
        date: "Feb 14 2013",
        pages: "",
        link: this.getUrl("holzer"),
        epub: ""
      },
      {
        name:
          "Low weight as an independent risk factor for adverse events during cardiac catheterization of infants",
        authors:
          "Backes CH, Cua C, Kreutzer J, Armsby L, El-Said H, Moore JW, Gauvreau K, Bergersen L, Holzer RJ",
        source: "Catheter Cardiovasc Interv.",
        date: "Feb 22 2013",
        pages: "",
        link: this.getUrl("backes"),
        epub: ""
      },
      {
        name:
          "Safety of Percutaneous Patent Ductus Arteriosus Closure: An Unselected Multicenter Population Experience",
        authors:
          "Howaida G. El-Said, MD PhD; Andras Bratincsak, MD PhD; Susan R. Foerster, MD; Joshua J. Murphy, MD; Julie Vincent, MD: Ralf Holzer, MD; Diego Porras, MD; John Moore, MD, MPH; Lisa Bergersen, MD, MPH ",
        source: "J Am Heart Assoc.",
        date: "2013",
        pages: "2:e000424",
        link: this.getUrl("J_Am_Heart_Assoc-2013-El-Said"),
        epub: ""
      },
      {
        name:
          "Adverse events rates and risk factors in adults undergoing cardiac catheterization at pediatric hospitals - results from the C3PO",
        authors:
          "Learn CP, Holzer RJ, Daniels CJ, Torres AJ, Vincent JA, Moore JW, Armsby LB, Landzberg MJ, Bergersen L",
        source: "Catheter Cardiovasc Interv",
        date: "2013",
        pages: "81:997-1005",
        link: this.getUrl("learn"),
        epub: ""
      },
      {
        name:
          "The efficacy and safety of balloon vavuloplasty: A Multi-Center Experience",
        authors:
          "Holzer R, Gauvreau K, Kreutzer J, Trucco S, Torres A, Shahanavaz S, Bergersen L",
        source: "Catheter Cardiovasc Interv.",
        date: "Mar 2012",
        pages: "doi: 10.1002/ccd.23473",
        link: this.getUrl(
          "2012_Efficacy and Safety of Pulmonary Valvuloplasty- A multi-center exp"
        ),
        epub: ""
      },
      {
        name:
          "Endomyocardial biopsy and selective coronary angiography are low-risk procedures in pediatric heart transplant recipients: Results of a multicenter experience",
        authors:
          "Ralf J. Holzer, MD, MSc; Kimberlee Gauvreau, ScD; Jacqueline Kreutzer, MD; Ryan Leahy, MD; Joshua Murphy, MD; James E. Lock, MD; John P. Cheatham, MD; Lisa Bergersen, MD, MPH",
        source: "J Heart Lung Transplant",
        date: "2012",
        pages: "31:398-409",
        link: this.getUrl("2011_C3PO_Endomyocardial biopsy"),
        epub: ""
      },
      {
        name:
          "Procedure-Type Risk Categories for Pediatric and Congenital Cardiac Catheterization",
        authors:
          " Lisa Bergersen, MD, MPH; Kimberlee Gauvreau, ScD; Audrey Marshall, MD; Jacqueline Kreutzer, MD; Robert Beekman, MD; Russel Hirsch, MD; Susan Foerster, MD; David Balzer, MD; Julie Vincent, MD; William Hellenbrand, MD; Ralf Holzer, MD; John Cheatham, MD; John Moore, MD; James Lock, MD; Kathy Jenkins, MD, MPH",
        source: "Circ Cardiovasc Interv.",
        date: "2011",
        pages: "4:188-94",
        link: this.getUrl("2011_C3PO_Procedure-Type Risk Categories"),
        epub: ""
      },
      {
        name:
          "Catheterization for Congenital Heart Disease Adjustment for Risk Method - CHARM ",
        authors:
          "Lisa Bergersen, MD, MPH, Kimberlee Gauvreau, SCD, Susan R. Foerster, MD, Audrey C. Marshall, MD, Doff B. McElhinney, MD, Robert H. Beekman III, MD, Russel Hirsch, MD, Jacqueline Kreutzer, MD, David Balzer, MD, Julie Vincent, MD, Willian E. Hellenbrand, MD,Ralf Holzer, MD, John P. Cheatham, MD, John W. Moore, MD, Grant Burch, MD, Laurie Armsby, MD, James E. Lock, MD, Kathy J. Jenkins, MD, MPH",
        source: "JACC Cardiovasc Interv.",
        date: "2011",
        pages: "4:1037-46",
        link: this.getUrl("2011_Catheterization for Congenital Heart Disease"),
        epub: ""
      },
      {
        name:
          "Balloon angioplasty and stenting of branch pulmonary arteries. Adverse events and procedural characteristics Results of a multi-institutional registry",
        authors:
          "Ralf J. Holzer, MD, MSc; Kimberlee Gauvreau, ScD; Jacqueline Kreutzer, MD; Ryan Leahy, MD; Joshua Murphy, MD; James E. Lock, MD; John P. Cheatham, MD; Lisa Bergersen, MD, MPH",
        source: "Circ Cardiovasc Interv.",
        date: "2011",
        pages: "4:287-96",
        link: this.getUrl("2010_C3PO_Balloon angioplasty"),
        epub: ""
      },
      {
        name:
          "Balloon Angioplasty and Stenting of Branch Pulmonary Arteries, Adverse Events and Procedural Characteristics: Results of a Multi-Institutional Registry",
        authors:
          "Ralf J. Holzer, MD, MSc; Kimberlee Gauvreau, ScD; Jacqueline Kreutzer, MD; Ryan Leahy, MD; Joshua Murphy, MD; James E. Lock, MD; John P. Cheatham, MD; Lisa Bergersen, MD, MPH",
        source: "Circ Cardiovasc Interv",
        date: "2011",
        pages: "4:287-96",
        link: this.getUrl("2011_Balloon angioplasty "),
        epub: ""
      },
      {
        name:
          "Report from The International Society for Nomenclature of Paediatric and Congenital Heart Disease: cardiovascular catheterisation for congenital and paediatric cardiac disease",
        authors:
          "Lisa Bergersen, Allen Dale Everett, Jorge Manuel Giroud, Gerard R. Martin, Rodney Cyril George Franklin, Marie Jose Beland, Otto Nils Krogmann, Vera Demarchi Aiello, Steven D. Colan, Martin J. Elliott, J. William Gaynor, Hiromi Kurosawa, Bohdan Maruszewski, Giovanni Stellin, Christo I. Tchervenkov, Henry Lane Walters III, Paul Weinberg, Jeffrey Phillip Jacobs, Jose Beland",
        source: "Cardiol Young",
        date: "2011",
        pages: "21:252-9",
        link: this.getUrl("2011_Report 1"),
        epub: ""
      }, 
      {
        name:
          "Report from The International Society for Nomenclature of Paediatric and Congenital Heart Disease: cardiovascular catheterisation for congenital and paediatric cardiac disease interventional cardiology",
        authors:
          "Lisa Bergersen, Jorge Manuel Giroud, Jeffrey Phillip Jacobs, Rodney Cyril George Franklin, Marie Jose Beland, Otto Nils Krogmann, Vera Demarchi Aiello, Steven D. Colan, Martin J. Elliott, J. William Gaynor, Hiromi Kurosawa, Bohdan Maruszewski, Giovanni Stellin, Christo I. Tchervenkov, Henry Lane Walters III, Paul Weinberg, Allen Dale Everett ",
        source: "Cardiol Young",
        date: "2011",
        pages: "21:260-5",
        link: this.getUrl("2011_Report 2"),
        epub: ""
      },
      {
        name:
          "Hybrid Procedures: Adverse Events and Procedural Characteristics - Results of a Multi-institutional Registry",
        authors:
          "Ralf Holzer, MD, Audrey Marshall, MD, Jackie Kreutzer, MD, Russel Hirsch, MD,Joanne Chisolm, RN, Sharon Hill, ACNP,Mark Galantowicz, MD,Alistair Phillips, MD,John Cheatham, MD, and Lisa Bergerson, MD",
        source: "Congenit Heart Dis.",
        date: "2010",
        pages: "5:233-42",
        link: this.getUrl("2010_C3PO_Hybrid Procedures AE"),
        epub: ""
      },
      {
        name:
          "Adverse event rates in congenital cardiac catheterization - a multi-center experience",
        authors:
          " Lisa Bergersen, MD, Audrey Marshall, MD, Kimberlee Gauvreau, ScD, Robert Beekman, MD, Russel Hirsch, MD, Susan Foerster, MD, David Balzer, MD, Julie Vincent, MD, William Hellenbrand, MD, Ralf Holzer, MD, John Cheatham, MD, John Moore, MD, James Lock, MD, and Kathy Jenkins, MD, MPH",
        source: "Catheter Cardiovasc Interv.",
        date: "2010",
        pages: "75:389-400",
        link: this.getUrl("2010_C3PO_Adverse event rates"),
        epub: ""
      }
    ];
  }

  ngOnInit() {}

  scroll() {
    try {
      this.container.nativeElement.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
    } catch (err) {}
  }

  getUrl(link) {
    return this.urlStart + link + ".pdf";
  }
}

interface Publication {
  name: string;
  authors: string;
  source: string;
  date: string;
  pages: string;
  link: string;
  epub: string;
}
