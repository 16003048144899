import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { CaseModel } from '../../shared/case/case-model';
import { map, takeUntil, tap } from 'rxjs/operators';
import { CONFIG } from '../../shared/config';
import { CaseSaveResponse } from '../../shared/case-save/case-save-response';
import { PreCaseCalculationModel } from '../../shared/pre-case-calculation-model';
import { PreCaseCalculationtResponse } from '../../shared/pre-case-calculation-response';
import { CaseResponse } from '../../shared/case/case-response';

let apiUrl = CONFIG.baseUrls.api;
@Injectable({
  providedIn: 'root'
})
export class CalculatorService {
  private http: Http = null;
  public case: CaseModel;
  //use this service to store the data from the calculator/make calculations
  public stsValues:number[] = [1,2,3];
  public stsSelection: number = 1;
  public readOnly: boolean = false;



  constructor(_http: Http) {
    this.http = _http;
    this.case = new CaseModel;
  }
  getSTSId(val) {
    return "sts-" + val;
  }


  save() {

    return this.saveCase(this.case);

  }

  saveCase(caseItem: CaseModel) {
    let headers = new Headers({
      //'Content-Type': 'application/x-www-form-urlencoded'
    });
    let options = new RequestOptions({ headers: headers });
    return this.http.post(apiUrl + "case/savecasedetails", caseItem, options)
      .pipe(map((response: Response) => <CaseSaveResponse>response.json()));


  }

  getPreCaseCalculationValue(caseid: number, updatedby: string) {
    let headers = new Headers({
      //'Content-Type': 'application/x-www-form-urlencoded'
    });
    let options = new RequestOptions({ headers: headers });
    return this.http.get(apiUrl + "case/getPreCaseCalculationValue?caseid=" + caseid + '&updatedby=' + updatedby, options)
      .pipe(map((response: Response) => <PreCaseCalculationtResponse>response.json()));
  }
}
