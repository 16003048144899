export class CaseAddlHemoDynamicsInfoModel {
  CaseAddlHemoDynamicId: number;
  CaseId: number;
  IsShuntClosure: string;
  IsRecommendedforSC: string;
  IsAVTestingPerfomed: string;
  CongentialHDDiagVal: string;
  VasodilatorAgentName: string;
  BasePVRIWuVal: number;
  BaseSVRWuVal: number;
  BasePVRSVRWuRatioVal: number;
  BaseQPQARatioVal: number;
  BaseCardIndCIVal: number;
  PostPVRIWuVal: number;
  PostSVRWuVal: number;
  PostPVRSVRWuRatioVal: number;
  PostQPQARatioVal: number;
  PostCardIndCIVal: number;
  Updatedby: string;
}
