import { Component, OnInit, ViewChild,Input } from "@angular/core";
import { TreeComponent, TreeModel, TreeNode } from 'angular-tree-component';
import { CaseTypeTreeModel } from "../../shared-data-entry/case-types-entry/case-type-tree-model";
import { StsDiagnosisService } from "../../shared-data-entry/sts-diagnosis.service";
import { StsDiagnosisResponse } from '../../../shared/sts-diagnosis-response';
import { StsDiagnosisModel } from '../../../shared/sts-diagnosis-model';
import { CaseTypesEntryService } from "../../shared-data-entry/case-types-entry/case-types-entry.service";
import { CaseFieldsService } from "../../user/add-case/case-fields.service";

@Component({
  selector: "app-sts-tree",
  templateUrl: "./sts-tree.component.html",
  styleUrls: ["./sts-tree.component.scss"]
})
export class StsTreeComponent implements OnInit {

  @ViewChild('tree') treeComponent: TreeComponent;
  fields: CaseFieldsService;
  public stsDiagnosisService: StsDiagnosisService;
  constructor(fields: CaseFieldsService, _stsDiagnosisService: StsDiagnosisService, service: CaseTypesEntryService) {
    this.fields = fields;
    this.stsDiagnosisService = _stsDiagnosisService;
    if (this.stsDiagnosisService.stsDiagnosisList == null) {
      this.stsDiagnosisService.getStsDiagnosis().subscribe(data => this.populateStsDiagnosis(data));
    }
  }
  populateStsDiagnosis(stsDiagResponse: StsDiagnosisResponse) {
    this.stsDiagnosisService.stsDiagnosisList = stsDiagResponse.DataObject;
    this.stsDiagnosisService.mapToDropDown();

    this.stsDiagnosisService.stsTreedata = this.stsDiagnosisService.dropDownStsDiagnosis;
  }
  onPrimaryDiagnosisSelect(event, tree) {
    if (event.node.isLeaf) {
      this.fields.getField('Clinical Characteristics', 'PrimarySTS').text = CaseTypeTreeModel.getKeyString(event.node, tree);
      this.fields.getField('Clinical Characteristics', 'PrimarySTS').value = event.node;
    }
    else {
      event.node.toggleExpanded();
      event.node.setIsActive(false);
    }
  }

  onSecondDiagnosisSelect(event, tree) {
    if (event.node.isLeaf) {
      this.fields.getField('Clinical Characteristics', 'SecondSTS').text = CaseTypeTreeModel.getKeyString(event.node, tree);
      this.fields.getField('Clinical Characteristics', 'SecondSTS').value = event.node;
    }
    else {
      event.node.toggleExpanded();
      event.node.setIsActive(false);
    }
  }

  onThirdDiagnosisSelect(event, tree) {
    if (event.node.isLeaf) {
      this.fields.getField('Clinical Characteristics', 'ThirdSTS').text = CaseTypeTreeModel.getKeyString(event.node, tree);
      this.fields.getField('Clinical Characteristics', 'ThirdSTS').value = event.node;
    }
    else {
      event.node.toggleExpanded();
      event.node.setIsActive(false);
    }
  }

  onFourthDiagnosisSelect(event, tree) {
    if (event.node.isLeaf) {
      this.fields.getField('Clinical Characteristics', 'FourthSTS').text = CaseTypeTreeModel.getKeyString(event.node, tree);
      this.fields.getField('Clinical Characteristics', 'FourthSTS').value = event.node;
    }
    else {
      event.node.toggleExpanded();
      event.node.setIsActive(false);
    }
  }

  clearPrimary() {
    this.fields.getField('Clinical Characteristics', 'PrimarySTS').text = null;
    this.fields.getField('Clinical Characteristics', 'PrimarySTS').value = null;
  }

  clearSecond() {
    this.fields.getField('Clinical Characteristics', 'SecondSTS').text = null;
    this.fields.getField('Clinical Characteristics', 'SecondSTS').value = null;
  }

  clearThird() {
    this.fields.getField('Clinical Characteristics', 'ThirdSTS').text = null;
    this.fields.getField('Clinical Characteristics', 'ThirdSTS').value = null;
  }

  clearFourth() {
    this.fields.getField('Clinical Characteristics', 'FourthSTS').text = null;
    this.fields.getField('Clinical Characteristics', 'FourthSTS').value = null;
  }

  ngOnInit() {
    this.stsDiagnosisService.treeModel = this.treeComponent.treeModel;
  }
}
