import { Component, OnInit, ViewChild, HostListener  } from "@angular/core";
import { CaseFieldsService } from "../case-fields.service";
import { StsDiagnosisService } from "../../../shared-data-entry/sts-diagnosis.service";
import { StsDiagnosisResponse } from '../../../../shared/sts-diagnosis-response';
import {
  Router,ActivatedRoute,ParamMap,NavigationEnd,NavigationStart,RouterEvent} from "@angular/router";
import { LoginService } from "../../../user/login.service";
import { PicklistItemService } from "../../../shared-data-entry/picklist-item.service";
import { PicklistItemResponse } from "../../../../shared/picklist-item-response";
import { PicklistItemModel } from "../../../../shared/picklist-item-model";
import { DropdownItemModel } from "../../../../shared/dropdown-item-model";
import { NgForm, FormGroup } from "@angular/forms";
import { ComponentCanDeactivate } from '../../../../CanDeactivateGaurd';
import { Observable } from 'rxjs';
import { filter, map, mergeMap, tap } from "rxjs/operators";

@Component({
  selector: "app-clinical-characteristics",
  templateUrl: "./clinical-characteristics.component.html",
  styleUrls: ["./clinical-characteristics.component.scss"],
  host: { class: "host" }
})
export class ClinicalCharacteristicsComponent implements OnInit, ComponentCanDeactivate {
  @ViewChild('form') form: NgForm;
  problemsDropDown = [];
  problems = [];
  selectedProblems = [];
  name = "Clinical Characteristics";
  weight;
  height;
  public fields: CaseFieldsService;
  public user: LoginService;
  public picklistItemService: PicklistItemService;

  stsDiagnosisService: StsDiagnosisService;
  stsDiagnosisList: StsDiagnosisResponse;
  constructor(private router: Router, fields: CaseFieldsService, _user: LoginService,
    _picklistItemService: PicklistItemService, _stsDiagnosisService: StsDiagnosisService) {
   
    router.events
      .pipe(filter(e => e instanceof NavigationStart))
      .subscribe((e: NavigationStart) => {
        localStorage.setItem('editcase', e.url);
      });
    this.fields = fields;
    this.user = _user;
    this.picklistItemService = _picklistItemService;
    this.stsDiagnosisService = _stsDiagnosisService;

    if (this.user.user) {
      this.stsDiagnosisService
        .getStsDiagnosis()
        .subscribe(data => this.populateStsDiagnosis(data));

      this.picklistItemService
        .getPicklistItem(this.user.user.HospitalId.toString(), "Non-Cardiac Problem")
        .subscribe(data => this.populatePicklistItems(data));
    }

    this.calcBSA();
  }

  ngOnInit() {
    this.stsDiagnosisService.treeModel.setFocusedNode(null);
    this.stsDiagnosisService.treeModel.expandedNodeIds = {};
    this.stsDiagnosisService.treeModel.activeNodeIds = {};
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    
    if (this.form.dirty)
      localStorage.setItem('dirty', 'true');

    
      return !this.form.dirty;
  }

  populateStsDiagnosis(stsDiagResponse: StsDiagnosisResponse) {
    this.stsDiagnosisService.stsDiagnosisList = stsDiagResponse.DataObject;
    this.stsDiagnosisService.mapToDropDown();
    this.stsDiagnosisService.stsTreedata = this.stsDiagnosisService.dropDownStsDiagnosis;
  }

  private populatePicklistItems(picklistItemResponse: PicklistItemResponse) {
    this.picklistItemService.picklistItemModel = picklistItemResponse.DataObject;
    this.picklistItemService.mapToDropDown();

    this.problemsDropDown = this.picklistItemService.dropDownPicklistItem;
    this.problems = this.problemsDropDown.map(x => x.name);
    this.loadProblems();
  }

  private loadProblems() {
    let valMap: Array<string> = [];

    let probs = this.fields.getValue(this.name, 'Non-cardiac Problems');
    if (probs != null) {
      let probArray = probs.split('|');

      for (let p of probArray) {
        for (let dropDownItem of this.problemsDropDown) {
          if (dropDownItem.code === p) {
            valMap.push(dropDownItem.name);
          }
        }
      }
    }

    this.selectedProblems = valMap;
  }

  public updateProblems() {
    if (this.selectedProblems.length > 0) {
      let valMap: Array<string> = [];

      for (let p of this.selectedProblems) {
        for (let dropDownItem of this.problemsDropDown) {
          if (dropDownItem.name === p) {
            valMap.push(dropDownItem.code)
          }
        }
      }

      this.fields.setValue(this.name, 'Non-cardiac Problems', valMap.join("|"));
    } else {
      this.fields.setValue(this.name, 'Non-cardiac Problems', null);
    }
  }

  calcBSA() {
    this.weight = +this.fields.getValue(this.name, "Weight");      
    this.weight = Math.round(this.weight*10)/10;
    if (this.weight <= 0 || this.weight > 500) {
      this.weight = null;
    }
    this.fields.setValue(this.name, "Weight", this.weight);

    this.height = +this.fields.getValue(this.name, "Height");
    this.height = Math.round(this.height);
    if (this.height <= 0 || this.height > 500) {
      this.height = null;      
    }
    this.fields.setValue(this.name, "Height", this.height);

    if (this.weight && this.height) {
      this.fields.getField(
        this.name,
        "BSA"
      ).value = ClinicalCharacteristicsComponent.round(
        Math.sqrt((this.height * this.weight) / 3600),
        3
      );
    } else {
      this.fields.setValue(this.name, "BSA", null);
    }
  }

  toggleCardiacProblems(val) {
    this.fields.getField(this.name, 'Non-cardiac Problems').hide = val;
  }

  getCardiacProblems() {
    return this.fields.getField(this.name, 'Non-cardiac Problems').hide;
  }

  static round(number, precision) {
    var factor = Math.pow(10, precision);
    var tempNumber = number * factor;
    var roundedTempNumber = Math.round(tempNumber);
    return roundedTempNumber / factor;
  }
}
