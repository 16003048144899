



//import { NgModule, Injectable } from "@angular/core";
//import { Router, CanDeactivate, ActivatedRoute } from "@angular/router";
////import { EditCaseComponent } from './components/user/add-case/edit-case.component';
//import { ComponentCanDeactivate } from './ComponentCanDeactivate';

//@Injectable()
//export class CanDeactivateGuard implements CanDeactivate<ComponentCanDeactivate> {

//  constructor() {

//  }
   
//  canDeactivate(component: ComponentCanDeactivate): boolean {
//    var savecase = localStorage.getItem("savecase");
//    if (component.canDeactivate()) {     
//      localStorage.setItem('previousurl', 'edit-case');
//      if (savecase == null) {
//        if (confirm("You have unsaved changes! If you leave, your changes will be lost.")) {
//          return true;
//        } else {
//          return false;
//        }
//      }
//      else {
//        localStorage.removeItem("savecase")
//        localStorage.removeItem("previousurl");
//      }
//    }
//    return true;
//  }
//}

import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class CanDeactivateGaurd implements CanDeactivate<ComponentCanDeactivate> {
  canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
    let componentChange = component.canDeactivate();

  
    if (localStorage.getItem('editcase').indexOf('edit-case') < 0 && localStorage.getItem('editcase').indexOf('add-case') < 0) {
      if (localStorage.getItem('editcase').indexOf('case-lookup') < 0)
      localStorage.removeItem('previousurl');
      
      if (componentChange == false || localStorage.getItem('dirty') == 'true') {
        localStorage.removeItem
     
        localStorage.removeItem('dirty');
        localStorage.removeItem('adverseevents')
        localStorage.removeItem('casetypes')
        localStorage.removeItem('casetypedone')
        localStorage.removeItem('hemo')
        localStorage.removeItem('prehemo')
        localStorage.removeItem('precasetypes')
        
        if (confirm("You have unsaved changes! If you leave, your changes will be lost.")) {
          return true;
        } else {
          return false;
        }
      }
      else {
       
        return true;
      }
      
    }
   
    return true;
  }
}
