import { Component, OnInit ,ViewChild} from '@angular/core';
import { MailService } from './mail.service';
import { MailRequestInfoModel } from '../../shared/email/mail-request-info-model';
import { SendEmailResponse } from '../../shared/email/send-email-response';
import { MailStatusModel } from '../../shared/email/mail-status-model';
import { NgForm } from "@angular/forms";

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent  implements OnInit {
  @ViewChild('contactform')
  form: NgForm;
  successMessage: string = "";
  errorMessage: string = "";

  public mailService: MailService;
  mailRequest: MailRequestInfoModel;

  constructor(_mailService: MailService) {
   
    this.mailService = _mailService;
    this.mailRequest = new MailRequestInfoModel;

    this.errorMessage = "";
    this.successMessage = "";
  }

  ngOnInit() {
  }

  send(sendTo: string) {
    this.errorMessage = "";
    this.successMessage = "";
    let reg = new RegExp(this.mailService.emailPattern);

    if (this.mailRequest.body.length > 0 && this.mailRequest.contactname.length > 0 && this.mailRequest.contactemail.length > 0 && reg.test(this.mailRequest.contactemail)) {

      if (sendTo == 'C3POR3') {
        this.mailRequest.subject = "C3PO-R3 : Contact Form Submission";
        this.mailRequest.mailsendto = "C3POR3"; 

      }

      if (sendTo == 'IQIC') {
        this.mailRequest.subject = "IQIC : Contact Form Submission";
        this.mailRequest.mailsendto = "IQIC"; 
      }

      this.mailService.SendEmail(this.mailRequest).subscribe(data => this.validateSend(data));

    } else {
      if (this.mailRequest.body.length === 0) {
        this.errorMessage = "Message is required";
      }

      if (this.errorMessage.length > 0) {
        this.errorMessage = this.errorMessage + "<br/>";
      }

      if (this.mailRequest.contactname.length === 0) {
        this.errorMessage = "Name is required";
      }

      if (this.errorMessage.length > 0) {
        this.errorMessage = this.errorMessage + "<br/>";
      }

      if (this.mailRequest.contactemail.length === 0) {
        this.errorMessage = this.errorMessage + "Email is required";
      } else if (!reg.test(this.mailRequest.contactemail)) {
        this.errorMessage = this.errorMessage + "Email is not a valid format";
      }
    }
  }

  validateSend(data: SendEmailResponse) {
    if (data.DataObject.status == "Mail Sent") {
      this.mailRequest = new MailRequestInfoModel;
      this.successMessage = "Message Sent"
    } else {
      this.errorMessage = data.DataObject.failreason;
    }
  }
}
