import { Component, OnInit, HostListener } from "@angular/core";
//import { BrowserModule } from '@angular/platform-browser';

import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Observable, pipe } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

import { LoginService } from "../../user/login.service";

import { CaseLookupService } from "../../user/case-lookup/case-lookup.service";
import { SearchResponse } from '../../../shared/search-response';

import { CaseService } from '../../user/add-case/case.service';
import { MarkCaseInactiveResponse } from '../../../shared/case-save/mark-case-inactive-response';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {
  public user: LoginService;
  public caseService: CaseService;
  public lookup: CaseLookupService;
  constructor(_user: LoginService, _lookup: CaseLookupService, _caseService: CaseService) {
    this.user = _user;
    this.lookup = _lookup;
    this.caseService = _caseService;
  }

  ngOnInit() {
  }

  delete() {
    if (this.caseService.deleteCaseId) {
      this.caseService.deactivateCase(this.user.user.UserId.toString()).subscribe(data => this.deleteResponse(data));
    }    
  }

  close() {
    this.caseService.deleteCaseId = 0;
  }

  private deleteResponse(data: MarkCaseInactiveResponse) {
    let caseCompleteStatusModel = data.DataObject;
    if (caseCompleteStatusModel.Status === "DeActivated") {
      this.caseService.deleteCaseId = 0;

      this.lookup.searchCases().subscribe(data => this.validateSearch(data));
    }
  }

  validateSearch(sr: SearchResponse) {
    if (sr.MessageType === "success") {
      this.lookup.searchCaseSummarys = sr.DataObject;
    }
  }
}
