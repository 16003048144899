import { Injectable } from '@angular/core';
import { CaseFieldsService } from "../../../user/add-case/case-fields.service";
import { PicklistItemService } from "../../../shared-data-entry/picklist-item.service";
import { PicklistItemResponse } from "../../../../shared/picklist-item-response";
import { PicklistItemModel } from "../../../../shared/picklist-item-model";
import { DropdownItemModel } from "../../../../shared/dropdown-item-model";

@Injectable({
  providedIn: 'root'
})
export class CrispCatSelectService {
  public name = "Hemodynamics";
  selection:number = null;
  values: number[] = [1, 2, 3];
  physiologicCategories: string[];

  public fields: CaseFieldsService;
  public picklistItemService: PicklistItemService;
  constructor(fields: CaseFieldsService, _picklistItemService: PicklistItemService) {
    this.fields = fields;
    this.picklistItemService = _picklistItemService;
    this.picklistItemService
      .getPicklistItem("1", "CRISP Physiologic category")
      .subscribe(data => this.populatePicklistItems(data));

    
  }

  private populatePicklistItems(picklistItemResponse: PicklistItemResponse) {
    this.picklistItemService.picklistItemModel = picklistItemResponse.DataObject;
    this.picklistItemService.mapToDropDown();
    this.physiologicCategories = this.picklistItemService.dropDownPicklistItem.map(x => x.code);
    this.setPC();
  }

  public setPC() {
    let pc = this.fields.getValue(this.name, 'Physiologic Category');
    if (pc != null) {
      let pcIndex = (this.physiologicCategories.indexOf(pc) + 1);
      this.selection = pcIndex;
    } else {
      this.selection = null;
    }
  }

  getRadioName(val) {
    return "crisp-cat-" + val;
  }

  changeSelection(val){
    this.selection = val;
    this.fields.setValue(this.name, 'Physiologic Category', this.physiologicCategories[val - 1]);
  }
}
