import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "ageType"
})
export class AgeTypePipe implements PipeTransform {
  transform(val: string): string {
    if (val === "1") {
      return "Days";
    }
    if (val === "2") {
      return "Months";
    }
    if (val === "3") {
      return "Years";
    }

    return val;
  }
}
