import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Observable, pipe } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { LoginModel } from '../../shared/login-model';
import { UserResponse } from '../../shared/user-response';
import { UserModel } from '../../shared/user-model';
import { CONFIG } from '../../shared/config';

let apiUrl = CONFIG.baseUrls.api;
@Injectable()
export class LoginService {
  private http: Http = null;

  private loggedIn: boolean = false;
  public user: UserModel;

  constructor(_http: Http) {
    this.http = _http;
  }


  IsLoggedIn() {
    if (!this.loggedIn) {
      try {
        //check session storage
        let u = JSON.parse(localStorage.getItem('currentUser'));
        if (u != null) {
          this.user = u;
          this.loggedIn = true;
        }
        else {
          this.logOut();
        }
      } catch {        
        this.logOut();
      }
      
    }

   

    return this.loggedIn;
  }

  logIn() {
    this.loggedIn = true;
  }

  logOut() {
    this.loggedIn = false;
    localStorage.removeItem('currentUser');
    this.user = null;
  }

  public IsAdmin(): boolean {
    if (this.user != null) {
      return this.user.Role === "Admin";
    }

    return false;
  }

  public IsSiteAdmin(): boolean {
    if (this.user != null) {
      return this.user.Role === "SiteAdmin";
    }

    return false;
  }

  public IsReadOnlyUser(): boolean {
    if (this.user != null) {
      return this.user.Role === "ReadOnlyUser";
    }

    return false;
  }

  public IsIqicUser(): boolean {
    if (this.user != null) {
      return this.user.UserAppRegistry === "IQIC" || this.user.UserAppRegistry === "BOTH";
    }

    return false;
  }

  checkUser(un: string, pw: string) {
    let loginfo = new LoginModel;
    loginfo.username = un;
    loginfo.password = pw;

    let headers = new Headers({
      //'Content-Type': 'application/x-www-form-urlencoded'
    });
    let options = new RequestOptions({ headers: headers });
    return this.http.post(apiUrl + "user/validateuser", loginfo, options)
               .pipe(map((response: Response) => <UserResponse>response.json()));
  }
}
