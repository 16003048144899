
import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild } from '@angular/router';
import { LoginService } from "../user/login.service";


/** A router wrapper, adding extra functions. */
//Used to determine the startup URL
//Adapted from stack overflow https://stackoverflow.com/questions/41038970/how-to-determine-previous-page-url-in-angular
@Injectable()
export class RouterService implements CanActivate, CanActivateChild{
  user: LoginService;
  private startUpUrl: string = undefined;
  private isIQIC: boolean = false; 

  constructor(user: LoginService) {
    this.user = user;    
    this.startUpUrl = window.location.href;  
   
    if ((this.startUpUrl.indexOf("IQIC")) > -1 || (this.startUpUrl.indexOf("iqic") > -1)) {      
      this.isIQIC = true;
    }
    else {
      this.isIQIC = false;
    }
   
  }

  public showIQIC() {
    return this.isIQIC;
  }

  canActivate() {

    if (this.user.IsIqicUser()) {

      return true;
    }

    else {

      return false;
    }
  }

  canActivateChild() {
   
    return true;
  }


}
