import { Location } from '@angular/common';
import { Component, OnInit, Input, HostListener } from "@angular/core";
import { UserManagementService } from "./user-management.service";
import { LoginService } from "../login.service";
import { MailRequestInfoModel } from '../../../shared/email/mail-request-info-model';
import { SendEmailResponse } from '../../../shared/email/send-email-response';
import { MailStatusModel } from '../../../shared/email/mail-status-model';
import { Directive, ElementRef } from '@angular/core';


@Component({
  selector: "app-manage-users",
  templateUrl: "./manage-users.component.html",
  styleUrls: ["./manage-users.component.scss"]
})
export class ManageUsersComponent implements OnInit {
  usersservice: UserManagementService;
  public user: LoginService;
 
  filter: string = "";
  key: string = 'UserName'; 
  reverse: boolean = false;
  p: number = 1;
  sort(key) {
    if (this.key === key) {
      //key is the same, change reverse
      this.reverse = !this.reverse;
    } else {
      //key changed, default reverse
      this.key = key;
      this.reverse = false;
    }
  }

  constructor(userserv: UserManagementService, _user: LoginService, private el: ElementRef, private location: Location) {
    this.user = _user;
    if (!this.user.IsAdmin()) {
      this.location.back();
    }

    this.usersservice = userserv;
    this.usersservice.getAllUsers().subscribe(data => this.getUsers(data));
  }

  

  getUsers(user) {
    this.usersservice.users = user.DataObject;
  //  console.log(user.DataObject);
  }

  

  


  ngOnInit() {}

  

}
