import { Component, OnInit, Input } from "@angular/core";
import { CaseTypesEntryService } from "./case-types-entry.service";
import { CaseTypeTreeModel } from "./case-type-tree-model";
import { Observable, pipe } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { ProcedurePicklistService } from "../procedure-picklist.service";
import { ProcedurePicklistResponse } from '../../../shared/procedure-picklist-response';
import { ProcedurePicklistModel } from '../../../shared/procedure-picklist-model';
import { CaseTypeCalcService } from "../case-type-calc.service";
import { Router } from "@angular/router";
import { CaseModel } from "../../../shared/case/case-model";

@Component({
  selector: "app-case-types-entry",
  templateUrl: "./case-types-entry.component.html",
  styleUrls: ["./case-types-entry.component.scss"]
})
export class CaseTypesEntryComponent implements OnInit {

  @Input() pre:boolean;
  treeModel: CaseTypeTreeModel;
  text = "Selected Procedures";
  public procedurePicklistService: ProcedurePicklistService;
  public caseTypeCalcService: CaseTypeCalcService;
  constructor(_ProcedurePicklistService: ProcedurePicklistService, _caseTypeCalcService: CaseTypeCalcService, private service:CaseTypesEntryService) {
    this.treeModel = new CaseTypeTreeModel(service, _caseTypeCalcService);

    this.procedurePicklistService = _ProcedurePicklistService;
    this.procedurePicklistService.getProcedurePicklist().subscribe(data => this.validateProcedurePicklist(data));

    this.caseTypeCalcService = _caseTypeCalcService;        
  }

  validateProcedurePicklist(ppr: ProcedurePicklistResponse) {
    if (ppr.MessageType === "success") {
      this.procedurePicklistService.procedureList = ppr.DataObject;
      this.procedurePicklistService.mapToDropDown(); 
      this.treeModel.procedures = this.procedurePicklistService.dropDownProcedureList;
    }
  }

  generateCaseType() {
    this.treeModel.switchCaseTypeDone(true);
  }

  search(filter, tree) {
    return tree.treeModel.filterNodes(filter.value);
  }

  ngOnInit() {
    if (this.pre) {
      this.treeModel.name = "Pre-Case Calculation";
      this.text = "Anticipated Procedures";
    }
    else { 
      this.treeModel.name = "Case Types";      
    }

    this.treeModel.loadProcedures();
     
  }
  ngOnDestroy() {
    
  }
}
