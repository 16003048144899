import { Component, OnInit, DoCheck } from '@angular/core';
import { Observable, pipe } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { CaseFieldsService } from '../case-fields.service';
import { CaseService } from '../case.service';
import { CaseResponse } from "../../../../shared/case/case-response";
import { CaseModel } from '../../../../shared/case/case-model';
import { CaseSaveStatusModel } from '../../../../shared/case-save/case-save-status-model';
import { CaseSaveResponse } from '../../../../shared/case-save/case-save-response';
import { CaseReqResourcesModel } from '../../../../shared/case/case-req-resources-model';
import { CaseProcEfficacyModel } from '../../../../shared/case/case-proc-efficacy-model';
import { CaseProcAssignmentModel } from '../../../../shared/case/case-proc-assignment-model';
import { CaseAEItemModel } from '../../../../shared/case/case-ae-item-model';
import { LoginService } from "../../../user/login.service";
import { MarkCaseCompleteResponse } from '../../../../shared/case-save/mark-case-complete-response';
import { CasePreCalcInfoModel } from '../../../../shared/case/case-pre-calc-info-model';
import { CaseHemoDynamicsInfoModel } from '../../../../shared/case/case-hemo-dynamics-info-model';

declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-missing-fields',
  templateUrl: './missing-fields.component.html',
  styleUrls: ['./missing-fields.component.scss'],
  host: { class: "host" }
})
export class MissingFieldsComponent implements OnInit, DoCheck {
  public loadCaseRun: boolean = false;
  public isNewCase: boolean = false;
  public isComplete: boolean = false;
  public completedCaseText: string = '';
  public saveCaseText: string = '';
  public caseLoadingText: string = '';

  public fields: CaseFieldsService;
  public caseService: CaseService;
  public user: LoginService;
  constructor(fields: CaseFieldsService, _caseService: CaseService, _user: LoginService, private router: Router, private location: Location) {

    localStorage.setItem('savecase', 'savecase');
    this.user = _user;
    if (this.user.IsReadOnlyUser()) {
      this.location.back();
    }

    this.fields = fields;
    this.caseService = _caseService;    
  }

  ngOnInit() {
    this.completedCaseText = '';
    this.saveCaseText = '';
    this.caseLoadingText = '';
    this.fields.validate = true;

    let url = this.router.url;
    let isNotPostSave = (url.indexOf("post-save") === -1);
    if (!isNotPostSave) {
      let urlSplit = url.split("/");
      let caseId = urlSplit[urlSplit.length - 1];

      this.saveCaseText = 'Case has been created and saved (Case ID: ' + caseId + ')';
    }

    this.checkCaseLoaded();
  }

  ngDoCheck() {
    if (this.loadCaseRun === false) {
      this.checkCaseLoaded();
    }
  }

  private checkCaseLoaded() {    
    if (this.fields.caseLoaded == false) {
      $('body').addClass('wait-overlay');
      this.caseLoadingText = 'Case is still loading...';
    } else {
      $('body').removeClass('wait-overlay');
      this.caseLoadingText = '';
      this.loadCase();
    }        
  }

  private loadCase() {
    this.loadCaseRun = true;
    this.isComplete = this.fields.isComplete();

    let url = this.router.url;
    let isNotPostSave = (url.indexOf("post-save") === -1);
    if (isNotPostSave) {
      console.log(this.fields.fields);
      this.caseService.clearCase();

      this.caseService.case.HospitalId = +this.fields.getField("Case Identification", 'Hospital').value;
      this.caseService.case.CaseUpdatedby = this.user.user.UserId.toString();
      this.caseService.case.CaseId = +this.fields.getField("Case Identification", 'Case Number').value;
      this.caseService.case.PrimaryOperatorId = +this.fields.getField("Case Identification", 'Operator').value;
      this.caseService.case.CathDate = this.formatDate(this.fields.getField("Case Identification", 'Cath Date').value);

      if (this.caseService.case.CathDate == '' && isNotPostSave) {
        this.fields.validate = false;
        this.fields.validateDate = true;
        let nav = this.fields.getParent("Case Identification")["link"];
        this.router.navigate([nav]);
      } else {
        this.fields.validateDate = false;
        if (this.formatTime(this.fields.getField("Case Identification", 'Case Time').value)!= null) {
          this.caseService.case.CathDate = this.formatDate(this.fields.getField("Case Identification", 'Cath Date').value) + ' ' + this.formatTime(this.fields.getField("Case Identification", 'Case Time').value);
        }
        this.caseService.case.CaseClinicalInfo.CaseClinicalInfoId = this.fields.getField("Clinical Characteristics", 'Id').value;
        this.caseService.case.CaseClinicalInfo.Updatedby = this.caseService.case.CaseUpdatedby;
        this.caseService.case.CaseClinicalInfo.CaseId = this.caseService.case.CaseId;
        this.caseService.case.CaseClinicalInfo.PatAge = this.fields.getField("Clinical Characteristics", 'Age').value;
        this.caseService.case.CaseClinicalInfo.PatAgeType = this.getAgeType(this.fields.getField("Clinical Characteristics", 'Age Unit').value);
        this.caseService.case.CaseClinicalInfo.PatSex = this.fields.getField("Clinical Characteristics", 'Sex').value;
        this.caseService.case.CaseClinicalInfo.PatWeight = this.fields.getField("Clinical Characteristics", 'Weight').value;
        this.caseService.case.CaseClinicalInfo.PatHeight = this.fields.getField("Clinical Characteristics", 'Height').value;
        this.caseService.case.CaseClinicalInfo.PatBSA = this.fields.getField("Clinical Characteristics", 'BSA').value;
        this.caseService.case.CaseClinicalInfo.StsDiagCode = this.setStsDiagCode();
        this.caseService.case.CaseClinicalInfo.PrevCathlast90dInd = this.getTrueFalse(this.fields.getField("Clinical Characteristics", 'Date Last Cath').value);
        this.caseService.case.CaseClinicalInfo.PrevSurglast90dInd = this.getTrueFalse(this.fields.getField("Clinical Characteristics", 'Date Last Cardiac Surgery').value);
        this.caseService.case.CaseClinicalInfo.GenSyndromeInd = this.getTrueFalse(this.fields.getField("Clinical Characteristics", 'Genetic Syndrome').value);
        this.caseService.case.CaseClinicalInfo.NonCardiacProbInd = this.getTrueFalse(!this.fields.getField("Clinical Characteristics", 'Non-cardiac Problems').hide);
        this.caseService.case.CaseClinicalInfo.NonCardiacProbValues = this.fields.getField("Clinical Characteristics", 'Non-cardiac Problems').value;

        this.caseService.case.CaseProcedureInfo.CaseProccharInfoId = this.fields.getField("Procedure Characteristics", 'Id').value;
        this.caseService.case.CaseProcedureInfo.Updatedby = this.caseService.case.CaseUpdatedby;
        this.caseService.case.CaseProcedureInfo.CaseId = this.caseService.case.CaseId;
        this.caseService.case.CaseProcedureInfo.FluroTime = this.fields.getField("Procedure Characteristics", 'Total Fluoro Time').value;
        //this.fields.getField("Procedure Characteristics", "Total Fluoro Time").hide = true;
        this.caseService.case.CaseProcedureInfo.TotalDap = this.fields.getField("Procedure Characteristics", 'Total DAP').value;
        //this.fields.getField("Procedure Characteristics", "Total DAP").hide = true;
        this.caseService.case.CaseProcedureInfo.SheathCathInDateTime = this.formatTime(this.fields.getField("Procedure Characteristics", 'Sheath-in Time').value);
        this.caseService.case.CaseProcedureInfo.SheathCathOutDateTime = this.formatTime(this.fields.getField("Procedure Characteristics", 'Sheath-out Time').value);
        this.caseService.case.CaseProcedureInfo.BloodTransfusion = this.getTrueFalse(this.fields.getField("Procedure Characteristics", 'Blood Transfusions').value);

        if (this.user.IsIqicUser()) {
          this.caseService.case.CaseAddlProcedureInfo.CaseAddlProccharInfoId = this.fields.getField("Procedure Characteristics", 'AddlId').value;
          this.caseService.case.CaseAddlProcedureInfo.Updatedby = this.caseService.case.CaseUpdatedby;
          this.caseService.case.CaseAddlProcedureInfo.CaseId = this.caseService.case.CaseId;
          this.caseService.case.CaseAddlProcedureInfo.FluroPerformedType = this.getTrueFalse(this.fields.getField("Procedure Characteristics", 'Type Fluoro Performed').value);
          this.caseService.case.CaseAddlProcedureInfo.TotalContrastVolumeVal = this.fields.getField("Procedure Characteristics", 'Total Contrast Volume').value;
          this.caseService.case.CaseAddlProcedureInfo.TotalContrastVolumePerKg = this.fields.getField("Procedure Characteristics", 'Total Contrast Volume Per Kg').value;
          this.caseService.case.CaseAddlProcedureInfo.PreCathHemoglobinVal = this.fields.getField("Procedure Characteristics", 'Pre Cath Hemoglobin').value;
        } else {
          this.caseService.case.CaseAddlProcedureInfo = null;
        }

        if (this.fields.getField("Pre-Case Calculation", 'Single Ventricle Physiology').value != null) {
          this.caseService.case.CasePreCalcInfo = new CasePreCalcInfoModel;
          this.caseService.case.CasePreCalcInfo.CasePrecalcinfoId = this.fields.getField("Pre-Case Calculation", 'Id').value;
          this.caseService.case.CasePreCalcInfo.Updatedby = this.caseService.case.CaseUpdatedby;
          this.caseService.case.CasePreCalcInfo.CaseId = this.caseService.case.CaseId;
          this.caseService.case.CasePreCalcInfo.IsSingleVenPhysiology = this.getTrueFalse(this.fields.getField("Pre-Case Calculation", 'Single Ventricle Physiology').value);
          this.caseService.case.CasePreCalcInfo.PhysiologicCategory = this.fields.getField("Pre-Case Calculation", 'Physiologic Category').value;
          this.caseService.case.CasePreCalcInfo.IsSVEDP = this.getTrueFalse(this.fields.getField("Pre-Case Calculation", 'SV EDP').value);
          this.caseService.case.CasePreCalcInfo.IsSysSat = this.getTrueFalse(this.fields.getField("Pre-Case Calculation", 'Sys Sat').value);
          this.caseService.case.CasePreCalcInfo.IsMvSat = this.getTrueFalse(this.fields.getField("Pre-Case Calculation", 'MV Sat').value);
          this.caseService.case.CasePreCalcInfo.IsPAMean = this.getTrueFalse(this.fields.getField("Pre-Case Calculation", 'PA').value);
          this.caseService.case.CasePreCalcInfo.IsQPQS = this.getTrueFalse(this.fields.getField("Pre-Case Calculation", 'Qp:Qs').value);
          this.caseService.case.CasePreCalcInfo.IsPVR = this.getTrueFalse(this.fields.getField("Pre-Case Calculation", 'PVR').value);
        } else {
          this.caseService.case.CasePreCalcInfo = null;
        }

        if (this.fields.getField("Hemodynamics", 'Single Ventricle Physiology').value != null) {
          this.caseService.case.CaseHemoDynamicInfo = new CaseHemoDynamicsInfoModel;
          this.caseService.case.CaseHemoDynamicInfo.CaseHemoDynamicsInfoId = this.fields.getField("Hemodynamics", 'Id').value;
          this.caseService.case.CaseHemoDynamicInfo.Updatedby = this.caseService.case.CaseUpdatedby;
          this.caseService.case.CaseHemoDynamicInfo.CaseId = this.caseService.case.CaseId;
          this.caseService.case.CaseHemoDynamicInfo.IsSingleVenPhysiology = this.getTrueFalse(this.fields.getField("Hemodynamics", 'Single Ventricle Physiology').value);
          this.caseService.case.CaseHemoDynamicInfo.PhysiologicCategory = this.fields.getField("Hemodynamics", 'Physiologic Category').value;
          this.caseService.case.CaseHemoDynamicInfo.IsSVEDP = this.getTrueFalse(this.fields.getField("Hemodynamics", 'SV EDP').value);
          this.caseService.case.CaseHemoDynamicInfo.IsSysSat = this.getTrueFalse(this.fields.getField("Hemodynamics", 'Sys Sat').value);
          this.caseService.case.CaseHemoDynamicInfo.IsMvSat = this.getTrueFalse(this.fields.getField("Hemodynamics", 'MV Sat').value);
          this.caseService.case.CaseHemoDynamicInfo.IsPAMean = this.getTrueFalse(this.fields.getField("Hemodynamics", 'PA').value);
          this.caseService.case.CaseHemoDynamicInfo.IsQPQS = this.getTrueFalse(this.fields.getField("Hemodynamics", 'Qp:Qs').value);
          this.caseService.case.CaseHemoDynamicInfo.IsPVR = this.getTrueFalse(this.fields.getField("Hemodynamics", 'PVR').value);
        } else {
          this.caseService.case.CaseHemoDynamicInfo = null;
        }

        if (this.user.IsIqicUser()) {
          this.caseService.case.CaseAddlHemoDynamicInfo.CaseAddlHemoDynamicId = this.fields.getField("Hemodynamics", 'AddlId').value;
          this.caseService.case.CaseAddlHemoDynamicInfo.CaseId = this.caseService.case.CaseId;
          this.caseService.case.CaseAddlHemoDynamicInfo.Updatedby = this.caseService.case.CaseUpdatedby;
          this.caseService.case.CaseAddlHemoDynamicInfo.IsShuntClosure = this.getTrueFalse(this.fields.getField("Hemodynamics", 'Considered for Shunt Closure').value);
          this.caseService.case.CaseAddlHemoDynamicInfo.IsRecommendedforSC = this.getTrueFalse(this.fields.getField("Hemodynamics", 'Is Recommended for SC').value);
          this.caseService.case.CaseAddlHemoDynamicInfo.IsAVTestingPerfomed = this.getTrueFalse(this.fields.getField("Hemodynamics", 'Acute Vasodilator Testing').value);
          this.caseService.case.CaseAddlHemoDynamicInfo.CongentialHDDiagVal = this.fields.getField("Hemodynamics", 'Congenital Heart Disease Diagnosis').value;
          this.caseService.case.CaseAddlHemoDynamicInfo.VasodilatorAgentName = this.fields.getField("Hemodynamics", 'Name of Vasodilator Agent').value;
          this.caseService.case.CaseAddlHemoDynamicInfo.BasePVRIWuVal = this.fields.getField("Hemodynamics", 'Baseline Pulmonary Vascular Resistance').value;
          this.caseService.case.CaseAddlHemoDynamicInfo.BaseSVRWuVal = this.fields.getField("Hemodynamics", 'Baseline Systemic Vascular Resistance').value;
          this.caseService.case.CaseAddlHemoDynamicInfo.BasePVRSVRWuRatioVal = this.fields.getField("Hemodynamics", 'Baseline PVR:SVR Ratio').value;
          this.caseService.case.CaseAddlHemoDynamicInfo.BaseQPQARatioVal = this.fields.getField("Hemodynamics", 'Baseline Qp:Qs Ratio').value;
          this.caseService.case.CaseAddlHemoDynamicInfo.BaseCardIndCIVal = this.fields.getField("Hemodynamics", 'Baseline Cardiac Index').value;
          this.caseService.case.CaseAddlHemoDynamicInfo.PostPVRIWuVal = this.fields.getField("Hemodynamics", 'Post-Testing Pulmonary Vascular Resistance').value;
          this.caseService.case.CaseAddlHemoDynamicInfo.PostSVRWuVal = this.fields.getField("Hemodynamics", 'Post-Testing Systemic Vascular Resistance').value;
          this.caseService.case.CaseAddlHemoDynamicInfo.PostPVRSVRWuRatioVal = this.fields.getField("Hemodynamics", 'Post-Testing PVR:SVR Ratio').value;
          this.caseService.case.CaseAddlHemoDynamicInfo.PostQPQARatioVal = this.fields.getField("Hemodynamics", 'Post-Testing Qp:Qs Ratio').value;
          this.caseService.case.CaseAddlHemoDynamicInfo.PostCardIndCIVal = this.fields.getField("Hemodynamics", 'Post-Testing Cardiac Index').value;
        } else {
          this.caseService.case.CaseAddlHemoDynamicInfo = null;
        }

        this.caseService.case.CaseProcAssingedlist = this.setCaseTypes(this.caseService.case.CaseUpdatedby, this.caseService.case.CaseId);

        this.caseService.case.CaseProcAEInfo.Updatedby = this.caseService.case.CaseUpdatedby;
        this.caseService.case.CaseProcAEInfo.CaseId = this.caseService.case.CaseId;
        this.caseService.case.CaseProcAEInfo.caseAEItems = this.setAdverseEvents(this.caseService.case.CaseUpdatedby, this.caseService.case.CaseId);
        this.caseService.case.CaseProcAEInfo.PatientDie = this.fields.getField("Adverse Events", "Patient Die").value;
        this.caseService.case.CaseProcAEInfo.UnanticipatedCath = this.fields.getField("Adverse Events", "Unanticipated Catheterization").value;
        this.caseService.case.CaseProcAEInfo.UnanticipatedSurg = this.fields.getField("Adverse Events", "Unanticipated Surgery").value;
        if (this.caseService.case.CaseProcAEInfo.caseAEItems.length > 0) {
          this.caseService.case.CaseProcAEInfo.CaseAENotes = this.fields.getField("Adverse Events", "Description").value;
        } else {
          this.caseService.case.CaseProcAEInfo.CaseAENotes = null;
        }

        this.caseService.case.CaseReqResources = this.setIndicators(this.caseService.case.CaseUpdatedby, this.caseService.case.CaseId);
        this.caseService.case.CaseProcEfficacy = this.setEfficacy(this.caseService.case.CaseUpdatedby, this.caseService.case.CaseId);

        this.caseService.case.CaseEOCAdmDisposn.CaseAdmtDspoId = this.fields.getField("EOC Admit/Disposition", 'Id').value;
        this.caseService.case.CaseEOCAdmDisposn.Updatedby = this.caseService.case.CaseUpdatedby;
        this.caseService.case.CaseEOCAdmDisposn.CaseId = this.caseService.case.CaseId;
        this.caseService.case.CaseEOCAdmDisposn.IsAdmSrcElective = this.getTrueFalse(this.fields.getField("EOC Admit/Disposition", 'Admission Source').value);
        this.caseService.case.CaseEOCAdmDisposn.PostCathLocation = this.fields.getField("EOC Admit/Disposition", 'Post-Cath Location').value;
        this.caseService.case.CaseEOCAdmDisposn.IsUnplannedAdmission = this.getTrueFalse(this.fields.getField("EOC Admit/Disposition", 'Unplanned Admission').value);
        this.caseService.case.CaseEOCAdmDisposn.AdmittedPriorCathInd = this.getTrueFalse(this.fields.getField("EOC Admit/Disposition", 'Admit Prior Cath').value);
        this.caseService.case.CaseEOCAdmDisposn.DischargePostCathInd = this.getTrueFalse(this.fields.getField("EOC Admit/Disposition", 'Discharge Post Cath').value);
        this.caseService.case.CaseEOCAdmDisposn.IsAliveAtDischarge = this.getTrueFalse(this.fields.getField("EOC Admit/Disposition", 'Alive at Discharge').value);

        console.log(this.caseService.case);

        this.isNewCase = this.caseService.case.CaseId === 0;        

        this.save();
      }
    }        
  }

  private save() {
    if (this.caseService.case.CathDate != '' && this.caseService.case.HospitalId > 0 && this.caseService.case.CaseUpdatedby != null && this.caseService.case.CaseUpdatedby != '')
    {
      //once case is marked complete it is complete we dont change the status back
      if (this.fields.completedCase) {
      //  //todo: switch from completed case back to need to mark completed
        this.fields.completedCase = false;
      }
      this.caseService.save().subscribe(data => this.saveResponse(data));
    }
  }

  private saveResponse(data: CaseSaveResponse) {
    let caseSaveStatusModel = data.DataObject;
    if (caseSaveStatusModel.Casestatus === "Saved Succesfully") {
      let newCaseId = caseSaveStatusModel.CaseId;
      this.saveCaseText = 'Case has been updated and saved (Case ID: ' + newCaseId + ')';
      if (newCaseId > 0) {
        if (this.isNewCase) {
          if (this.caseService.route == "addCaseForCalculation") {
            this.router.navigate(['/user-portal/edit-case/case-id/' + newCaseId]);
          }
          else {
            this.router.navigate(['/user-portal/edit-case/post-save/' + newCaseId]);
          }
        } else {
          let reload = false;

          if ((this.caseService.case.CaseHemoDynamicInfo != null) && (this.fields.getValue("Hemodynamics", "Id") == 0)) {
            reload = true;
          }
          if ((this.caseService.case.CasePreCalcInfo != null) && (this.fields.getValue("Pre-Case Calculation", "Id") == 0)) {
            reload = true;
          }

          if (reload) {
            this.caseService.getCaseDetails(newCaseId).subscribe(data => this.loadCaseResponse(data));
          }
        }
      }
    } else {
      alert(caseSaveStatusModel.Casestatus);
    }
  }

  private loadCaseResponse(data: CaseResponse) {
    let caseModel = data.DataObject;

    if (caseModel.CaseHemoDynamicInfo && caseModel.CaseHemoDynamicInfo.CaseHemoDynamicsInfoId > 0) {
      this.fields.setValue("Hemodynamics", 'Id', caseModel.CaseHemoDynamicInfo.CaseHemoDynamicsInfoId);
    }

    if (caseModel.CasePreCalcInfo && caseModel.CasePreCalcInfo.CasePrecalcinfoId > 0) {
      this.fields.setValue("Pre-Case Calculation", 'Id', caseModel.CasePreCalcInfo.CasePrecalcinfoId);
    }
  }


  private formatDate(date: string): string {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (isNaN(year)) {
      return '';
    }

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    let response = [month, day, year].join('-');

    if (year < 2000) {
      response = '';
    }

    return response;
  }

  private formatTime(date: string): string {
    if (date == null) {
      return null;
    }

    let d = new Date(date),
      hoursNumber = d.getHours(),
      hours = '' + d.getHours(),
      minutes = '' + d.getMinutes();

    if (isNaN(hoursNumber)) {
      return null;
    }

    if (hours.length < 2) hours = '0' + hours;
    if (minutes.length < 2) minutes = '0' + minutes;

    return hours + ":" + minutes;
  }

  private getAgeType(type: string): number {
    if (type === 'Days') {
      return 0;
    }
    if (type === 'Months') {
      return 1;
    }
    if (type === 'Years') {
      return 2;
    }
  }

  private getTrueFalse(type: boolean): string {
    if (type == null) {
      return null;
    }

    if (type) {
      return "1";
    }
    return "0";
  }

  private setStsDiagCode(): string {
    const words = [];
    let PrimarySTS = this.fields.getField("Clinical Characteristics", 'PrimarySTS').value;
    let SecondSTS = this.fields.getField("Clinical Characteristics", 'SecondSTS').value;
    let ThirdSTS = this.fields.getField("Clinical Characteristics", 'ThirdSTS').value;
    let FourthSTS = this.fields.getField("Clinical Characteristics", 'FourthSTS').value;
    if (PrimarySTS) {
      words.push(PrimarySTS.data.code);
    }
    if (SecondSTS) {
      words.push(SecondSTS.data.code);
    }
    if (ThirdSTS) {
      words.push(ThirdSTS.data.code);
    }
    if (FourthSTS) {
      words.push(FourthSTS.data.code);
    }

    var uniqueWords = words.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    })

    return uniqueWords.toString();

  }

  private setCaseTypes(updatedBy: string, caseId: number): CaseProcAssignmentModel[] {
    let result = new Array<CaseProcAssignmentModel>();

    let caseTypes = this.fields.getField("Case Types", "Recorded Procedures").value;
    if (caseTypes != null) {
      let caseTypeNames = Object.getOwnPropertyNames(caseTypes);
      for (let name of caseTypeNames) {
        let item = new CaseProcAssignmentModel;
        item.CaseId = caseId;
        item.Updatedby = updatedBy;
        item.CaseProcAsgnId = 0;

        let caseType = caseTypes[name];
        item.ProcedureCode = caseType["id"].replace("procedure-", "");
        item.IsPrimary = caseType["primary"] === true ? "1" : "0";
        item.IsSecondary = caseType["primary"] === false ? "1" : "0";
        item.IsAnticipatedPrimary = "0";
        item.IsAnticipatedSecondary = "0";
        item.ProcedureTextValue = caseType["details"];
        result.push(item);
      }
    }

    let antCaseTypes = this.fields.getField("Pre-Case Calculation", "Recorded Procedures").value;
    this.fields.getField("Case Types", "Anticipated Procedures").value = antCaseTypes;
    if (antCaseTypes != null) {
      let antCaseTypeNames = Object.getOwnPropertyNames(antCaseTypes);
      if (antCaseTypeNames.length==0) {
        this.fields.getField("Case Types", "Anticipated Procedures").value = null;
      }
      for (let name of antCaseTypeNames) {
        let item = new CaseProcAssignmentModel;
        item.CaseId = caseId;
        item.Updatedby = updatedBy;
        item.CaseProcAsgnId = 0;

        let caseType = antCaseTypes[name];
        item.ProcedureCode = caseType["id"].replace("procedure-", "");
        item.IsPrimary = "0";
        item.IsSecondary = "0";
        item.IsAnticipatedPrimary = caseType["primary"] === true ? "1" : "0";
        item.IsAnticipatedSecondary = caseType["primary"] === false ? "1" : "0";
        item.ProcedureTextValue = caseType["details"];
        result.push(item);
      }
    }
    
    return result;
  }

  private setAdverseEvents(updatedBy: string, caseId: number): CaseAEItemModel[] {
    let result = new Array<CaseAEItemModel>();

    let adverseEvents = this.fields.getField("Adverse Events", "Recorded Events").value;
    if (adverseEvents != null) {
      let adverseEventNames = Object.getOwnPropertyNames(adverseEvents);
      for (let name of adverseEventNames) {
        let item = new CaseAEItemModel;
        item.CaseId = caseId;
        item.Updatedby = updatedBy;
        item.CaseAEId = 0;

        let ae = adverseEvents[name];
        item.AECODE = ae["code"];
        item.AEMajorInd = ae["major"]; 
        item.AESeriousness = ae["seriousness"];
        item.Timeneeded = ae["timeneeded"];
        item.AeTier = ae["aetier"];
        result.push(item);

        // Seriousness and Timeneeded validation check
        if (ae["seriousness"] != "AES01" && ae["seriousness"] != "AES02" && ae["seriousness"] != "AES03"
          && ae["seriousness"] != "AES04" && ae["seriousness"] != "AES05") {

          this.fields.getField("Adverse Events", "Seriousness").value = null;
        }
        if (ae["timeneeded"] === "" || ae["timeneeded"] === null) {

          this.fields.getField("Adverse Events", "Time Needed").value = null;
        }
      }
    }
       
    return result;
  }

  private setIndicators(updatedBy: string, caseId: number): CaseReqResourcesModel[] {
    let result = new Array<CaseReqResourcesModel>();

    let categories: Array<string> = ["Airway Management", "Ventilation", "Sedation", "Lines", "Drains", "Mechanical Support", "IV Medications","Checked6Hours", "Checked24Hours", "Checked48Hours"];
    let procs: Array<string> = ["Pre-Procedure", "Procedural", "Post-Procedure","48 Hrs Post-Cath"];
    for (let category of categories) {
      for (let proc of procs) {
        try {
          let val = this.fields.getField("Indicators of Required Resources", (category + " " + proc)).value;

          let crrm = new CaseReqResourcesModel;
          crrm.ReqResourceId = this.fields.getField("Indicators of Required Resources", (category + " " + proc)).id;
          crrm.CaseId = caseId;
          crrm.Updatedby = updatedBy;
          crrm.ResourceName = category;
          crrm.ResourceValue = val;
          if ((category + " " + proc) == "Checked6Hours Pre-Procedure") {
            crrm.ResourceValue = this.fields.checked6Hours.toString();
          }

          if ((category + " " + proc) == "Checked24Hours Post-Procedure") {
            crrm.ResourceValue = this.fields.checked24Hours.toString();
          }

          if ((category + " " + proc) == "Checked48Hours 48 Hrs Post-Cath") {
            crrm.ResourceValue = this.fields.checked48Hours.toString();
          } 

          if (proc === "Pre-Procedure") {
            crrm.IsResUsedPreProcTime = "1";
            crrm.IsResUsedProcTime = "0";
            crrm.IsResUsedPostProcTime = "0";
            crrm.IsResUsed48HoursTime = "0";
          } else if (proc === "Procedural") {
            crrm.IsResUsedPreProcTime = "0";
            crrm.IsResUsedProcTime = "1";
            crrm.IsResUsedPostProcTime = "0";
            crrm.IsResUsed48HoursTime = "0";
          } else if (proc === "Post-Procedure") {
            crrm.IsResUsedPreProcTime = "0";
            crrm.IsResUsedProcTime = "0";
            crrm.IsResUsedPostProcTime = "1";
            crrm.IsResUsed48HoursTime = "0";
          } else if (proc === "48 Hrs Post-Cath") {
            crrm.IsResUsedPreProcTime = "0";
            crrm.IsResUsedProcTime = "0";
            crrm.IsResUsedPostProcTime = "0";
            crrm.IsResUsed48HoursTime = "1";
          } else {
            crrm.IsResUsedPreProcTime = "0";
            crrm.IsResUsedProcTime = "0";
            crrm.IsResUsedPostProcTime = "0";
            crrm.IsResUsed48HoursTime = "0";
          } 

          if (crrm.ResourceValue) {
            result.push(crrm);
          }                   
        } catch (ex) {
          console.log(ex);
        }        
      }
    }

    let category: string = "Nursing Assignment";
    let procsNA: Array<string> = ["6 Hrs Pre-Procedure", "18 to 24 Hrs Post-Cath","48 Hrs Post-Cath"];
    for (let proc of procsNA) {
      try {
        let crrm = new CaseReqResourcesModel;
        crrm.ReqResourceId = this.fields.getField("Indicators of Required Resources", (category + " " + proc)).id;
        crrm.CaseId = caseId;
        crrm.Updatedby = updatedBy;
        crrm.ResourceName = category;
        crrm.ResourceValue = this.fields.getField("Indicators of Required Resources", (category + " " + proc)).value;

        if (proc === "6 Hrs Pre-Procedure") {
          crrm.IsResUsedPreProcTime = "1";
          crrm.IsResUsedProcTime = "0";
          crrm.IsResUsedPostProcTime = "0";
          crrm.IsResUsed48HoursTime = "0";
        } else if (proc === "18 to 24 Hrs Post-Cath") {
          crrm.IsResUsedPreProcTime = "0";
          crrm.IsResUsedProcTime = "0";
          crrm.IsResUsedPostProcTime = "1";
          crrm.IsResUsed48HoursTime = "0";
          crrm.ResourceName = ">6 hrs post-cath";
        } else if (proc === "48 Hrs Post-Cath") {
          crrm.IsResUsedPreProcTime = "0";
          crrm.IsResUsedProcTime = "0";
          crrm.IsResUsedPostProcTime = "0";
          crrm.IsResUsed48HoursTime = "1";
          crrm.ResourceName = "48 Hrs Post-Cath";
        } else {          
          crrm.IsResUsedPreProcTime = "0";
          crrm.IsResUsedProcTime = "0";
          crrm.IsResUsedPostProcTime = "0";
          crrm.IsResUsed48HoursTime = "0";
        }

        if (crrm.ResourceValue) {
          result.push(crrm);
        }        
      } catch (ex) {
        console.log(ex);
      } 
    }

    // set default value 6 Hours Pre-Procedure when checked6Hours is cehcked only
    if (this.fields.checked6Hours) {
      this.fields.getField("Indicators of Required Resources", "Nursing Assignment 6 Hrs Pre-Procedure").hide = true;
      result = result.filter(function (item) {
        if (item.IsResUsedPreProcTime == "1") {
          if (item.ResourceName == "Airway Management") {
            item.ResourceValue = "AM01";
            return item;
          }
          else if (item.ResourceName == "Ventilation") {
            item.ResourceValue = "VENT01";
            return item;
          }
          else if (item.ResourceName == "Checked6Hours") {
            return item;
          }
          else {
            return;
          }
        }
        else {
          return item;
        }
      });
    }

    // set default value for 24 hours and 48 hours post cath when checked24Hours is cehcked
    if (this.fields.checked24Hours) {
      this.fields.getField("Indicators of Required Resources", "Nursing Assignment 18 to 24 Hrs Post-Cath").hide = true;
      this.fields.getField("Indicators of Required Resources", "Nursing Assignment 48 Hrs Post-Cath").hide = true;
      result = result.filter(function (item) {
        if (item.IsResUsedPostProcTime == "1" || item.IsResUsed48HoursTime == "1") {
          if (item.ResourceName == "Airway Management") {
            item.ResourceValue = "AM01";
            return item;
          }
          else if (item.ResourceName == "Ventilation") {
            item.ResourceValue = "VENT01";
            return item;
          }
          else if (item.ResourceName == "Checked24Hours"||item.ResourceName == "Checked48Hours") {
            return item;
          }
          else {
            return;
          }
        }
        else {
          return item;
        }
      });
    }
    // set default value 48 hours post cath when checked48Hours is cehcked only
    if (this.fields.checked48Hours && !this.fields.checked24Hours) {
      this.fields.getField("Indicators of Required Resources", "Nursing Assignment 48 Hrs Post-Cath").hide = true;
        result = result.filter(function (item) {
          if (item.IsResUsed48HoursTime == "1") {
            if (item.ResourceName == "Airway Management") {
              item.ResourceValue = "AM01";
              return item;
            }
            else if (item.ResourceName == "Ventilation") {
              item.ResourceValue = "VENT01";
              return item;
            }
            else if (item.ResourceName == "Checked48Hours") {
              return item;
            }
            else {
              return;
            }
          }
          else {
            return item;
          }
        });
    }

    return result;
  }

  private setEfficacy(updatedBy: string, caseId: number): CaseProcEfficacyModel[] {
    try {
      let result = new Array<CaseProcEfficacyModel>();
      let record = new CaseProcEfficacyModel;

      let categories: Array<string> = ["AV Procedure indications", "Aortic regurgitation Pre-Procedure", "Aortic regurgitation Post-Procedure", "Peak systolic AV gradient Pre-Procedure", "Peak systolic AV gradient Post-Procedure", "PV Procedure indications",
        "Pulmonary regurgitation Pre-Procedure", "Pulmonary regurgitation Post-Procedure", "Tricuspid valve regurgitation Pre-Procedure", "Tricuspid valve regurgitation Post-Procedure", "ASDDC Procedure Indications", "CP Procedure Indications", "Peak systolic gradient at coarctation Pre-Procedure",
        "Peak systolic gradient at coarctation Post-Procedure","PDADC Procedure Indications", "PDA shunt Size", "Aortic Valvuloplasty", "Pulmonary Valvuloplasty", "ASD Device Closure", "Residual shunt on echocardiogram", "Coarctation Procedure",
      "Any aneurysm post procedure", "stent migration", "Jailing of head/neck vessels", "New or Changed mitral valve insufficiency", "Any main pulmonary artery or right ventricular outflow tract tear", "Pulmonary Valve Type",
      "Subvalvar Obstruction", "PDA Device Closure", "Residual Shunt", "New left pulmonaryartery stenosis", "New aortic arch obstruction", "Intervention performed",
        "PDA Reason for device removal", "Device removed from body", "Was a second device placed?", "Was the second device removed?","PDA Reason for second device removal", "Hemolysis", "Pre Pulmonary valve gradient", "Post Pulmonary valve gradient", "ASD shunt Size", "Pre Left Ventricle Dysfunction", "Post Left Ventricle Dysfunction" ];
   
    for (let category of categories) {

      let cpem = new CaseProcEfficacyModel;
      if (category == "Aortic Valvuloplasty" || category == "AV Procedure indications" || category.indexOf("Aortic regurgitation") >= 0
        || category.indexOf("Peak systolic AV gradient") >= 0 || category == "Pre Left Ventricle Dysfunction" || category == "Post Left Ventricle Dysfunction")
      {
        if (this.fields.getField("Procedural Efficacy", "Aortic Valvuloplasty").value == 1) {
          record = this.setEfficacyArray(category, "EFPRC01", updatedBy, caseId)
          if (record.EfficacyDataValue != null && record.EfficacyDataValue != undefined)
            result.push(record);
        }
        if (category == "Aortic Valvuloplasty" && this.fields.getField("Procedural Efficacy", "Aortic Valvuloplasty").value == 0) {
          record = this.setEfficacyArray(category, "EFPRC01", updatedBy, caseId)
          if (record.EfficacyDataValue != null && record.EfficacyDataValue != undefined)
            result.push(record);
        }
        
        if (category == "Aortic Valvuloplasty" && this.fields.getField("Procedural Efficacy", "Aortic Valvuloplasty").value == 1) {
          
          this.fields.getField("Procedural Efficacy", "Aortic regurgitation Pre-Procedure").hide = false;
          this.fields.getField("Procedural Efficacy", "Aortic regurgitation Post-Procedure").hide = false;
          this.fields.getField("Procedural Efficacy", "Peak systolic AV gradient Pre-Procedure").hide = false;
          this.fields.getField("Procedural Efficacy", "Peak systolic AV gradient Post-Procedure").hide = false;
          this.fields.getField("Procedural Efficacy", "AV Procedure indications").hide = false;
        }
        else if (this.fields.getField("Procedural Efficacy", "Aortic Valvuloplasty").value == 0)
        {
          this.fields.getField("Procedural Efficacy", "Aortic regurgitation Pre-Procedure").hide = true;
          this.fields.getField("Procedural Efficacy", "Aortic regurgitation Post-Procedure").hide = true;
          this.fields.getField("Procedural Efficacy", "Peak systolic AV gradient Pre-Procedure").hide = true;
          this.fields.getField("Procedural Efficacy", "Peak systolic AV gradient Post-Procedure").hide = true;
          this.fields.getField("Procedural Efficacy", "AV Procedure indications").hide = true;
        }
       // cpem.EfficacyProcedureCode = "EFPRC01";
      }
      if (category == "Pulmonary Valvuloplasty" || category == "PV Procedure indications" || category.indexOf("Pulmonary regurgitation") >= 0
        || category == "Pre Pulmonary valve gradient" || category == "Post Pulmonary valve gradient" 
        || category.indexOf("Tricuspid valve regurgitation") >= 0 || category == "Pulmonary Valve Type" || category == "Subvalvar Obstruction"
        || category == "Any main pulmonary artery or right ventricular outflow tract tear" || category == "Intervention performed")
      {
        if (this.fields.getField("Procedural Efficacy", "Pulmonary Valvuloplasty").value == 1)
        {
          record = this.setEfficacyArray(category, "EFPRC02", updatedBy, caseId)
          if (record.EfficacyDataValue != null && record.EfficacyDataValue != undefined)
            result.push(record);
        }
        if (category == "Pulmonary Valvuloplasty" && this.fields.getField("Procedural Efficacy", "Pulmonary Valvuloplasty").value == 0) {
          record = this.setEfficacyArray(category, "EFPRC02", updatedBy, caseId)
          if (record.EfficacyDataValue != null && record.EfficacyDataValue != undefined)
            result.push(record);
        }

        if (category == "Pulmonary Valvuloplasty" && this.fields.getField("Procedural Efficacy", "Pulmonary Valvuloplasty").value == 1)
        {         
          this.fields.getField("Procedural Efficacy", "PV Procedure indications").hide = false;
          this.fields.getField("Procedural Efficacy", "Pulmonary regurgitation Pre-Procedure").hide = false;
          this.fields.getField("Procedural Efficacy", "Pulmonary regurgitation Post-Procedure").hide = false;
          this.fields.getField("Procedural Efficacy", "Tricuspid valve regurgitation Pre-Procedure").hide = false;
          this.fields.getField("Procedural Efficacy", "Tricuspid valve regurgitation Post-Procedure").hide = false;
        }
        else if (this.fields.getField("Procedural Efficacy", "Pulmonary Valvuloplasty").value == 0)
        {
          this.fields.getField("Procedural Efficacy", "PV Procedure indications").hide = true;
          this.fields.getField("Procedural Efficacy", "Pulmonary regurgitation Pre-Procedure").hide = true;
          this.fields.getField("Procedural Efficacy", "Pulmonary regurgitation Post-Procedure").hide = true;
          this.fields.getField("Procedural Efficacy", "Tricuspid valve regurgitation Pre-Procedure").hide = true;
          this.fields.getField("Procedural Efficacy", "Tricuspid valve regurgitation Post-Procedure").hide = true;
        }
       // cpem.EfficacyProcedureCode = "EFPRC02";
      }
      if (category == "ASDDC Procedure Indications" || category == "ASD Device Closure" || category == "Residual shunt on echocardiogram" || category == "ASD shunt Size" || category == "New or Changed mitral valve insufficiency")
      {
        if (this.fields.getField("Procedural Efficacy", "ASD Device Closure").value == 1) {
          record = this.setEfficacyArray(category, "EFPRC03", updatedBy, caseId)
          if (record.EfficacyDataValue != null && record.EfficacyDataValue != undefined)
            result.push(record);
        }

        if (category == "ASD Device Closure" && this.fields.getField("Procedural Efficacy", "ASD Device Closure").value == 0) {
          record = this.setEfficacyArray(category, "EFPRC03", updatedBy, caseId)
          if (record.EfficacyDataValue != null && record.EfficacyDataValue != undefined)
            result.push(record);
        }

        if (category == "ASD Device Closure" && this.fields.getField("Procedural Efficacy", "ASD Device Closure").value == 1)
        {          
          this.fields.getField("Procedural Efficacy", "ASDDC Procedure Indications").hide = false;
          if (this.fields.getField("Procedural Efficacy", "Residual shunt on echocardiogram").value == 1)
          {
            this.fields.getField("Procedural Efficacy", "ASD shunt Size").hide = false;
          }
          else
          {
            this.fields.getField("Procedural Efficacy", "ASD shunt Size").hide = true;
          }
         
        }
        else if (this.fields.getField("Procedural Efficacy", "ASD Device Closure").value == 0)
        {
          this.fields.getField("Procedural Efficacy", "ASDDC Procedure Indications").hide = true;
          this.fields.getField("Procedural Efficacy", "ASD shunt Size").hide = true;
        }
       // cpem.EfficacyProcedureCode = "EFPRC03";
      }
      if (category == "Coarctation Procedure" || category == "CP Procedure Indications" || category.indexOf("Peak systolic gradient at coarctation") >= 0       
        || category == "Any aneurysm post procedure" || category == "stent migration" || category == "Jailing of head/neck vessels")
      {
        if (this.fields.getField("Procedural Efficacy", "Coarctation Procedure").value == 1) {
          record = this.setEfficacyArray(category, "EFPRC04", updatedBy, caseId)
          if (record.EfficacyDataValue != null && record.EfficacyDataValue != undefined)
            result.push(record);
        }
        if (category == "Coarctation Procedure" && this.fields.getField("Procedural Efficacy", "Coarctation Procedure").value == 0) {
           record = this.setEfficacyArray(category, "EFPRC04", updatedBy, caseId)
          if (record.EfficacyDataValue != null && record.EfficacyDataValue != undefined)
            result.push(record);
        }
        if (category == "Coarctation Procedure" && this.fields.getField("Procedural Efficacy", "Coarctation Procedure").value == 1)
        {          
          this.fields.getField("Procedural Efficacy", "CP Procedure Indications").hide = false;
          this.fields.getField("Procedural Efficacy", "Peak systolic gradient at coarctation Pre-Procedure").hide = false;
          this.fields.getField("Procedural Efficacy", "Peak systolic gradient at coarctation Post-Procedure").hide = false;
        }
        else if (this.fields.getField("Procedural Efficacy", "Coarctation Procedure").value == 0)
        {
          this.fields.getField("Procedural Efficacy", "CP Procedure Indications").hide = true;
          this.fields.getField("Procedural Efficacy", "Peak systolic gradient at coarctation Pre-Procedure").hide = true;
          this.fields.getField("Procedural Efficacy", "Peak systolic gradient at coarctation Post-Procedure").hide = true;
        }
      //  cpem.EfficacyProcedureCode = "EFPRC04";
      }
      if (category == "PDA Device Closure" || category == "PDADC Procedure Indications" || category == "PDA shunt Size" || category == "Residual Shunt"
        || category == "New left pulmonaryartery stenosis" || category == "New aortic arch obstruction" || category == "Hemolysis"
        || category == "Device removed from body" || category == "Was a second device placed?" || category == "PDA Reason for device removal"
        || category == "Was the second device removed?" || category == "PDA Reason for second device removal")
      {
        if (this.fields.getField("Procedural Efficacy", "PDA Device Closure").value == 1) {
          record = this.setEfficacyArray(category, "EFPRC05", updatedBy, caseId)
          if (record.EfficacyDataValue != null && record.EfficacyDataValue != undefined)
            result.push(record);
        }
        if (category == "PDA Device Closure" && this.fields.getField("Procedural Efficacy", "PDA Device Closure").value == 0) {
          record = this.setEfficacyArray(category, "EFPRC05", updatedBy, caseId)
          if (record.EfficacyDataValue != null && record.EfficacyDataValue != undefined)
            result.push(record);
        }
        if (category == "PDA Device Closure" && this.fields.getField("Procedural Efficacy", "PDA Device Closure").value == 1)
        {          
          this.fields.getField("Procedural Efficacy", "PDADC Procedure Indications").hide = false;
          if (this.fields.getField("Procedural Efficacy", "Residual Shunt").value == 1) {
            this.fields.getField("Procedural Efficacy", "PDA shunt Size").hide = false;
          }
          else
          {
            this.fields.getField("Procedural Efficacy", "PDA shunt Size").hide = true;
          }
          if (this.fields.getField("Procedural Efficacy", "Device removed from body").value == 1) {
            this.fields.getField("Procedural Efficacy", "PDA Reason for device removal").hide = false;
          }
          else {
            this.fields.getField("Procedural Efficacy", "PDA Reason for device removal").hide = true;
          }
          if (this.fields.getField("Procedural Efficacy", "Was the second device removed?").value == 1) {
            this.fields.getField("Procedural Efficacy", "PDA Reason for second device removal").hide = false;
          }
          else
          {
            this.fields.getField("Procedural Efficacy", "PDA Reason for second device removal").hide = true;
          }
        }
        else if (this.fields.getField("Procedural Efficacy", "PDA Device Closure").value == 0)
        {
          this.fields.getField("Procedural Efficacy", "PDADC Procedure Indications").hide = true;
          this.fields.getField("Procedural Efficacy", "PDA shunt Size").hide = true;
          this.fields.getField("Procedural Efficacy", "PDA Reason for device removal").hide = true;
          this.fields.getField("Procedural Efficacy", "PDA Reason for second device removal").hide = true;
        }
       // cpem.EfficacyProcedureCode = "EFPRC05";
      }

 
    
      }
    return result;
    } catch (ex) {
      console.log(ex);
    }
  }

  setEfficacyArray(category: string, procCode: string, updatedBy: string, caseId: number)
  {
    try {
      let cpem = new CaseProcEfficacyModel;

   // let result = new Array<CaseProcEfficacyModel>();
   // let procs: Array<string> = ["Pre-Procedure", "Post-Procedure"];

    cpem.EfficacyProcedureCode = procCode; 

    if (category.indexOf("Peak systolic AV gradient") >= 0 || category.indexOf("Aortic regurgitation") >= 0 || category.indexOf("Pulmonary regurgitation") >= 0
      || category.indexOf("Tricuspid valve regurgitation") >= 0 || category.indexOf("Peak systolic gradient at coarctation") >= 0) {
    //  for (let proc of procs) {
        try {
         // let cpem1 = new CaseProcEfficacyModel;
          cpem.EfficacyProcedureCode = cpem.EfficacyProcedureCode;
          let val = this.fields.getField("Procedural Efficacy", (category)).value;



          cpem.CaseProcEfficacyInfoid = this.fields.getField("Procedural Efficacy", (category)).id;
          cpem.CaseId = caseId;
          cpem.Updatedby = updatedBy;
          cpem.EfficacyDataName = category;
          cpem.EfficacyDataValue = val;

          if (category.indexOf("Pre-Procedure") >=0) {
            cpem.IsDataPreProcTime = "1";

            cpem.IsDataPostProcTime = "0";
          }
          else if (category.indexOf("Post-Procedure") >= 0) {
            cpem.IsDataPreProcTime = "0";
            cpem.IsDataPostProcTime = "1";
          } else {
            cpem.IsDataPreProcTime = "0";
            cpem.IsDataPostProcTime = "0";

         // }

         // if ((val != null || val != undefined)) {
         //   result.push(cpem);
          }


        } catch (ex) {
          console.log(ex);
        }
     // }
    }
    else if (category == "Pre Pulmonary valve gradient" || category == "Post Pulmonary valve gradient" || category == "Pre Left Ventricle Dysfunction" || category == "Post Left Ventricle Dysfunction") {
      let val = this.fields.getField("Procedural Efficacy", (category)).value;

      cpem.CaseProcEfficacyInfoid = this.fields.getField("Procedural Efficacy", (category)).id;
      cpem.CaseId = caseId;
      cpem.Updatedby = updatedBy;
      cpem.EfficacyDataName = category;
      cpem.EfficacyDataValue = val;
      if (category == "Pre Pulmonary valve gradient") {
        cpem.IsDataPostProcTime = "0";
        cpem.IsDataPreProcTime = "1";
      }
      else {
        cpem.IsDataPostProcTime = "1";
        cpem.IsDataPreProcTime = "0";
      }

      //if ((val != null || val != undefined)) {
        // if ((cpem.IsDataPostProcTime == "0" && cpem.IsDataPreProcTime == "0" && cpem.EfficacyDataValue != "0")) {
      //  result.push(cpem);
        // }
     // }

    }
    else {

      let val = this.fields.getField("Procedural Efficacy", (category)).value;

      cpem.CaseProcEfficacyInfoid = this.fields.getField("Procedural Efficacy", (category)).id;
      cpem.CaseId = caseId;
      cpem.Updatedby = updatedBy;
      cpem.EfficacyDataName = category;
      cpem.EfficacyDataValue = val;
      cpem.IsDataPostProcTime = "0";
      cpem.IsDataPreProcTime = "0";
     // if ((val != null || val != undefined)) {
        // if ((cpem.IsDataPostProcTime == "0" && cpem.IsDataPreProcTime == "0" && cpem.EfficacyDataValue != "0"))
        //  {
      //  result.push(cpem);
        //  }          
    //  }

    }
    
    return cpem;
  } catch(ex) {
    console.log(ex);
  }
  }

  disablemarkcomplete()
  {
    return !this.fields.isComplete() && !this.fields.completedCase;
  }

  markCaseComplete() {
    let url = this.router.url;
    let isNotPostSave = (url.indexOf("post-save") === -1);
    if (!isNotPostSave) {
      //load case values
      this.caseService.clearCase();
      this.caseService.case.CaseUpdatedby = this.user.user.UserId.toString();
      this.caseService.case.CaseId = +this.fields.getField("Case Identification", 'Case Number').value;
    }
    this.caseService.markCaseComplete().subscribe(data => this.completeResponse(data));
  }

  private completeResponse(data: MarkCaseCompleteResponse) {
    let caseCompleteStatusModel = data.DataObject;
    if (caseCompleteStatusModel.Status === "Completed") {
      this.fields.completedCase = true;
      this.completedCaseText = 'Case has been marked as Completed';
    } else {
      this.completedCaseText = caseCompleteStatusModel.Status;
    }
  }
}
