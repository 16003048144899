export class CaseProcAssignmentModel {
  CaseProcAsgnId: number;
  CaseId: number;
  ProcedureCode: string;
  IsPrimary: string;
  IsSecondary: string;
  IsAnticipatedPrimary: string;
  IsAnticipatedSecondary: string;
  ProcedureTextValue: string;
  Updatedby: string;
}
