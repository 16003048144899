import { Component, OnInit } from '@angular/core';
import { UserResponse } from '../../../shared/user-response';
import { UserModel } from '../../../shared/user-model';
import { LoginService } from '../../user/login.service';
import { Router } from '@angular/router';
import { enterAnimation } from '../../../animations/enter.animation';


@Component({
  selector: 'app-login-card',
  templateUrl: './login-card.component.html',
  styleUrls: ['./login-card.component.scss'],
  animations: [enterAnimation]
})
export class LoginCardComponent implements OnInit {

  userName: string = '';
  passWord: string = '';
  errorMessage: string = '';

  userResponse: UserResponse;
  userModel: UserModel;

  user: LoginService
  constructor(user: LoginService, private router: Router) {
    this.user = user;

    this.userName = '';
    this.passWord = '';
    this.errorMessage = '';
  }

  ngOnInit() {
    
  }

  Login() {
    this.errorMessage = '';

    if (this.userName.length > 0 && this.passWord.length > 0) {
      this.user.checkUser(this.userName, this.passWord).subscribe(data => this.validateUser(data));
    }    
  }
  validateUser(ur: UserResponse) {
    if (ur.MessageType === "success"
      && ur.DataObject.length === 1
      && ur.DataObject[0].UserId > 0
      && ur.DataObject[0].ValidationMessage === "Success") {
      localStorage.setItem('currentUser', JSON.stringify(ur.DataObject[0]));
      this.user.user = ur.DataObject[0];

      this.user.logIn();
      this.router.navigate(['/user-portal']);
    } else {
      this.passWord = '';

      if (ur.DataObject.length === 1
        && ur.DataObject[0].UserId === 0) {
        this.errorMessage = ur.DataObject[0].ValidationMessage;
      } else {
        this.errorMessage = "Error logging in user";
      }
    }
  }
}
