
import { Component, OnInit,ViewChild,HostListener } from '@angular/core';
import { CaseFieldsService } from '../case-fields.service';
import { ComponentCanDeactivate } from '../../../../CanDeactivateGaurd';
import { Router, ActivatedRoute, ParamMap, NavigationEnd, NavigationStart, RouterEvent } from "@angular/router";
import { filter, map, mergeMap, tap } from "rxjs/operators";
import { Observable } from 'rxjs';
import { HemoInfo } from "../../../../shared/report-model-hemo-info";

import { NgForm } from "@angular/forms";

@Component({
  selector: 'app-pre-calc',
  templateUrl: './pre-calc.component.html',
  styleUrls: ['./pre-calc.component.scss'],
  host: { 'class': "host" }
})
export class PreCalcComponent  implements OnInit, ComponentCanDeactivate {
  @ViewChild('form') form: NgForm;
  loading: boolean = false;
  public fields: CaseFieldsService;
  constructor(private router: Router,_fields: CaseFieldsService) {
    router.events
      .pipe(filter(e => e instanceof NavigationStart))
      .subscribe((e: NavigationStart) => {
        localStorage.setItem('editcase', e.url);
      });
    this.fields = _fields;
  }

  ngOnInit() {
    let precaseTypes = this.fields.getField("Pre-Case Calculation", "Recorded Procedures").value
    let hemoinfo = new HemoInfo;
    hemoinfo.IsSVP = this.fields.getField("Pre-Case Calculation", "Single Ventricle Physiology").value
    hemoinfo.PhysiologicCategory = this.fields.getField("Pre-Case Calculation","Physiologic Category").value
    hemoinfo.IsSVEDP18mmHgOrHigher = this.fields.getField("Pre-Case Calculation", "SV EDP").value
    hemoinfo.SysSatIND = this.fields.getField("Pre-Case Calculation", "Sys Sat").value
    hemoinfo.IsMVSATLessThan50Percent = this.fields.getField("Pre-Case Calculation", "MV Sat").value
    hemoinfo.IsPAMeanEqual17mmOrHigher = this.fields.getField("Pre-Case Calculation", "PA").value
    hemoinfo.IsQPQSGreaterThan1Point5 = this.fields.getField("Pre-Case Calculation", "Qp:Qs").value
    hemoinfo.ISPVREqual3WUROrHigher = this.fields.getField("Pre-Case Calculation", "PVR").value
    localStorage.setItem('precasetypes', JSON.stringify(precaseTypes));
    localStorage.setItem('prehemo', JSON.stringify(hemoinfo));
  }

  private convertTrueFalse(type: boolean): string {

    if (type === true) return '1';
    else if (type === false || type === null) return '0';
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    if (localStorage.getItem('dirty') == 'false' || localStorage.getItem('dirty') == null) {
      let precaseTypes = this.fields.getField("Pre-Case Calculation", "Recorded Procedures").value
      let hemoinfo = new HemoInfo;
      hemoinfo.IsSVP = this.fields.getField("Pre-Case Calculation", "Single Ventricle Physiology").value
      hemoinfo.PhysiologicCategory = this.fields.getField("Pre-Case Calculation", "Physiologic Category").value
      hemoinfo.IsSVEDP18mmHgOrHigher = this.fields.getField("Pre-Case Calculation", "SV EDP").value
      hemoinfo.SysSatIND = this.fields.getField("Pre-Case Calculation", "Sys Sat").value
      hemoinfo.IsMVSATLessThan50Percent = this.fields.getField("Pre-Case Calculation", "MV Sat").value
      hemoinfo.IsPAMeanEqual17mmOrHigher = this.fields.getField("Pre-Case Calculation", "PA").value
      hemoinfo.IsQPQSGreaterThan1Point5 = this.fields.getField("Pre-Case Calculation", "Qp:Qs").value
      hemoinfo.ISPVREqual3WUROrHigher = this.fields.getField("Pre-Case Calculation", "PVR").value
    //  if ((precaseTypes != null && Object.keys(precaseTypes).length != 0 && localStorage.getItem('precaseTypes') != null && JSON.stringify(precaseTypes) != localStorage.getItem('precaseTypes') ) || JSON.stringify(hemoinfo) != localStorage.getItem('prehemo'))
      if ((precaseTypes != null && Object.keys(precaseTypes).length > 0 ) || JSON.stringify(hemoinfo) != localStorage.getItem('prehemo'))
        localStorage.setItem('dirty', 'true')
      else
        localStorage.setItem('dirty', 'false')

    }
    return !this.form.dirty;
  }

  ngOnDestroy() {
    if (localStorage.getItem('dirty') == 'false' || localStorage.getItem('dirty') == null) {
      let precaseTypes = this.fields.getField("Pre-Case Calculation", "Recorded Procedures").value
      let hemoinfo = new HemoInfo;
      hemoinfo.IsSVP = this.fields.getField("Pre-Case Calculation", "Single Ventricle Physiology").value
      hemoinfo.PhysiologicCategory = this.fields.getField("Pre-Case Calculation", "Physiologic Category").value
      hemoinfo.IsSVEDP18mmHgOrHigher = this.fields.getField("Pre-Case Calculation", "SV EDP").value
      hemoinfo.SysSatIND = this.fields.getField("Pre-Case Calculation", "Sys Sat").value
      hemoinfo.IsMVSATLessThan50Percent = this.fields.getField("Pre-Case Calculation", "MV Sat").value
      hemoinfo.IsPAMeanEqual17mmOrHigher = this.fields.getField("Pre-Case Calculation", "PA").value
      hemoinfo.IsQPQSGreaterThan1Point5 = this.fields.getField("Pre-Case Calculation", "Qp:Qs").value
      hemoinfo.ISPVREqual3WUROrHigher = this.fields.getField("Pre-Case Calculation", "PVR").value
      if ((precaseTypes != null && Object.keys(precaseTypes).length > 0) || JSON.stringify(hemoinfo) != localStorage.getItem('prehemo'))
        localStorage.setItem('dirty', 'true')
      else
        localStorage.setItem('dirty', 'false')
    }
  }

  
}
