import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-c3po-qi',
  templateUrl: './c3po-qi.component.html',
  styleUrls: ['./c3po-qi.component.scss'],
  host: { 'class': "host" }
})
export class C3poQiComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
