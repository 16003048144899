/// <reference path="../../user/manage-users/user-management.service.ts" />
import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserManagementService } from "../../user/manage-users/user-management.service";
import { UserResponse } from '../../../shared/user-response';
import { UserModel } from '../../../shared/user-model';
import { LoginService } from '../../user/login.service';
import { enterAnimation } from '../../../animations/enter.animation';
import { Router } from "@angular/router";
import { RouterService } from '../../navigation/router.service';


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  animations: [enterAnimation]
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  user: LoginService;
  oldPwd: string = '';
  newPwd: string = '';
  confirmPwd: string = '';
  errorMessage: string = '';
  successMessage: string = '';
  interval: any;


  usersservice: UserManagementService;
  userModel: UserModel;

  constructor(userserv: UserManagementService, user: LoginService, public router: Router, public routerService: RouterService ) {
    this.usersservice = userserv;
    this.userModel = new UserModel;
    this.user = user;
    this.oldPwd = '';
    this.newPwd = '';
    this.confirmPwd = '';
    //Send out an email after password change?
    //this.usersservice.email = "";
    //this.usersservice.passwordResponse = "";
  }

  ngOnInit() {
  }


  ngOnDestroy() {
    clearInterval(this.interval);
  }


  upDatePwd() {
    this.errorMessage = '';
    this.successMessage = '';

    if (this.oldPwd.length > 0 && this.newPwd.length > 0 && this.confirmPwd.length > 0) {
      //Check if the current password is corrrect     
      this.user.checkUser(this.user.user.UserName, this.oldPwd).subscribe(data => this.validateOldPassword(data));
    }
    else {
      this.errorMessage = "Both old and new password are required.";

    }

  }


  validateOldPassword(ur: UserResponse) {
    if (ur.MessageType === "success"
      && ur.DataObject.length === 1
      && ur.DataObject[0].UserId > 0
      && ur.DataObject[0].ValidationMessage === "Success") {

      this.updateUser();      
      
    } else {
      
      if (ur.DataObject.length === 1
        && ur.DataObject[0].UserId === 0) {
        //this.errorMessage = ur.DataObject[0].ValidationMessage;
        this.errorMessage = "Invalid old password.";
      } else {
        this.errorMessage = "Invalid old password.";
      }
    }

  }


  updateUser() {

    this.userModel.Password = this.newPwd;
    this.userModel.Email = this.user.user.Email;
    this.userModel.FullName = this.user.user.FullName;
    this.userModel.Hospital = this.user.user.Hospital;
    this.userModel.HospitalId = this.user.user.HospitalId;
    this.userModel.IsActive = this.user.user.IsActive;

    this.userModel.Role = this.user.user.Role;
    this.userModel.SelfProviderId = this.user.user.SelfProviderId;
    this.userModel.UserAppRegistry = this.user.user.UserAppRegistry;
    this.userModel.UserAppRegistryID = this.user.user.UserAppRegistryID;
    this.userModel.UserId = this.user.user.UserId;
    this.userModel.UserName = this.user.user.UserName;

    
    if (this.newPwd.length < 6){
      this.errorMessage = "Input error, the minumum required password length is six characters.";      
    }
    else if (this.newPwd != this.confirmPwd) {
      this.errorMessage = "Input error, the new and confirm passwords do not match.";      
    }
    else if (this.newPwd == this.confirmPwd) {
      this.usersservice.updateUserPassword(this.userModel).subscribe(data => this.updateUserResponse(data));
    } 
   
  }

  updateUserResponse(ur: UserResponse) {
    if (ur.MessageType === "success")      
    {
      this.errorMessage = "";
      this.successMessage = "Password has been updated! Please log in with your new password.";

      var isIQIC = this.routerService.showIQIC();

      //Pause then log them out.
      this.interval = setInterval(() => {
            //Log them out
            this.user.logOut();
            //Did this originate from C3PO or IQIC URL?           
            if (isIQIC) {
              this.router.navigate(['/home/IQIC']);
            }
            else {
              this.router.navigate(['/home']);            
        }       
      }, 4000);
      
    }     

  }
  clearInputs() {
      ///Change to a Modal?
      //this.router.navigate(['/user-portal']);
    this.oldPwd = '';
    this.newPwd = '';
  }

}
