import { Injectable } from "@angular/core";
import { Http, Response, Headers, RequestOptions } from "@angular/http";
import { Observable, pipe } from "rxjs";
import { map, takeUntil, tap } from "rxjs/operators";
import { CaseTypeCalcResponse } from "../../shared/case-type-calc-response";
import { CaseTypeCalcRequest } from "../../shared/case-type-calc-request";
import { CONFIG } from "../../shared/config";

let apiUrl = CONFIG.baseUrls.api;
@Injectable()
export class CaseTypeCalcService {
  private http: Http = null;

  constructor(_http: Http) {
    this.http = _http;
  }

  getCaseType(primaryProcedureCode: string, secondaryProcedureCodes: string) {
    let body = new CaseTypeCalcRequest();
    body.PrimaryProcedureCode = primaryProcedureCode;
    body.SecondaryProcedureCodes = secondaryProcedureCodes;
    let headers = new Headers({
      //'Content-Type': 'application/x-www-form-urlencoded'
    });
    let options = new RequestOptions({ headers: headers });

    return this.http
      .post(apiUrl + "case/getcasetype", body, options)
      .pipe(map((response: Response) => <CaseTypeCalcResponse>response.json()));
  }
}
