import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-databse-manual',
  templateUrl: './database-manual.component.html',
  styleUrls: ['./database-manual.component.scss'],
  host: { 'class': "host" }
})
export class DatabaseManualComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
