import { CaseTypesEntryService } from "./case-types-entry.service";
import { Router } from "@angular/router";
import { CaseFieldsService } from "../../user/add-case/case-fields.service";
import { CaseTypeCalcService } from "../case-type-calc.service";
import { CaseTypeCalcResponse } from '../../../shared/case-type-calc-response';

export class CaseTypeTreeModel { 
  procedures = [];
  diagnosticOnly: boolean = false;
  toAddNode;
  toAddTree;
  toAddNodePre;
  toAddTreePre;
  primarySelected: boolean = false;
  selectedCaseGroup = 0;
  currentPrimary = "";
  name = "Case Types";

  public options = {
    animateExpand: true,
    animateAcceleration: 1.2,
    scrollOnActivate: true,
    animateSpeed: 10
  };
  public fields: CaseFieldsService;
  service: CaseTypesEntryService;
  public caseTypeCalcService: CaseTypeCalcService;
  constructor(service: CaseTypesEntryService, _caseTypeCalcService: CaseTypeCalcService) {
    
    this.caseTypeCalcService = _caseTypeCalcService;
    this.service = service;
    this.fields = this.service.fields;
    service.myBool$.subscribe((val: boolean) => {

      if (this.selectedCaseGroup!=0) {
        this.addWithDetails();
      };
     
    });
  }

  //produces the selected key string for a particular procedure
  static getKeyString(node, tree) {
    //all procedure names are parsed as
    let text = "";
    if (node && node.path) {
      let path = node.path;
      for (let id of path) {
        let parent = tree.treeModel.getNodeById(id);
        if (text !== "") {
          text = text + " | " + parent.data.name;
        } else {
          text = text + parent.data.name;
        }
      }
    }

    if (text.length > 0) {
      text = text.replace(" [add details]", "");
    }

    return text;
  }

  //when selecting a leaf, creates a new procedure entry
  //otherwise, opens parent
  onSelect(event, tree) {
    this.selectedCaseGroup = 1;
    this.service.inputVal = "";
    let procs = this.getProcedures();
    if (event.node.isLeaf && !this.diagnosticOnly) {      
      if (event.node.data.code === "1101") {
        //Diagnostic Only selected, will be only selection
        this.setProcedures({});
        this.primarySelected = false;
        this.createRecord(event.node, tree);
        this.diagnosticOnly = true;
      } else if (event.node.data.needsDetails) {
        //if extra details are necessary, opens modal to collect them
        document.getElementById("openModalButton").click();
        this.toAddNode = event.node;
        this.toAddTree = tree;

        if (!procs) {
          this.setProcedures({});
        }
      } else {
        //creates normal entry
        if (!procs) {
          this.setProcedures({});
        }
        this.createRecord(event.node, tree);
      }
    } else {
      //opens parent node
      event.node.toggleExpanded();
    }
  }
  onPreSelect(event, tree) {
    this.selectedCaseGroup = 2;
    this.service.inputVal = "";
    let procs = this.getProcedures();
    if (event.node.isLeaf && !this.diagnosticOnly) {
      if (event.node.data.code === "1101") {
        //Diagnostic Only selected, will be only selection
        this.setProcedures({});
        this.primarySelected = false;
        this.createRecord(event.node, tree);
        this.diagnosticOnly = true;
      } else if (event.node.data.needsDetails) {
        //if extra details are necessary, opens modal to collect them
        document.getElementById("openModalButton").click();
        this.toAddNodePre = event.node;
        this.toAddTreePre = tree;

        if (!procs) {
          this.setProcedures({});
        }
      } else {
        //creates normal entry
        if (!procs) {
          this.setProcedures({});
        }
        this.createRecord(event.node, tree);
      }
    } else {
      //opens parent node
      event.node.toggleExpanded();
    }
  }

  //creates a recorded procedure
  createRecord(node, tree) {
    let procs = this.getProcedures();
    let details = this.service.inputVal !== "" ? " | " + this.service.inputVal : "";

    if (!this.diagnosticOnly || (!node.data.needsDetails || (node.data.needsDetails && details.length > 0))) {
      let nodeString = CaseTypeTreeModel.getKeyString(node, tree);
      
      let primary = false;
      if (!this.primarySelected) {
        primary = true;
        this.primarySelected = true;
        this.currentPrimary = nodeString;
      }

      procs[nodeString + details] = {
        primary: primary,
        id: "procedure-" + node.data.code,
        details: this.service.inputVal
      };     
    }
    this.switchCaseTypeDone(false);
    this.sortProcedures();
    return true;
  }

  //creates a procedure with extra details
  addWithDetails() {
   
    let createdRecord = false;
    if (this.service.canChange) {
      if (this.selectedCaseGroup == 1 && this.toAddNode) {
        createdRecord=this.createRecord(this.toAddNode, this.toAddTree);
      }
      if (this.selectedCaseGroup == 2 && this.toAddNodePre) {
        createdRecord=this.createRecord(this.toAddNodePre, this.toAddTreePre);
      }
      if (createdRecord) {
        document.getElementById("procedure-close").click();
        this.toAddNode = null;
        this.toAddTree = null;
        this.toAddTreePre = null;
        this.toAddNodePre = null;
        this.service.inputVal = "";
      }
    } else {
      this.toAddNode = null;
      this.toAddTree = null;
      this.toAddTreePre = null;
      this.toAddNodePre = null;
    }
    //this.service.canChange = false;
  }

  //sets a new primary procedure
  newPrimary(event, name) {
    let procs = this.getProcedures();
    procs[this.currentPrimary]["primary"] = false;
    this.primarySelected = true;
    this.currentPrimary = name;
    procs[name]["primary"] = true;
    this.switchCaseTypeDone(false);
  }

  //deletes a procedure from the procedure list
  deleteProcedure(event, val) {
    let procedures = this.getProcedures();
    let item = procedures[val];
    delete procedures[val];    
    let arr = this.getProceduresArray();
    if (arr.length > 0) {
      let noPrimary = item.primary;
      if (noPrimary || arr.length == 1) {
        this.currentPrimary = arr[0];
        this.primarySelected = true;
        procedures[arr[0]]["primary"] = true;        
      }
    } else {
      this.diagnosticOnly = false;
      this.currentPrimary = "";
      this.setProcedures(null);
      if (this.name == "Pre-Case Calculation") {
        this.fields.getField("Case Types", "Anticipated Procedures").hide = false;
      }
      this.primarySelected = false;
     
    }
    this.switchCaseTypeDone(false);
    this.sortProcedures();
  }

  loadProcedures() {
    let caseTypes = this.fields.getField(this.name, "Recorded Procedures").value;
    if (caseTypes != null) {
      let caseTypeNames = Object.getOwnPropertyNames(caseTypes);
      for (let name of caseTypeNames) {
        let caseType = caseTypes[name];

        if (caseType["primary"] === true) {
          this.primarySelected = true;
          this.currentPrimary = name;
        }

        let code = caseType["id"].replace("procedure-", "");
        if (code === "1101") {
          this.diagnosticOnly = true;
        }
      }

      this.generateCaseType();
    }
  }

  //retrieves the recorded procedure list from the case list service
  getProcedures() {
      return this.fields.getField(this.name, "Recorded Procedures").value;
  }

  //sets the recorded procedures
  setProcedures(val) {
    this.fields.getField(this.name, "Recorded Procedures").value = val;
  }

  sortProcedures() {
    let unordered = this.fields.getField(this.name, "Recorded Procedures").value;
    if (unordered != null) {
      let ordered = {};
      Object.keys(unordered).sort().forEach(function (key) {
        ordered[key] = unordered[key];
      });
      this.fields.getField(this.name, "Recorded Procedures").value = ordered;
      if (this.name == "Pre-Case Calculation") {
        this.fields.anticipatedCase = false;
      }
    }
  }

  //gets the recorded procedures as an array
  getProceduresArray() {
    var proc = this.getProcedures();
    if (proc) {
      return Object.getOwnPropertyNames(this.getProcedures());
    } else {
      return [];
    }
  }

  //makes the case type visible or invisible, depending on parameter
  switchCaseTypeDone(bool) {
    if (this.name === 'Case Type') {
      this.fields.getParent("Pre-Case Calculation")["hide"] = true;
    }
    
    if (!bool) {
      this.fields.getField(this.name, "Case Type").value = null;
    }

    this.generateCaseType();    
  }

  //retrieves whether case type has been generated
  getCaseTypeDone() {
    return this.fields.getValue(this.name, "Case Type Done");
  }

  generateCaseType() {
    let primary: string = "";
    let secondary: string = "";
    let procs = this.getProcedures();
    let procsArr = this.getProceduresArray();    
    for (let item of procsArr) {
      let proc = procs[item];
      let proc_id = proc['id'];
      proc_id = proc_id.replace("procedure-", "");

      if (proc['primary']) {
        primary = proc_id;
      } else {
        secondary = secondary + proc_id + ",";
      }
    }

    if (primary.length > 0) {
      this.fields.getField(this.name, "Case Type Done").value = true;

      if (secondary.length > 0) {
        secondary = secondary.slice(0, -1);
      }      

      this.caseTypeCalcService.getCaseType(primary, secondary).subscribe(data => this.validateCaseType(data));
    } else {
      this.fields.getField(this.name, "Case Type Done").value = false; 
    }
  }

  validateCaseType(ctcr: CaseTypeCalcResponse) {
    if (ctcr.MessageType === "success" && ctcr.DataObject.CaseTypeDescription) {
      this.fields.getField(this.name, "Case Type").value = ctcr.DataObject.CaseTypeDescription;      
    }
  }
}
