import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { CalculatorService } from '../calculator.service';
import { CaseFieldsService } from '../../user/add-case/case-fields.service';
import { LoginService } from "../../user/login.service";
import { PicklistItemService } from "../../shared-data-entry/picklist-item.service";
import { PicklistItemResponse } from "../../../shared/picklist-item-response";

@Component({
  selector: 'app-calc-characteristics',
  templateUrl: './calc-characteristics.component.html',
  styleUrls: ['./calc-characteristics.component.scss']
})
export class CalcCharacteristicsComponent implements OnInit {
  problemsDropDown = [];
  problems = [];
  selectedProblems = [];
  name = "Clinical Characteristics";
  weight;
  height;
  public isCaseCalc: boolean;
  public calc: CalculatorService;
  fields: CaseFieldsService;
  public user: LoginService;
  public picklistItemService: PicklistItemService;
  constructor(calc: CalculatorService, fields: CaseFieldsService, _user: LoginService,
    _picklistItemService: PicklistItemService,  private router: Router) {
    this.calc = calc;
    this.fields = fields;
    this.user = _user;
    this.picklistItemService = _picklistItemService;
    let url = this.router.url;
    this.isCaseCalc = (url.indexOf("user-portal") > 0);
    this.calc.readOnly = this.isCaseCalc;
    if (this.user.user) {
      this.picklistItemService
        .getPicklistItem(this.user.user.HospitalId.toString(), "Non-Cardiac Problem")
        .subscribe(data => this.populatePicklistItems(data));
    }
    this.calcBSA();
    if (this.isCaseCalc && this.fields.getField('Pre-Case Calculation', 'STS Diagnosis Category').value) {
      this.calc.stsSelection = this.fields.getField('Pre-Case Calculation', 'STS Diagnosis Category').value;
    }
   }

  ngOnInit() {
  }

  private populatePicklistItems(picklistItemResponse: PicklistItemResponse) {
    this.picklistItemService.picklistItemModel = picklistItemResponse.DataObject;
    this.picklistItemService.mapToDropDown();

    this.problemsDropDown = this.picklistItemService.dropDownPicklistItem;
    this.problems = this.problemsDropDown.map(x => x.name);
    this.loadProblems();
  }
  private loadProblems() {
    let valMap: Array<string> = [];

    let probs = this.fields.getValue(this.name, 'Non-cardiac Problems');
    if (probs != null) {
      let probArray = probs.split('|');

      for (let p of probArray) {
        for (let dropDownItem of this.problemsDropDown) {
          if (dropDownItem.code === p) {
            valMap.push(dropDownItem.name);
          }
        }
      }
    }

    this.selectedProblems = valMap;
  }
  calcWeight() {
    let weight = +this.fields.getValue('Clinical Characteristics', "Weight");
    weight = Math.round(weight*10)/10;
    if (weight <= 0 || weight > 500) {
      weight = null;
    }

    this.fields.setValue('Clinical Characteristics', "Weight", weight);
  }
  toggleCardiacProblems(val) {
    this.fields.getField(this.name, 'Non-cardiac Problems').hide = val;
  }
  getCardiacProblems() {
    return this.fields.getField('Clinical Characteristics', 'Non-cardiac Problems').hide;
  }
  public updateProblems() {
    if (this.selectedProblems.length > 0) {
      let valMap: Array<string> = [];

      for (let p of this.selectedProblems) {
        for (let dropDownItem of this.problemsDropDown) {
          if (dropDownItem.name === p) {
            valMap.push(dropDownItem.code)
          }
        }
      }

      this.fields.setValue(this.name, 'Non-cardiac Problems', valMap.join("|"));
    } else {
      this.fields.setValue(this.name, 'Non-cardiac Problems', null);
    }
  }
  calcBSA() {
    this.weight = +this.fields.getValue(this.name, "Weight");
    this.weight = Math.round(this.weight * 10) / 10;
    if (this.weight <= 0 || this.weight > 500) {
      this.weight = null;
    }
    this.fields.setValue(this.name, "Weight", this.weight);

    this.height = +this.fields.getValue(this.name, "Height");
    this.height = Math.round(this.height);
    if (this.height <= 0 || this.height > 500) {
      this.height = null;
    }
    this.fields.setValue(this.name, "Height", this.height);

    if (this.weight && this.height) {
      this.fields.getField(
        this.name,
        "BSA"
      ).value = CalcCharacteristicsComponent.round(
        Math.sqrt((this.height * this.weight) / 3600),
        3
      );
    } else {
      this.fields.setValue(this.name, "BSA", null);
    }
  }

  static round(number, precision) {
    var factor = Math.pow(10, precision);
    var tempNumber = number * factor;
    var roundedTempNumber = Math.round(tempNumber);
    return roundedTempNumber / factor;
  }
}
