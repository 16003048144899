import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Observable, pipe } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { MajorAdverseEventResponse } from '../../shared/major-adverse-event-response';
import { MajorAdverseEventModel } from '../../shared/major-adverse-event-model';
import { DropdownItemModel } from '../../shared/dropdown-item-model';
import { CONFIG } from '../../shared/config';

let apiUrl = CONFIG.baseUrls.api;
@Injectable()
export class MajorAdverseEventService {
  private http: Http = null;

  public majorAdverseEventList: MajorAdverseEventModel[];
  public dropDownMajorAdverseEvent: DropdownItemModel[];

  constructor(_http: Http) {
    this.http = _http;
  }

  getMajorAdverseEvent() {
    let headers = new Headers({
      //'Content-Type': 'application/x-www-form-urlencoded'
    });
    let options = new RequestOptions({ headers: headers });
    return this.http.get(apiUrl + "picklist/getmajoradverseevents", options)
      .pipe(map((response: Response) => <MajorAdverseEventResponse>response.json()));
  }

  mapToDropDown() {
    this.dropDownMajorAdverseEvent = [];

    this.majorAdverseEventList.sort(function (a, b) { return a.MAEventDisplaySeq - b.MAEventDisplaySeq });

    for (let entry of this.majorAdverseEventList) {
      let newItem = new DropdownItemModel;
      newItem.id = entry.MAEventCode;
      newItem.code = entry.MAEventCode.toString();
      newItem.name = entry.MAEventTextToShow;
      newItem.description = entry.MAEventInfoText;
      this.dropDownMajorAdverseEvent.push(newItem);
    }    
  }
}
