import { Component, OnInit , ViewChild , HostListener } from '@angular/core';
import { CaseFieldsService } from "../case-fields.service";
import { LoginService } from "../../../user/login.service";
import { PicklistItemService } from "../../../shared-data-entry/picklist-item.service";
import { PicklistItemResponse } from "../../../../shared/picklist-item-response";
import { PicklistItemModel } from "../../../../shared/picklist-item-model";
import { DropdownItemModel } from "../../../../shared/dropdown-item-model";
import { window } from 'rxjs/operators';
import { NgForm, FormGroup } from "@angular/forms";
import { ComponentCanDeactivate } from '../../../../CanDeactivateGaurd';
import { Observable } from 'rxjs';
import { filter, map, mergeMap, tap } from "rxjs/operators";
import { Router, ActivatedRoute, ParamMap, NavigationEnd, NavigationStart, RouterEvent } from "@angular/router";
import { HemoInfo } from "../../../../shared/report-model-hemo-info";

@Component({
  selector: 'app-hemodynamics',
  templateUrl: './hemodynamics.component.html',
  styleUrls: ['./hemodynamics.component.scss'],
  host: { 'class': "host" }
})
export class HemodynamicsComponent implements OnInit, ComponentCanDeactivate {
  @ViewChild('form') form: NgForm;
  fields: CaseFieldsService;
  user: LoginService;
  picklistItemService: PicklistItemService;
  name = "Hemodynamics";
  loading: boolean = false;
  consideredShuntClosure = false;
  isRecommendedForSCClicked = false;
  aVTPerformed = false;
  vasodilatorAgentsDropDown = [];
  vasodilatorAgents = [];
  vasodilatorAgentsSelected = [];
  congitalHeartDiseaseDiagnosisDropDown = [];
  congitalHeartDiseaseDiagnosis = [];
  congitalHeartDiseaseDiagnosisSelected = [];
  constructor(private router: Router, fields: CaseFieldsService, user: LoginService, _picklistItemService: PicklistItemService) {
    router.events
      .pipe(filter(e => e instanceof NavigationStart))
      .subscribe((e: NavigationStart) => {
        localStorage.setItem('editcase', e.url);
      });
   
    this.fields = fields;
    this.user = user;
    this.picklistItemService = _picklistItemService;

    this.calculateBaseline();
    this.calculatePostTest();

    if (this.fields.getValue(this.name, "Is Recommended for SC") == null) {
      this.isRecommendedForSCClicked = false;
    } else {
      this.isRecommendedForSCClicked = true;
    }

    if (this.user.user) {
      this.picklistItemService
        .getPicklistItem(this.user.user.HospitalId.toString(), "Vasodilator Agent")
        .subscribe(data => this.populatePicklistItemsVA(data));
    }    
  }

  ngOnInit() {
    
  }


  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    if (localStorage.getItem('dirty') == 'false' || localStorage.getItem('dirty') == null) {
      if (localStorage.getItem('hemo') != null) {
        let hemoinfo = new HemoInfo;
        hemoinfo.IsSVP = this.convertTrueFalse(this.fields.fields[4].fields[1].value);
        hemoinfo.PhysiologicCategory = this.fields.fields[4].fields[2].value;
        hemoinfo.IsSVP = this.convertTrueFalse(this.fields.fields[4].fields[3].value);
        hemoinfo.SysSatIND = this.convertTrueFalse(this.fields.fields[4].fields[4].value);
        hemoinfo.IsMVSATLessThan50Percent = this.convertTrueFalse(this.fields.fields[4].fields[5].value);
        hemoinfo.IsPAMeanEqual17mmOrHigher = this.convertTrueFalse(this.fields.fields[4].fields[6].value);
        hemoinfo.IsQPQSGreaterThan1Point5 = this.convertTrueFalse(this.fields.fields[4].fields[7].value);
        hemoinfo.ISPVREqual3WUROrHigher = this.convertTrueFalse(this.fields.fields[4].fields[8].value);
        if (JSON.stringify(hemoinfo) == localStorage.getItem('hemo'))
          localStorage.setItem('dirty', 'false');
        else
          localStorage.setItem('dirty', 'true');
      }
    }
    return !this.form.dirty;
  }

  private populatePicklistItemsVA(picklistItemResponse: PicklistItemResponse) {
    this.picklistItemService.picklistItemModel = picklistItemResponse.DataObject;
    this.picklistItemService.mapToDropDown();

    this.vasodilatorAgentsDropDown = this.picklistItemService.dropDownPicklistItem;
    this.vasodilatorAgents = this.vasodilatorAgentsDropDown.map(x => x.name);

    this.picklistItemService
      .getPicklistItem(this.user.user.HospitalId.toString(), "Congital Heart Disease Diagnosis")
      .subscribe(data => this.populatePicklistItemsCHDD(data));
  }

  private populatePicklistItemsCHDD(picklistItemResponse: PicklistItemResponse) {
    this.picklistItemService.picklistItemModel = picklistItemResponse.DataObject;
    this.picklistItemService.mapToDropDown();

    this.congitalHeartDiseaseDiagnosisDropDown = this.picklistItemService.dropDownPicklistItem;
    this.congitalHeartDiseaseDiagnosis = this.congitalHeartDiseaseDiagnosisDropDown.map(x => x.name);

    this.setSelections();

    this.consideredShuntClosure = this.fields.getValue(this.name, "Considered for Shunt Closure");
    this.aVTPerformed = this.fields.getValue(this.name, "Acute Vasodilator Testing");
  }

  toggleConsideredShuntClosure(selection: boolean) {
    this.consideredShuntClosure = selection;

    this.fields.setValue(this.name, "Considered for Shunt Closure", selection);
  }

  toggleAVTPerformed(selection: boolean) {
    this.aVTPerformed = selection;

    this.fields.setValue(this.name, "Acute Vasodilator Testing", selection);
  }

  toggleIsRecommendedForSCClicked(selection: boolean) {
    this.isRecommendedForSCClicked = true;

    this.fields.setValue(this.name, "Is Recommended for SC", selection);
  }

  getShowBaseLine() {
    return this.consideredShuntClosure || this.aVTPerformed;
  }

  getShowPostTesting() {
    return this.aVTPerformed;
  }

  updateCHDD() {
    let val: Array<string> = [];

    for (let c of this.congitalHeartDiseaseDiagnosisSelected) {
      for (let dd of this.congitalHeartDiseaseDiagnosisDropDown) {
        if (c == dd.name) {
          val.push(dd.code);
        }
      }
    }

    this.fields.setValue(this.name, "Congenital Heart Disease Diagnosis", val.join('|'));
  }

  updateNoVA() {
    let val: Array<string> = [];

    for (let v of this.vasodilatorAgentsSelected) {
      for (let dd of this.vasodilatorAgentsDropDown) {
        if (v == dd.name) {
          val.push(dd.code);
        }
      }
    }

    this.fields.setValue(this.name, "Name of Vasodilator Agent", val.join('|'));
  }

  setSelections() {
    let chdd = this.fields.getValue(this.name, "Congenital Heart Disease Diagnosis");
    if (chdd != null && chdd != "") {
      let resultList: Array<string> = [];
      let valList: Array<string> = [];
      valList = chdd.split("|");

      for (let v of valList) {
        for (let dd of this.congitalHeartDiseaseDiagnosisDropDown) {
          if (v == dd.code) {
            resultList.push(dd.name);
          }
        }
      }

      this.congitalHeartDiseaseDiagnosisSelected = resultList;
    } else {
      this.congitalHeartDiseaseDiagnosisSelected = null;
    }

    let nva = this.fields.getValue(this.name, "Name of Vasodilator Agent");
    if (nva != null && nva != "") {
      let resultList: Array<string> = [];
      let valList: Array<string> = [];
      valList = nva.split("|");

      for (let v of valList) {
        for (let dd of this.vasodilatorAgentsDropDown) {
          if (v == dd.code) {
            resultList.push(dd.name);
          }
        }
      }

      this.vasodilatorAgentsSelected = resultList;
    } else {
      this.vasodilatorAgentsSelected = [];
    }
  }

  calculateBaseline() {
    let pvr = this.fields.getValue(this.name, 'Baseline Pulmonary Vascular Resistance');
    let svr = this.fields.getValue(this.name, 'Baseline Systemic Vascular Resistance');

    if (svr > 0) {
      this.fields.setValue(this.name, 'Baseline PVR:SVR Ratio', this.round((pvr / svr), 3));
    } else {
      this.fields.setValue(this.name, 'Baseline PVR:SVR Ratio', null);
    }
  }

  calculatePostTest() {
    let pvr = this.fields.getValue(this.name, 'Post-Testing Pulmonary Vascular Resistance');
    let svr = this.fields.getValue(this.name, 'Post-Testing Systemic Vascular Resistance');

    if (svr > 0) {
      this.fields.setValue(this.name, 'Post-Testing PVR:SVR Ratio', this.round((pvr / svr), 3));
    } else {
      this.fields.setValue(this.name, 'Post-Testing PVR:SVR Ratio', null);
    }
  }


  round(number, precision) {
    var factor = Math.pow(10, precision);
    var tempNumber = number * factor;
    var roundedTempNumber = Math.round(tempNumber);
    return roundedTempNumber / factor;
  }

  ngOnDestroy() {
    if (localStorage.getItem('dirty') == 'false' || localStorage.getItem('dirty') == null) {
      if (localStorage.getItem('hemo') != null) {
        let hemoinfo = new HemoInfo;
        hemoinfo.IsSVP = this.convertTrueFalse(this.fields.fields[4].fields[1].value);
        hemoinfo.PhysiologicCategory = this.fields.fields[4].fields[2].value;
        hemoinfo.IsSVP = this.convertTrueFalse(this.fields.fields[4].fields[3].value);
        hemoinfo.SysSatIND = this.convertTrueFalse(this.fields.fields[4].fields[4].value);
        hemoinfo.IsMVSATLessThan50Percent = this.convertTrueFalse(this.fields.fields[4].fields[5].value);
        hemoinfo.IsPAMeanEqual17mmOrHigher = this.convertTrueFalse(this.fields.fields[4].fields[6].value);
        hemoinfo.IsQPQSGreaterThan1Point5 = this.convertTrueFalse(this.fields.fields[4].fields[7].value);
        hemoinfo.ISPVREqual3WUROrHigher = this.convertTrueFalse(this.fields.fields[4].fields[8].value);
        if (JSON.stringify(hemoinfo) == localStorage.getItem('hemo'))
          localStorage.setItem('dirty', 'false');
        else
          localStorage.setItem('dirty', 'true');
      }
    }
  }

  private convertTrueFalse(type: boolean): string {

    if (type === true) return '1';
    else if (type === false || type === null) return '0';
  }
}
