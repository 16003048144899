import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-key-driver',
  templateUrl: './key-driver.component.html',
  styleUrls: ['./key-driver.component.scss'],
  host: { 'class': "host" }
})
export class KeyDriverComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
