export class CaseReqResourcesModel {
  ReqResourceId: number;
  CaseId: number;
  ResourceName: string;
  ResourceValue: string;
  IsResUsedPreProcTime: string;
  IsResUsedProcTime: string;
  IsResUsedPostProcTime: string;
  IsResUsed48HoursTime: string;
  Updatedby: string;
}
