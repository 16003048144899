/// <reference path="../../../shared/hospital-item-modal.ts" />

import { Injectable  } from "@angular/core";
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Observable, pipe } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { UserResponse } from '../../../shared/user-response';
import { UserModel } from '../../../shared/user-model';
import { CONFIG } from '../../../shared/config';
import { MailRequestInfoModel } from '../../../shared/email/mail-request-info-model';
import { SendEmailResponse } from '../../../shared/email/send-email-response';
import { MailStatusModel } from '../../../shared/email/mail-status-model';
import { PicklistItemService } from "../../shared-data-entry/picklist-item.service";
import { PicklistItemResponse } from '../../../shared/picklist-item-response';
import { PicklistItemModel } from '../../../shared/picklist-item-model';
import { DropdownItemModel } from '../../../shared/dropdown-item-model';
import { CaseFieldsService } from '../add-case/case-fields.service';
import { HospitalItemModel } from '../../../shared/hospital-item-modal';
import { HospitalItemResponse } from '../../../shared/hospital-item-response';


let apiUrl = CONFIG.baseUrls.api;

@Injectable({
  providedIn: "root"
})
export class UserManagementService {
  private http: Http = null;
  public usrMod: UserModel;
  name = "Case Identification";
  mailRequest: MailRequestInfoModel;
  hospitals = [];

  fields = [];
  validate = false;
  public message = "";
  public passwordResponse = "";
  public field: CaseFieldsService;
  public picklistItemService: PicklistItemService;
  //public emailPattern = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}\.[a-zA-Z]{2,10}$";
  public emailPattern = "^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$";
  public isDisabled = false;
  public email: string;
  public oldPassword: string = "";
  public newPassword: string = "";
  public users = [
   
    
  ];

 

  public selectedUser;
  public editableUser = {};
  public indexSelected = -1;
  public hospitalItemModel: HospitalItemModel[];
  public dropDownItem: DropdownItemModel[];

  constructor(_http: Http, _picklistItemService: PicklistItemService, field: CaseFieldsService) {
    this.http = _http;
    this.usrMod = new UserModel;
    this.field = field;
    this.message = "";
    this.passwordResponse = "";
    this.picklistItemService = _picklistItemService;
    //this.picklistItemService.getPicklistItem("1", "HOSPITALS").subscribe(data => this.populatePicklistItemsHospital(data));
   
  }

  public inputValidator(event: any) {
    //console.log(event.target.value);
    const pattern = /^[a-zA-Z\s]*$/; 
    //let inputChar = String.fromCharCode(event.charCode)
    if (!pattern.test(event.target.value)) {
      event.target.value = event.target.value.replace(/[^a-zA-Z]/g, "");
      // invalid character, prevent input

    }
  }

  public EmailValidator(event: any) {
    //console.log(event.target.value);
    const pattern = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/;
    //let inputChar = String.fromCharCode(event.charCode)
    if (!pattern.test(event.target.value)) {
      event.target.value = event.target.value.replace(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/g, "");
      // invalid character, prevent input

    }
  }

  public lengthValidator(event: any) {
    //console.log(event.target.value);
    const pattern = /^[a-z\sA-Z]*$/;
    //let inputChar = String.fromCharCode(event.charCode)
    if (!pattern == (event.target.value.length)) {
      event.target.value = event.target.value.replace(/[^a-z\sA-Z]/g, "");
      // invalid character, prevent input

    }
  }

  //public populatedropdownonregistrychange(deviceValue)
  //{
  //  this.picklistItemService.getPicklistItem("1", "HOSPITALS").subscribe(data => this.populatePicklistItemsHospital(data, deviceValue));    
  //}


  setUser(user, i) {
    this.selectedUser = user;
    if (this.hospitals.length > 0 && this.hospitals.find(x => x.name == this.selectedUser.Hospital) == undefined)
    {
      this.selectedUser.HospitalId = undefined;
      this.selectedUser.Hospital = undefined;
    }
    this.set(this.editableUser, this.selectedUser);
    this.indexSelected = i;
    this.usrMod = user;
    this.isDisabled = false;
    this.message = "Edit User Details";
    this.populatedropdownonregistrychange(user.UserAppRegistry,0);
   // console.log(this.usrMod);
  }

  set(user1, user2) {
    for (var att in user2) {
      user1[att] = user2[att];
    }
  }

  addUser() {
    let usr = new UserModel;
    this.setUser(usr, -1);
    this.message = "Create New User";
    this.isDisabled = false;
    
    
  }

  getHospitalPicklistByRegistry(registryValue: string) {
    
    
    let headers = new Headers({
      //'Content-Type': 'application/x-www-form-urlencoded'
    });
    let options = new RequestOptions({ headers: headers });
    return this.http.get(apiUrl + "user/GetHospitalsByRegistry?appregistry=" + registryValue, options)
      .pipe(map((response: Response) => <HospitalItemResponse>response.json()));
  }

  public populatedropdownonregistrychange(registryValue,dropdownchange) {
    if (dropdownchange == 1)
    {
      this.selectedUser.HospitalId = undefined;
      this.selectedUser.Hospital = undefined;
    }
    this.getHospitalPicklistByRegistry(registryValue).subscribe(data => this.populateHospitalItems(data));
  }

  populateHospitalItems(hospitalItemResponse: HospitalItemResponse) { 
    this.hospitalItemModel = hospitalItemResponse.DataObject;
    this.mapToDropDown();
    this.hospitals = this.dropDownItem;     
  }

  mapToDropDown() {
    this.dropDownItem =  [];

    this.hospitalItemModel.sort(function (a, b) { return (a.HospitalSeq - b.HospitalSeq) });
    let newItem = new DropdownItemModel;
    this.dropDownItem.push(newItem);

    for (let entry of this.hospitalItemModel) {
      let newItem = new DropdownItemModel;
      newItem.name = entry.HospitalName;
      newItem.description = "Hospitals";
      newItem.code = entry.HospitalID.toString();
      this.dropDownItem.push(newItem);
    }

  }

  save(event: any) {

    this.set(this.editableUser, this.selectedUser);    
    this.selectedUser.IsActive = true;
    this.selectedUser.Hospital = this.hospitals.find(x => x.code == this.selectedUser.HospitalId)["name"];
    
    if (this.users.indexOf(this.selectedUser) == -1) {
      this.users.push(this.selectedUser);
    }
    if (!this.usrMod.UserId) {
      this.saveapi().subscribe(data => this.saveResponse(data));
      this.message = "User Added Successfully";
      this.isDisabled = true;
    }
    else {
      this.updateapi().subscribe(data => this.saveResponse(data));
      this.message = "User Updated Successfully";
      this.isDisabled = true;
    }  
      
    

  }

  close() {
    this.set(this.selectedUser, this.editableUser);
  }

  saveResponse(data: UserResponse) {
    let userstatusmodel = data.DataObject;
    
    
  }

  PassResponse(data: any) {
    let userstatusmodel = data.DataObject;
    this.passwordResponse = "If your email is registered, we will send the password to the registered email address.";
    this.email = "";
    //this.passwordResponse = userstatusmodel.Userstatus;
    //if (this.passwordResponse == "Success")
    //  this.passwordResponse = "Password has been successfully Sent to this email address."

  }

  

  saveapi() {
    let headers = new Headers({
      //  'Content-Type': 'application/json'
    });
    let options = new RequestOptions({ headers: headers });



   // console.log(this.usrMod);
    console.log(apiUrl)
    return this.http.post(apiUrl + "user/AddUser", this.usrMod, options)
      .pipe(map((response: Response) => <UserResponse>response.json()));
  }

  updateapi() {
    let headers = new Headers({
      //  'Content-Type': 'application/json'
    });
    let options = new RequestOptions({ headers: headers });



  //  console.log(this.usrMod);
    console.log(apiUrl)
    return this.http.post(apiUrl + "user/UpdateUser", this.usrMod, options)
      .pipe(map((response: Response) => <UserResponse>response.json()));
  }

  updateUserPassword(userModel: UserModel) {
    let headers = new Headers({     
    });
    let options = new RequestOptions({ headers: headers }); 
    
    return this.http.post(apiUrl + "user/UpdateUser", userModel, options)
      .pipe(map((response: Response) => <UserResponse>response.json()));
  }


  Reset(user,i) {
    this.selectedUser = user;
    this.set(this.editableUser, this.selectedUser);
    this.indexSelected = i;
    this.usrMod = user;
    this.ResetApi().subscribe(data => this.saveResponse(data));
    
  }

  ResetApi() {
    let headers = new Headers({
      //  'Content-Type': 'application/json'
    });
    let options = new RequestOptions({ headers: headers });

    //console.log(this.usrMod);
    console.log(apiUrl)
    return this.http.post(apiUrl + "user/ResetPassword", this.usrMod, options)
      .pipe(map((response: Response) => <UserResponse>response.json()));
  }


  Fogotpwd() {
    this.usrMod.Email = this.email;
    this.forgotpwd().subscribe(data => this.PassResponse(data));;
    

  }

  forgotpwd() {
    let headers = new Headers({
      //  'Content-Type': 'application/json'
    });
    let options = new RequestOptions({ headers: headers });

   // console.log(this.usrMod);
    console.log(apiUrl)
    return this.http.post(apiUrl + "user/ForgotPassword", this.usrMod, options)
      .pipe(map((response: Response) => <UserResponse>response.json()));
  }

  LockUnLock(user, i) {
    this.selectedUser = user;
    this.set(this.editableUser, this.selectedUser);
    this.indexSelected = i;
    this.usrMod = user;
    this.LockUnLockApi().subscribe(data => this.saveResponse(data));;
  }

  LockUnLockApi() {
    let headers = new Headers({
      //  'Content-Type': 'application/json'
    });
    let options = new RequestOptions({ headers: headers });

   // console.log(this.usrMod);
    console.log(apiUrl)
    if (this.usrMod.IsActive)
      this.usrMod.IsActive = false;
    else
      this.usrMod.IsActive = true;
    return this.http.post(apiUrl + "user/LockUnlockUser", this.usrMod, options)
      .pipe(map((response: Response) => <UserResponse>response.json()));
  }

  

  getAllUsers() {
    
    let headers = new Headers({
      //'Content-Type': 'application/x-www-form-urlencoded'
    });
    let options = new RequestOptions({ headers: headers });
    return this.http.get(apiUrl + "user/GetAllUsers", options)
      .pipe(map((response: Response) => <UserResponse>response.json()));
  }

  
}
