import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Observable, pipe } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { PicklistItemResponse } from '../../shared/picklist-item-response';
import { PicklistItemModel } from '../../shared/picklist-item-model';
import { PicklistLookupModel } from '../../shared/picklist-lookup-model';
import { DropdownItemModel } from '../../shared/dropdown-item-model';
import { CONFIG } from '../../shared/config';

let apiUrl = CONFIG.baseUrls.api;
@Injectable()
export class PicklistItemService {
  private http: Http = null;

  public picklistItemModel: PicklistItemModel[];
  public dropDownPicklistItem: DropdownItemModel[];

  constructor(_http: Http) {
    this.http = _http;
  }

  getPicklistItem(hospitalId: string, picklistName: string) {
    let lookup = new PicklistLookupModel;
    lookup.HospitalId = hospitalId;
    lookup.PicklistName = picklistName;

    //{ "HospitalId":"1", "PickListName":"Airway Management" }
    //{ "HospitalId":"1", "PickListName":"ALL" }
    //{ "HospitalId":"1", "PickListName":"OPERATORS" }

    let headers = new Headers({
      //'Content-Type': 'application/x-www-form-urlencoded'
    });
    let options = new RequestOptions({ headers: headers });
    return this.http.post(apiUrl + "picklist/getpicklistinfo", lookup, options)
      .pipe(map((response: Response) => <PicklistItemResponse>response.json()));
  }

  

  mapToDropDown() {
    this.dropDownPicklistItem = [];

    this.picklistItemModel.sort(function (a, b) { return (a.DisplayOrder - b.DisplayOrder) });

    for (let entry of this.picklistItemModel) {
      let newItem = new DropdownItemModel;
      newItem.name = entry.ChoiceText;
      newItem.description = entry.PicklistName;
      newItem.code = entry.ChoiceCode;     
      this.dropDownPicklistItem.push(newItem);
    }
  }
}
