export class HemoInfo {  
  CaseID: string;
  IsSVP: string;
  SysSatIND: string;
  IsSysSatlessthan95Percent: string;
  IsPASysequal45mmorHigher: string;
  IsMVSATLessThan60Percent: string;
  IsSysSatLessThan78Percent: string;
  IsMVSATLessThan50Percent: string;
  IsPAMeanEqual17mmOrHigher: string;
  ISPVREqual3WUROrHigher: string;
  IsQPQSGreaterThan1Point5: string;
  IsSVEDP18mmHgOrHigher: string;
  PhysiologicCategory: string;
  CathLocationID: string;
  CathDateTime: string; 
  
}
