export class ReportDataMM {
  CaseAeID: string;
  CaseID: string;
  Flag: string;
  Fellow: string;
  MRN: string;
  FirstName: string;
  LastName:string
  AEName: string;
  Preventability: string;
  Attributability: string;
  Severity: string;
  Tier: string;
  DateOfCath: string;
  Operator: string;
  Age: string;
  PatientInitial: string;
  Preventabilty: string;
  Relationship: string;
  Outcome: string;
  Timing: string;
  TimeToManage: string;
  MajorIndicator: string;
  STSDiagnosis: string;
  CaseType: string;
  Description: string;
  TimeNeeded: string;
  Presentation: string;
  Reviewed: boolean;
  Updatedby: number;
}
