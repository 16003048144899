import { Injectable } from "@angular/core";
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Observable, pipe } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { CONFIG } from '../../../../shared/config';
import { ReportResponse } from "../../../../shared/report-response";
import { ReportDataMM } from "../../../../shared/report-model-mm";
import { ReportDataAE } from "../../../../shared/report-model-ae";
import { CaseSaveResponse } from "../../../../shared/case-save/case-save-response";
//import { ReportModel } from "../../../../shared/report-model";
import { ReportRequest } from "../../../../shared/report-request";


let apiUrl = CONFIG.baseUrls.api;
@Injectable({
  providedIn: "root"
})
export class ReportService {
  private http: Http = null;
  public editCaseReportMM: ReportDataMM;
  public editCaseReportAE: ReportDataAE;

  constructor(_http: Http) {
    this.http = _http;
    this.editCaseReportMM = new ReportDataMM;
    this.editCaseReportAE = new ReportDataAE;
  }

  getReportData(reportParams: ReportRequest) {
    let headers = new Headers({
      //'Content-Type': 'application/x-www-form-urlencoded'
    });
    let options = new RequestOptions({ headers: headers });
    //let params = "{'FromDate':'31-AUG-15','ToDate':'31-AUG-18'}";
    
      return this.http.post(apiUrl + "report/getreportdata", reportParams, options)
        .pipe(map((response: Response) => <ReportResponse>response.json()));
    
  }

  SaveMMReport() {
    var ss = this.editCaseReportMM;
    return this.saveMMReportCase(ss);
  }
  saveMMReportCase(caseItem: ReportDataMM) {
    let headers = new Headers({
      //'Content-Type': 'application/x-www-form-urlencoded'
    });
    let options = new RequestOptions({ headers: headers });
    return this.http.post(apiUrl + "report/SaveMMReportCase", caseItem, options)
      .pipe(map((response: Response) => <CaseSaveResponse>response.json()));
  }

  SaveAEReport() {
    var ss = this.editCaseReportAE;
    return this.saveAEReportCase(ss);
  }
  saveAEReportCase(caseItem: ReportDataAE) {
    let headers = new Headers({
      //'Content-Type': 'application/x-www-form-urlencoded'
    });
    let options = new RequestOptions({ headers: headers });
    return this.http.post(apiUrl + "report/SaveAEReportCase", caseItem, options)
      .pipe(map((response: Response) => <CaseSaveResponse>response.json()));
  }


}
