import { Component, OnInit } from "@angular/core";
import { CaseFieldsService } from "../../user/add-case/case-fields.service";

@Component({
  selector: "app-seriousness",
  templateUrl: "./seriousness.component.html",
  styleUrls: ["./seriousness.component.scss"]
})
export class SeriousnessComponent implements OnInit {
   types = [
    {
       name: "1 - None",
       code: "AES01",
      class: "ae none",
      description:
        " No harm, no change in condition, may have required monitoring to assess for potential change in condition with no intervention indicated."
    },
    {
      name: "2 - Minor",
      code: "AES02",
      class: "ae minor",
      description:
        "Transient change in condition, not life threatening, condition returns to baseline, required monitoring, required minor intervention such as holding a medication or obtaining a lab test."
    },
    {
      name: "3 - Moderate",
      code: "AES03",
      class: "ae moderate",
      description:
        "Transient change in condition may be life-threatening if not treated, condition returns to baseline, required monitoring, required intervention such as reversal agent, additional medication, transfer to the intensive care unit for monitoring, or moderate transcatheter intervention to correct condition."
    },
    {
      name: "4 - Major",
      code: "AES04",
      class: "ae major",
      description:
        "Change in condition, life threatening if not treated, change in condition may be permanent, may have required an intensive care unit admission or emergent readmit to hospital, may have required invasive monitoring, required interventions such as electrical cardioversion or unanticipated intubation or required major invasive procedures or trans-catheter interventions to correct condition."
    },
    {
      name: "5 - Catastrophic",
      code: "AES05",
      class: "ae catastrophic",
      description:
        "Any death, and emergent surgery or heart lung bypass support (ECMO) to prevent death with failure to wean from bypass support. "
    }
  ];

  constructor(public fields: CaseFieldsService) {}

  ngOnInit() {}
}
