import { Component, ViewChild, Input, ElementRef, OnInit, Output, EventEmitter, AfterViewInit  } from '@angular/core';
import { EventArgs } from 'wijmo/wijmo';
import { NgxSpinnerService } from 'ngx-spinner';
import * as wjMenu from 'wijmo/wijmo.angular2.input';

//Used for AE Summary Report
@Component({   
  template: `<wj-menu #menustacking [(value)]="stacking" [header]="'Stacking'" [maxDropDownWidth]="15" [maxDropDownHeight]="75" (itemClicked)="menuItemClicked(menustacking, $event)">
                     <wj-menu-item [value]="'None'">None</wj-menu-item>
                     <wj-menu-item [value]="'Stacked'">Stacked</wj-menu-item>
                     <wj-menu-item [value]="'Stacked100pc'">Stacked 100%</wj-menu-item>
                   </wj-menu>`,  
  selector: 'stacked-items-template',
})
export class StackedItemsComponent implements OnInit, AfterViewInit{
  @ViewChild('menustacking') menuStacking: wjMenu.WjMenu
  @Input() stacking: string;
  @Input() spinner: NgxSpinnerService;
  @Output() stackingChange = new EventEmitter<string>();
  

  constructor(public element: ElementRef) {
   
  }
  ngOnInit(): void {
    
   
  }

  ngAfterViewInit() {
    //Set custom tool tip content
    //this.menuStacking.isDisabled == true;
  }


  ngOnChanges(changes: any) {
    //this.stacking = changes.stacking.currentValue;
  }

  menuItemClicked(sender: any, e: EventArgs) {
    var menuItem = sender.selectedValue;
    //if (menuItem == 'Stacked100pc' || menuItem == 'Stacked') {
    //  this.spinner.show();
    //}

    this.spinner.show();
    this.stackingChange.emit(menuItem);
  }

}
