import { Component, OnInit ,ViewChild,HostListener} from "@angular/core";
import { CaseFieldsService } from "../case-fields.service";
import { NgForm, FormGroup } from "@angular/forms";
import { ComponentCanDeactivate } from '../../../../CanDeactivateGaurd';
import { Observable } from 'rxjs';
import { filter, map, mergeMap, tap } from "rxjs/operators";
import { Router, ActivatedRoute, ParamMap, NavigationEnd, NavigationStart, RouterEvent } from "@angular/router";

@Component({
  selector: "app-case-types",
  templateUrl: "./case-types.component.html",
  styleUrls: ["./case-types.component.scss"],
  host: { class: "host" }
})
export class CaseTypesComponent implements OnInit, ComponentCanDeactivate {
  @ViewChild('form') form: NgForm;
  fields:CaseFieldsService;
  name = "Case Types";
  loading: boolean = false;
  constructor(private router: Router,fields: CaseFieldsService) {
    router.events
      .pipe(filter(e => e instanceof NavigationStart))
      .subscribe((e: NavigationStart) => {
        localStorage.setItem('editcase', e.url);
      });
    this.fields = fields;
  }



  toggleHideSecondTree(bool) {
    this.fields.getParent('Pre-Case Calculation')['hide'] = bool;
    this.fields.setValue('Pre-Case Calculation', "Recorded Procedures", null);
    if (bool) {
      //clear pre-case anticipated case types when hiding tree (Match set to Yes)
      this.fields.anticipatedCase = false;
      this.fields.setValue('Pre-Case Calculation', "Recorded Procedures", this.fields.getField("Case Types", "Recorded Procedures").value);
      this.fields.setValue('Pre-Case Calculation', "Case Type Done", null);
      this.fields.getField("Case Types", "Anticipated Procedures").hide = true;
    }
    else {
      this.fields.anticipatedCase = true;
      this.fields.getField("Case Types", "Anticipated Procedures").hide = false;
    }
  }

  showSecondTree() {
    let caseTypePrimary = "";
    let antCaseTypePrimary = "";
    let caseTypeProcs = this.getProcedures("Case Types");
    let antCaseTypeProcs = this.getProcedures("Pre-Case Calculation");

    if (caseTypeProcs != null) {
      let caseTypeNames = Object.getOwnPropertyNames(caseTypeProcs);
      for (let name of caseTypeNames) {

        let caseType = caseTypeProcs[name];

        if (caseType["primary"] === true) {
            caseTypePrimary = name;
        }
      }

    }
    if (antCaseTypeProcs != null) {
      let caseTypeNames = Object.getOwnPropertyNames(antCaseTypeProcs);
      for (let name of caseTypeNames) {

        let caseType = antCaseTypeProcs[name];

        if (caseType["primary"] === true) {
          antCaseTypePrimary = name;
        }
      }

    }

    return caseTypePrimary != antCaseTypePrimary;
    //let a = !this.fields.getParent('Pre-Case Calculation')['hide'];
    //let b = this.getCaseTypeDone();
    //let c = this.fields.getField(this.name, 'Case Type').value;
    //return !this.fields.getParent('Pre-Case Calculation')['hide'] 
    //&& this.getCaseTypeDone() 
    //&& this.fields.getField(this.name, 'Case Type').value;
  }

  getProcedures(type) {
    return this.fields.getField(type, "Recorded Procedures").value;
  }

  getCaseTypeDone() {
    return this.fields.getValue(this.name, 'Case Type Done');
  }
  

  ngOnInit() {
   
    let caseTypes = this.fields.getField("Case Types", "Recorded Procedures").value
    localStorage.setItem('casetypes', JSON.stringify(caseTypes));

    let caseTypeDone = this.fields.getField("Case Types", "Case Type Done").value
    localStorage.setItem('casetypedone', caseTypeDone);

    if (this.fields.anticipatedCase) {
      this.toggleHideSecondTree(false); 
    }
  }

  ngOnDestroy() {
    if (localStorage.getItem('dirty') == 'false' || localStorage.getItem('dirty') == null) {
      var casetypestorage = localStorage.getItem('casetypes');
      var casetypedonestorage = localStorage.getItem('casetypedone');
      if (casetypestorage != JSON.stringify(this.fields.getField("Case Types", "Recorded Procedures").value) || casetypedonestorage != JSON.stringify(this.fields.getField("Case Types", "Case Type Done").value))
        localStorage.setItem('dirty', 'true');
      else
        localStorage.setItem('dirty', 'false');
      if (this.form.dirty)
        localStorage.setItem('dirty', 'true');
    }
   

  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    if (localStorage.getItem('dirty') == 'false' || localStorage.getItem('dirty') == null) {
      var casetypestorage = localStorage.getItem('casetypes');
      var casetypedonestorage = localStorage.getItem('casetypedone');
      if (casetypestorage != JSON.stringify(this.fields.getField("Case Types", "Recorded Procedures").value) || casetypedonestorage != JSON.stringify(this.fields.getField("Case Types", "Case Type Done").value))
        localStorage.setItem('dirty', 'true');
      else
        localStorage.setItem('dirty', 'false');
      if (this.form.dirty)
        localStorage.setItem('dirty', 'true');
    }
    return !this.form.dirty;
  }
}
