export class CaseAdmitDisposInfoModel {
  CaseAdmtDspoId: number;
  CaseId: number;
  IsAdmSrcElective: string;
  AdmittedPriorCathInd: string;
  DischargePostCathInd: string;
  PostCathLocation: string;
  IsAliveAtDischarge: string;
  IsUnplannedAdmission: string;
  Updatedby: string;
}
