/// <reference path="../../user/manage-users/user-management.service.ts" />
import { Component, OnInit } from '@angular/core';
import { UserManagementService } from "../../user/manage-users/user-management.service";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  usersservice: UserManagementService;

  constructor(userserv: UserManagementService) {
    this.usersservice = userserv;
    this.usersservice.email = "";
    this.usersservice.passwordResponse = "";
  }

  ngOnInit() {
  }

}
