import { Injectable } from "@angular/core";
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Observable, pipe } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { SearchModel } from '../../../shared/search-model';
import { SearchResponse } from '../../../shared/search-response';
import { WeeklySummaryResponse } from '../../../shared/weekly-summary-response';
import { SearchCaseSummaryModel } from '../../../shared/search-case-summary-model';
import { WeeklySummaryModel } from '../../../shared/weekly-summary-model';
import { CONFIG } from '../../../shared/config';

let apiUrl = CONFIG.baseUrls.api;
@Injectable({
  providedIn: "root"
})
export class CaseLookupService {

  CaseId: number;
  HospitalId: number;
  CompleteDataOnly: boolean = false;
  IncompleteDataOnly: boolean = false;
  HighAE: boolean = false;
  MedAE: boolean = false;
  LowAE: boolean = false;
  CathDateFrom: Date = null;
  CathDateTo: Date = null;
  AgeRange: string = '';
  AdverseEventSeverity: string = '';
  AdverseEventSeveritys: any;
  CaseType: string = '';
  TimeInterval: string = '';
  reportCategorySelected = '';
  siteSelected : any;
  reportSelected = '';
  OperatorID: number;
  Operator: any;
  ShowInactiveCases: boolean = false;
  RegistrySelected: string = '';
  PedcathCasesOnly: boolean = false;
  Reviewed: boolean = false;
  NotReviewed: boolean = false;

  public searchCaseSummarys: SearchCaseSummaryModel[];
  public weeklySummary: WeeklySummaryModel[];

  private http: Http = null;
  constructor(_http:Http) {
    this.http = _http;
  }

  searchClear() {
    this.CaseId = null;
    this.CathDateFrom = null;
    this.CathDateTo = null;
    this.AgeRange = '';
    this.AdverseEventSeverity = '';
    this.CaseType = '';
    this.TimeInterval = '';
    this.IncompleteDataOnly = false;
    this.CompleteDataOnly = false;
    this.HighAE = false;
    this.MedAE = false;
    this.LowAE = false;
    this.reportCategorySelected = '';
    this.reportSelected = '';
    this.searchCaseSummarys = [];
    this.PedcathCasesOnly = false;
  }

  searchCases() {
    let searchInfo = new SearchModel;

    searchInfo.CaseId = this.CaseId;    
    searchInfo.HospitalId = this.HospitalId;
    searchInfo.ShowInactiveCases = this.ShowInactiveCases;
    searchInfo.ShowPedcathCases = this.PedcathCasesOnly;

    if (this.CaseId == null) {
      //has To date but no From date
      if (this.CathDateFrom == null && this.CathDateTo) {
        let dFrom = new Date(this.CathDateTo);
        dFrom.setDate(dFrom.getDate() - 30);
        this.CathDateFrom = dFrom;
      }

      //has From date but no To date
      if (this.CathDateFrom && this.CathDateTo == null) {
        this.CathDateTo = new Date;
      }

      try {
        searchInfo.CathDateFrom = this.formatDate(new Date(this.CathDateFrom));
      } catch {
        searchInfo.CathDateFrom = '';
      }

      try {
        searchInfo.CathDateTo = this.formatDate(new Date(this.CathDateTo)) + ' 23:59';
      } catch {
        searchInfo.CathDateTo = '';
      }

      searchInfo.CompleteDataOnly = this.CompleteDataOnly;
      searchInfo.IncompleteDataOnly = this.IncompleteDataOnly;
      searchInfo.HighAE = this.HighAE;
      searchInfo.MedAE = this.MedAE;
      searchInfo.LowAE = this.LowAE;

      searchInfo.AgeRange = this.AgeRange;     

      if (searchInfo.CompleteDataOnly && searchInfo.IncompleteDataOnly) {
        searchInfo.CompleteDataOnly = false;
        searchInfo.IncompleteDataOnly = false;
      } 
    }
    else {
      this.CathDateFrom = null;
      this.CathDateTo = null;
      this.AgeRange = '';      
      this.IncompleteDataOnly = false;
      this.CompleteDataOnly = false;

      searchInfo.CathDateFrom = '';
      searchInfo.CathDateTo = '';
      searchInfo.AgeRange = '';     
      searchInfo.CompleteDataOnly = false;
      searchInfo.IncompleteDataOnly = false;
      searchInfo.ShowPedcathCases = false;
      
    }
          
    let headers = new Headers({
      //'Content-Type': 'application/x-www-form-urlencoded'
    });
    let options = new RequestOptions({ headers: headers });
    return this.http.post(apiUrl + "case/getcasesearchresults", searchInfo, options)
      .pipe(map((response: Response) => <SearchResponse>response.json()));
  }

  getWeeklyDashBoard() {
    let headers = new Headers({
    });
    let options = new RequestOptions({ headers: headers });
    return this.http.get(apiUrl + "case/getweeklydashboard?hospitalid=" + this.HospitalId, options)
      .pipe(map((response: Response) => <WeeklySummaryResponse>response.json()));
  }

  private formatDate(d: Date): string {
    if (d) {
      let month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (isNaN(year)) {
        return '';
      }

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      let response = [month, day, year].join('-');

      if (year < 2000) {
        response = '';
      }

      return response;
    }

    return '';
  }
}
