export class CaseClinicalInfoModel {
  CaseClinicalInfoId: number;
  CaseId: number;
  PatSex: string;
  PatAge: number;
  PatAgeType: number;
  PatHeight: number;
  PatWeight: number;
  PatBSA: number;
  StsDiagCode: string;
  GenSyndromeInd: string;
  NonCardiacProbInd: string;
  NonCardiacProbValues: string;
  PrevCathlast90dInd: string;
  PrevSurglast90dInd: string;
  Updatedby: string;
}
