import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Observable, pipe } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { OtherAdverseEventResponse } from '../../shared/other-adverse-event-response';
import { OtherAdverseEventModel } from '../../shared/other-adverse-event-model';
import { DropdownItemModel } from '../../shared/dropdown-item-model';
import { CONFIG } from '../../shared/config';

let apiUrl = CONFIG.baseUrls.api;
@Injectable()
export class OtherAdverseEventService {
  private http: Http = null;

  public otherAdverseEventList: OtherAdverseEventModel[];
  public dropDownOtherAdverseEvent: DropdownItemModel[];

  constructor(_http: Http) {
    this.http = _http;
  }

  getOtherAdverseEvent() {
    let headers = new Headers({
      //'Content-Type': 'application/x-www-form-urlencoded'
    });
    let options = new RequestOptions({ headers: headers });
    return this.http.get(apiUrl + "picklist/getotheradverseevents", options)
      .pipe(map((response: Response) => <OtherAdverseEventResponse>response.json()));
  }

  mapToDropDown() {
    let ids = [];
    this.dropDownOtherAdverseEvent = [];

    for (let entry of this.otherAdverseEventList) {
      if (ids.indexOf(entry.AEParentId) > -1) {
        ids.push(entry.AElementId);

        let newChildItem = new DropdownItemModel;
        newChildItem.id = entry.AElementId;
        newChildItem.name = entry.AElementTextToShow;
        newChildItem.code = entry.AElementCode;
        newChildItem.children = [];

        let parentItem = this.dropDownOtherAdverseEvent.find(x => x.id === entry.AEParentId);
        if (parentItem != null) {
          if (parentItem.children == null) {
            parentItem.children = [];
          }
          parentItem.children.push(newChildItem);
        } else {
          let found = false;
          while (found === false) {
            for (let item of this.dropDownOtherAdverseEvent) {
              let childParentItem = item.children.find(x => x.id === entry.AEParentId);
              if (childParentItem != null) {
                if (childParentItem.children == null) {
                  childParentItem.children = [];
                }
                childParentItem.children.push(newChildItem);
                found = true;
                break;
              }
            }
          }
        }
      } else {
        ids.push(entry.AEParentId);
        ids.push(entry.AElementId);

        let newParentItem = new DropdownItemModel;
        newParentItem.id = entry.AEParentId;
        newParentItem.name = entry.AEParentTextToShow;
        newParentItem.children = [];

        let newChildItem = new DropdownItemModel;
        newChildItem.id = entry.AElementId;
        newChildItem.name = entry.AElementTextToShow;
        newChildItem.code = entry.AElementCode;
        newChildItem.children = [];

        newParentItem.children.push(newChildItem);
        this.dropDownOtherAdverseEvent.push(newParentItem);
      }
    }
  }
}
