export class PreCaseCalculationModel {
  CaseId: string;
  PreCaseCardiacStatus: string;
  HemodynamicVulnerabilityScore: string;
  RadiationExposureCategory: string;
  CaseDuration: string;
  PredictRiskCategory: string;
  RiskCategoryAeRate: string;
  AdverseEventRisk: string;
}
