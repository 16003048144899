export class CaseProcEfficacyModel {
  CaseProcEfficacyInfoid: number;
  CaseId: number;
  EfficacyProcedureCode: string;
  EfficacyDataName: string;
  EfficacyDataValue: string;
  IsDataPreProcTime: string;
  IsDataPostProcTime: string; 
  Updatedby: string;
}
