import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Observable, pipe } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { CONFIG } from '../../shared/config';
import { MailRequestInfoModel } from '../../shared/email/mail-request-info-model';
import { SendEmailResponse } from '../../shared/email/send-email-response';

let apiUrl = CONFIG.baseUrls.api;
@Injectable()
export class MailService {
  //public emailPattern = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}\.[a-zA-Z]{2,10}$";
    public emailPattern = "^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$";

  private http: Http = null;
  constructor(_http: Http) {
    this.http = _http;
  }

  Send(subject: string, body: string, contactName: string, contactEmail: string) {
    let request = new MailRequestInfoModel;
    request.body = body;
    request.subject = subject;
    request.contactname = contactName;
    request.contactemail = contactEmail;

    return this.SendEmail(request);
  }

  SendEmail(request: MailRequestInfoModel) {
    let headers = new Headers({
      //'Content-Type': 'application/x-www-form-urlencoded'
    });
    let options = new RequestOptions({ headers: headers });
    return this.http.post(apiUrl + "mail/sendemail", request, options)
      .pipe(map((response: Response) => <SendEmailResponse>response.json()));
  }
}
