import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-c3po",
  templateUrl: "./c3po.component.html",
  styleUrls: ["./c3po.component.scss"],
  host: { 'class': "host" }
})
export class C3poComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
