import { Pipe, PipeTransform } from "@angular/core";
import { CaseFieldsService } from "../components/user/add-case/case-fields.service";

@Pipe({
  name: "validationFilter"
})
export class ValidationPipe implements PipeTransform {
  fields: CaseFieldsService;
  constructor(fields: CaseFieldsService) {
    this.fields = fields;
  }

  transform(items: any[]): any {
    if (!items) {
      return items;
    }

    return items.filter(item => !item["hide"] && this.checkFields(item));
  }

  checkFields(field) {
    if (field.name == "Recorded Procedures" && !field.hide) {
      if ((field.value === null) || (Object.keys(field.value).length === 0)) {
        return true;
      }
    }
    if (field.name == "Physiologic Category" ||
        field.name == "Nursing Assignment 6 Hrs Pre-Procedure" ||
        field.name == "Nursing Assignment 18 to 24 Hrs Post-Cath" ||
        field.name == "Nursing Assignment 48 Hrs Post-Cath") {
      if (this.fields.getExpectionValidation()) {
        field.hide = true;
      }
    }
    if (!field.value && !field.hide) {
      return true;
    }
  }
}
