import { Injectable } from "@angular/core";
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Observable, pipe } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { CaseModel } from '../../../shared/case/case-model';
import { CaseResponse } from '../../../shared/case/case-response';
import { CaseSaveResponse } from '../../../shared/case-save/case-save-response';
import { MarkCaseCompleteResponse } from '../../../shared/case-save/mark-case-complete-response';
import { MarkCaseInactiveResponse } from '../../../shared/case-save/mark-case-inactive-response';
import { CaseStsDiagCategoryResponse } from '../../../shared/case-save/case-sts-diag-category-response';
import { SearchResponse } from '../../../shared/search-response';
import { ServerResponse } from '../../../shared/server-response';

import { CONFIG } from '../../../shared/config';

let apiUrl = CONFIG.baseUrls.api;
@Injectable({
  providedIn: "root"
})
export class CaseService {
  private http: Http = null;
  public case: CaseModel;
  public deleteCaseId: number;
  public saveCaseId: number;
  public IsCaseComplete: boolean;
  public MessageData: string;
  public route: string;

  constructor(_http: Http) {
    this.http = _http;
    this.case = new CaseModel;
    this.deleteCaseId = 0;
    this.saveCaseId = 0;
    this.IsCaseComplete = false;
  }  

  save() {
          
      return this.saveCase(this.case);
    
  }

  saveCase(caseItem: CaseModel) {
    let headers = new Headers({
      //'Content-Type': 'application/x-www-form-urlencoded'
    });
    let options = new RequestOptions({ headers: headers });
    return this.http.post(apiUrl + "case/savecasedetails", caseItem, options)
      .pipe(map((response: Response) => <CaseSaveResponse>response.json()));

    
  }

  clearCase() {
    this.case = new CaseModel;
  }

  getCaseDetails(caseid: number) {
    let headers = new Headers({
      //'Content-Type': 'application/x-www-form-urlencoded'
    });
    let options = new RequestOptions({ headers: headers });
    return this.http.get(apiUrl + "case/getcasedetails?caseid=" + caseid, options)
      .pipe(map((response: Response) => <CaseResponse>response.json()));
  }

  postXMLData(formData,options,hospitalid,userid) {

    return this.http.post(apiUrl + 'case/importdata?hospitalid=' + hospitalid + '&userid=' + userid, formData, options)
      .pipe(map((response: Response) => <ServerResponse>response.json()));
  }

  

  markCaseComplete() {
    let ininfo = {
      CaseId: this.case.CaseId,
      CaseCompletedby: this.case.CaseUpdatedby
    };

    let headers = new Headers({
      //'Content-Type': 'application/x-www-form-urlencoded'
    });
    let options = new RequestOptions({ headers: headers });
    return this.http.post(apiUrl + "case/markcasecomplete", ininfo, options)
      .pipe(map((response: Response) => <MarkCaseCompleteResponse>response.json()));
  }

  deactivateCase(updatedBy: string) {
    let ininfo = {
      CaseId: this.deleteCaseId,
      CaseInactivatedby: updatedBy
    };

    let headers = new Headers({
      //'Content-Type': 'application/x-www-form-urlencoded'
    });
    let options = new RequestOptions({ headers: headers });
    return this.http.post(apiUrl + "case/deactivatecase", ininfo, options)
      .pipe(map((response: Response) => <MarkCaseInactiveResponse>response.json()));
  }

  getSTSDiagCategory(caseId: number) {
    let ininfo = {
      CaseId: caseId
    };

    let headers = new Headers({
      //'Content-Type': 'application/x-www-form-urlencoded'
    });
    let options = new RequestOptions({ headers: headers });
    return this.http.post(apiUrl + "case/getstsdiagcategory", ininfo, options)
      .pipe(map((response: Response) => <CaseStsDiagCategoryResponse>response.json()));
  }
}
