import { Component, OnInit, OnDestroy, ViewChild, HostListener } from "@angular/core";
import { fadeAnimation } from "../../../animations/fade.animation";
import {
  Router,
  ActivatedRoute,
  ParamMap,
  NavigationEnd,
  NavigationStart,
  RouterEvent
} from "@angular/router";
import { filter, map, mergeMap, tap } from "rxjs/operators";
import { Observable } from "rxjs";
import { RouterHelper } from "../../../router-helper";
import { CaseFieldsService } from "./case-fields.service";
import { CaseService } from "./case.service";
import { CaseResponse } from "../../../shared/case/case-response";
import { CaseModel } from '../../../shared/case/case-model';
import { CaseReqResourcesModel } from '../../../shared/case/case-req-resources-model';
import { CaseProcAssignmentModel } from '../../../shared/case/case-proc-assignment-model';
import { CaseAEItemModel } from '../../../shared/case/case-ae-item-model';
import { LoginService } from "../login.service";

import { StsDiagnosisService } from "../../shared-data-entry/sts-diagnosis.service";
import { StsDiagnosisResponse } from '../../../shared/sts-diagnosis-response';
import { MajorAdverseEventService } from "../../shared-data-entry/major-adverse-event.service";
import { MajorAdverseEventResponse } from "../../../shared/major-adverse-event-response";
import { OtherAdverseEventService } from "../../shared-data-entry/other-adverse-event.service";
import { OtherAdverseEventResponse } from "../../../shared/other-adverse-event-response";
import { ProcedurePicklistService } from "../../shared-data-entry/procedure-picklist.service";
import { ProcedurePicklistResponse } from '../../../shared/procedure-picklist-response';

import { CaseTypeCalcService } from "../../shared-data-entry/case-type-calc.service";
import { CaseTypeCalcResponse } from '../../../shared/case-type-calc-response';
import { CaseTypeCalcModel } from '../../../shared/case-type-calc-model';

import { CaseStsDiagCategoryResponse } from '../../../shared/case-save/case-sts-diag-category-response';
import { CaseProcEfficacyModel } from '../../../shared/case/case-proc-efficacy-model';

import { ComponentCanDeactivate } from '../../../CanDeactivateGaurd';
import { HemoInfo } from "../../../shared/report-model-hemo-info";

import { NgForm } from "@angular/forms";


@Component({
  selector: "app-edit-case",
  templateUrl: "./edit-case.component.html",
  styleUrls: ["./edit-case.component.scss"],
  animations: [fadeAnimation]
})
export class EditCaseComponent  implements OnInit, OnDestroy, ComponentCanDeactivate {

  @ViewChild('form') form: NgForm;
  //form: NgForm;

  caseId: number;
  mAEData = [];
  aEData = [];
  stsData = [];
  plData = [];
  caseModel: CaseModel;
  loadData: boolean;
  loading: boolean = false;
  nextRoute;
  prevRoute;

  fields: CaseFieldsService;
  routerHelper: RouterHelper;
  activatedRoute: ActivatedRoute;
  caseService: CaseService;
  user: LoginService;
  public stsDiagnosisService: StsDiagnosisService;
  public majorAdverseEventService: MajorAdverseEventService;
  public otherAdverseEventService: OtherAdverseEventService;
  public procedurePicklistService: ProcedurePicklistService;
  public caseTypeCalcService: CaseTypeCalcService;
  constructor(private router: Router,
      _activatedRoute: ActivatedRoute,
      _fields: CaseFieldsService,
      _caseService: CaseService,
      _user: LoginService,
      _stsDiagnosisService: StsDiagnosisService,
      _majorAdverseEventService: MajorAdverseEventService,
      _otherAdverseEventService: OtherAdverseEventService,
      _procedurePicklistService: ProcedurePicklistService,
    _caseTypeCalcService: CaseTypeCalcService) {
   
    this.activatedRoute = _activatedRoute;
    this.caseService = _caseService;
    this.fields = _fields;
    this.user = _user;
    this.caseTypeCalcService = _caseTypeCalcService;

    this.routerHelper = new RouterHelper(this.router, this.activatedRoute);      
    this.setCaseBase();

    this.majorAdverseEventService = _majorAdverseEventService;
    this.otherAdverseEventService = _otherAdverseEventService;
    this.stsDiagnosisService = _stsDiagnosisService;
    this.procedurePicklistService = _procedurePicklistService;
    localStorage.setItem('casedatabeforechange', JSON.stringify(this.fields.fields));
    console.log('before');
    console.log(this.fields.fields)
  }

  @HostListener('window:beforeunload', ['$event'])
  canDeactivate(): Observable<boolean> | boolean {
    console.log('after');
    console.log(this.fields.fields);
    if (localStorage.getItem('casedatabeforechange') == JSON.stringify(this.fields.fields))
      return true;
    else
      return false;
  }

 

  private getServiceData() {
    console.log("getServiceData");
    this.majorAdverseEventService
      .getMajorAdverseEvent()
      .subscribe(data => this.populateMajorEvents(data));  
  }

  populateMajorEvents(majorAEResponse: MajorAdverseEventResponse) {
    this.majorAdverseEventService.majorAdverseEventList =
      majorAEResponse.DataObject;
    this.majorAdverseEventService.mapToDropDown();

    this.otherAdverseEventService
      .getOtherAdverseEvent()
      .subscribe(data => this.populateOtherEvents(data));
  }
 

  populateOtherEvents(otherAEResponse: OtherAdverseEventResponse) {
    this.otherAdverseEventService.otherAdverseEventList =
      otherAEResponse.DataObject;
    this.otherAdverseEventService.mapToDropDown();

    this.stsDiagnosisService
      .getStsDiagnosis()
      .subscribe(data => this.populateStsDiagnosis(data));
  }

  populateStsDiagnosis(stsDiagResponse: StsDiagnosisResponse) {
    this.stsDiagnosisService.stsDiagnosisList = stsDiagResponse.DataObject;
    this.stsDiagnosisService.mapToDropDown();

    this.procedurePicklistService
      .getProcedurePicklist()
      .subscribe(data => this.validateProcedurePicklist(data));    
  }

  validateProcedurePicklist(ppr: ProcedurePicklistResponse) {
    this.procedurePicklistService.procedureList = ppr.DataObject;
    this.procedurePicklistService.mapToDropDown();

    this.setCaseData();
  }

  validateSTSDiagCategory(sdc: CaseStsDiagCategoryResponse) {
    let data = sdc.DataObject;
    this.fields.setValue("Pre-Case Calculation", 'STS Diagnosis Category', data.CategoryValue);
  }

  private setCaseBase() {
    console.log("setCaseBase");
    this.loadData = false;
    let url = this.router.url;
    let urlSplit = url.split("/");
    let id = urlSplit[urlSplit.length - 1];
    this.caseId = +id;

    this.setCaseListData();

    this.getFieldsBase();       
  }

  private getFieldsBase() {
    console.log("getFieldsBase");
    let fieldsCaseId = this.fields.getField("Case Identification", 'Case Number').value;
    if (fieldsCaseId != this.caseId) {
      if (this.caseId) {        
        this.fields.caseLoaded = false;        
        this.fields.type = "edit";
        this.fields.resetFields();

        this.fields.setValue("Case Identification", "Case Number", this.caseId);
        this.caseService.getCaseDetails(this.caseId).subscribe(data => this.loadResponse(data));

        this.fields.getParent("Case Identification")["link"] = this.fields.getParent("Case Identification")["link"] + "/" + this.caseId;
        this.fields.getParent("Clinical Characteristics")["link"] = this.fields.getParent("Clinical Characteristics")["link"] + "/" + this.caseId;
        this.fields.getParent("Procedure Characteristics")["link"] = this.fields.getParent("Procedure Characteristics")["link"] + "/" + this.caseId;
        this.fields.getParent("Hemodynamics")["link"] = this.fields.getParent("Hemodynamics")["link"] + "/" + this.caseId;
        this.fields.getParent("Case Types")["link"] = this.fields.getParent("Case Types")["link"] + "/" + this.caseId;
        this.fields.getParent("Pre-Case Calculation")["link"] = this.fields.getParent("Pre-Case Calculation")["link"] + "/" + this.caseId;
        this.fields.getParent("Adverse Events")["link"] = this.fields.getParent("Adverse Events")["link"] + "/" + this.caseId;
        this.fields.getParent("Indicators of Required Resources")["link"] = this.fields.getParent("Indicators of Required Resources")["link"] + "/" + this.caseId;
        this.fields.getParent("Procedural Efficacy")["link"] = this.fields.getParent("Procedural Efficacy")["link"] + "/" + this.caseId;
        this.fields.getParent("EOC Admit/Disposition")["link"] = this.fields.getParent("EOC Admit/Disposition")["link"] + "/" + this.caseId;                
      }
    }

    this.caseService.getSTSDiagCategory(this.caseId).subscribe(data => this.validateSTSDiagCategory(data)); 
  }

  private loadResponse(data: CaseResponse) {
    
    let hemoinfo = new HemoInfo;
    this.caseModel = data.DataObject;
    this.loadData = true;
   


    //this.fields.completedCase = this.caseModel.CompletionStatus == "1";
    if (this.caseModel.CompletionStatus == "1")
      this.fields.completedCase = true;

    try {
      this.fields.setValue("Case Identification", "Hospital", this.caseModel.HospitalId);

      this.fields.setValue("Case Identification", "Cath Date", new Date(this.caseModel.CathDate));
      if (this.caseModel.CathDate.indexOf(':') !== -1) {
        this.fields.setValue("Case Identification", "Case Time", new Date(this.caseModel.CathDate));  
      }    
      if (this.caseModel.PrimaryOperatorId > 0) {
        this.fields.setValue("Case Identification", "Operator", this.caseModel.PrimaryOperatorId);
      }

      //Remove Operator validation for IQIC users 
      if (this.user.IsIqicUser()) {
        this.fields.getField("Case Identification", 'Operator').hide = true
      }
      
              

    } catch {
      console.log("Error loading Case Identification");
    }

    try {
      if (this.caseModel.CaseClinicalInfo && this.caseModel.CaseClinicalInfo.CaseClinicalInfoId > 0) {
        this.fields.setValue("Clinical Characteristics", 'Id', this.caseModel.CaseClinicalInfo.CaseClinicalInfoId);
        this.fields.setValue("Clinical Characteristics", 'Date Last Cath', this.convertTrueFalse(this.caseModel.CaseClinicalInfo.PrevCathlast90dInd));
        this.fields.setValue("Clinical Characteristics", 'Date Last Cardiac Surgery', this.convertTrueFalse(this.caseModel.CaseClinicalInfo.PrevSurglast90dInd));
        this.fields.setValue("Clinical Characteristics", 'Genetic Syndrome', this.convertTrueFalse(this.caseModel.CaseClinicalInfo.GenSyndromeInd));
        this.fields.setValue("Clinical Characteristics", 'Non-cardiac Problems', this.caseModel.CaseClinicalInfo.NonCardiacProbValues);
        this.fields.getField("Clinical Characteristics", 'Non-cardiac Problems').hide = !this.convertTrueFalse(this.caseModel.CaseClinicalInfo.NonCardiacProbInd);

        if (this.caseModel.CaseClinicalInfo.PatAge > 0) {
          this.fields.setValue("Clinical Characteristics", 'Age', this.caseModel.CaseClinicalInfo.PatAge);
          this.fields.setValue("Clinical Characteristics", 'Age Unit', this.convertAgeType(this.caseModel.CaseClinicalInfo.PatAgeType));
        }
        if (this.caseModel.CaseClinicalInfo.PatSex != '') {
          this.fields.setValue("Clinical Characteristics", 'Sex', this.caseModel.CaseClinicalInfo.PatSex);
        }
        if (this.caseModel.CaseClinicalInfo.PatWeight > 0) {
          this.fields.setValue("Clinical Characteristics", 'Weight', this.caseModel.CaseClinicalInfo.PatWeight);
        }
        if (this.caseModel.CaseClinicalInfo.PatHeight > 0) {
          this.fields.setValue("Clinical Characteristics", 'Height', this.caseModel.CaseClinicalInfo.PatHeight);
        }
        if (this.caseModel.CaseClinicalInfo.PatBSA > 0) {
          this.fields.setValue("Clinical Characteristics", 'BSA', this.caseModel.CaseClinicalInfo.PatBSA);
        }
      } else {
        this.fields.setValue("Clinical Characteristics", 'Id', 0);
      }
    } catch {      
      console.log("Error loading Clinical Characteristics");      
    }

    try {
      if (this.caseModel.CaseProcedureInfo && this.caseModel.CaseProcedureInfo.CaseProccharInfoId > 0) {
        this.fields.setValue("Procedure Characteristics", 'Id', this.caseModel.CaseProcedureInfo.CaseProccharInfoId);
        //this.fields.setValue("Procedure Characteristics", 'Total Fluoro Time', this.caseModel.CaseProcedureInfo.FluroTime);               
        this.fields.setValue("Procedure Characteristics", 'Sheath-in Time', this.loadTime(this.caseModel.CaseProcedureInfo.SheathCathInDateTime));
        this.fields.setValue("Procedure Characteristics", 'Sheath-out Time', this.loadTime(this.caseModel.CaseProcedureInfo.SheathCathOutDateTime));
        this.fields.setValue("Procedure Characteristics", 'Duration', this.loadDuration(this.caseModel.CaseProcedureInfo.SheathCathInDateTime, this.caseModel.CaseProcedureInfo.SheathCathOutDateTime));
        this.fields.setValue("Procedure Characteristics", 'Blood Transfusions', this.convertTrueFalse(this.caseModel.CaseProcedureInfo.BloodTransfusion));

        if (this.caseModel.CaseProcedureInfo.FluroTime != null && this.caseModel.CaseProcedureInfo.FluroTime.length > 0) {
          this.fields.setValue("Procedure Characteristics", 'Total Fluoro Time', this.caseModel.CaseProcedureInfo.FluroTime);
        }
        if (this.caseModel.CaseProcedureInfo.TotalDap != null && this.caseModel.CaseProcedureInfo.TotalDap.length > 0) {
          this.fields.setValue("Procedure Characteristics", 'Total DAP', this.caseModel.CaseProcedureInfo.TotalDap);
        }
      }
    } catch {
      console.log("Error loading Procedure Characteristics");
    }

    try {
      if (this.caseModel.CaseAddlProcedureInfo && this.caseModel.CaseAddlProcedureInfo.CaseAddlProccharInfoId > 0) {
        this.fields.setValue("Procedure Characteristics", 'AddlId', this.caseModel.CaseAddlProcedureInfo.CaseAddlProccharInfoId);
        this.fields.setValue("Procedure Characteristics", 'Type Fluoro Performed', this.convertTrueFalse(this.caseModel.CaseAddlProcedureInfo.FluroPerformedType));
        this.fields.setValue("Procedure Characteristics", 'Total Contrast Volume', this.caseModel.CaseAddlProcedureInfo.TotalContrastVolumeVal);
        this.fields.setValue("Procedure Characteristics", 'Total Contrast Volume Per Kg', this.caseModel.CaseAddlProcedureInfo.TotalContrastVolumePerKg);
        this.fields.setValue("Procedure Characteristics", 'Pre Cath Hemoglobin', this.caseModel.CaseAddlProcedureInfo.PreCathHemoglobinVal);
      }
    } catch {
      console.log("Error loading Addl Procedure Characteristics");
    }

    try {
      if (this.caseModel.CasePreCalcInfo && this.caseModel.CasePreCalcInfo.CasePrecalcinfoId > 0) {
        this.fields.setValue("Pre-Case Calculation", 'Id', this.caseModel.CasePreCalcInfo.CasePrecalcinfoId);
        this.fields.setValue("Pre-Case Calculation", 'Single Ventricle Physiology', this.convertTrueFalse(this.caseModel.CasePreCalcInfo.IsSingleVenPhysiology));
        this.fields.setValue("Pre-Case Calculation", 'Physiologic Category', this.caseModel.CasePreCalcInfo.PhysiologicCategory);
        this.fields.setValue("Pre-Case Calculation", 'SV EDP', this.convertTrueFalse(this.caseModel.CasePreCalcInfo.IsSVEDP));
        this.fields.setValue("Pre-Case Calculation", 'Sys Sat', this.convertTrueFalse(this.caseModel.CasePreCalcInfo.IsSysSat));
        this.fields.setValue("Pre-Case Calculation", 'MV Sat', this.convertTrueFalse(this.caseModel.CasePreCalcInfo.IsMvSat));
        this.fields.setValue("Pre-Case Calculation", 'PA', this.convertTrueFalse(this.caseModel.CasePreCalcInfo.IsPAMean));
        this.fields.setValue("Pre-Case Calculation", 'Qp:Qs', this.convertTrueFalse(this.caseModel.CasePreCalcInfo.IsQPQS));
        this.fields.setValue("Pre-Case Calculation", 'PVR', this.convertTrueFalse(this.caseModel.CasePreCalcInfo.IsPVR));
      } else {
        this.fields.setValue("Pre-Case Calculation", 'Id', 0);
      }
    } catch {
      console.log("Error loading Pre-Case");
    }

    try {
      if (this.caseModel.CaseHemoDynamicInfo && this.caseModel.CaseHemoDynamicInfo.CaseHemoDynamicsInfoId > 0) {
        this.fields.setValue("Hemodynamics", 'Id', this.caseModel.CaseHemoDynamicInfo.CaseHemoDynamicsInfoId);
        this.fields.setValue("Hemodynamics", 'Single Ventricle Physiology', this.convertTrueFalse(this.caseModel.CaseHemoDynamicInfo.IsSingleVenPhysiology));
        hemoinfo.IsSVP = this.caseModel.CaseHemoDynamicInfo.IsSingleVenPhysiology;
        this.fields.getField("Hemodynamics", "Single Ventricle Physiology").hide = true;
        this.fields.setValue("Hemodynamics", 'Physiologic Category', this.caseModel.CaseHemoDynamicInfo.PhysiologicCategory);
        hemoinfo.PhysiologicCategory = this.caseModel.CaseHemoDynamicInfo.PhysiologicCategory;
        this.fields.setValue("Hemodynamics", 'SV EDP', this.convertTrueFalse(this.caseModel.CaseHemoDynamicInfo.IsSVEDP));
        hemoinfo.IsSVP = this.caseModel.CaseHemoDynamicInfo.IsSVEDP;
        this.fields.setValue("Hemodynamics", 'Sys Sat', this.convertTrueFalse(this.caseModel.CaseHemoDynamicInfo.IsSysSat));
        hemoinfo.SysSatIND = this.caseModel.CaseHemoDynamicInfo.IsSysSat;
        this.fields.setValue("Hemodynamics", 'MV Sat', this.convertTrueFalse(this.caseModel.CaseHemoDynamicInfo.IsMvSat));
        hemoinfo.IsMVSATLessThan50Percent = this.caseModel.CaseHemoDynamicInfo.IsMvSat;
        this.fields.setValue("Hemodynamics", 'PA', this.convertTrueFalse(this.caseModel.CaseHemoDynamicInfo.IsPAMean));
        hemoinfo.IsPAMeanEqual17mmOrHigher = this.caseModel.CaseHemoDynamicInfo.IsPAMean;
        this.fields.setValue("Hemodynamics", 'Qp:Qs', this.convertTrueFalse(this.caseModel.CaseHemoDynamicInfo.IsQPQS));
        hemoinfo.IsQPQSGreaterThan1Point5 = this.caseModel.CaseHemoDynamicInfo.IsQPQS;
        this.fields.setValue("Hemodynamics", 'PVR', this.convertTrueFalse(this.caseModel.CaseHemoDynamicInfo.IsPVR));
        hemoinfo.ISPVREqual3WUROrHigher = this.caseModel.CaseHemoDynamicInfo.IsPVR;
        localStorage.setItem('hemo', JSON.stringify(hemoinfo));
      } else {
        this.fields.setValue("Hemodynamics", 'Id', 0);        
      }
    } catch {
      console.log("Error loading Hemodynamics");
    }

    try {
      if (this.caseModel.CaseAddlHemoDynamicInfo && this.caseModel.CaseAddlHemoDynamicInfo.CaseAddlHemoDynamicId > 0) {
        this.fields.setValue("Hemodynamics", 'AddlId', this.caseModel.CaseAddlHemoDynamicInfo.CaseAddlHemoDynamicId);        
        this.fields.setValue("Hemodynamics", 'Considered for Shunt Closure', this.convertTrueFalse(this.caseModel.CaseAddlHemoDynamicInfo.IsShuntClosure));
        this.fields.setValue("Hemodynamics", 'Is Recommended for SC', this.convertTrueFalse(this.caseModel.CaseAddlHemoDynamicInfo.IsRecommendedforSC));
        this.fields.setValue("Hemodynamics", 'Acute Vasodilator Testing', this.convertTrueFalse(this.caseModel.CaseAddlHemoDynamicInfo.IsAVTestingPerfomed));
        this.fields.setValue("Hemodynamics", 'Congenital Heart Disease Diagnosis', this.caseModel.CaseAddlHemoDynamicInfo.CongentialHDDiagVal);
        this.fields.setValue("Hemodynamics", 'Name of Vasodilator Agent', this.caseModel.CaseAddlHemoDynamicInfo.VasodilatorAgentName);
        this.fields.setValue("Hemodynamics", 'Baseline Pulmonary Vascular Resistance', this.caseModel.CaseAddlHemoDynamicInfo.BasePVRIWuVal);
        this.fields.setValue("Hemodynamics", 'Baseline Systemic Vascular Resistance', this.caseModel.CaseAddlHemoDynamicInfo.BaseSVRWuVal);
        this.fields.setValue("Hemodynamics", 'Baseline PVR:SVR Ratio', this.caseModel.CaseAddlHemoDynamicInfo.BasePVRSVRWuRatioVal);
        this.fields.setValue("Hemodynamics", 'Baseline Qp:Qs Ratio', this.caseModel.CaseAddlHemoDynamicInfo.BaseQPQARatioVal);
        this.fields.setValue("Hemodynamics", 'Baseline Cardiac Index', this.caseModel.CaseAddlHemoDynamicInfo.BaseCardIndCIVal);
        this.fields.setValue("Hemodynamics", 'Post-Testing Pulmonary Vascular Resistance', this.caseModel.CaseAddlHemoDynamicInfo.PostPVRIWuVal);
        this.fields.setValue("Hemodynamics", 'Post-Testing Systemic Vascular Resistance', this.caseModel.CaseAddlHemoDynamicInfo.PostSVRWuVal);
        this.fields.setValue("Hemodynamics", 'Post-Testing PVR:SVR Ratio', this.caseModel.CaseAddlHemoDynamicInfo.PostPVRSVRWuRatioVal);
        this.fields.setValue("Hemodynamics", 'Post-Testing Qp:Qs Ratio', this.caseModel.CaseAddlHemoDynamicInfo.PostQPQARatioVal);
        this.fields.setValue("Hemodynamics", 'Post-Testing Cardiac Index', this.caseModel.CaseAddlHemoDynamicInfo.PostCardIndCIVal);
      }
    } catch {
      console.log("Error loading Addl Hemodynamics");
    }

    try {
      this.fields.getField("Case Types", "Recorded Procedures").value = {};
      this.fields.getField("Pre-Case Calculation", "Recorded Procedures").value = {};
    } catch {
      console.log("Error loading Case Types");
    }

    try {
      if (this.caseModel.CaseProcAEInfo) {
        if (this.caseModel.CaseProcAEInfo.CaseAENotes != null && this.caseModel.CaseProcAEInfo.CaseAENotes != '') {
          this.fields.setValue("Adverse Events", 'Description', this.caseModel.CaseProcAEInfo.CaseAENotes);
        } else {
          this.fields.setValue("Adverse Events", 'Description', null);
        }
        if (this.caseModel.CaseProcAEInfo.PatientDie.indexOf('true') > -1) {
          this.fields.setValue("Adverse Events", 'Patient Die', true);
        }
        if (this.caseModel.CaseProcAEInfo.UnanticipatedCath.indexOf('true') > -1) {
          this.fields.setValue("Adverse Events", 'Unanticipated Catheterization', true);
        }
        if (this.caseModel.CaseProcAEInfo.UnanticipatedSurg.indexOf('true') > -1) {
          this.fields.setValue("Adverse Events", 'Unanticipated Surgery', true);
        }
        this.fields.getParent("Adverse Events")["hide"] = true;
      }
    } catch {
      console.log("Error loading Adverse Events");
    }

    try {
      if (this.caseModel.CaseEOCAdmDisposn && this.caseModel.CaseEOCAdmDisposn.CaseAdmtDspoId > 0) {
        this.fields.setValue("EOC Admit/Disposition", 'Id', this.caseModel.CaseEOCAdmDisposn.CaseAdmtDspoId);
        this.fields.setValue("EOC Admit/Disposition", 'Admission Source', this.convertTrueFalseForElective(this.caseModel.CaseEOCAdmDisposn.IsAdmSrcElective));        
        this.fields.setValue("EOC Admit/Disposition", 'Unplanned Admission', this.convertTrueFalse(this.caseModel.CaseEOCAdmDisposn.IsUnplannedAdmission));
        this.fields.setValue("EOC Admit/Disposition", 'Admit Prior Cath', this.convertTrueFalse(this.caseModel.CaseEOCAdmDisposn.AdmittedPriorCathInd));
        this.fields.setValue("EOC Admit/Disposition", 'Discharge Post Cath', this.convertTrueFalse(this.caseModel.CaseEOCAdmDisposn.DischargePostCathInd));
        this.fields.setValue("EOC Admit/Disposition", 'Alive at Discharge', this.convertTrueFalse(this.caseModel.CaseEOCAdmDisposn.IsAliveAtDischarge));

        if (this.caseModel.CaseEOCAdmDisposn.PostCathLocation.length > 0) {
          this.fields.setValue("EOC Admit/Disposition", 'Post-Cath Location', this.caseModel.CaseEOCAdmDisposn.PostCathLocation);
        } else {
          this.fields.setValue("EOC Admit/Disposition", 'Post-Cath Location', null);
        }        
      } else {
        this.fields.setValue("EOC Admit/Disposition", 'Id', 0);
      }
    } catch {
      console.log("Error loading EOC Admit/Disposition");      
    }

    console.log(this.fields.fields);

    this.getServiceData();
    
  }

  private setCaseData() {
    console.log("setCaseData");
    this.stsData = this.stsDiagnosisService.dropDownStsDiagnosis;
    this.mAEData = this.majorAdverseEventService.dropDownMajorAdverseEvent;
    this.aEData = this.otherAdverseEventService.dropDownOtherAdverseEvent;
    this.plData = this.procedurePicklistService.dropDownProcedureList;

    this.loadResponseListData();
  }

  private setCaseListData() {
    this.routerHelper
      .getRouteData(this.router.events)
      .pipe(
      map(data => {
        if (data["next"]) {
          //if (data["next"].indexOf("efficacy") >= 0) {
          //  if (this.user.IsIqicUser()) { return data["next"]; }
          //  else {
          //    return "//user-portal//edit-case//adverse-events";
          //  }
          //}
          //else {
            return data["next"];
         // }

        }
      })
      )
      .subscribe(pathString => {
        this.nextRoute = pathString + "/" + this.caseId;
      });
    this.routerHelper
      .getRouteData(this.router.events)
      .pipe(
      map(data => {

     //   if (data["prev"].indexOf("efficacy") >= 0) {
        //  if (this.user.IsIqicUser()) { return data["prev"]; }
        //  else {
        //    return "//user-portal//edit-case//case-types";
        //  }
        //}
        //else {
          return data["prev"];
        //}
      })
      )
      .subscribe(pathString => {
        this.prevRoute = pathString + "/" + this.caseId;
      });    
  }

  private loadResponseListData() {
    console.log("loadResponseListData");
    let cantComplete = false;
    if (this.loadData) {
      try {
        if (this.caseModel.CaseProcAssingedlist.length > 0) {
          this.loadCaseTypes(this.caseModel.CaseProcAssingedlist);
        } else {
          if (this.caseModel.CaseTypeCalcResponse.CaseTypeId.length > 0) {
            let qiText = this.caseModel.CaseTypeCalcResponse.CaseTypeDescription + " (" + this.caseModel.CaseTypeCalcResponse.CaseTypeId + ")";
            this.fields.setValue("Case Types", "Case Type QI Text", qiText);
          }
        }
      } catch (err) {
        console.log("Error loading Case Types (loadResponseListData) " + err.message);
        cantComplete = true;
        this.setCaseData();
      }

      try {
        if (this.caseModel.CaseClinicalInfo) {
          this.loadSts(this.caseModel.CaseClinicalInfo.StsDiagCode);
        }
      } catch (err) {
        console.log("Error loading Clinical Characteristics (loadResponseListData) " + err.message);
        cantComplete = true;
        this.setCaseData();
      }

      try {
        if (this.caseModel.CaseProcAEInfo) {
          if (this.caseModel.CaseProcAEInfo.caseAEItems.length > 0) {
            this.fields.getParent("Adverse Events")["hide"] = false;
            this.fields.getField("Adverse Events", "Recorded Events").value = {};
            this.loadAdverseEvents(this.caseModel.CaseProcAEInfo.caseAEItems);
          }
        }
      } catch (err) {
        console.log("Error loading Adverse Events (loadResponseListData) " + err.message);
        cantComplete = true;
        this.setCaseData();
      }

      try {
        if (this.caseModel.CaseReqResources) {
          this.loadIndicators(this.caseModel.CaseReqResources);
        }
      } catch (err) {
        console.log("Error loading Indicators of Required Resources (loadResponseListData) " + err.message);
        cantComplete = true;
        this.setCaseData();
      }

      try {
        if (this.caseModel.CaseProcEfficacy) {
          this.loadEfficacy(this.caseModel.CaseProcEfficacy);
        }
      } catch (err) {
        console.log("Error loading Procedural efficacy  (loadResponseListData) " + err.message);
        cantComplete = true;
        this.setCaseData();
      }
    } else {
      this.setCaseData();
    }

    this.fields.caseLoaded = (!cantComplete);   
  }  


  private loadSts(stsDiagCode: string) {
    const words = stsDiagCode.split(',');
    var uniqueWords = words.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    })
    var PrimarySTSdiagCode = uniqueWords[0];
    var SecondSTSdiagCode = uniqueWords[1];
    var ThirdSTSdiagCode = uniqueWords[2]
    var FourthSTSdiagCode = uniqueWords[3]

    if (PrimarySTSdiagCode != null) {
      this.loadStsDetails(PrimarySTSdiagCode,1);
    }
    if (SecondSTSdiagCode != null) {
      this.loadStsDetails(SecondSTSdiagCode, 2);
    }
    if (ThirdSTSdiagCode != null) {
      this.loadStsDetails(ThirdSTSdiagCode, 3);
    }
    if (FourthSTSdiagCode != null) {
      this.loadStsDetails(FourthSTSdiagCode, 4);
    } 
  }

  private loadStsDetails(stsDiagCode: string, diagnosis: number) {
    let continuePath = true;
    let nodePath = "";
    let node = {
      data: {}
    };

    if (stsDiagCode != null) {
      for (let treePath of this.stsData) {
        if (continuePath) {
          if (treePath.children == null || treePath.children.length === 0) {
            if (treePath.code === stsDiagCode) {
              continuePath = false;
              node.data = treePath;
              nodePath = treePath.name;
            }
          } else {
            for (let childPath of treePath.children) {
              if (continuePath) {
                if (childPath.children == null || childPath.children.length === 0) {
                  if (childPath.code === stsDiagCode) {
                    continuePath = false;
                    node.data = childPath;
                    nodePath = treePath.name + " | " + childPath.name;
                  }
                } else {
                  for (let children of childPath.children) {
                    if (continuePath) {
                      if (children.code === stsDiagCode) {
                        continuePath = false;
                        node.data = children;
                        nodePath = treePath.name + " | " + childPath.name + " | " + children.name;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      if (!continuePath) {
        if (diagnosis == 1) {
          this.fields.getField('Clinical Characteristics', 'PrimarySTS').text = nodePath;
          this.fields.getField('Clinical Characteristics', 'PrimarySTS').value = node;
        }
        if (diagnosis == 2) {
          this.fields.getField('Clinical Characteristics', 'SecondSTS').text = nodePath;
          this.fields.getField('Clinical Characteristics', 'SecondSTS').value = node;
        }
        if (diagnosis == 3) {
          this.fields.getField('Clinical Characteristics', 'ThirdSTS').text = nodePath;
          this.fields.getField('Clinical Characteristics', 'ThirdSTS').value = node;
        }
        if (diagnosis == 4) {
          this.fields.getField('Clinical Characteristics', 'FourthSTS').text = nodePath;
          this.fields.getField('Clinical Characteristics', 'FourthSTS').value = node;
        }
      }
    }
  }

  private loadCaseTypes(data: CaseProcAssignmentModel[]) {
    let primary = "";
    let secondary = "";
    let prePrimary = "";
    let preSecondary = "";

    this.fields.setValue("Case Types", "Case Type", null);
    this.fields.setValue("Case Types", "Case Type Done", null);
    this.fields.setValue("Pre-Case Calculation", "Case Type", null);
    this.fields.setValue("Pre-Case Calculation", "Case Type Done", null);

    for (let datum of data) {
      if (datum.IsPrimary === "1") {
        this.fields.setValue("Case Types", "Case Type", "");
      }

      let toAdd = {
        name: "",
        id: "procedure-" + datum.ProcedureCode,
        primary: ((datum.IsPrimary === "1") || (datum.IsAnticipatedPrimary === "1")),
        details: datum.ProcedureTextValue,
      }

      if (datum.IsPrimary === "1") {
        primary = datum.ProcedureCode;
      } else if (datum.IsAnticipatedPrimary === "1") {
        prePrimary = datum.ProcedureCode;
      } else if (datum.IsSecondary === "1") {
        secondary = secondary + datum.ProcedureCode + + ",";
      } else if (datum.IsAnticipatedSecondary === "1") {
        preSecondary = preSecondary + datum.ProcedureCode + + ",";
      }

      for (let pl of this.plData) {
        if (toAdd.name.length === 0) {
          if (datum.ProcedureCode == pl.code) {
            toAdd.name = pl.name;
          } else {
            //go through children
            if (pl.children.length > 0 && toAdd.name.length === 0) {
              for (let plChild of pl.children) {
                if (datum.ProcedureCode == plChild.code) {
                  toAdd.name = pl.name + " | " + plChild.name;
                } else {
                  //go through children
                  if (plChild.children.length > 0 && toAdd.name.length === 0) {
                    for (let plChildChild of plChild.children) {
                      if (datum.ProcedureCode == plChildChild.code) {
                        toAdd.name = pl.name + " | " + plChild.name + " | " + plChildChild.name;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      if (toAdd.name.length > 0) {
        toAdd.name = toAdd.name.replace(" [add details]", "");
      }

      if (toAdd.details && toAdd.details.length > 0) {
        toAdd.name = toAdd.name + " | " + toAdd.details; 
      }

      if (((datum.IsPrimary === "1") || (datum.IsSecondary === "1"))){
        this.fields.getField("Case Types", "Recorded Procedures").value[toAdd.name + ""] = toAdd;        
      } else {
        this.fields.getField("Pre-Case Calculation", "Recorded Procedures").value[toAdd.name + ""] = toAdd;
      }
    }

    let unordered = this.fields.getField("Case Types", "Recorded Procedures").value;
    let ordered = {};
    Object.keys(unordered).sort().forEach(function (key) {
      ordered[key] = unordered[key];
    });
    this.fields.getField("Case Types", "Recorded Procedures").value = ordered;

    let unorderedPre = this.fields.getField("Pre-Case Calculation", "Recorded Procedures").value;
    let orderedPre = {};
    Object.keys(unorderedPre).sort().forEach(function (key) {
      orderedPre[key] = unorderedPre[key];
    });
    this.fields.getField("Pre-Case Calculation", "Recorded Procedures").value = orderedPre;

    this.loadCaseTypeGenerated(primary, secondary, prePrimary, preSecondary);
    if (this.fields.anticipatedCase) {
      this.fields.getField("Case Types", "Anticipated Procedures").hide = false;
    }

  }

  private loadCaseTypeGenerated(primary: string, secondary: string, prePrimary: string, preSecondary: string) {    
    this.caseTypeCalcService.getCaseType(primary, secondary).subscribe(data => this.validateCaseType(data, "Case Types"));      
    this.caseTypeCalcService.getCaseType(prePrimary, preSecondary).subscribe(data => this.validateCaseType(data, "Pre-Case Calculation"));
  }

  validateCaseType(ctcr: CaseTypeCalcResponse, name: string) {
    if (ctcr.MessageType === "success" && ctcr.DataObject.CaseTypeDescription) {
      this.fields.setValue(name, "Case Type", ctcr.DataObject.CaseTypeDescription);
      this.fields.setValue(name, "Case Type Done", true);
      if (name == "Pre-Case Calculation") {
        this.fields.getParent('Pre-Case Calculation')['hide'] = false;
      }
    }
  }

  private loadAdverseEvents(data: CaseAEItemModel[]) {
    for (let datum of data) {    
      let toAdd = {
        name: "",
        id: 0,
        code: datum.AECODE,
        major: datum.AEMajorInd,
        seriousness: datum.AESeriousness,
        timeneeded: datum.Timeneeded,
        aetier: datum.AeTier
      };

      if (datum.AEMajorInd === "1") {
        for (let mae of this.mAEData) {
          if (toAdd.id === 0) {
            if (datum.AECODE == mae.code) {
              toAdd.id = mae.id;
              toAdd.name = mae.name;
            }
          }          
        }        
      } else {
        for (let ae of this.aEData) {
          if (toAdd.id === 0) {
            if (datum.AECODE == ae.code) {
              toAdd.id = ae.id;
              toAdd.name = ae.name;
            } else {
              //go through children
              if (ae.children.length > 0 && toAdd.id === 0) {
                for (let aeChild of ae.children) {
                  if (datum.AECODE == aeChild.code) {
                    toAdd.id = aeChild.id;
                    toAdd.name = ae.name + " | " + aeChild.name;
                  } else {
                    //go through children
                    if (aeChild.children.length > 0 && toAdd.id === 0) {
                      for (let aeChildChild of aeChild.children) {
                        if (datum.AECODE == aeChildChild.code) {
                          toAdd.id = aeChildChild.id;
                          toAdd.name = ae.name + " | " + aeChild.name + " | " + aeChildChild.name;
                        }
                      }
                    }
                  }
                }
              }
            }
          }          
        } 
      }

      if (toAdd.name!=""){  
        this.fields.getField("Adverse Events", "Recorded Events").value[toAdd.name + ""] = toAdd;
        }
    }

    let unordered = this.fields.getField("Adverse Events", "Recorded Events").value;
    let ordered = {};
    Object.keys(unordered).sort().forEach(function (key) {
      ordered[key] = unordered[key];
    });
    this.fields.getField("Adverse Events", "Recorded Events").value = ordered;
  }

  private loadIndicators(data: CaseReqResourcesModel[]) {
    this.fields.checked6Hours = false;
    this.fields.checked24Hours = false; 
    this.fields.checked48Hours = false;
    for (let datum of data) {
      let name = datum.ResourceName;

      if (name == "Nursing Assignment") {
        this.fields.setValue("Indicators of Required Resources", "Nursing Assignment 6 Hrs Pre-Procedure", datum.ResourceValue);
      } else if (name == "<6 hrs post-cath") {
        this.fields.setValue("Indicators of Required Resources", "Nursing Assignment Post-Procedure Before 6hrs", datum.ResourceValue);
      } else if (name == ">6 hrs post-cath") {
        this.fields.setValue("Indicators of Required Resources", "Nursing Assignment 18 to 24 Hrs Post-Cath", datum.ResourceValue);
      } else if (name == "48 Hrs Post-Cath") {
        this.fields.setValue("Indicators of Required Resources", "Nursing Assignment 48 Hrs Post-Cath", datum.ResourceValue);
      } else {
        let proc = "";
        if (datum.IsResUsedPreProcTime === "1") {
          proc = "Pre-Procedure";
        } else if (datum.IsResUsedProcTime === "1") {
          proc = "Procedural";
        } else if (datum.IsResUsedPostProcTime === "1") {
          proc = "Post-Procedure";
        } else if (datum.IsResUsed48HoursTime === "1") {
          proc = "48 Hrs Post-Cath";
        }


        this.fields.setValue("Indicators of Required Resources", name + " " + proc, datum.ResourceValue);
        this.fields.getField("Indicators of Required Resources", name + " " + proc).id = datum.ReqResourceId;

        if (name == "Checked6Hours") {
          this.fields.checked6Hours = JSON.parse(datum.ResourceValue);
          if (this.fields.checked6Hours) {
            this.fields.getField("Indicators of Required Resources", "Nursing Assignment 6 Hrs Pre-Procedure").hide = true;
          }
        }

        if (name == "Checked24Hours") {
          this.fields.checked24Hours = JSON.parse(datum.ResourceValue);
          if (this.fields.checked24Hours) {
            this.fields.getField("Indicators of Required Resources", "Nursing Assignment 18 to 24 Hrs Post-Cath").hide = true;
            this.fields.getField("Indicators of Required Resources", "Nursing Assignment 48 Hrs Post-Cath").hide = true;
          }         
        }
        if (name == "Checked48Hours") {
          this.fields.checked48Hours = JSON.parse(datum.ResourceValue);
          if (this.fields.checked48Hours) {
            this.fields.getField("Indicators of Required Resources", "Nursing Assignment 48 Hrs Post-Cath").hide = true;
          }
        }
      }      
    }
  }

  private loadEfficacy(data: CaseProcEfficacyModel[]) {
    for (let datnum of data) {
      if (datnum.EfficacyDataValue == "0" || datnum.EfficacyDataValue == "1") {
        this.fields.setValue("Procedural Efficacy", datnum.EfficacyDataName, datnum.EfficacyDataValue);
        this.fields.getField("Procedural Efficacy", datnum.EfficacyDataName).hide = !this.convertTrueFalse(datnum.EfficacyDataValue);
      }
      else {
        let name = datnum.EfficacyDataName;
        let proc = "";
        if (datnum.IsDataPreProcTime === "1") {
          proc = "Pre-Procedure";
        } else if (datnum.IsDataPostProcTime === "1") {
          proc = "Post-Procedure";

        }
        if (name == "Peak systolic AV gradient" || name == "Aortic regurgitation" || name == "Pulmonary regurgitation"
          || name == "Tricuspid valve regurgitation" || name == "Peak systolic gradient at coarctation") {
          this.fields.setValue("Procedural Efficacy", name + " " + proc, datnum.EfficacyDataValue);
          this.fields.getField("Procedural Efficacy", name + " " + proc).id = datnum.CaseProcEfficacyInfoid;
        }
        else {
        
          this.fields.setValue("Procedural Efficacy", name, datnum.EfficacyDataValue);
          this.fields.getField("Procedural Efficacy", name).id = datnum.CaseProcEfficacyInfoid;
        }
      }
        
    }

  }


  private loadTime(time: string): Date {
    if (time.length > 0) {
      let today = new Date();
      let todaySplit = today.toString().split(" ");
      todaySplit[4] = time;
      let d = todaySplit.join(" ");
      return new Date(d);
    }

    return null;
  }

  private loadDuration(time1: string, time2: string): string {
    if (time1.length > 0 && time2.length > 0) {
      try {
        let time1Array = time1.split(":");
        let time1Hours = +time1Array[0];
        let time1Mins = +time1Array[1];
        let time1Full = (time1Hours * 60) + time1Mins;

        let time2Array = time2.split(":");
        let time2Hours = +time2Array[0];
        let time2Mins = +time2Array[1];
        let time2Full = (time2Hours * 60) + time2Mins;

        let timeMinutes = 0;
        //if inTime is larger than outTime, assume outTime is the next day, so add 1440 minutes to outTime
        if (time1Full > time2Full) {
          time2Full = time2Full + 1440;
        }
        timeMinutes = (time2Full - time1Full);

        let hours = Math.floor(timeMinutes / 60);
        let minutes = timeMinutes % 60;

        var format = function (val) {
          return (val < 10) ? "0" + val : val;
        }
        var timeFormat = function (hr, min) {
          return hr + ':' + min;
        }

        let hourStr = format(hours);
        let minuteStr = format(minutes)
        return timeFormat(hourStr, minuteStr);
      } catch {
        return "";
      }
    }
    return "";
  }

  private convertTrueFalse(type: string): boolean {
    if (type == null) {
      return null;
    }

    return (type === "1");
  }

  private convertTrueFalseForElective(type: string): boolean {
    if (type == null) {
      return null;
    }

    return (type != "0" );
  }

  private convertAgeType(type: number): string {
    if (type === 0) {
      return 'Days';
    }
    if (type === 1) {
      return 'Months';
    }
    if (type === 2) {
      return 'Years';
    }
  }

  saveCase(casedata: number) {
    this.caseService.route = "editcase"
     this.caseService.saveCaseId = casedata;       
  }

  ngOnInit() {
    localStorage.setItem('previousurl', 'edit-case');
    this.fields.caseCreated = true;
  }

  ngOnDestroy() {
    this.fields.resetFields();
  }
}
