
import { NgForm } from "@angular/forms";

import { Component, OnInit, HostListener, Pipe, PipeTransform, ViewChild } from "@angular/core";
import { DatePipe } from '@angular/common'
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Observable, pipe } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

import { LoginService } from "../login.service";

import { CaseLookupService } from "./case-lookup.service";
import { SearchResponse } from '../../../shared/search-response';
import { WeeklySummaryResponse } from '../../../shared/weekly-summary-response';
import { SearchModel } from '../../../shared/search-model';
import { SearchCaseSummaryModel } from '../../../shared/search-case-summary-model';
import { CaseReqResourcesModel } from '../../../shared/case/case-req-resources-model';

import { PicklistItemService } from "../../shared-data-entry/picklist-item.service";
import { PicklistItemResponse } from '../../../shared/picklist-item-response';
import { PicklistItemModel } from '../../../shared/picklist-item-model';

import { CaseService } from '../add-case/case.service';
import { CaseFieldsService } from "../../user/add-case/case-fields.service";
import { CaseResponse } from "../../../shared/case/case-response";
import { CaseModel } from '../../../shared/case/case-model';
import { StsDiagnosisService } from "../../shared-data-entry/sts-diagnosis.service";
import { StsDiagnosisResponse } from '../../../shared/sts-diagnosis-response';
import { CaseAEItemModel } from "../../../shared/case/case-ae-item-model";
import { forEach } from "@angular/router/src/utils/collection";
import { MajorAdverseEventService } from "../../shared-data-entry/major-adverse-event.service";
import { MajorAdverseEventResponse } from "../../../shared/major-adverse-event-response";
import { OtherAdverseEventService } from "../../shared-data-entry/other-adverse-event.service";
import { OtherAdverseEventResponse } from "../../../shared/other-adverse-event-response";
import { ProcedurePicklistService } from "../../shared-data-entry/procedure-picklist.service";
import { ProcedurePicklistResponse } from '../../../shared/procedure-picklist-response';
import { ProcedurePicklistModel } from '../../../shared/procedure-picklist-model';
import { SectionValidatePipe } from "../../../pipes/section-validate.pipe";
import { ServerResponse } from '../../../shared/server-response';
import { WeekDayModel } from '../../../shared/weekday-model';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import * as FileSaver from 'file-saver';
import { LineMarker } from "wijmo/wijmo.chart";
import { NgxSpinnerService } from 'ngx-spinner';
//import { win32 } from 'path';




declare var jquery: any;
declare var $: any;

@Pipe({
  name: 'safeHtml'
})

@Component({
  selector: "app-case-lookup",
  templateUrl: "./case-lookup.component.html",
  styleUrls: ["./case-lookup.component.scss"]
})
export class CaseLookupComponent  implements OnInit {
  @ViewChild('caselookupform')
  form: NgForm;

  public successMessage = "";
  public errorMessage = "";
  public ageOptions = [];

  public setDate: Date = new Date();
  
  filter: string = "";
  key: string = "CathDate";
  reverse: boolean = false;
  caseTimeResult: string;
  p: number = 1;

  config = {
    id: 'custom',
    itemsPerPage: 1,
    currentPage: 1
  };

  public maxSize: number = 7;
  public directionLinks: boolean = true;
  public autoHide: boolean = false;
  public responsive: boolean = true;
  public labels: any = {
    previousLabel: '<--',
    nextLabel: '-->',
    screenReaderPaginationLabel: 'Pagination',
    screenReaderPageLabel: 'page',
    screenReaderCurrentLabel: `You're on page`
  };

  public stsDiagnosisService: StsDiagnosisService;
  public lookup: CaseLookupService;
  public lookupstore: CaseLookupService;
  public user: LoginService;
  public picklistItemService: PicklistItemService;
  public caseService: CaseService;
  public fields: CaseFieldsService;
  containsEfficacy: boolean = false;
  efficacyCount: number = 0;
  showImport = false;
  showWeeklyDashboard = false;
  showCaseSummary = false;
  showCaseSummaryIE = false;
  caseSummary: string = "";
  caseSummaryTI: string = "";
  caseSummaryT: string = "";
  caseSummaryTII: string = "";
  labelValue: string = "Choose File";
  monday: string = "";
  tuesday: string = "";
  caseModel: CaseModel;
  dropDownNames: PicklistItemResponse;
  dropDownHospitals: PicklistItemResponse;
  dropDownOperators: PicklistItemResponse;
  dropDownCrisp: PicklistItemResponse;
  dropDownProcedure: ProcedurePicklistModel[];
  stsDiagnosisList: StsDiagnosisResponse;
  AdverseEventModel: CaseAEItemModel[];
  weeklyList: Array<WeekDayModel> = [];
   fileList: FileList;
  public majorAdverseEventService: MajorAdverseEventService;
  public otherAdverseEventService: OtherAdverseEventService;
  public procedurePicklistService: ProcedurePicklistService;

  constructor(public datepipe: DatePipe, private Spinner: NgxSpinnerService, _ProcedurePicklistService: ProcedurePicklistService,_lookup: CaseLookupService, _user: LoginService, _picklistItemService: PicklistItemService,
    _caseService: CaseService, _fields: CaseFieldsService, _stsDiagnosisService: StsDiagnosisService,
    _majorAdverseEventService: MajorAdverseEventService,
    _otherAdverseEventService: OtherAdverseEventService, private sanitized: DomSanitizer) {

    this.setDate.setMonth(this.setDate.getMonth() + 3);
    if ("ActiveXObject" in window == true) {
      this.showCaseSummaryIE = false;
    }
    else {
      this.showCaseSummaryIE = true;
    }

    this.user = _user;    

    this.majorAdverseEventService = _majorAdverseEventService;
    this.otherAdverseEventService = _otherAdverseEventService;
    this.caseService = _caseService;
    this.stsDiagnosisService = _stsDiagnosisService;
    this.fields = _fields;
    this.fields.resetFields();

    this.lookup = _lookup;    
    this.lookup.HospitalId = this.user.user.HospitalId;
    this.lookup.searchClear();
   
   
    this.errorMessage = "";

    this.majorAdverseEventService
      .getMajorAdverseEvent()
      .subscribe(data => this.populateMajorEvents(data));

    this.otherAdverseEventService
      .getOtherAdverseEvent()
      .subscribe(data => this.populateOtherEvents(data));

    this.picklistItemService = _picklistItemService;
    this.picklistItemService.getPicklistItem(this.lookup.HospitalId.toString(), "Age Range").subscribe(data => this.populatePicklistItems(data,"Age Range"));

    
    if (this.user.user) {
      this.lookupstore = JSON.parse(localStorage.getItem('lookup'));
      if (this.lookupstore != null && localStorage.getItem('previousurl') != null && localStorage.getItem('previousurl') == 'edit-case') {
        localStorage.removeItem('previousurl');
        this.lookup.CaseId = this.lookupstore.CaseId;
        this.lookup.AgeRange = this.lookupstore.AgeRange;
        this.lookup.CaseType = this.lookupstore.CaseType;
        this.lookup.CathDateFrom =this.lookupstore.CathDateFrom;
        this.lookup.CathDateTo = this.lookupstore.CathDateTo;
        this.lookup.CompleteDataOnly = this.lookupstore.CompleteDataOnly;
        this.lookup.IncompleteDataOnly = this.lookupstore.IncompleteDataOnly;
        this.lookup.HighAE = this.lookupstore.HighAE;
        this.lookup.MedAE = this.lookupstore.MedAE;
        this.lookup.LowAE = this.lookupstore.LowAE;
        this.lookup.PedcathCasesOnly = this.lookupstore.PedcathCasesOnly;
        
      }

      this.picklistItemService
        .getPicklistItem(this.user.user.HospitalId.toString(), "ALL")
        .subscribe(data => this.populatePicklistItems(data, "ALL"));

      this.picklistItemService
        .getPicklistItem(this.user.user.HospitalId.toString(), "HOSPITALS")
        .subscribe(data => this.populatePicklistItems(data, "HOSPITALS"));

      this.picklistItemService
        .getPicklistItem("1", "CRISP Physiologic category")
        .subscribe(data => this.populatePicklistItems(data, "CRISP"));    

      this.picklistItemService.getPicklistItem(this.user.user.HospitalId.toString(), "OPERATORS").subscribe(data => this.populatePicklistItems(data, "OPERATORS"));

      this.stsDiagnosisService
        .getStsDiagnosis()
        .subscribe(data => this.populateStsDiagnosis(data));

      this.procedurePicklistService = _ProcedurePicklistService;
      this.procedurePicklistService.getProcedurePicklist().subscribe(data => this.validateProcedurePicklist(data));

    }
  }
  onPageChange(event) {
    console.log(event);
    this.config.currentPage = event;
  }
  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }

  validateProcedurePicklist(ppr: ProcedurePicklistResponse) {
    if (ppr.MessageType === "success") {
      this.dropDownProcedure = ppr.DataObject;    
    }
  }

  populateMajorEvents(majorAEResponse: MajorAdverseEventResponse) {
    this.majorAdverseEventService.majorAdverseEventList =
      majorAEResponse.DataObject;    
  }

  populateOtherEvents(otherAEResponse: OtherAdverseEventResponse) {
    this.otherAdverseEventService.otherAdverseEventList =
      otherAEResponse.DataObject;
    
  }

  populateStsDiagnosis(stsDiagResponse: StsDiagnosisResponse) {
    this.stsDiagnosisList = stsDiagResponse;    
  }

  populatePicklistItems(picklistItemResponse: PicklistItemResponse,type) {
    this.picklistItemService.picklistItemModel = picklistItemResponse.DataObject;
    this.picklistItemService.mapToDropDown();
    if (type == "Age Range")
      this.ageOptions = this.picklistItemService.dropDownPicklistItem;

    if (type == "ALL")
      this.dropDownNames = picklistItemResponse;
    else if (type == "HOSPITALS")
      this.dropDownHospitals = picklistItemResponse;
    else if (type == "OPERATORS")
      this.dropDownOperators = picklistItemResponse;
    else if (type == "CRISP")
      this.dropDownCrisp = picklistItemResponse;
    
  }

  sort(key) {
    if (this.key === key) {
      //key is the same, change reverse
      this.reverse = !this.reverse;
    } else {
      //key changed, default reverse
      this.key = key;
      this.reverse = false;
    }       
  }

  ngOnInit() {
    this.SearchCase();
    this.fields.anticipatedCase = false;
  }

  GenerateReport(caseID) {
    this.showCaseSummary = true;
    this.caseService.getCaseDetails(caseID).subscribe(data => this.loadResponse(data));
  }



  public fileChangeEvent(fileInput: any) {
    this.fileList = fileInput.target.files;   
    this.labelValue = fileInput.target.files[0].name;    
    
  }

  private loadResponse(data: CaseResponse) {
   
    this.caseModel = data.DataObject;
    var primaryProc = "";
    var secondaryProc = "";
    var anticipatedPriProc = "";
    var anticipatedSecProc = "";
    var hospital = "";
    if (this.dropDownHospitals.DataObject.find(x => x.ChoiceCode == this.caseModel.HospitalId.toString()) != undefined)
        hospital = this.dropDownHospitals.DataObject.find(x => x.ChoiceCode == this.caseModel.HospitalId.toString())["ChoiceText"];
    var operators = "";
    if (this.dropDownOperators.DataObject.find(x => x.ChoiceCode == this.caseModel.PrimaryOperatorId.toString()) != undefined)
       operators = this.dropDownOperators.DataObject.find(x => x.ChoiceCode == this.caseModel.PrimaryOperatorId.toString())["ChoiceText"];
    var patSex = "";
    if (this.caseModel.CaseClinicalInfo.PatSex == "2")     { patSex = "Female" } else { patSex = "Male" }
    var stsDiagCode = "";
    if (this.caseModel.CaseClinicalInfo.StsDiagCode != "") {
      let length = this.caseModel.CaseClinicalInfo.StsDiagCode.indexOf(',');
      let primaryStsDiagCode = length > 0 ? this.caseModel.CaseClinicalInfo.StsDiagCode.substr(0, length) : this.caseModel.CaseClinicalInfo.StsDiagCode;
      this.stsDiagnosisList.DataObject.find(x => x.StsDiagCode == primaryStsDiagCode)["StsDiagParentTextToShow"];
    }
    var PrevCathlast90dInd = "No"; if (this.caseModel.CaseClinicalInfo.PrevCathlast90dInd == "1") { PrevCathlast90dInd = "Yes" }
    var PrevSurglast90dInd = "No"; if (this.caseModel.CaseClinicalInfo.PrevSurglast90dInd == "1") { PrevSurglast90dInd = "Yes" } 
    var GenSyndromeInd = "No"; if (this.caseModel.CaseClinicalInfo.GenSyndromeInd == "1") { GenSyndromeInd = "Yes" } 
    var NonCardiacProbInd = "No"; if (this.caseModel.CaseClinicalInfo.NonCardiacProbInd == "1") { NonCardiacProbInd = "Yes" } 
    var NonCardiacProbValues = ""; if (this.caseModel.CaseClinicalInfo.NonCardiacProbInd == "1")
    {
      if (this.dropDownNames.DataObject.find(x => x.ChoiceCode == this.caseModel.CaseClinicalInfo.NonCardiacProbValues.toString()) != undefined)
      NonCardiacProbValues = this.dropDownNames.DataObject.find(x => x.ChoiceCode == this.caseModel.CaseClinicalInfo.NonCardiacProbValues.toString())["ChoiceText"];
    }
    var AdmissionSource = "Non-Elective"; if (this.caseModel.CaseEOCAdmDisposn.IsAdmSrcElective == "1") { AdmissionSource = "Elective" }
    var PostCathLocation = "";
    if (this.caseModel.CaseEOCAdmDisposn.PostCathLocation != "") {
      if (this.dropDownNames.DataObject.find(x => x.ChoiceCode == this.caseModel.CaseEOCAdmDisposn.PostCathLocation) != undefined)
      PostCathLocation = this.dropDownNames.DataObject.find(x => x.ChoiceCode == this.caseModel.CaseEOCAdmDisposn.PostCathLocation)["ChoiceText"];
    }
     
    var AdmitPriorCath = "No"; if (this.caseModel.CaseEOCAdmDisposn.AdmittedPriorCathInd == "1") { AdmitPriorCath = "Yes" }
    var DischargePostCath = "No"; if (this.caseModel.CaseEOCAdmDisposn.DischargePostCathInd == "1") { DischargePostCath = "Yes" }
    var UnplannedAdmission = "No"; if (this.caseModel.CaseEOCAdmDisposn.IsUnplannedAdmission == "1") { UnplannedAdmission = "Yes" }
    var DeathPostCath = "No"; if (this.caseModel.CaseEOCAdmDisposn.IsAliveAtDischarge == "1") { DeathPostCath = "Yes" } 
    var AdverseEventOccured = "No";
    var airwayManagement = "", airwayManagementPre = "", airwayManagementPost = "", airwayManagementProc = "";
    var Ventilation = "", VentilationPre = "", VentilationPost = "", VentilationProc = "";
    var Sedation = "", SedationPre = "", SedationPost = "", SedationProc = "";
    var Lines = "", LinesPre = "", LinesPost = "", LinesProc = "", LinesProc = "";
    var Drains = "", DrainsPre = "", DrainsPost = "", DrainsProc = "";
    var MechanicalSupport = "", MechanicalSupportPre = "", MechanicalSupportPost = "", MechanicalSupportProc = "";
    var IVMedications = "", IVMedicationsPre = "", IVMedicationsPost = "", IVMedicationsProc = "";
    var Nursing = "";
    var SingleVentricle = "No"; if (this.caseModel.CaseHemoDynamicInfo.IsSingleVenPhysiology == "1") { SingleVentricle = "Yes" }
    var IsMvSat = "No"; if (this.caseModel.CaseHemoDynamicInfo.IsMvSat == "1") { IsMvSat = "Yes" }
    var IsPAMean = "No"; if (this.caseModel.CaseHemoDynamicInfo.IsPAMean == "1") { IsPAMean = "Yes" }
    var IsPVR = "No"; if (this.caseModel.CaseHemoDynamicInfo.IsPVR == "1") { IsPVR = "Yes" }
    var IsQPQS = "No"; if (this.caseModel.CaseHemoDynamicInfo.IsQPQS == "1") { IsQPQS = "Yes" }
    var IsSVEDP = "No"; if (this.caseModel.CaseHemoDynamicInfo.IsSVEDP == "1") { IsSVEDP = "Yes" }
    var IsSysSat = "No"; if (this.caseModel.CaseHemoDynamicInfo.IsSysSat == "1") { IsSysSat = "Yes" }
    var crisp = ""
    var totalDAP = this.caseModel.CaseProcedureInfo.TotalDap == "" ? 0 : this.caseModel.CaseProcedureInfo.TotalDap;
    var bloodTransfusion = "No"; if (this.caseModel.CaseProcedureInfo.BloodTransfusion == "1") { bloodTransfusion = "Yes" }
    let nursingvalpre = "", nursingvalpost = "";
    this.caseSummaryTI = "";
    this.caseSummaryTII = "";
    if (this.caseModel.CaseHemoDynamicInfo.PhysiologicCategory != "") {
      if (this.dropDownCrisp.DataObject.find(x => x.ChoiceCode == this.caseModel.CaseHemoDynamicInfo.PhysiologicCategory) != undefined)
      crisp = this.dropDownCrisp.DataObject.find(x => x.ChoiceCode == this.caseModel.CaseHemoDynamicInfo.PhysiologicCategory)["ChoiceText"];
    }
     
    let dateString = this.caseModel.CathDate;
    let newDate = new Date(dateString);
    if (this.caseModel.CaseProcAEInfo.caseAEItems.length > 0)
      AdverseEventOccured = "Yes";
    
    this.AdverseEventModel = this.caseModel.CaseProcAEInfo.caseAEItems;
    this.caseSummaryT = "", this.caseSummaryTI = "";

    this.caseSummaryT += "<div class=\"form-row\" style=\" margin-top:10px\">"

    this.caseSummaryT += "<div class=\" col-lg-2 \"><span style=\" font-size: larger !important;font-weight : 900 !important;\"> Case Number:</span>&nbsp;<span style=\"font-weight: 500;font-size:larger !important;\">" + this.caseModel.CaseId + "</span></div>"
    this.caseSummaryT += "<div class=\" col-lg-4  \"><span style=\" font-size: larger !important;font-weight : bold !important;\"> Hospital:</span>&nbsp;<span style=\"font-weight: 500;font-size:larger !important;\">" + hospital + "</span></div>"
    this.caseSummaryT += "<div class=\" col-lg-3 \"><span style=\" font-size:larger !important;font-weight : bold !important;\"> Cath Date: </span>&nbsp;<span style=\"font-weight: 500;font-size:larger !important;\">" + newDate.toDateString() + "</span></div>"
    this.caseSummaryT += " <div class=\"col-lg-3  \"><span style=\" font-size: larger !important;font-weight : bold !important;\">Operator: </span>&nbsp;<span style=\"font-weight: 500;font-size:larger !important;\">" + operators + "</span></div>"
    this.caseSummaryT += "</div>"
    this.caseSummaryT += "<div class=\"form-row\">"
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-12 sect-header\"></div>"
    this.caseSummaryT += "</div>"
    //clinical info header
    this.caseSummaryT += "<div class=\"form-row\">"
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-12  \" style=\" font-size: large !important;font-weight : bold !important;text-transform: uppercase !important;\">Clinical Characteristics</div>"
    this.caseSummaryT += "</div>"
    this.caseSummaryT += "<div class=\"form-row\">"
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-1  \"> <span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Age:</span></div><div  class=\"d-flex  col-lg-3  \"><span style=\"font-weight: 500;font-size:larger;\">" + this.caseModel.CaseClinicalInfo.PatAge + "&nbsp;" + this.convertAgeType(this.caseModel.CaseClinicalInfo.PatAgeType) + "</span></div>"
    
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-3  \"> <span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Last Cath < 90 Days:</span></div><div  class=\"d-flex  col-lg-5  \"><span style=\"font-weight: 500;font-size:large;\">" + PrevCathlast90dInd + "</span></div>"

    this.caseSummaryT += "<div  class=\"d-flex  col-lg-1  \"> <span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Sex:</span></div><div  class=\"d-flex  col-lg-3  \"><span style=\"font-weight: 500;font-size:larger;\">" + patSex + "</span></div>"
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-3  \"> <span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Last Cardiac Surgery < 90 Days:</span></div><div  class=\"d-flex  col-lg-5  \"><span style=\"font-weight: 500;font-size:large;\">" + PrevSurglast90dInd + "</span></div>"
    
    
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-1  \"> <span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Weight:</span></div><div  class=\"d-flex  col-lg-3  \"><span style=\"font-weight: 500;font-size:larger;\">" + this.caseModel.CaseClinicalInfo.PatWeight + "&nbsp;kg</span></div>"
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-3  \"> <span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Diagnosis:</span></div><div  class=\"d-flex  col-lg-5  \"><span style=\"font-weight: 500;font-size:large;\">" + stsDiagCode + "</span></div>"
    
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-1  \"> <span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Height:</span></div><div  class=\"d-flex  col-lg-3  \"><span style=\"font-weight: 500;font-size:larger;\">" + this.caseModel.CaseClinicalInfo.PatHeight + "&nbsp;cm</span></div>"
    
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-3  \"> <span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Genetic Syndrome:</span></div><div  class=\"d-flex  col-lg-5  \"><span style=\"font-weight: 500;font-size:larger;\">" + GenSyndromeInd + "</span></div>"   
    
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-1  \"> <span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">BSA:</span></div><div  class=\"d-flex  col-lg-3  \"><span style=\"font-weight: 500;font-size:larger;\">" + this.caseModel.CaseClinicalInfo.PatBSA + "&nbsp;m²</span></div>"
    if (this.caseModel.CaseClinicalInfo.NonCardiacProbInd == "0") {
      this.caseSummaryT += "<div  class=\"d-flex  col-lg-3  \"> <span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Non Cardiac Problems:</span></div><div  class=\"d-flex  col-lg-5  \"><span style=\"font-weight: 500;font-size:large;\">" + NonCardiacProbInd + "</span></div>"
    }
    else if (this.caseModel.CaseClinicalInfo.NonCardiacProbInd == "1") {
      this.caseSummaryT += "<div  class=\"d-flex  col-lg-3 \"> <span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Non Cardiac Problems:</span></div><div  class=\"d-flex  col-lg-5  \"><span style=\"font-weight: 500;font-size:large;\">" + NonCardiacProbValues + "</span></div>"
    }
    
    this.caseSummaryT += "</div>"
    this.caseSummaryT += "<div class=\"form-row\">"
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-12 sect-header\"></div>"
    this.caseSummaryT += "</div>"
    this.caseSummaryT += "<div class=\"form-row\">"
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-5  \" style=\" font-size: large !important;font-weight : bold !important;text-transform: uppercase !important;\">Procedure Information</div>"
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-6 \" style=\" font-size: large !important;font-weight : bold !important;text-transform: uppercase !important;\">Hemodynamics </div>"
    this.caseSummaryT += "</div>"
    this.caseSummaryT += "<div class=\"form-row\">"
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-3  \"> <span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">First catheter in time:</span></div><div  class=\"d-flex  col-lg-2  \"><span style=\"font-weight: 500;font-size:large;\">" + this.caseModel.CaseProcedureInfo.SheathCathInDateTime.substring(0, 5) + "</span></div>"
    
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-3  \"> <span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Physiologic Category:</span></div><div  class=\"d-flex  col-lg-3  \"><span style=\"font-weight: 500;font-size:large;\">" + crisp + "</span></div>"
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-3  \"> <span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Last catheter out time:</span></div><div  class=\"d-flex  col-lg-2  \"><span style=\"font-weight: 500;font-size:large;\">" + this.caseModel.CaseProcedureInfo.SheathCathOutDateTime.substring(0, 5) + "</span></div>"
    
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-3  \"> <span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Single Ventricle Physiology:</span></div><div  class=\"d-flex  col-lg-3  \"><span style=\"font-weight: 500;font-size:large;\">" + SingleVentricle + "</span></div>"
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-3  \"> <span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Case Duration:</span></div><div  class=\"d-flex  col-lg-2  \"><span style=\"font-weight: 500;font-size:large;\">" + this.loadDuration(this.caseModel.CaseProcedureInfo.SheathCathInDateTime, this.caseModel.CaseProcedureInfo.SheathCathOutDateTime) + "</span></div>"
    
    
    if (SingleVentricle == "Yes") {
      this.caseSummaryT += "<div  class=\"d-flex  col-lg-3  \"> <span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">MV Sat < 50%:</span></div><div  class=\"d-flex  col-lg-3  \"><span style=\"font-weight: 500;font-size:large;\">" + IsMvSat + "</span></div>"
    }
    if (SingleVentricle == "No") {
      this.caseSummaryT += "<div  class=\"d-flex  col-lg-3  \"> <span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">MV Sat < 60%:</span></div><div  class=\"d-flex  col-lg-3  \"><span style=\"font-weight: 500;font-size:large;\">" + IsMvSat + "</span></div>"
    }
    
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-3  \"> <span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Fluoro Time:</span></div><div  class=\"d-flex  col-lg-2  \"><span style=\"font-weight: 500;font-size:large;\">" + this.caseModel.CaseProcedureInfo.FluroTime + "&nbsp;minutes</span></div>"
    
    if (SingleVentricle == "Yes") {
      this.caseSummaryT += "<div  class=\"d-flex  col-lg-3  \"> <span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Sys Sat < 78%:</span></div><div  class=\"d-flex  col-lg-3  \"><span style=\"font-weight: 500;font-size:large;\">" + IsSysSat + "</span></div>"
    }
    if (SingleVentricle == "No") {
      this.caseSummaryT += "<div  class=\"d-flex  col-lg-3  \"> <span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Sys Sat < 95%:</span></div><div  class=\"d-flex  col-lg-3  \"><span style=\"font-weight: 500;font-size:large;\">" + IsSysSat + "</span></div>"
    }
    
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-3   \"> <span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Total DAP:</span></div><div  class=\"d-flex  col-lg-2  \"><span style=\"font-weight: 500;font-size:large;\">" + totalDAP + "&nbsp;μGy*M²</span></div>"
    
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-3  \"> <span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">SV EDP ≥ 18mmHg:</span></div><div  class=\"d-flex  col-lg-3  \"><span style=\"font-weight: 500;font-size:large;\">" + IsSVEDP + "</span></div>"
    
    if (SingleVentricle == "Yes") {
      this.caseSummaryT += "<div  class=\"d-flex  col-lg-3  \"> <span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Total DAP/kg:</span></div><div  class=\"d-flex  col-lg-2  \"><span style=\"font-weight: 500;font-size:large;\"></span></div>"
      
      this.caseSummaryT += "<div  class=\"d-flex  col-lg-3 \"> <span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">PA mean ≥ 17mmHg:</span></div><div  class=\"d-flex  col-lg-3  \"><span style=\"font-weight: 500;font-size:large;\">" + IsPAMean + "</span></div>"
    }
    if (SingleVentricle == "No") {
      this.caseSummaryT += "<div  class=\"d-flex  col-lg-3  \"> <span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Total DAP/kg:</span></div><div  class=\"d-flex  col-lg-2  \"><span style=\"font-weight: 500;font-size:large;\"></span></div>"
      
      this.caseSummaryT += "<div  class=\"d-flex  col-lg-3 \"> <span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">PA Systolic ≥ 45mmHg:</span></div><div  class=\"d-flex  col-lg-3  \"><span style=\"font-weight: 500;font-size:large;\">" + IsPAMean + "</span></div>"
    }

    this.caseSummaryT += "<div  class=\"d-flex  col-lg-3  \"> <span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Blood Transfusion:</span></div><div  class=\"d-flex  col-lg-2  \"><span style=\"font-weight: 500;font-size:large;\">" + bloodTransfusion + "</span></div>"
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-3  \"> <span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Qp:Qs > 1.5:</span></div><div  class=\"d-flex  col-lg-3  \"><span style=\"font-weight: 500;font-size:large;\">" + IsQPQS + "</span></div>"

    this.caseSummaryT += "<div  class=\"d-flex  col-lg-5  \"> </div>"
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-3  \"> <span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">PVR>3WU</span></div><div  class=\"d-flex  col-lg-3  \"><span style=\"font-weight: 500;font-size:large;\">" + IsPVR + "</span></div>"
    
    
  

    this.caseSummaryT += "</div>"
   
    for (let proc in this.caseModel.CaseProcAssingedlist) {
      var procedure = this.caseModel.CaseProcAssingedlist[proc];
      if (procedure.IsPrimary == "1") {
        if (primaryProc != "") primaryProc = primaryProc + "<br/>";
        primaryProc = primaryProc +  this.dropDownProcedure.find(x => x.ProcedureCode == procedure.ProcedureCode)["ProcedureTextToShow"]
      }
      if (procedure.IsSecondary == "1") {
        if (secondaryProc != "") secondaryProc = secondaryProc + "<br/>";
        secondaryProc = secondaryProc + this.dropDownProcedure.find(x => x.ProcedureCode == procedure.ProcedureCode)["ProcedureTextToShow"]
      }
      if (procedure.IsAnticipatedPrimary == "1") {
        if (anticipatedPriProc != "") anticipatedPriProc = anticipatedPriProc + "<br/>";
        anticipatedPriProc = anticipatedPriProc + this.dropDownProcedure.find(x => x.ProcedureCode == procedure.ProcedureCode)["ProcedureTextToShow"]
      }
      if (procedure.IsAnticipatedSecondary == "1") {
        if (anticipatedSecProc != "") anticipatedSecProc = anticipatedSecProc + "<br/>";
        anticipatedSecProc = anticipatedSecProc + this.dropDownProcedure.find(x => x.ProcedureCode == procedure.ProcedureCode)["ProcedureTextToShow"]
      }

    }
    this.caseSummaryT += "<div class=\"form-row\">"
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-12 sect-header\"></div>"
    this.caseSummaryT += "</div>"
   
   
    this.caseSummaryT += "<div class=\"form-row\">"
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-3  \" style=\" font-size: large !important;font-weight : bold !important;text-transform: uppercase !important;\">Case Type</div>"
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-9   \"> <span style=\"font-weight: 500;font-size:large;\">" + this.caseModel.CaseTypeCalcResponse.CaseTypeDescription + "</span></div>"
    this.caseSummaryT += "</div>"
    this.caseSummaryT += "<div class=\"form-row\" >"
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-3  \" style=\" font-size: large !important;font-weight : bold !important;\"> Primary Procedure</div>"
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-9   \"> <span style=\"font-weight: 500;font-size:large;\">" + primaryProc + "</span></div>"
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-3  \" style=\" font-size: large !important;font-weight : bold !important;\">Secondary Procedure(s)</div>"  
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-9   \"> <span style=\"font-weight: 500;font-size:large;text-align:start !important;\">" + secondaryProc + "</span></div>"
    
    this.caseSummaryT += "</div>"  

    this.caseSummaryT += "<div class=\"form-row\">"
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-12 sect-header\"></div>"
    this.caseSummaryT += "</div>"
  
    if (AdverseEventOccured == "No") {
      this.caseSummaryT += "<div class=\"form-row\" >"
      this.caseSummaryT += "<div  class=\"d-flex  col-lg-12   \"> <span style=\" font-size: large !important;font-weight : bold !important;text-transform: uppercase !important;\">Adverse Event:</span>&nbsp;<span style=\"font-weight: 500;font-size:large;\">NO</span></div>"
      this.caseSummaryT += "</div>"
    }

    if (AdverseEventOccured == "Yes") {
      var MajorAEName = "";
      var OtherAEName = "";
      this.caseSummaryT += "<div class=\"form-row\" >"
      this.caseSummaryT += "<div  class=\"d-flex  col-lg-6 \" style=\" font-size: large !important;font-weight : bold !important;text-transform: uppercase !important;\"> Adverse Event</div>"
      this.caseSummaryT += "<div  class=\"d-flex  col-lg-6  \" style=\" font-size: large !important;font-weight : bold !important;\">Seriousness</div>"

      this.caseSummaryT += "</div>"    
     
      for (let ae of this.AdverseEventModel) {

        if (ae.AEMajorInd == "1") {
    
          MajorAEName = MajorAEName +  "<div class=\"form-row\" >"
          MajorAEName += "<div  class=\"d-flex  col-lg-6   \"> <span style=\"font-weight: 500;font-size:large;\">" + this.majorAdverseEventService.majorAdverseEventList.find(x => x.MAEventCode == Number(ae.AECODE))["MAEventTextToShow"] + "</span></div>"
          MajorAEName += "<div  class=\"d-flex  col-lg-6   \"> <span style=\"font-weight: 500;font-size:large;\">" + this.getAdverseEventSeriousness(ae.AESeriousness) + "</span></div>"


          MajorAEName += "</div>"

        }
        else if (ae.AEMajorInd == "0") {          
          MajorAEName = MajorAEName + "<div class=\"form-row\">"
          MajorAEName += "<div  class=\"d-flex  col-lg-6   \"> <span style=\"font-weight: 500;font-size:large;\">" + this.otherAdverseEventService.otherAdverseEventList.find(x => x.AElementCode == ae.AECODE)["AElementTextToShow"] + "</span></div>"
          MajorAEName += "<div  class=\"d-flex  col-lg-6   \"> <span style=\"font-weight: 500;font-size:large;\">" + this.getAdverseEventSeriousness(ae.AESeriousness) + "</span></div>"
     
          MajorAEName += "</div>"

        }

      }
      this.caseSummaryT += MajorAEName;
      this.caseSummaryT += "<div class=\"form-row\">"
      this.caseSummaryT += "<div  class=\"d-flex  col-lg-12  \" style=\" font-size: large !important;font-weight : bold !important;\"> Description:</div>"
      this.caseSummaryT += "<div  class=\"d-flex  col-lg-12  \"> <span style=\"font-weight: 500;font-size:large;;text-align:start !important\">" + this.caseModel.CaseProcAEInfo.CaseAENotes + "</span></div>" 
      this.caseSummaryT += "</div>"
    }

    this.caseSummaryT += "<div class=\"form-row\">"
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-12 sect-header\"></div>"
    this.caseSummaryT += "</div>"
    this.caseSummaryT += "<div class=\"form-row\">"
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-3  \"> </div>"
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-3  \" style=\" font-size: large !important;font-weight : bold !important;\"> Pre Procedure</div>"
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-3  \" style=\" font-size: large !important;font-weight : bold !important;\">Procedure</div>"
    this.caseSummaryT += "<div  class=\"d-flex  col-lg-3  \" style=\" font-size: large !important;font-weight : bold !important;\">Post Procedure</div>"
    this.caseSummaryT += "</div>"

    for (let crr in this.caseModel.CaseReqResources) {
      var resource = this.caseModel.CaseReqResources[crr];
      var resourceName = "";

      let splitResource: Array<string> = [];
      if (resource.ResourceValue.indexOf("|") > 0) {

        splitResource = resource.ResourceValue.split("|");
        for (let v of splitResource) {

          if (resourceName != "")
            resourceName += "<br/>" + this.dropDownNames.DataObject.find(x => x.ChoiceCode == v)["ChoiceText"];
          else
            resourceName += this.dropDownNames.DataObject.find(x => x.ChoiceCode == v)["ChoiceText"];


        }
      }
      else {
       
        resourceName += this.dropDownNames.DataObject.find(x => x.ChoiceCode == resource.ResourceValue)["ChoiceText"];
      }

      

      if (resource.ResourceName == "Airway Management") {

        if (resource.IsResUsedPreProcTime == "1") {
          airwayManagementPre += "<div  class=\"d-flex  col-lg-3  \"> <span style=\"font-weight: 500;font-size:large;text-align:start !important\">" + resourceName + "</span></div>"         
        }
        if (resource.IsResUsedProcTime == "1") {
          airwayManagementProc += "<div  class=\"d-flex  col-lg-3  \"> <span style=\"font-weight: 500;font-size:large;;text-align:start !important\">" + resourceName + "</span></div>"          
        }
        if (resource.IsResUsedPostProcTime == "1") {
          airwayManagementPost += "<div  class=\"d-flex  col-lg-3  \"> <span style=\"font-weight: 500;font-size:large;;text-align:start !important\">" + resourceName + "</span></div>"          
        }
      }
      if (resource.ResourceName == "Ventilation") {
        if (resource.IsResUsedPreProcTime == "1") {
          VentilationPre += "<div  class=\"d-flex  col-lg-3  \"> <span style=\"font-weight: 500;font-size:large;text-align:start !important\">" + resourceName + "</span></div>"         
        }
        if (resource.IsResUsedProcTime == "1") {
          VentilationProc += "<div  class=\"d-flex  col-lg-3  \"> <span style=\"font-weight: 500;font-size:large;text-align:start !important\">" + resourceName + "</span></div>"
        
        }
        if (resource.IsResUsedPostProcTime == "1") {
          VentilationPost += "<div  class=\"d-flex  col-lg-3  \"> <span style=\"font-weight: 500;font-size:large;text-align:start !important\">" + resourceName + "</span></div>"
         
        }
      }
      if (resource.ResourceName == "Sedation") {
        if (resource.IsResUsedPreProcTime == "1") {
          SedationPre += "<div  class=\"d-flex  col-lg-3  \"> <span style=\"font-weight: 500;font-size:large;;text-align:start !important\">" + resourceName + "</span></div>"
        }
        if (resource.IsResUsedProcTime == "1") {
          SedationProc += "<div  class=\"d-flex  col-lg-3  \"> <span style=\"font-weight: 500;font-size:large;;text-align:start !important\">" + resourceName + "</span></div>"
        }
        if (resource.IsResUsedPostProcTime == "1") {
          SedationPost += "<div  class=\"d-flex  col-lg-3  \"> <span style=\"font-weight: 500;font-size:large;;text-align:start !important\">" + resourceName + "</span></div>"
        }
      }
      if (resource.ResourceName == "Lines") {
        if (resource.IsResUsedPreProcTime == "1") {
          LinesPre += "<div  class=\"d-flex  col-lg-3  \"> <span style=\"font-weight: 500;font-size:large;text-align:start !important\">" + resourceName + "</span></div>"
        }
        if (resource.IsResUsedProcTime == "1") {
          LinesProc += "<div  class=\"d-flex  col-lg-3  \"> <span style=\"font-weight: 500;font-size:large;text-align:start !important\">" + resourceName + "</span></div>"
        }
        if (resource.IsResUsedPostProcTime == "1") {
          LinesPost += "<div  class=\"d-flex  col-lg-3  \"> ;<span style=\"font-weight: 500;font-size:large;text-align:start !important\">" + resourceName + "</span></div>"
        }
      }
      if (resource.ResourceName == "Drains") {
        if (resource.IsResUsedPreProcTime == "1") {
          DrainsPre += "<div  class=\"d-flex  col-lg-3  \"> <span style=\"font-weight: 500;font-size:large;text-align:start !important\">" + resourceName + "</span></div>"
        }
        if (resource.IsResUsedProcTime == "1") {
          DrainsProc += "<div  class=\"d-flex  col-lg-3  \"> <span style=\"font-weight: 500;font-size:large;text-align:start !important\">" + resourceName + "</span></div>"
        }
        if (resource.IsResUsedPostProcTime == "1") {
          DrainsPost += "<div  class=\"d-flex  col-lg-3  \"> <span style=\"font-weight: 500;font-size:large;text-align:start !important\">" + resourceName + "</span></div>"
        }
      }
      if (resource.ResourceName == "Mechanical Support") {
        if (resource.IsResUsedPreProcTime == "1") {
          MechanicalSupportPre += "<div  class=\"d-flex  col-lg-3  \"> <span style=\"font-weight: 500;font-size:large;text-align:start !important;\">" + resourceName + "</span></div>"
        }
        if (resource.IsResUsedProcTime == "1") {
          MechanicalSupportProc += "<div  class=\"d-flex  col-lg-3  \"> <span style=\"font-weight: 500;font-size:large;text-align:start !important;\">" + resourceName + "</span></div>"
        }
        if (resource.IsResUsedPostProcTime == "1") {
          MechanicalSupportPost += "<div  class=\"d-flex  col-lg-3  \"> <span style=\"font-weight: 500;font-size:large;text-align:start !important;\">" + resourceName + "</span></div>"
        }
      }
      if (resource.ResourceName == "IV Medications") {
        if (resource.IsResUsedPreProcTime == "1") {
          IVMedicationsPre += "<div  class=\"d-flex  col-lg-3  \"> <span style=\"font-weight: 500;font-size:large;text-align:start !important;\">" + resourceName + "</span></div>"
        }
        if (resource.IsResUsedProcTime == "1") {
          IVMedicationsProc += "<div  class=\"d-flex  col-lg-3  \"><span style=\"font-weight: 500;font-size:large;text-align:start !important;\">" + resourceName + "</span></div>"
        }
        if (resource.IsResUsedPostProcTime == "1") {
          IVMedicationsPost += "<div  class=\"d-flex  col-lg-3  \"> <span style=\"font-weight: 500;font-size:large;text-align:start !important;\">" + resourceName + "</span></div>"
        }
      }
     
      if (resource.ResourceName == "Nursing Assignment") {

        Nursing += "<div  class=\"d-flex  col-lg-3  \"> <span style=\"font-weight: 500;font-size:large;text-align:start !important;\">" + resourceName + "</span></div>"

      }
      if (resource.ResourceName == "<6 hrs post-cath") {
        nursingvalpre =  resourceName;       
      }
      if (resource.ResourceName == ">6 hrs post-cath") {
        nursingvalpost = resourceName;        
      }
      



    }
    if (nursingvalpre != "" && nursingvalpost != "") {
      let nursval = "<6hrs: " + nursingvalpre + "</br> >6hrs: " + nursingvalpost
      Nursing += "<div  class=\"d-flex  col-lg-3  \"></div>"
      Nursing += "<div  class=\"d-flex  col-lg-3  \"> <span style=\" font-size: large !important;font-weight : 500 !important;text-transform: capitalize !important;\">" + nursval +  "</span></div>"
    }
    else {
      Nursing += "<div  class=\"d-flex  col-lg-3  \"></div>"
      Nursing += "<div  class=\"d-flex  col-lg-3  \"> <span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\"><6 HRS:</span>&nbsp;<span style=\"font-weight: 500;font-size:large;text-align:start !important;\">" + nursingvalpre + nursingvalpost + "</span></div>"
    }
    if (airwayManagementPre != "" || airwayManagementPost != "" || airwayManagementProc != "") {
      if (airwayManagementPre == "")
        airwayManagementPre = "<div  class=\"d-flex  col-lg-3  \"> <span style=\"font-weight: 500;font-size:large;\">None</span></div>"
      if (airwayManagementProc == "")
        airwayManagementProc = "<div  class=\"d-flex  col-lg-3  \"> <span style=\"font-weight: 500;font-size:large;\">None</span></div>"
      if (airwayManagementPost == "")
        airwayManagementPost = "<div  class=\"d-flex  col-lg-3  \"> <span style=\"font-weight: 500;font-size:large;\">None</span></div>"
      airwayManagement = airwayManagementPre + airwayManagementProc + airwayManagementPost;
      this.caseSummaryT += "<div class=\"form-row\">"
     
      this.caseSummaryT += "<div  class=\"d-flex  col-lg-3  \" style=\" font-size: large !important;font-weight : bold !important;text-transform: uppercase !important;\"> Airway Management</div>"


      this.caseSummaryT += airwayManagement
      this.caseSummaryT += "</div>"
    }
    if (VentilationPre != "" || VentilationProc != "" || VentilationPost != "") {
      Ventilation = VentilationPre + VentilationProc + VentilationPost;
      if (VentilationPre == "")
        VentilationPre = "<div  class=\"d-flex  col-lg-3  \"> <span style=\"font-weight: 500;font-size:large;\">None</span></div>"
      if (VentilationProc == "")
        VentilationProc = "<div  class=\"d-flex  col-lg-3  \"> <span style=\"font-weight: 500;font-size:large;\">None</span></div>"
      if (VentilationPost == "")
        VentilationPost = "<div  class=\"d-flex  col-lg-3  \"> <span style=\"font-weight: 500;font-size:large;\">None</span></div>"
      this.caseSummaryT += "<div class=\"form-row\">"
      this.caseSummaryT += "<div  class=\"d-flex  col-lg-3  \" style=\" font-size: large !important;font-weight : bold !important;text-transform: uppercase !important;\"> Ventilation</div>"
      this.caseSummaryT += Ventilation
      this.caseSummaryT += "</div>"
    }
    if (SedationPre != "" || SedationProc != "" || SedationPost != "") {
      if (SedationPre == "")
        SedationPre = "<div  class=\"d-flex  col-lg-3  \"> <span style=\"font-weight: 500;font-size:large;\">None</span></div>"
      if (SedationProc == "")
        SedationProc = "<div  class=\"d-flex  col-lg-3  \"> <span style=\"font-weight: 500;font-size:large;\">None</span></div>"
      if (SedationPost == "")
        SedationPost = "<div  class=\"d-flex  col-lg-3  \"> <span style=\"font-weight: 500;font-size:large;\">None</span></div>"
      Sedation = SedationPre + SedationProc + SedationPost;
      this.caseSummaryT += "<div class=\"form-row\">"
      this.caseSummaryT += "<div  class=\"d-flex  col-lg-3  \" style=\" font-size: large !important;font-weight : bold !important;text-transform: uppercase !important;\"> Sedation</div>"
      this.caseSummaryT += Sedation
      this.caseSummaryT += "</div>"
    }
    if (LinesPre != "" || LinesProc != "" || LinesPost != "") {
      if (LinesPre == "")
        LinesPre = "<div  class=\"d-flex  col-lg-3  \"> <span style=\"font-weight: 500;font-size:large;\">None</span></div>"
      if (LinesProc == "")
        LinesProc = "<div  class=\"d-flex  col-lg-3  \"> <span style=\"font-weight: 500;font-size:large;\">None</span></div>"
      if (LinesPost == "")
        LinesPost = "<div  class=\"d-flex  col-lg-3  \"> <span style=\"font-weight: 500;font-size:large;\">None</span></div>"
      Lines = LinesPre + LinesProc + LinesPost;
      this.caseSummaryT += "<div class=\"form-row\">"
      this.caseSummaryT += "<div  class=\"d-flex  col-lg-3  \" style=\" font-size: large !important;font-weight : bold !important;text-transform: uppercase !important;\"> Lines</div>"
      this.caseSummaryT += Lines
      this.caseSummaryT += "</div>"
    }
    if (DrainsPre != "" || DrainsProc || DrainsPost != "") {
      if (DrainsPre == "")
        DrainsPre = "<div  class=\"d-flex  col-lg-3  \"> <span style=\"font-weight: 500;font-size:large;\">None</span></div>"
      if (DrainsProc == "")
        DrainsProc = "<div  class=\"d-flex  col-lg-3  \"> <span style=\"font-weight: 500;font-size:large;\">None</span></div>"
      if (DrainsPost == "")
        DrainsPost = "<div  class=\"d-flex  col-lg-3  \"> <span style=\"font-weight: 500;font-size:large;\">None</span></div>"
      Drains = DrainsPre + DrainsProc + DrainsPost;
      this.caseSummaryT += "<div class=\"form-row\">"
      this.caseSummaryT += "<div  class=\"d-flex  col-lg-3  \" style=\" font-size: large !important;font-weight : bold !important;text-transform: uppercase !important;\"> Drains</div>"
      this.caseSummaryT += Drains
      this.caseSummaryT += "</div>"
    }
    if (MechanicalSupportPre != "" || MechanicalSupportProc != "" || MechanicalSupportPost != "") {
      if (MechanicalSupportPre == "")
        MechanicalSupportPre = "<div  class=\"d-flex  col-lg-3  \"> <span style=\"font-weight: 500;font-size:large;\">None</span></div>"
      if (MechanicalSupportProc == "")
        MechanicalSupportProc = "<div  class=\"d-flex  col-lg-3  \"> <span style=\"font-weight: 500;font-size:large;\">None</span></div>"
      if (MechanicalSupportPost == "")
        MechanicalSupportPost = "<div  class=\"d-flex  col-lg-3  \"> <span style=\"font-weight: 500;font-size:large;\">None</span></div>"
      MechanicalSupport = MechanicalSupportPre + MechanicalSupportProc + MechanicalSupportPost
      this.caseSummaryT += "<div class=\"form-row\">"
      this.caseSummaryT += "<div  class=\"d-flex  col-lg-3  \" style=\" font-size: large !important;font-weight : bold !important;text-transform: uppercase !important;\"> Mechanical Support</div>"
      this.caseSummaryT += MechanicalSupport
      this.caseSummaryT += "</div>"
    }
    if (IVMedicationsPre != "" || IVMedicationsProc != "" || IVMedicationsPost != "") {
      if (IVMedicationsPre == "")
        IVMedicationsPre = "<div  class=\"d-flex  col-lg-3  \"> <span style=\"font-weight: 500;font-size:large;\">None</span></div>"
      if (IVMedicationsProc == "")
        IVMedicationsProc = "<div  class=\"d-flex  col-lg-3  \"> <span style=\"font-weight: 500;font-size:large;\">None</span></div>"
      if (IVMedicationsPost == "")
        IVMedicationsPost = "<div  class=\"d-flex  col-lg-3  \"> <span style=\"font-weight: 500;font-size:large;\">None</span></div>"
      IVMedications = IVMedicationsPre + IVMedicationsProc + IVMedicationsPost;
      this.caseSummaryT += "<div class=\"form-row\">"
      this.caseSummaryT += "<div  class=\"d-flex  col-lg-3  \" style=\" font-size: large !important;font-weight : bold !important;text-transform: uppercase !important;\"> IV Medications</div>"
      this.caseSummaryT += IVMedications
      this.caseSummaryT += "</div>"
    }
    if (Nursing != "") {

      this.caseSummaryT += "<div class=\"form-row\" style=\" margin-bottom:5px\">"
      this.caseSummaryT += "<div  class=\"d-flex  col-lg-3  \" style=\" font-size: large !important;font-weight : bold !important;text-transform: uppercase !important;\"> Nursing Assignment</div>"
      this.caseSummaryT += Nursing 
      this.caseSummaryT += "</div>"
    }
    this.containsEfficacy = (this.caseModel.CaseProcEfficacy.filter(x => x.EfficacyProcedureCode == "EFPRC01").length > 1 || this.caseModel.CaseProcEfficacy.filter(x => x.EfficacyProcedureCode == "EFPRC02").length > 1 || this.caseModel.CaseProcEfficacy.filter(x => x.EfficacyProcedureCode == "EFPRC03").length > 1 || this.caseModel.CaseProcEfficacy.filter(x => x.EfficacyProcedureCode == "EFPRC04").length > 1 || this.caseModel.CaseProcEfficacy.filter(x => x.EfficacyProcedureCode == "EFPRC05").length > 1);
   
    
    
    
    if (this.user.IsIqicUser()) {

    

      if (this.caseModel.CaseProcEfficacy.filter(x => x.EfficacyProcedureCode == "EFPRC01").length > 1) {
        this.efficacyCount++;
        let aorticProcedure, aorticRegurgPre, aorticRegurgPost, avGradPre, avGradPost, leftVentriclePre, leftVentriclePost: string;
        this.caseSummaryTI += "<div class=\"form-row\">"
        this.caseSummaryTI += "<div  class=\"d-flex  col-lg-12 sect-header\"></div>"
        this.caseSummaryTI += "</div>"

        var avProcedure = this.caseModel.CaseProcEfficacy.filter(x => x.EfficacyProcedureCode == "EFPRC01");

        for (let aortic in avProcedure) {
          if (avProcedure[aortic].EfficacyDataName == "AV Procedure indications") {
            aorticProcedure = "";
            if (avProcedure[aortic].EfficacyDataValue.indexOf("|") > 0) {
              let splitAV: Array<string> = [];
              splitAV = avProcedure[aortic].EfficacyDataValue.split("|");
              for (let av of splitAV) {
                if (aorticProcedure != "")
                  aorticProcedure += "<br/>" + this.dropDownNames.DataObject.find(x => x.ChoiceCode == av && x.PicklistName == "AV Procedure indications")["ChoiceText"];
                else
                  aorticProcedure += this.dropDownNames.DataObject.find(x => x.ChoiceCode == av && x.PicklistName == "AV Procedure indications")["ChoiceText"];
              }
            }
            else
              aorticProcedure = this.dropDownNames.DataObject.find(x => x.ChoiceCode == avProcedure[aortic].EfficacyDataValue && x.PicklistName == "AV Procedure indications")["ChoiceText"];
          }
          if (avProcedure[aortic].EfficacyDataName == "Aortic regurgitation Pre-Procedure")
            aorticRegurgPre = this.dropDownNames.DataObject.find(x => x.ChoiceCode == avProcedure[aortic].EfficacyDataValue && x.PicklistName == 'Aortic regurgitation')["ChoiceText"];
          if (avProcedure[aortic].EfficacyDataName == "Aortic regurgitation Post-Procedure")
            aorticRegurgPost = this.dropDownNames.DataObject.find(x => x.ChoiceCode == avProcedure[aortic].EfficacyDataValue && x.PicklistName == 'Aortic regurgitation')["ChoiceText"];
          if (avProcedure[aortic].EfficacyDataName == "Peak systolic AV gradient Pre-Procedure")
            avGradPre = this.dropDownNames.DataObject.find(x => x.ChoiceCode == avProcedure[aortic].EfficacyDataValue && x.PicklistName == "Peak systolic AV gradient")["ChoiceText"];
          if (this.caseModel.CaseProcEfficacy[aortic].EfficacyDataName == "Peak systolic AV gradient Post-Procedure")
            avGradPost = this.dropDownNames.DataObject.find(x => x.ChoiceCode == avProcedure[aortic].EfficacyDataValue && x.PicklistName == "Peak systolic AV gradient")["ChoiceText"];
          if (avProcedure[aortic].EfficacyDataName == "Pre Left Ventricle Dysfunction")
            leftVentriclePre = avProcedure[aortic].EfficacyDataValue == "1" ? 'Yes' : 'No';
          if (avProcedure[aortic].EfficacyDataName == "Post Left Ventricle Dysfunction")
            leftVentriclePost = avProcedure[aortic].EfficacyDataValue == "1" ? 'Yes' : 'No';

        }
        this.caseSummaryTI += "<div class=\"form-row\">"
        this.caseSummaryTI += "<div  class=\"d-flex  col-lg-12  \" style=\" font-size: large !important;font-weight : bold !important;text-transform: uppercase !important;\">Aortic Valvuloplasty</div>"
        this.caseSummaryTI += "</div>"
        this.caseSummaryTI += "<div class=\"form-row\">"
        this.caseSummaryTI += "<div class=\"d-flex   col-lg-3\" ><span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Procedure Indications:  </span></div>"
        this.caseSummaryTI += "<div  class=\"d-flex  col-lg-4  \"> <span style=\"font-weight: 500;font-size:large;text-align:start !important\">" + aorticProcedure + "</span></div>"
        this.caseSummaryTI += "</div>"
        this.caseSummaryTI += "<div class=\"form-row\">"
        this.caseSummaryTI += "<div class=\"d-flex   col-lg-3\" ></div>"
        this.caseSummaryTI += "<div class=\"d-flex   col-lg-4\" ><span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Pre Procedure </span></div>"
        this.caseSummaryTI += "<div class=\"d-flex   col-lg-4\" ><span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Post Procedure </span></div>"

        this.caseSummaryTI += "<div class=\"d-flex   col-lg-3\" ><span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Left Ventricle Dysfunction  </span></div>"
        this.caseSummaryTI += "<div  class=\"d-flex  col-lg-4  \"> <span style=\"font-weight: 500;font-size:large;\">" + leftVentriclePre + "</span></div>"
        this.caseSummaryTI += "<div  class=\"d-flex  col-lg-4  \"> <span style=\"font-weight: 500;font-size:large;\">" + leftVentriclePost + "</span></div>"

        this.caseSummaryTI += "<div class=\"d-flex   col-lg-3\" ><span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Aortic regurgitation </span></div>"

        this.caseSummaryTI += "<div  class=\"d-flex  col-lg-4  \"> <span style=\"font-weight: 500;font-size:large;\">" + aorticRegurgPre + "</span></div>"
        this.caseSummaryTI += "<div  class=\"d-flex  col-lg-4  \"> <span style=\"font-weight: 500;font-size:large;\">" + aorticRegurgPost + "</span></div>"

        this.caseSummaryTI += "<div class=\"d-flex   col-lg-3\" ><span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Peak systolic AV gradient  </span></div>"

        this.caseSummaryTI += "<div  class=\"d-flex  col-lg-4  \"> <span style=\"font-weight: 500;font-size:large;\">" + avGradPre + "</span></div>"
        this.caseSummaryTI += "<div  class=\"d-flex  col-lg-4  \"> <span style=\"font-weight: 500;font-size:large;\">" + avGradPost + "</span></div>"
        this.caseSummaryTI += "</div>"
      }
     
      if (this.caseModel.CaseProcEfficacy.filter(x => x.EfficacyProcedureCode == "EFPRC02").length > 1) {
        this.efficacyCount++;
        var pvProcedure = this.caseModel.CaseProcEfficacy.filter(x => x.EfficacyProcedureCode == "EFPRC02");
        let pulmProcedure, pulmValvePre = "<30mmHg", pulmValvePost = "<30mmHg", pulmRegurPre, pulmRegurPost, triValvePre, subObstru = "No", pulmValve = "Non Dysplastic", pulmArtery = "No", pulmIntervention = "No", triValvePost : string;
        this.caseSummaryTI += "<div class=\"form-row\">"
        this.caseSummaryTI += "<div  class=\"d-flex  col-lg-12 sect-header\"></div>"
        this.caseSummaryTI += "</div>"
        for (let pulm in pvProcedure) {
          if (pvProcedure[pulm].EfficacyDataName == "PV Procedure indications") {
            pulmProcedure = "";
            if (pvProcedure[pulm].EfficacyDataValue.indexOf("|") > 0) {
              let splitPU: Array<string> = [];
              splitPU = pvProcedure[pulm].EfficacyDataValue.split("|");
              for (let pu of splitPU) {
                if (pulmProcedure != "")
                  pulmProcedure += "<br/>" + this.dropDownNames.DataObject.find(x => x.ChoiceCode == pu && x.PicklistName == "PV Procedure indications ")["ChoiceText"];
                else
                  pulmProcedure += this.dropDownNames.DataObject.find(x => x.ChoiceCode == pu && x.PicklistName == "PV Procedure indications ")["ChoiceText"];
              }
            }
            else
              pulmProcedure = this.dropDownNames.DataObject.find(x => x.ChoiceCode == pvProcedure[pulm].EfficacyDataValue && x.PicklistName == "PV Procedure indications ")["ChoiceText"];
          }
          if (pvProcedure[pulm].EfficacyDataName == "Pulmonary regurgitation Pre-Procedure")
            pulmRegurPre = this.dropDownNames.DataObject.find(x => x.ChoiceCode == pvProcedure[pulm].EfficacyDataValue && x.PicklistName == 'Pulmonary regurgitation')["ChoiceText"];
          if (pvProcedure[pulm].EfficacyDataName == "Pulmonary regurgitation Post-Procedure")
            pulmRegurPost = this.dropDownNames.DataObject.find(x => x.ChoiceCode == pvProcedure[pulm].EfficacyDataValue && x.PicklistName == 'Pulmonary regurgitation')["ChoiceText"];
          if (pvProcedure[pulm].EfficacyDataName == "Tricuspid valve regurgitation Pre-Procedure")
            triValvePre = this.dropDownNames.DataObject.find(x => x.ChoiceCode == pvProcedure[pulm].EfficacyDataValue && x.PicklistName == 'Tricuspid valve regurgitation')["ChoiceText"];
          if (pvProcedure[pulm].EfficacyDataName == "Tricuspid valve regurgitation Post-Procedure")
            triValvePost = this.dropDownNames.DataObject.find(x => x.ChoiceCode == pvProcedure[pulm].EfficacyDataValue && x.PicklistName == 'Tricuspid valve regurgitation')["ChoiceText"];
          if (pvProcedure[pulm].EfficacyDataName == "Pre Pulmonary valve gradient")
            pulmValvePre = pvProcedure[pulm].EfficacyDataValue == "1" ? '>30mmHg' : '<30mmHg';
          if (pvProcedure[pulm].EfficacyDataName == "Post Pulmonary valve gradient")
            pulmValvePost = pvProcedure[pulm].EfficacyDataValue == "1" ? '>30mmHg' : '<30mmHg';
          if (pvProcedure[pulm].EfficacyDataName == "Subvalvar Obstruction")
            subObstru = pvProcedure[pulm].EfficacyDataValue == "1" ? 'Yes' : 'No';
          if (pvProcedure[pulm].EfficacyDataName == "Pulmonary Valve Type")
            pulmValve = pvProcedure[pulm].EfficacyDataValue == "1" ? 'Nondysplastic' : 'Dysplastic';
          if (pvProcedure[pulm].EfficacyDataName == "Any main pulmonary artery or right ventricular outflow tract tear")
            pulmArtery = pvProcedure[pulm].EfficacyDataValue == "1" ? 'Yes' : 'No';
          if (pvProcedure[pulm].EfficacyDataName == "Intervention performed")
            pulmIntervention = pvProcedure[pulm].EfficacyDataValue == "1" ? 'Yes' : 'No';
        }
        this.caseSummaryTI += "<div class=\"form-row\">"
        this.caseSummaryTI += "<div  class=\"d-flex  col-lg-12  \" style=\" font-size: large !important;font-weight : bold !important;text-transform: uppercase !important;\">Pulmonary Valvuloplasty</div>"
        this.caseSummaryTI += "</div>"
        this.caseSummaryTI += "<div class=\"form-row\">"
        this.caseSummaryTI += "<div class=\"d-flex   col-lg-3\" ><span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Procedure Indications:  </span></div>"
        this.caseSummaryTI += "<div  class=\"d-flex  col-lg-9  \"> <span style=\"font-weight: 500;font-size:large;text-align:start !important\">" + pulmProcedure + "</span></div>"

        this.caseSummaryTI += "<div  class=\"d-flex  col-lg-3  \"> <span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Subvalvar Obstruction:</span></div><div  class=\"d-flex  col-lg-9  \"><span style=\"font-weight: 400;font-size:large;\">" + subObstru + "</span></div>"
        this.caseSummaryTI += "<div  class=\"d-flex  col-lg-3  \"> <span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Pulmonary Valve Type:</span></div><div  class=\"d-flex  col-lg-9  \"><span style=\"font-weight: 400;font-size:large;\">" + pulmValve + "</span></div>"



        this.caseSummaryTI += "</div>"
        this.caseSummaryTI += "<div class=\"form-row\">"
        this.caseSummaryTI += "<div class=\"d-flex   col-lg-3\" ></div>"
        this.caseSummaryTI += "<div class=\"d-flex   col-lg-4\" ><span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Pre Procedure </span></div>"
        this.caseSummaryTI += "<div class=\"d-flex   col-lg-4\" ><span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Post Procedure </span></div>"

        this.caseSummaryTI += "<div class=\"d-flex   col-lg-3\" ><span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Pulmonary valve gradient  </span></div>"
        this.caseSummaryTI += "<div  class=\"d-flex  col-lg-4  \"> <span style=\"font-weight: 500;font-size:large;\">" + pulmValvePre + "</span></div>"
        this.caseSummaryTI += "<div  class=\"d-flex  col-lg-4  \"> <span style=\"font-weight: 500;font-size:large;\">" + pulmValvePost + "</span></div>"

        this.caseSummaryTI += "<div class=\"d-flex   col-lg-3\" ><span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Pulmonary regurgitation </span></div>"

        this.caseSummaryTI += "<div  class=\"d-flex  col-lg-4  \"> <span style=\"font-weight: 500;font-size:large;\">" + pulmRegurPre + "</span></div>"
        this.caseSummaryTI += "<div  class=\"d-flex  col-lg-4  \"> <span style=\"font-weight: 500;font-size:large;\">" + pulmRegurPost + "</span></div>"

        this.caseSummaryTI += "<div class=\"d-flex   col-lg-3\" ><span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Tricuspid valve regurgitation  </span></div>"
        this.caseSummaryTI += "<div  class=\"d-flex  col-lg-4  \"> <span style=\"font-weight: 500;font-size:large;\">" + triValvePre + "</span></div>"
        this.caseSummaryTI += "<div  class=\"d-flex  col-lg-4  \"> <span style=\"font-weight: 500;font-size:large;\">" + triValvePost + "</span></div>"
        this.caseSummaryTI += "</div>"
        this.caseSummaryTI += "<div class=\"form-row\">"
        this.caseSummaryTI += "<div  class=\"d-flex  col-lg-7  \"> <span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;text-align:start !important\">Any main pulmonary artery or right ventricular outflow tract tear:</span></div><div  class=\"d-flex  col-lg-4  \"><span style=\"font-weight: 500;font-size:large;\">" + pulmArtery + "</span></div>"
        if (pulmArtery == "Yes") {
          this.caseSummaryTI += "<div  class=\"d-flex  col-lg-3  \"> <span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Intervention performed</span></div><div  class=\"d-flex  col-lg-7  \"><span style=\"font-weight: 500;font-size:large;\">" + pulmIntervention + "</span></div>"
        }       
        this.caseSummaryTI += "</div>"

      }
     
      if (this.caseModel.CaseProcEfficacy.filter(x => x.EfficacyProcedureCode == "EFPRC03").length > 1) {
        this.efficacyCount++;
        var asdProcedure = this.caseModel.CaseProcEfficacy.filter(x => x.EfficacyProcedureCode == "EFPRC03");
        let asdProcedureIn, asdResidual = "No", asdMitral = "No", asdShunt : string;
        this.caseSummaryTI += "<div class=\"form-row\">"
        this.caseSummaryTI += "<div  class=\"d-flex  col-lg-12 sect-header\"></div>"
        this.caseSummaryTI += "</div>"
        for (let asd in asdProcedure) {
          if (asdProcedure[asd].EfficacyDataName == "ASDDC Procedure Indications") {
            asdProcedureIn = "";
            if (asdProcedure[asd].EfficacyDataValue.indexOf("|") > 0) {
              let splitASD: Array<string> = [];
              splitASD = asdProcedure[asd].EfficacyDataValue.split("|");
              for (let asdi of splitASD) {
                if (asdProcedureIn != "")
                  asdProcedureIn += "<br/>" + this.dropDownNames.DataObject.find(x => x.ChoiceCode == asdi && x.PicklistName == "ASDDC Procedure Indications")["ChoiceText"];
                else
                  asdProcedureIn += this.dropDownNames.DataObject.find(x => x.ChoiceCode == asdi && x.PicklistName == "ASDDC Procedure Indications")["ChoiceText"];
              }
            }
            else
              asdProcedureIn = this.dropDownNames.DataObject.find(x => x.ChoiceCode == asdProcedure[asd].EfficacyDataValue && x.PicklistName == "ASDDC Procedure Indications")["ChoiceText"];
          }
          if (asdProcedure[asd].EfficacyDataName == "ASD shunt Size")
            asdShunt = this.dropDownNames.DataObject.find(x => x.ChoiceCode == asdProcedure[asd].EfficacyDataValue && x.PicklistName == 'ASD shunt Size')["ChoiceText"];
          if (asdProcedure[asd].EfficacyDataName == "Residual shunt on echocardiogram")
            asdResidual = asdProcedure[asd].EfficacyDataValue == "1" ? 'Yes' : 'No';
          if (asdProcedure[asd].EfficacyDataName == "New or Changed mitral valve insufficiency")
            asdMitral = asdProcedure[asd].EfficacyDataValue == "1" ? 'Yes' : 'No';

        }
        this.caseSummaryTI += "<div class=\"form-row\">"
        this.caseSummaryTI += "<div  class=\"d-flex  col-lg-12  \" style=\" font-size: large !important;font-weight : bold !important;text-transform: uppercase !important;\">ASD Device Closure</div>"
        this.caseSummaryTI += "</div>"
        this.caseSummaryTI += "<div class=\"form-row\">"
        this.caseSummaryTI += "<div class=\"d-flex   col-lg-4\" ><span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Procedure Indications:  </span></div>"
        this.caseSummaryTI += "<div  class=\"d-flex  col-lg-8  \"> <span style=\"font-weight: 500;font-size:large;text-align:start !important\">" + asdProcedureIn + "</span></div>"


        this.caseSummaryTI += "<div  class=\"d-flex  col-lg-4  \"> <span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Residual shunt on echocardiogram:</span></div><div  class=\"d-flex  col-lg-8  \"><span style=\"font-weight: 500;font-size:large;\">" + asdResidual + "</span></div>"
        if (asdResidual == "Yes") {
          this.caseSummaryTI += "<div  class=\"d-flex  col-lg-4  \"> <span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Residual shunt size:</span></div><div  class=\"d-flex  col-lg-6  \"><span style=\"font-weight: 500;font-size:large;\">" + asdShunt + "</span></div>"
        }
        else {
          this.caseSummaryTI += "<div  class=\"d-flex  col-lg-7  \"> </div>"
        }
        this.caseSummaryTI += "<div  class=\"d-flex  col-lg-4  \"> <span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">New or Changed mitral valve insufficiency:</span></div><div  class=\"d-flex  col-lg-8  \"><span style=\"font-weight: 500;font-size:large;\">" + asdMitral + "</span></div>"

        this.caseSummaryTI += "</div>"
      }
      

      if (this.caseModel.CaseProcEfficacy.filter(x => x.EfficacyProcedureCode == "EFPRC04").length > 1) {
        this.efficacyCount++;
        var cpProcedure = this.caseModel.CaseProcEfficacy.filter(x => x.EfficacyProcedureCode == "EFPRC04");
        let cpProcedureIn, cpPostProc = "No", cpStent = "No", cpJailing = "No", cpCoarPre, cpCoarPost: string;
        this.caseSummaryTI += "<div class=\"form-row\">"
        this.caseSummaryTI += "<div  class=\"d-flex  col-lg-12 sect-header\"></div>"
        this.caseSummaryTI += "</div>"
        for (let cp in cpProcedure) {
          if (cpProcedure[cp].EfficacyDataName == "CP Procedure Indications") {
            cpProcedureIn = "";
            if (cpProcedure[cp].EfficacyDataValue.indexOf("|") > 0) {
              let splitCP: Array<string> = [];
              splitCP = cpProcedure[cp].EfficacyDataValue.split("|");
              for (let cpi of splitCP) {
                if (cpProcedureIn != "")
                  cpProcedureIn += "<br/>" + this.dropDownNames.DataObject.find(x => x.ChoiceCode == cpi && x.PicklistName == "CP Procedure Indications")["ChoiceText"];
                else
                  cpProcedureIn += this.dropDownNames.DataObject.find(x => x.ChoiceCode == cpi && x.PicklistName == "CP Procedure Indications")["ChoiceText"];
              }
            }
            else
              cpProcedureIn = this.dropDownNames.DataObject.find(x => x.ChoiceCode == cpProcedure[cp].EfficacyDataValue && x.PicklistName == "CP Procedure Indications")["ChoiceText"];
          }
          if (cpProcedure[cp].EfficacyDataName == "Peak systolic gradient at coarctation Pre-Procedure")
            cpCoarPre = this.dropDownNames.DataObject.find(x => x.ChoiceCode == cpProcedure[cp].EfficacyDataValue && x.PicklistName == 'Peak systolic gradient at coarctation')["ChoiceText"];
          if (cpProcedure[cp].EfficacyDataName == "Peak systolic gradient at coarctation Post-Procedure")
            cpCoarPost = this.dropDownNames.DataObject.find(x => x.ChoiceCode == cpProcedure[cp].EfficacyDataValue && x.PicklistName == 'Peak systolic gradient at coarctation')["ChoiceText"];
          if (cpProcedure[cp].EfficacyDataName == "Any aneurysm post procedure")
            cpPostProc = cpProcedure[cp].EfficacyDataValue == "1" ? 'Yes' : 'No';
          if (cpProcedure[cp].EfficacyDataName == "stent migration")
            cpStent = cpProcedure[cp].EfficacyDataValue == "1" ? 'Yes' : 'No';
          if (cpProcedure[cp].EfficacyDataName == "Jailing of head/neck vessels")
            cpJailing = cpProcedure[cp].EfficacyDataValue == "1" ? 'Yes' : 'No';

        }
        this.caseSummaryTI += "<div class=\"form-row\">"
        this.caseSummaryTI += "<div  class=\"d-flex  col-lg-12  \" style=\" font-size: large !important;font-weight : bold !important;text-transform: uppercase !important;\">Coarctation</div>"
        this.caseSummaryTI += "</div>"
        this.caseSummaryTI += "<div class=\"form-row\">"
        this.caseSummaryTI += "<div class=\"d-flex   col-lg-3\" ><span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Procedure Indications:  </span></div>"
        this.caseSummaryTI += "<div  class=\"d-flex  col-lg-9  \"> <span style=\"font-weight: 500;font-size:large;text-align:start !important\">" + cpProcedureIn + "</span></div>"

        this.caseSummaryTI += "<div  class=\"d-flex  col-lg-3  \"> <span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Any aneurysm post procedure:</span></div><div  class=\"d-flex  col-lg-9  \"><span style=\"font-weight: 500;font-size:large;\">" + cpPostProc + "</span></div>"
        this.caseSummaryTI += "<div  class=\"d-flex  col-lg-3  \"> <span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Stent migration:</span></div><div  class=\"d-flex  col-lg-9  \"><span style=\"font-weight: 500;font-size:large;\">" + cpStent + "</span></div>"
        this.caseSummaryTI += "<div  class=\"d-flex  col-lg-3  \"> <span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Jailing of head/Neck vessels:</span></div><div  class=\"d-flex  col-lg-9  \"><span style=\"font-weight: 500;font-size:large;\">" + cpJailing + "</span></div>"
        this.caseSummaryTI += "</div>"
        this.caseSummaryTI += "<div class=\"form-row\">"
        this.caseSummaryTI += "<div class=\"d-flex   col-lg-3\" ></div>"
        this.caseSummaryTI += "<div class=\"d-flex   col-lg-4\" ><span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Pre Procedure </span></div>"
        this.caseSummaryTI += "<div class=\"d-flex   col-lg-4\" ><span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Post Procedure </span></div>"

        this.caseSummaryTI += "<div class=\"d-flex   col-lg-3\" ><span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;text-align:start !important\">Peak systolic gradient at coarctation </span></div>"
        this.caseSummaryTI += "<div  class=\"d-flex  col-lg-4  \"> <span style=\"font-weight: 500;font-size:large;\">" + cpCoarPre + "</span></div>"
        this.caseSummaryTI += "<div  class=\"d-flex  col-lg-4  \"> <span style=\"font-weight: 500;font-size:large;\">" + cpCoarPost + "</span></div>"
        this.caseSummaryTI += "</div>"
      }
    
      if (this.caseModel.CaseProcEfficacy.filter(x => x.EfficacyProcedureCode == "EFPRC05").length > 1) {
        this.efficacyCount++;
        var pdaProcedure = this.caseModel.CaseProcEfficacy.filter(x => x.EfficacyProcedureCode == "EFPRC05");
        let pdaProcedureIn, pdaShunt = "No", pdaSizeShunt, pdaPulmonary = "No", pdaObstruction = "No", pdaHemolysis = "No", pdaDevice = "No", pdaReason: string, pdaSeccondReason: string;;
        this.caseSummaryTI += "<div class=\"form-row\">"
        this.caseSummaryTI += "<div  class=\"d-flex  col-lg-12 sect-header\"></div>"
        this.caseSummaryTI += "</div>"
        for (let pda in pdaProcedure) {
          if (pdaProcedure[pda].EfficacyDataName == "PDADC Procedure Indications") {
            pdaProcedureIn = "";
            if (pdaProcedure[pda].EfficacyDataValue.indexOf("|") > 0) {
              let splitPDA: Array<string> = [];
              splitPDA = pdaProcedure[pda].EfficacyDataValue.split("|");
              for (let pdai of splitPDA) {
                if (pdaProcedureIn != "")
                  pdaProcedureIn += "<br/>" + this.dropDownNames.DataObject.find(x => x.ChoiceCode == pdai && x.PicklistName == "PDADC Procedure Indications")["ChoiceText"];
                else
                  pdaProcedureIn += this.dropDownNames.DataObject.find(x => x.ChoiceCode == pdai && x.PicklistName == "PDADC Procedure Indications")["ChoiceText"];
              }
            }
            else
              pdaProcedureIn = this.dropDownNames.DataObject.find(x => x.ChoiceCode == pdaProcedure[pda].EfficacyDataValue && x.PicklistName == "PDADC Procedure Indications")["ChoiceText"];
          }
          if (pdaProcedure[pda].EfficacyDataName == "PDA Reason for device removal")
            pdaReason = this.dropDownNames.DataObject.find(x => x.ChoiceCode == pdaProcedure[pda].EfficacyDataValue && x.PicklistName == "PDA Reason for device removal")["ChoiceText"];
          if (pdaProcedure[pda].EfficacyDataName == "PDA Reason for second device removal")
            pdaSeccondReason = this.dropDownNames.DataObject.find(x => x.ChoiceCode == pdaProcedure[pda].EfficacyDataValue && x.PicklistName == "PDA Reason for second device removal")["ChoiceText"];
          if (pdaProcedure[pda].EfficacyDataName == "PDA shunt Size")
            pdaSizeShunt = this.dropDownNames.DataObject.find(x => x.ChoiceCode == pdaProcedure[pda].EfficacyDataValue && x.PicklistName == 'PDA shunt Size')["ChoiceText"];
          if (pdaProcedure[pda].EfficacyDataName == "Residual Shunt")
            pdaShunt = pdaProcedure[pda].EfficacyDataValue == "1" ? 'Yes' : 'No';
          if (pdaProcedure[pda].EfficacyDataName == "New left pulmonaryartery stenosis")
            pdaPulmonary = pdaProcedure[pda].EfficacyDataValue == "1" ? 'Yes' : 'No';
          if (pdaProcedure[pda].EfficacyDataName == "New aortic arch obstruction")
            pdaObstruction = pdaProcedure[pda].EfficacyDataValue == "1" ? 'Yes' : 'No';
          if (pdaProcedure[pda].EfficacyDataName == "Hemolysis")
            pdaHemolysis = pdaProcedure[pda].EfficacyDataValue == "1" ? 'Yes' : 'No';
          if (pdaProcedure[pda].EfficacyDataName == "Device removed from body")
            pdaDevice = pdaProcedure[pda].EfficacyDataValue == "1" ? 'Yes' : 'No';

        }
        this.caseSummaryTI += "<div class=\"form-row\">"
        this.caseSummaryTI += "<div  class=\"d-flex  col-lg-12  \" style=\" font-size: large !important;font-weight : bold !important;text-transform: uppercase !important;\">PDA Device Closure</div>"
        this.caseSummaryTI += "</div>"
        this.caseSummaryTI += "<div class=\"form-row\">"
        this.caseSummaryTI += "<div class=\"d-flex   col-lg-3\" ><span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Procedure Indications:</span></div>"
        this.caseSummaryTI += "<div  class=\"d-flex  col-lg-9  \"> <span style=\"font-weight: 500;font-size:large;text-align:start !important\">" + pdaProcedureIn + "</span></div>"



        this.caseSummaryTI += "<div  class=\"d-flex  col-lg-3  \"> <span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Residual Shunt:</span></div><div  class=\"d-flex  col-lg-1  \"><span style=\"font-weight: 500;font-size:large;\">" + pdaShunt + "</span></div>"
        if (pdaShunt == "Yes") {
          this.caseSummaryTI += "<div  class=\"d-flex  col-lg-3  \"> <span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Size of Shunt:</span></div><div  class=\"d-flex  col-lg-5  \"><span style=\"font-weight: 500;font-size:large;\">" + pdaSizeShunt + "</span></div>"
        }
        else {
          this.caseSummaryTI += "<div  class=\"d-flex  col-lg-8  \"></div>"
        }

        this.caseSummaryTI += "<div  class=\"d-flex  col-lg-3  \"> <span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Device removed from body:</span></div><div  class=\"d-flex  col-lg-1  \"><span style=\"font-weight: 500;font-size:large;\">" + pdaDevice + "</span></div>"
        if (pdaDevice == "Yes") {
          this.caseSummaryTI += "<div  class=\"d-flex  col-lg-3  \"> <span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Reason for device removal:</span></div><div  class=\"d-flex  col-lg-3  \"><span style=\"font-weight: 500;font-size:large;\">" + pdaReason + "</span></div>"
        }
        else {
          this.caseSummaryTI += "<div  class=\"d-flex  col-lg-8  \"></div>"
        }

        this.caseSummaryTI += "<div  class=\"d-flex  col-lg-3  \"> <span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;text-align:start !important\">New left pulmonary artery stenosis:</span></div><div  class=\"d-flex  col-lg-9  \"><span style=\"font-weight: 500;font-size:large;\">" + pdaPulmonary + "</span></div>"
        this.caseSummaryTI += "<div  class=\"d-flex  col-lg-3  \"> <span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">New aortic arch obstruction:</span></div><div  class=\"d-flex  col-lg-9  \"><span style=\"font-weight: 500;font-size:large;\">" + pdaObstruction + "</span></div>"
        this.caseSummaryTI += "<div  class=\"d-flex  col-lg-3  \"> <span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Hemolysis:</span></div><div  class=\"d-flex  col-lg-9  \"><span style=\"font-weight: 500;font-size:large;\">" + pdaHemolysis + "</span></div>"

        this.caseSummaryTI += "</div>"

      }     
    }
    if (this.efficacyCount < 2 || this.efficacyCount == 5) {
      this.caseSummaryT += "<div class=\"form-row\">"
      this.caseSummaryT += "<div  class=\"d-flex  col-lg-12 sect-header\"></div>"
      this.caseSummaryT += "</div>"
      this.caseSummaryT += "<div class=\"form-row\">"
      this.caseSummaryT += "<div  class=\"d-flex  col-lg-12  \" style=\" font-size: large !important;font-weight : bold !important;text-transform: uppercase !important;\">EOC Admit/Disposition</div>"
      this.caseSummaryT += "</div>"
      this.caseSummaryT += "<div class=\"form-row\">"
      this.caseSummaryT += "<div  class=\"d-flex  col-lg-3  \"> <span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Admission Source:</span></div><div  class=\"d-flex col-lg-3\"><span style=\"font-weight: 500;font-size:large;\">" + AdmissionSource + "</span></div>"
      this.caseSummaryT += "<div  class=\"d-flex  col-lg-3  \"> <span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Post-Cath Location:</span></div><div  class=\"d-flex col-lg-3\"><span style=\"font-weight: 500;font-size:large;\">" + PostCathLocation + "</span></div>"
      this.caseSummaryT += "<div  class=\"d-flex  col-lg-3  \"> <span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Admit>48 Hrs prior To Cath:</span></div><div  class=\"d-flex col-lg-3\"><span style=\"font-weight: 500;font-size:large;\">" + AdmitPriorCath + "</span></div>"
      this.caseSummaryT += "<div  class=\"d-flex  col-lg-3  \"> <span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Discharge > 48 Hrs Post-Cath:</span></div><div  class=\"d-flex col-lg-3\"><span style=\"font-weight: 500;font-size:large;\">" + DischargePostCath + "</span></div>"
      this.caseSummaryT += "<div  class=\"d-flex  col-lg-3  \"> <span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Unplanned Admission:</span></div><div  class=\"d-flex col-lg-3\"><span style=\"font-weight: 500;font-size:large;\">" + UnplannedAdmission + "</span></div>"
      this.caseSummaryT += "<div  class=\"d-flex  col-lg-3  \"> <span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Death < 72 Hrs Post-Cath:</span></div><div  class=\"d-flex col-lg-3\"><span style=\"font-weight: 500;font-size:large;\">" + DeathPostCath + "</span></div>"
      this.caseSummaryT += "</div>"
      this.caseSummaryT += "<div class=\"form-row\">"
      this.caseSummaryT += "</div>"
    }
    else
    {
      this.caseSummaryTII += "<div class=\"form-row\">"
      this.caseSummaryTII += "<div  class=\"d-flex  col-lg-12 sect-header\"></div>"
      this.caseSummaryTII += "</div>"
      this.caseSummaryTII += "<div class=\"form-row\">"
      this.caseSummaryTII += "<div  class=\"d-flex  col-lg-12  \" style=\" font-size: large !important;font-weight : bold !important;text-transform: uppercase !important;\">Episode Of Care</div>"
      this.caseSummaryTII += "</div>"
      this.caseSummaryTII += "<div class=\"form-row\">"
      this.caseSummaryTII += "<div  class=\"d-flex  col-lg-3  \"> <span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Admission Source:</span></div><div  class=\"d-flex col-lg-3\"><span style=\"font-weight: 500;font-size:large;\">" + AdmissionSource + "</span></div>"
      this.caseSummaryTII += "<div  class=\"d-flex  col-lg-3  \"> <span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Post-Cath Location:</span></div><div  class=\"d-flex col-lg-3\"><span style=\"font-weight: 500;font-size:large;\">" + PostCathLocation + "</span></div>"
      this.caseSummaryTII += "<div  class=\"d-flex  col-lg-3  \"> <span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Admit>48 Hrs prior To Cath:</span></div><div  class=\"d-flex col-lg-3\"><span style=\"font-weight: 500;font-size:large;\">" + AdmitPriorCath + "</span></div>"
      this.caseSummaryTII += "<div  class=\"d-flex  col-lg-3  \"> <span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Discharge > 48 Hrs Post-Cath:</span></div><div  class=\"d-flex col-lg-3\"><span style=\"font-weight: 500;font-size:large;\">" + DischargePostCath + "</span></div>"
      this.caseSummaryTII += "<div  class=\"d-flex  col-lg-3  \"> <span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Unplanned Admission:</span></div><div  class=\"d-flex col-lg-3\"><span style=\"font-weight: 500;font-size:large;\">" + UnplannedAdmission + "</span></div>"
      this.caseSummaryTII += "<div  class=\"d-flex  col-lg-3  \"> <span style=\" font-size: large !important;font-weight : bold !important;text-transform: capitalize !important;\">Death < 72 Hrs Post-Cath:</span></div><div  class=\"d-flex col-lg-3\"><span style=\"font-weight: 500;font-size:large;\">" + DeathPostCath + "</span></div>"
      this.caseSummaryTII += "</div>"
      this.caseSummaryTII += "<div class=\"form-row\">"
      this.caseSummaryTII += "</div>"
    }

    if (this.efficacyCount < 2) {
      this.caseSummaryT = this.caseSummaryT + this.caseSummaryTI;
    }
    else
    {
      this.caseSummaryTI = this.caseSummaryTII + this.caseSummaryTI;
    }
       


    
    

  }

  onPrint() {    
      
    let pdf = new jspdf('p', 'mm', 'a4');   
    let caseData = <HTMLElement>document.querySelector("#caseSummaryReport");    
    let caseId = caseData.innerText.split(':')[1].split('H')[0];
    const filename = 'CaseSummary_' + caseId.replace("_", " ") + '.pdf';
    

    html2canvas(document.querySelector('#caseSummaryReport')).then(canvas1 => {
  
      pdf.addImage(canvas1.toDataURL('image/jpeg'), 'JPEG', 0, 0, 211, 298);
      if (this.efficacyCount < 2) {
        pdf.save(filename);       
      }
     
    });
    if (this.containsEfficacy && this.user.IsIqicUser() && this.efficacyCount > 1) {
      
    
      html2canvas(document.querySelector("#caseSummaryReportI")).then(canvas2 => {
        
       
        pdf.addPage('a4');
        
        if (this.efficacyCount < 4) {
          pdf.addImage(canvas2.toDataURL('image/jpeg'), 'JPEG', 3, 3, 211, 198);
        }
        else
        {
          pdf.addImage(canvas2.toDataURL('image/jpeg'), 'JPEG', 0, 0, 211, 298);
        }
        pdf.save(filename);  
      });
     
    }
 
   
  }



  
  private convertAgeType(type: number): string {
    if (type === 0) {
      return 'Days';
    }
    if (type === 1) {
      return 'Months';
    }
    if (type === 2) {
      return 'Years';
    }
  }


  onPrintCancel() {
    this.showCaseSummary = false;
  }

  onImportCancel() {
    this.showImport = false;
    this.successMessage = '';
    this.errorMessage = '';
    this.labelValue = "Choose File";
   // this.errorMessage = 'Please enter at least one search criteria';
  }

  onImportUpload() {
   
    this.Spinner.show();  
    if (this.fileList.length > 0 && this.labelValue != "Choose File") {
      let file: File = this.fileList[0];
      let formData: FormData = new FormData();
      formData.append('uploadFile', file, file.name);
      let headers = new Headers()  
      headers.append('Accept', 'application/json');

      let options = new RequestOptions({ headers: headers });

      this.caseService.postXMLData(formData, options, this.user.user.HospitalId, this.user.user.UserId)
        .subscribe(
        data => this.validateServerResponse(data)
          
        )
    }
    this.Spinner.hide();  
  }

  getAdverseEventSeriousness( val) {    

    switch (val) {
      case "AES01": {
        return "1 - None";
        
      }
      case "AES02": {
        return "2 - Minor";
        
      }
      case "AES03": {
       return "3 - Moderate";
        
      }
      case "AES04": {
        return "4 - Major";
       
      }
      case "AES05": {
       return "5 - Catastrophic";
       
      }
    }
  }

  ImportData() {
    localStorage.removeItem("previousurl");
    localStorage.removeItem("lookup");
    this.showImport = true;
  }

  SearchCase() {
    localStorage.setItem("lookup", JSON.stringify(this.lookup));
    if ((this.lookup.CaseId == null || this.lookup.CaseId <= 0)
      && this.lookup.CathDateFrom == null
      && this.lookup.CathDateTo == null
      && this.lookup.AgeRange == ''
      && this.lookup.IncompleteDataOnly == false
      && this.lookup.CompleteDataOnly == false
      && this.lookup.PedcathCasesOnly == false
      && this.lookup.HighAE == false && this.lookup.MedAE == false && this.lookup.LowAE == false) {
      this.lookup.CaseId = null;
      this.errorMessage = "Please enter at least one search criteria";
      this.lookup.searchCaseSummarys = [];
    } else {
      this.errorMessage = "";
      this.lookup.searchCases().subscribe(data => this.validateSearch(data));
    }    
  }

  SearchClear() {
    this.lookup.searchClear();
    this.errorMessage = "Please enter at least one search criteria";
    $("#case-number").val('');
    $("#cath-date-from").val('');
    $("#cath-date-to").val('');
  }

  GetWeeklyDashBoard() {
    this.showWeeklyDashboard = true;
    this.weeklyList = [];
    this.lookup.getWeeklyDashBoard().subscribe(data => this.validateWeeklySummaryResponse(data));
  }

  CancelWeeklyDashBoard() {
    this.showWeeklyDashboard = false;
  }

  caseIdCheck() {
    if (!this.lookup.CaseId) {
      $("#case-number").val('');
    }
  }

  dateCheck() {
    if (this.lookup.CathDateFrom) {
      let millFrom = Date.parse(this.lookup.CathDateFrom.toString());
      if (!(millFrom > 0)) {
        this.lookup.CathDateFrom = null;
        $("#cath-date-from").val('');
      }
    } else {
      $("#cath-date-from").val('');
    }

    if (this.lookup.CathDateTo) {
      let millTo = Date.parse(this.lookup.CathDateTo.toString());
      if (!(millTo > 0)) {
        this.lookup.CathDateTo = null;
        $("#cath-date-to").val('');
      } else if (this.isfutureDate(this.lookup.CathDateTo)) {
        this.lookup.CathDateTo = new Date();
      }
    } else {
      $("#cath-date-to").val('');
    }

    if ((this.lookup.CathDateFrom && this.lookup.CathDateTo) && (this.lookup.CathDateFrom > this.lookup.CathDateTo)) {
      let newFrom = this.lookup.CathDateTo;
      let newTo = this.lookup.CathDateFrom;

      this.lookup.CathDateTo = newTo;
      this.lookup.CathDateFrom = newFrom;
    }
  }

  validateSearch(sr: SearchResponse) {
    if (sr.MessageType === "success") {
      this.p = 1;
      this.lookup.searchCaseSummarys = sr.DataObject;
      this.errorMessage = '';
      this.showImport = false;
    }
    else {
      this.errorMessage = sr.Message;
    }
  }

  validateWeeklySummaryResponse(sr: WeeklySummaryResponse) {
    if (sr.MessageType === "success") {
      this.p = 1;
      this.lookup.weeklySummary = sr.DataObject;
      var weekDayModel = new WeekDayModel();
      var oldIndex = -1;
      var OldWeekDayDetailsDiv = '';
      var weekendMultipleCase = false;
      var lastModelIndex = 0;

      this.lookup.weeklySummary.forEach((eachSummary, i) => {
        var headerDiv = '<div style="background-color: #BEBEBE;"><b style="font-size: 140%;">';
        var calendarDate = this.datepipe.transform(eachSummary.CalendarDate, 'MM/dd/yyyy') + '</div>';
        var statusColor = eachSummary.CompleteFlag == 'High'   ? 'red'
                        : eachSummary.CompleteFlag == 'Medium' ? '#ffc107'
                        : eachSummary.CompleteFlag == 'Low' ? 'green'
                        : eachSummary.CompleteFlag == '---' ? 'gray':'';
        var repeatWeekDayIndex = eachSummary.WeekDayIndex == oldIndex ? true : false;
        var status = statusColor != '' ?
          '<div class="col-12 col-lg-2"><div class="text-lg-right" style="padding-top:10px;padding-right:4%;"><i class="fas fa-circle" style="color: '
          + statusColor + '"></i></div></div>' : '';
        var weekDayDetailsDiv = '<div class="row form-row mt-4 d-flex flex-nowrap">' + status
          + '<div class="col-12 col-lg-10"><div class="text-lg-left">'
          + eachSummary.AgeAndCaseType + '</br><p style="color:#008fb3;font-style: italic;font-weight:800;">'
          + eachSummary.CaseTimeAndOperator + '</p></div></div></div>';
        var weekDayDetailsDiv = repeatWeekDayIndex ? OldWeekDayDetailsDiv + weekDayDetailsDiv : weekDayDetailsDiv;
        var bodyDiv = '</b></br>'+calendarDate + weekDayDetailsDiv;     

        if (eachSummary.WeekDayIndex == 1) {
          var dateObj = new Date(eachSummary.CalendarDate);
          var month = dateObj.getUTCMonth() + 1; //months from 1-12
          var day = dateObj.getUTCDate();
          weekDayModel.WeekDate = month + "/" + day;
        }
        weekDayModel.WeekIndex = eachSummary.WeekNo;
        weekDayModel.Monday = eachSummary.WeekDayIndex == 1 ? headerDiv + 'Monday' + bodyDiv : weekDayModel.Monday;
        weekDayModel.Tuesday = eachSummary.WeekDayIndex == 2 ? headerDiv + 'Tuesday' + bodyDiv : weekDayModel.Tuesday;
        weekDayModel.Wednesday = eachSummary.WeekDayIndex == 3 ? headerDiv + 'Wednesday' + bodyDiv: weekDayModel.Wednesday;
        weekDayModel.Thursday = eachSummary.WeekDayIndex == 4 ? headerDiv + 'Thursday' + bodyDiv : weekDayModel.Thursday;
        weekDayModel.Friday = eachSummary.WeekDayIndex == 5 ? headerDiv + 'Friday' + bodyDiv : weekDayModel.Friday;

        oldIndex = eachSummary.WeekDayIndex;
        OldWeekDayDetailsDiv = weekDayDetailsDiv;
        if (weekDayModel.WeekIndex == lastModelIndex && eachSummary.WeekDayIndex == 6) {
          weekendMultipleCase = true;
        }
        else {
          weekendMultipleCase = false;
        }
        if (eachSummary.WeekDayIndex == 6 && !weekendMultipleCase) {
          this.weeklyList.push(weekDayModel);
          lastModelIndex = weekDayModel.WeekIndex;
          weekDayModel = new WeekDayModel();
        }
      });

      this.errorMessage = '';
      this.showImport = false;
    }
    else {
      this.errorMessage = sr.Message;
    }
  }
  checkCathTime(date: string) {
    if (date.indexOf("T00:00:00Z") !== -1) {
      this.caseTimeResult = "--";
      return false;
    }
    else {
      let reT = "T";
      let reZ = "Z";
      this.caseTimeResult = date.replace(reT, " ");
      this.caseTimeResult = this.caseTimeResult.replace(reZ, " ");
      return true;
    }

  }

  validateServerResponse(sr: ServerResponse) {
    if (sr.MessageType === "Success") {
      this.p = 1;
      //  this.lookup.searchCaseSummarys = sr.DataObject;
      this.successMessage = sr.Message;
      this.errorMessage = '';
      //this.showImport = false;
    }
    else {
      this.successMessage = '';
      this.errorMessage = sr.Message;
    }   
  }

  deleteCaseSet(caseId: number) {
    this.caseService.deleteCaseId = caseId;
  }

  private isfutureDate(target: Date) {

    if (target.getFullYear() > this.setDate.getFullYear()) {
      return true;
    } else if (target.getFullYear() == this.setDate.getFullYear()) {
      if (target.getMonth() > this.setDate.getMonth()) {
        return true;
      } else if (target.getMonth() == this.setDate.getMonth()) {
        if (target.getDate() > this.setDate.getDate()) {
          return true;
        }
      }
    }

    return false;
  }

  private loadDuration(time1: string, time2: string): string {

    if (time1.length > 0 && time2.length > 0) {
      try {
        let time1Array = time1.split(":");
        let time1Hours = +time1Array[0]; 
        let time1Mins = +time1Array[1];
        let time1Full = (time1Hours * 60) + time1Mins;

        let time2Array = time2.split(":");
        let time2Hours = +time2Array[0];
        let time2Mins = +time2Array[1];
        let time2Full = (time2Hours * 60) + time2Mins;

        let timeMinutes = 0;      
        if (time1Full > time2Full) {
          time2Full = time2Full + 1440;
        }
        timeMinutes = (time2Full - time1Full);

        let hours = Math.floor(timeMinutes / 60);
        let minutes = timeMinutes % 60;

        var format = function (val) {
          return (val < 10) ? "0" + val : val;
        }
        var timeFormat = function (hr, min) {
          return hr + ':' + min;
        }

        let hourStr = format(hours);
        let minuteStr = format(minutes)
        return timeFormat(hourStr, minuteStr);
      } catch {
        return "";
      }
    }
    return "";
  }
}
